import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import App from "./app/App";
import {
    FluentProvider,
    Text,
    makeStyles,
    shorthands,
    Spinner,
    tokens,
    mergeClasses,
} from "@fluentui/react-components";
import { isDarkThemeInCache, matchTheme } from "./theme";
import { privateRoutes, router, routes } from "./router";
import { state } from "./state/stateAdapter";
import "./styles/index.scss";
import { useAuthorization } from "../application/useCases/useAuthorization";
import { User, hasUserAdminPermissions } from "../domain/user/user";
import { AirErrorKind } from "../application/airError/airError";
import { useStorage } from "../application/useCases/useStorage";
import { StatePort } from "./state/statePort";
import { useNotification } from "../application/useCases/useNotification";
import { NotifierAdapter } from "./notificationAdapter";
import { MiniAppKind } from "../domain/miniApp/miniApp";
import { Option } from "../domain/types/types";
import qs from "qs";
import { useDownloadLocation } from "../application/useCases/useDownloadLocation";
import { CrossTabId, CrossTabMessage } from "./models";
import { DownloadLocationCreateServiceAccountParams } from "../application/downloadLocation/downloadLocationPort";
import { initEventBusListeners } from "../application/eventHandlers/init";
import { createState } from "@persevie/statemanjs";
import { MainRouteListener } from "./router/routeListener";
import ShareAccess from "./ShareAccess/ShareAccess";

initEventBusListeners();

const modalNotifierAdapter = new NotifierAdapter(useStorage.notifications);

useNotification.init(modalNotifierAdapter);

state.theme.set(matchTheme(isDarkThemeInCache()));

const hasBeforeUnloadHandlerState = createState(false);

function unloadHandler(event: BeforeUnloadEvent): string {
    const upload = useStorage.uploaderMiniApp.upload.get();

    let message = `Close and cancel ${upload.uploading} ongoing`;

    if (upload.pending.size > 0) {
        message += `and ${upload.pending} queued upload jobs. Are you sure?`;
    }

    event.returnValue = message;
    return message;
}

useStorage.uploaderMiniApp.upload.subscribe(s => {
    if (
        (s.uploading.size > 0 || s.pending.size > 0) &&
        !hasBeforeUnloadHandlerState.get()
    ) {
        window.addEventListener("beforeunload", unloadHandler);
        hasBeforeUnloadHandlerState.set(true);
    } else {
        window.removeEventListener("beforeunload", unloadHandler);
        hasBeforeUnloadHandlerState.set(false);
    }
});

type ThemeWrapperProps = {
    children: JSX.Element;
};

const useStyles = makeStyles({
    loader: {
        position: "absolute",
        ...shorthands.margin("auto", "auto"),
        height: "100%",
        width: "100%",
    },
    root: { backgroundColor: tokens.colorNeutralBackground5 },
});

function handleRoute(pathname: string, user: Option<User>): void {
    if (pathname.startsWith(`${routes.sharingApp}/`)) {
        return;
    }

    if (pathname === "/") {
        router.redirect(router.routes.explorer);
    } else if (pathname === router.routes.signInHandler) {
        if (user !== null && user !== undefined) {
            router.redirect(router.routes.explorer);
        } else {
            useAuthorization.signIn();
        }
    } else if (privateRoutes.includes(pathname)) {
        if (user === null || user === undefined) {
            useAuthorization.authenticate();
            return;
        }

        if (
            pathname === routes.admin &&
            user !== null &&
            user !== undefined &&
            !hasUserAdminPermissions(user)
        ) {
            router.redirect(router.routes.explorer);
            return;
        }
    } else {
        router.redirect(router.routes.explorer);
    }
}

function getActiveMiniApp(pathname: string): MiniAppKind {
    switch (pathname) {
        case router.routes.explorer:
            return MiniAppKind.Explorer;

        case router.routes.collections:
            return MiniAppKind.Collections;

        case router.routes.admin:
            return MiniAppKind.Admin;

        case router.routes.addServiceAccountCallback:
            return MiniAppKind.Admin;

        case router.routes.uploader:
            return MiniAppKind.Uploader;

        case router.routes.jobs:
            return MiniAppKind.Jobs;

        default:
            return MiniAppKind.Explorer;
    }
}

class AppRouteChangeListener implements MainRouteListener {
    private readonly _statePort: StatePort;

    constructor(statePort: StatePort) {
        this._statePort = statePort;
    }

    onRouteChange(url: URL): void {
        state.pathname.set(url.pathname);
    }
}

const appRouteChangeListener = new AppRouteChangeListener(state);
router.routeListenersManager.init(appRouteChangeListener);

function ThemeWrapper(props: ThemeWrapperProps): JSX.Element {
    const theme = state.useState(state.theme);
    const classes = useStyles();

    return (
        <FluentProvider theme={theme.theme}>
            <div className={mergeClasses(classes.root, "app-wrapper")}>
                {props.children}
            </div>
        </FluentProvider>
    );
}

async function createAndSendServiceAccount(
    params: DownloadLocationCreateServiceAccountParams,
): Promise<void> {
    const sa = await useDownloadLocation.createServiceAccount(params);

    if (sa !== null && sa !== undefined) {
        const message: CrossTabMessage = {
            windowID: CrossTabId.AddServiceAccount,
            message: JSON.stringify(sa),
            isOk: true,
        };
        window.opener.postMessage(message, "*");
        window.close();
    } else {
        const message: CrossTabMessage = {
            windowID: CrossTabId.AddServiceAccount,
            message: null,
            isOk: false,
        };
        window.opener.postMessage(message, "*");
        window.close();
    }
}

function AddServiceAccount(): JSX.Element {
    const classes = useStyles();
    const [params, setParams] =
        useState<DownloadLocationCreateServiceAccountParams | null>(null);

    useEffect(() => {
        const query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        if (query.code && query.state) {
            setParams({
                code: query.code as string,
                state: query.state as string,
                redirectUri:
                    window.location.origin + routes.addServiceAccountCallback,
            });
        } else {
            const message: CrossTabMessage = {
                windowID: CrossTabId.AddServiceAccount,
                message: null,
                isOk: false,
            };
            window.opener.postMessage(message, "*");
            window.close();
        }
    }, []);

    useEffect(() => {
        if (params !== null) {
            createAndSendServiceAccount(params);
        }
    }, [params]);

    return (
        <ThemeWrapper>
            <Spinner
                className={classes.loader}
                label="Adding service account..."
            />
        </ThemeWrapper>
    );
}

function SignInHandler(): JSX.Element {
    const classes = useStyles();

    return (
        <ThemeWrapper>
            <Spinner className={classes.loader} label="Auth in progress..." />
        </ThemeWrapper>
    );
}

interface IndexProps {
    user: User;
    pathname: string;
}

function Index(props: IndexProps): JSX.Element {
    const activeMiniApp = getActiveMiniApp(props.pathname);

    useEffect(() => {
        useStorage.appCommon.activeMiniApp.set(activeMiniApp);
    }, [activeMiniApp]);

    return (
        <ThemeWrapper>
            <App user={props.user} />
        </ThemeWrapper>
    );
}

function RouteWrapper(): JSX.Element {
    const pathname = state.useState(state.pathname);
    const user = state.useState(useStorage.authorization.user);
    const errors = state.useState(useStorage.airErrors);

    const classes = useStyles();
    const error =
        errors.get(AirErrorKind.Authenticate) ??
        errors.get(AirErrorKind.SignIn) ??
        errors.get(AirErrorKind.SignOut) ??
        null;

    const isSignInCallback = pathname === router.routes.signInHandler;

    const isServiceAccountCallback =
        pathname === router.routes.addServiceAccountCallback;

    useEffect(() => {
        router.routeListenersManager.notify(router.url);
    }, []);

    useEffect(() => {
        handleRoute(pathname, user);
    }, [pathname, user]);

    if (error) {
        return (
            <ThemeWrapper>
                <div id="authError">
                    <Text as="h1" size={500}>
                        {error.toString()}
                    </Text>
                </div>
            </ThemeWrapper>
        );
    }

    if (isSignInCallback) {
        return <SignInHandler />;
    }

    if (isServiceAccountCallback) {
        return <AddServiceAccount />;
    }

    const shareUrl = pathname.startsWith(`${routes.sharingApp}/`)
        ? pathname
        : null;

    if (shareUrl !== null) {
        return (
            <ThemeWrapper>
                <ShareAccess shareUrl={shareUrl} />
            </ThemeWrapper>
        );
    }

    if (user !== null && user !== undefined) {
        return <Index user={user} pathname={pathname} />;
    }

    return (
        <ThemeWrapper>
            <Spinner className={classes.loader} label="Loading..." />
        </ThemeWrapper>
    );
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<RouteWrapper />);
