import React, { useEffect, useState } from "react";
import { state } from "../../state/stateAdapter";
import { makeStyles, mergeClasses, Button } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { tokens } from "@fluentui/react-theme";
import "./app-dialog-panel.scss";

const useStyles = makeStyles({
    panel: {
        backgroundColor: tokens.colorNeutralBackground3,
        boxShadow: tokens.shadow28,
    },
});

const useMountTransition = (
    isMounted: boolean,
    unmountDelay: number,
): boolean => {
    const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        let timeoutId: string | number | NodeJS.Timeout | undefined;

        if (isMounted && !hasTransitionedIn) {
            setHasTransitionedIn(true);
        } else if (!isMounted && hasTransitionedIn) {
            timeoutId = setTimeout(
                () => setHasTransitionedIn(false),
                unmountDelay,
            );
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [unmountDelay, isMounted, hasTransitionedIn]);

    return hasTransitionedIn;
};

function AppDialogPanel(): JSX.Element {
    const classes = useStyles();
    const appDialogPanel = state.useState(state.appDialogPanel);
    const isMounted = appDialogPanel !== null && appDialogPanel !== null;
    const hasTransitionedIn = useMountTransition(isMounted, 1000);

    function closeAppPanel(): void {
        state.appDialogPanel.set(null);
    }

    if (hasTransitionedIn || isMounted) {
        return (
            <div
                className={`app-dialog-panel ${
                    hasTransitionedIn && isMounted && "app-dialog-panel_open"
                }`}
            >
                <div
                    role="presentation"
                    className="app-dialog-panel__overlay"
                    onClick={closeAppPanel}
                />
                <div
                    className={mergeClasses(
                        classes.panel,
                        "app-dialog-panel__container",
                    )}
                >
                    <div className="app-dialog-panel__body">
                        {appDialogPanel !== null &&
                            appDialogPanel !== undefined &&
                            appDialogPanel()}
                    </div>

                    <Button
                        appearance="subtle"
                        aria-label="close"
                        icon={<Dismiss24Regular />}
                        onClick={closeAppPanel}
                        className="app-dialog-panel__close"
                    ></Button>
                </div>
            </div>
        );
    }

    return <></>;
}

export default AppDialogPanel;
