export enum DownloadLocationType {
    "Local" = "LocalDownloadLocation",
    "OneDrive" = "OneDriveDownloadLocation",
    "AzureFiles" = "AzureFilesDownloadLocation",
    "SharePoint" = "SharePointDownloadLocation",
}

export type DownloadLocationPermission = {
    key: string;
    value: string;
};

export enum DownloadLocationAuthorizationType {
    User = "user",
    Application = "application",
}

export type DownloadLocationLocal = {
    id: number;
    title: string;
    locationType: DownloadLocationType;
    bytesLimit: number;
    enabled: boolean;
    permissions: DownloadLocationPermission[];
};

export type DownloadLocationOneDrive = {
    basePath: string;
} & DownloadLocationLocal;

export type DownloadLocationSharePoint = {
    authorizationType: DownloadLocationAuthorizationType;
    serviceAccounts: DownloadLocationServiceAccount[];
} & DownloadLocationOneDrive;

export type DownloadLocationAzureFiles = {
    subscriptionId: string | null;
    resourceId: string | null;
    storageName: string | null;
    shareName: string | null;
    accessKey: string | null;
} & DownloadLocationOneDrive;

export type DownloadLocationServiceAccount = {
    id: number;
    userName: string;
    email: string;
    enabled: boolean;
};
