import React from "react";
import { state } from "../../state/stateAdapter";
import NotificationToast from "../NotificationToast/NotificationToast";
import { StorageItemPort } from "../../../application/storageItemPort";
import { NotificationsStorageValue } from "../../../application/storageValues";

type NotificationToastsContainerProps = {
    notificationsStorage: StorageItemPort<NotificationsStorageValue>;
    className?: string;
    onHide: (id: string) => void;
    onAutoHide?: (id: string) => void;
    above?: boolean;
};

function NotificationToastsContainer(
    props: NotificationToastsContainerProps,
): JSX.Element | null {
    const notifications = state.useState(props.notificationsStorage);

    if (notifications.size > 0) {
        const items = Array.from(notifications.values()).reverse();

        return (
            <div className={props.className}>
                {items.map(i => (
                    <React.Fragment key={i.id}>
                        <NotificationToast
                            element={i}
                            onHide={props.onHide}
                            autoHide={props.onAutoHide}
                            above={props.above}
                        />
                    </React.Fragment>
                ))}
            </div>
        );
    }

    return null;
}

export default NotificationToastsContainer;
