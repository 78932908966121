import { Session } from "../../domain/session/session";
import { User } from "../../domain/user/user";
import { router } from "../../ui/router";
import { AppCachePort } from "../../application/appCache/appCachePort";

export class CacheAdapter implements AppCachePort {
    private readonly userCacheKey = "air.user";
    private readonly sessionStateCacheKey = "air.session";
    private readonly authorizationStateCacheKey = "air.authorization.state";
    private readonly authorizationCodeCacheKey = "air.authorization.code";
    private readonly authorizationReturnUriCacheKey =
        "air.authorization.returnUri";

    private readonly cacheService = window.localStorage;

    setAuthorizationState(state: string): void {
        this.cacheService.setItem(this.authorizationStateCacheKey, state);
    }

    setAuthorizationCode(code: string): void {
        this.cacheService.setItem(this.authorizationCodeCacheKey, code);
    }

    getAuthorizationState(): string | null {
        return this.cacheService.getItem(this.authorizationStateCacheKey);
    }

    getAuthorizationCode(): string | null {
        return this.cacheService.getItem(this.authorizationCodeCacheKey);
    }

    setUser(user: User): void {
        this.cacheService.setItem(this.userCacheKey, JSON.stringify(user));
    }

    getUser(): User | null {
        const cached = this.cacheService.getItem(this.userCacheKey);

        if (cached === null) {
            return null;
        }

        return JSON.parse(cached);
    }

    setReturnUri(returnUri: string): void {
        if (returnUri.includes(router.routes.signInHandler)) {
            this.cacheService.setItem(
                this.authorizationReturnUriCacheKey,
                router.routes.explorer,
            );
        } else {
            this.cacheService.setItem(
                this.authorizationReturnUriCacheKey,
                returnUri,
            );
        }
    }

    getReturnUri(): string | null {
        const returnUri = this.cacheService.getItem(
            this.authorizationReturnUriCacheKey,
        );

        if (returnUri === null) {
            return returnUri;
        }

        return returnUri.includes(router.routes.signInHandler)
            ? router.routes.explorer
            : returnUri;
    }

    setSession(session: Session): void {
        this.cacheService.setItem(
            this.sessionStateCacheKey,
            JSON.stringify(session),
        );
    }

    getSession(): Session | null {
        const cached = this.cacheService.getItem(this.sessionStateCacheKey);

        if (cached === null) {
            return null;
        }

        return JSON.parse(cached);
    }

    clear(): void {
        this.cacheService.removeItem(this.userCacheKey);
        this.cacheService.removeItem(this.sessionStateCacheKey);
        this.cacheService.removeItem(this.authorizationStateCacheKey);
        this.cacheService.removeItem(this.authorizationCodeCacheKey);
        this.cacheService.removeItem(this.authorizationReturnUriCacheKey);
    }

    setData(key: string, data: string): void {
        this.cacheService.setItem(key, data);
    }

    getData(key: string): string | null {
        return this.cacheService.getItem(key);
    }

    removeData(key: string[]): void {
        key.forEach(k => this.cacheService.removeItem(k));
    }
}
