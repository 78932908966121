import React, { useEffect } from "react";
import {
    Button,
    Text,
    Caption1,
    Table,
    TableHeader,
    TableCellLayout,
    TableCellActions,
    TableColumnDefinition,
    createTableColumn,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
} from "@fluentui/react-components";
import { Add24Filled } from "@fluentui/react-icons";
import { IndexedLocation } from "../../../../../../application/indexedLocation/indexedLocation";
import { state } from "../../../../../state/stateAdapter";
import Loader from "../../../../../components/Loader/Loader";
import AdminLayout from "../../AdminLayout/AdminLayout";
import { useCommonFluentuiStyles } from "../../../../../styles/griffel";
import { useIndexedLocation } from "../../../../../../application/useCases/useIndexedLocation";
import { useStorage } from "../../../../../../application/useCases/useStorage";
import { router } from "../../../../../router";
import { protectedDataParamsKeys } from "../../../../../../domain/dataParams/dataParams";
import { EditIcon } from "../../../../../components/BundledIcons";

export enum ColumnKind {
    Location = "location",
    Container = "container",
    Storage = "storage",
}

export const columnSizes = new Map<string | number, number>([
    [ColumnKind.Location, 200],
    [ColumnKind.Container, 200],
    [ColumnKind.Storage, 200],
]);

const columns: TableColumnDefinition<IndexedLocation>[] = [
    createTableColumn<IndexedLocation>({
        columnId: ColumnKind.Location,
        renderHeaderCell: () => <Caption1>Location name</Caption1>,
        renderCell: item => {
            return (
                <>
                    <TableCellLayout className="cell-layout">
                        <Text truncate block wrap={false} title={item.title}>
                            {item.title}
                        </Text>
                    </TableCellLayout>
                    <TableCellActions>
                        <Button
                            icon={<EditIcon />}
                            appearance="subtle"
                            aria-label="Edit"
                            as="a"
                            href={router.createRoute({
                                search: {
                                    items: [
                                        {
                                            key: "id",
                                            value: item.id.toString(),
                                        },
                                    ],
                                    protect: [
                                        ...protectedDataParamsKeys,
                                        "activeMenu",
                                    ],
                                },
                            })}
                            data-route
                        />
                        {/*
                        TO DO: uncomment when delete location API will be designed
                        <Button
                            icon={<DeleteIcon />}
                            appearance="subtle"
                            aria-label="Delete"
                            onClick={(): void => {
                                onDelete(item);
                            }}
                        /> */}
                    </TableCellActions>
                </>
            );
        },
    }),
    createTableColumn<IndexedLocation>({
        columnId: ColumnKind.Container,
        renderHeaderCell: () => (
            <TableCellLayout>
                <Caption1>Container name</Caption1>
            </TableCellLayout>
        ),
        renderCell: item => (
            <Text truncate block wrap={false} title={item.containerName}>
                {item.containerName}
            </Text>
        ),
    }),
    createTableColumn<IndexedLocation>({
        columnId: ColumnKind.Storage,
        renderHeaderCell: () => (
            <TableCellLayout>
                <Caption1>Storage account</Caption1>
            </TableCellLayout>
        ),
        renderCell: item => (
            <Text truncate block wrap={false} title={item.storageName}>
                {item.storageName}
            </Text>
        ),
    }),
];

function IndexedLocationsList(): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();
    const indexedLocations = state.useState(
        useStorage.indexedLocation.indexedLocations,
    );
    const isIndexedLocationQueryInProgress = state.useState(
        useStorage.indexedLocation.isQueryInProgress,
    );

    useEffect(() => {
        if (useStorage.indexedLocation.indexedLocations.get().length === 0) {
            useIndexedLocation.fetchIndexedLocations();
        }
    }, []);

    if (isIndexedLocationQueryInProgress === true) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            title="Indexed Locations"
            buttons={[
                {
                    title: "Add indexed location",
                    as: "a",
                    href: router.createRoute({
                        search: {
                            items: [
                                {
                                    key: "id",
                                    value: "add",
                                },
                            ],
                            protect: [...protectedDataParamsKeys, "activeMenu"],
                        },
                    }),
                    dataRoute: true,
                    icon: <Add24Filled />,
                    appearance: "primary",
                },
            ]}
        >
            {indexedLocations.length === 0 ? (
                <div>
                    <Text>No Indexed Locations</Text>
                </div>
            ) : (
                <div className="table-container">
                    <Table className="table">
                        <TableHeader>
                            <TableRow>
                                {columns.map(i => (
                                    <TableHeaderCell
                                        key={i.columnId}
                                        style={
                                            columnSizes.has(i.columnId)
                                                ? {
                                                      width: `${columnSizes.get(
                                                          i.columnId,
                                                      )}px`,
                                                  }
                                                : undefined
                                        }
                                    >
                                        {i.renderHeaderCell()}
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {indexedLocations.map(item => (
                                <TableRow
                                    className={commonClasses.tableRow}
                                    key={item.id}
                                >
                                    {columns.map(i => (
                                        <TableCell key={i.columnId}>
                                            {i.renderCell(item)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
        </AdminLayout>
    );
}

export default IndexedLocationsList;
