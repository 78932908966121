import React from "react";
import { Skeleton, SkeletonItem } from "@fluentui/react-components";
import { TimelineDataItem } from "../Timeline";
import { state } from "../../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../../videoExtendedViewLocalState";

export default function TimelineKeyframes(props: {
    data?: TimelineDataItem[];
}): JSX.Element | null {
    const thumbnails = state.useState(videoExtendedLocalState.thumbnails);

    if (props.data === undefined) {
        return null;
    }

    return (
        <div className="timeline__keyframes">
            {props.data.map((i: TimelineDataItem) => {
                const timeCode = i.startTime;
                const hasThumbnail = thumbnails && thumbnails[timeCode];

                return (
                    <div className="timeline__keyframe" key={i.value}>
                        <div
                            className="timeline__keyframe-img"
                            title={`Keyframe #${i.value} ${timeCode} - ${i.endTime}`}
                            role="presentation"
                            style={
                                hasThumbnail
                                    ? {
                                          backgroundImage: `url(${thumbnails[timeCode]})`,
                                      }
                                    : {}
                            }
                        >
                            {!hasThumbnail ? (
                                <Skeleton className="skeleton">
                                    <SkeletonItem className="skeleton" />
                                </Skeleton>
                            ) : null}
                        </div>
                        <div className="timeline__keyframe-label">
                            Keyframe #{i.value}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
