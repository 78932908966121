import React from "react";
import {
    Avatar,
    AvatarProps,
    Tooltip,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
    people: {
        cursor: "pointer",
        marginLeft: "4px",
        "&::before": {
            ...shorthands.transition(
                " background, border, color",
                tokens.durationFaster,
                tokens.curveEasyEase,
            ),
        },
        ":hover::before": {
            color: tokens.colorBrandStroke1,
        },
    },
    peopleUnset: {
        "&::before": {
            color: "transparent",
        },
    },
});

type PeopleProps = {
    name: string | null;
    image?: string | null;
    isActive: boolean;
    onClick: () => void;
} & AvatarProps;

function People(props: PeopleProps): JSX.Element {
    const classes = useStyles();
    return (
        <Tooltip content={props.name} relationship="label" withArrow>
            <Avatar
                image={{ src: props.image ?? "" }}
                size={props.size || 36}
                onClick={props.onClick}
                name={props.name ?? undefined}
                active={"active"}
                className={mergeClasses(
                    classes.people,
                    "people",
                    props.isActive ? "" : classes.peopleUnset,
                )}
                color="colorful"
            />
        </Tooltip>
    );
}

export default People;
