import {
    SwitchOnChangeData,
    InputOnChangeData,
    Switch,
    Field,
    Input,
    InfoLabel,
} from "@fluentui/react-components";
import { Edit20Regular } from "@fluentui/react-icons";
import { minimatch } from "minimatch";
import React, { useCallback, useState } from "react";
import Form from "../../../../../components/Form/Form";
import { state } from "../../../../../state/stateAdapter";
import { uploaderSettingsState } from "../uploaderSettingsState";
import {
    ConfigKey,
    ConfigUploaderIgnoredPath,
    UploaderConfig,
} from "../../../../../../application/config/config";
import { useStorage } from "../../../../../../application/useCases/useStorage";
import { useAdmin } from "../../../../../../application/useCases/useAdmin";

type EditIgnoredPathProps = {
    item: ConfigUploaderIgnoredPath | null;
};

function EditIgnoredPath(props: EditIgnoredPathProps): JSX.Element {
    const [tempData, setTempData] = useState<ConfigUploaderIgnoredPath>(
        props.item === null
            ? {
                  label: "",
                  template: "",
                  isEnabled: false,
              }
            : {
                  ...props.item,
              },
    );
    const [highlightedText, setHighlightedText] = useState<React.ReactNode[]>(
        [],
    );

    const items = useStorage.config.config
        .get()
        .features.uploader.ignoredPaths.map(i => ({
            label: i.label,
            template: i.template,
        }));

    const isRequiredNotValid =
        tempData.template.length === 0 ||
        tempData.label.length === 0 ||
        items.some(
            i =>
                i.label === tempData.label &&
                (props.item === null || i.label !== props.item.label),
        );

    const isDisabled =
        isRequiredNotValid ||
        (props.item !== null &&
            props.item.template === tempData.template &&
            props.item.label === tempData.label &&
            props.item.isEnabled === tempData.isEnabled);

    const onChangeEnabled = useCallback(
        (
            _ev: React.ChangeEvent<HTMLInputElement>,
            data: SwitchOnChangeData,
        ): void => {
            setTempData({ ...tempData, isEnabled: data.checked });
        },
        [tempData],
    );

    const onChangeLabel = useCallback(
        (
            _ev: React.ChangeEvent<HTMLInputElement>,
            data: InputOnChangeData,
        ): void => {
            setTempData({ ...tempData, label: data.value });
        },
        [tempData],
    );

    const onChangeTemplate = useCallback(
        (
            _ev: React.ChangeEvent<HTMLInputElement>,
            data: InputOnChangeData,
        ): void => {
            setTempData({ ...tempData, template: data.value });
        },
        [tempData],
    );

    const highlightMatches = useCallback(
        (inputText: string): void => {
            const inputTextSplit = inputText.split(",");
            const results = minimatch.match(inputTextSplit, tempData.template, {
                dot: true,
                nocase: true,
            });

            setHighlightedText(
                inputTextSplit.map((part, index) => {
                    return (
                        <span key={index}>
                            {results.includes(encodeURIComponent(part)) ? (
                                <span className="highlight">{part}</span>
                            ) : (
                                <span>{part.length > 0 ? part : ","}</span>
                            )}

                            {part.length > 0 ? "," : ""}
                        </span>
                    );
                }),
            );
        },
        [tempData.template],
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>): void => {
        highlightMatches(e.currentTarget.textContent || "");
    };

    return (
        <Form
            buttons={[
                {
                    title: "Save changes",
                    onClick: (): void => {
                        const newSettings = state.unwrap(
                            useStorage.config.config,
                        ).features.uploader;

                        if (newSettings.ignoredPaths.length > 0) {
                            const itemIndex =
                                newSettings.ignoredPaths.findIndex(
                                    i =>
                                        i.label ===
                                        (props.item === null
                                            ? tempData.label
                                            : props.item.label),
                                );

                            if (itemIndex !== -1) {
                                newSettings.ignoredPaths[itemIndex].label =
                                    tempData.label;
                                newSettings.ignoredPaths[itemIndex].template =
                                    tempData.template;
                                newSettings.ignoredPaths[itemIndex].isEnabled =
                                    tempData.isEnabled;
                            } else {
                                newSettings.ignoredPaths.push(tempData);
                            }
                        } else {
                            newSettings.ignoredPaths = [tempData];
                        }

                        useAdmin
                            .updateConfigByKey<UploaderConfig>(
                                ConfigKey.Uploader,
                                newSettings,
                            )
                            .then(r => {
                                if (r) {
                                    useStorage.config.config.update(s => {
                                        if (s) {
                                            s.features.uploader = JSON.parse(
                                                r.propertiesString,
                                            );
                                        }
                                    });
                                }
                            });

                        const tempSettings =
                            uploaderSettingsState.tempUploaderSettings.get() ||
                            newSettings;
                        uploaderSettingsState.tempUploaderSettings.set({
                            ...tempSettings,
                            ignoredPaths: newSettings.ignoredPaths,
                        });
                        state.appDialogPanel.set(null);
                    },
                    appearance: "primary",
                    disabled: isDisabled,
                },
                {
                    title: "Cancel",
                    onClick: (): void => {
                        state.appDialogPanel.set(null);
                    },
                    appearance: "subtle",
                },
            ]}
            title="Edit ignored path"
            titleIcon={<Edit20Regular />}
        >
            <>
                <Switch
                    label={tempData.isEnabled ? "Enabled" : "Disabled"}
                    labelPosition="after"
                    checked={tempData.isEnabled}
                    onChange={onChangeEnabled}
                />

                <Field label="Label" required>
                    <Input
                        defaultValue={tempData.label}
                        onChange={onChangeLabel}
                    />
                </Field>

                <Field
                    required
                    label={
                        <InfoLabel info="The pattern or template to match file names, extensions, or paths. Supports regular expressions (RegEx)">
                            Template
                        </InfoLabel>
                    }
                >
                    <Input
                        defaultValue={tempData.template}
                        onChange={onChangeTemplate}
                    />
                </Field>

                <Field
                    label={
                        <InfoLabel info="Test your regex expression above, highlighted yellow is a positive match">
                            Test string
                        </InfoLabel>
                    }
                >
                    <div>
                        <div className="textarea-container">
                            <div
                                className="textarea"
                                contentEditable
                                onInput={handleInputChange}
                            />

                            <div className="textarea overlay">
                                {highlightedText}
                            </div>
                        </div>
                    </div>
                </Field>
            </>
        </Form>
    );
}

export default EditIgnoredPath;
