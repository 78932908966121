import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Input,
    InputProps,
    makeStyles,
    mergeClasses,
    tokens,
} from "@fluentui/react-components";
import React, { useEffect, useState, KeyboardEvent } from "react";
import AppPanel from "../../app/AppPanel/AppPanel";
import CommandBar from "../../components/CommandBar/CommandBar";
import ShareAccessFileExplorer from "./ShareAccessFileExplorer/ShareAccessFileExplorer";
import shareAccessState from "../shareAccessAppState";
import ShareAccessCommandBar from "./ShareAccessCommandBar/ShareAccessCommandBar";
import { useEventBus } from "../../../application/useCases/useEventBus";
import { ShareAccessEvents } from "../shareAccessEventBus";
import { state } from "../../state/stateAdapter";
import { useStorage } from "../../../application/useCases/useStorage";
import Loader from "../../components/Loader/Loader";
import { SharingGetSharingDetailsRes } from "../../../application/sharing/sharingPort";
import { useAuthorization } from "../../../application/useCases/useAuthorization";
import "./shareAccessLayout.scss";
import { useCache } from "../../../application/useCases/useCache";

const useStyles = makeStyles({
    appMain: {
        backgroundColor: tokens.colorNeutralBackground3,
    },
});

type LayoutProps = {
    sharingDetails: SharingGetSharingDetailsRes;
};

function Layout(props: LayoutProps): JSX.Element {
    const isAccessGranted = state.useState(useStorage.sharing.isAccessGranted);

    if (!isAccessGranted) {
        if (props.sharingDetails.password) {
            return <PasswordAccessLayout />;
        }

        if (props.sharingDetails.isDomainOnly) {
            return <MSEntryIDAccessLayout />;
        }

        return <PulicAccessLayout />;
    }

    return <AppLayout />;
}

function PulicAccessLayout(): JSX.Element {
    useEffect(() => {
        useEventBus.emit(ShareAccessEvents.CheckAccess, {});
    }, []);

    return (
        <div className="app_loading app_loading-abs">
            <Loader text="Loading..." />
        </div>
    );
}

function PasswordAccessLayout(): JSX.Element {
    const isGetFilesQueryInProgress = state.useState(
        useStorage.sharing.isGetFilesQueryInProgress,
    );

    const [tempPassword, setTempPassword] = useState<string>("");

    const onPasswordType: InputProps["onChange"] = (ev, data) => {
        setTempPassword(data.value);
    };

    const onEnter = (): void => {
        useStorage.sharing.password.set(tempPassword);
        setTempPassword("");
        useEventBus.emit(ShareAccessEvents.CheckAccess, {});
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === "Enter") {
            e.preventDefault();
            onEnter();
        }
    };

    return (
        <Dialog open={true}>
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                {isGetFilesQueryInProgress ? (
                    <div className="app-dialog__content">
                        <div className="app_loading">
                            <Loader text="Loading..." />
                        </div>
                    </div>
                ) : (
                    <DialogBody>
                        <DialogTitle>
                            Enter password to access the content
                        </DialogTitle>
                        <DialogContent>
                            <div className="app-dialog__content">
                                <Input
                                    disabled={isGetFilesQueryInProgress}
                                    value={tempPassword}
                                    type="password"
                                    placeholder="type password here"
                                    onChange={onPasswordType}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                disabled={tempPassword.length === 0}
                                onClick={onEnter}
                            >
                                Enter
                            </Button>
                        </DialogActions>
                    </DialogBody>
                )}
            </DialogSurface>
        </Dialog>
    );
}

const sessionKey = "session";

function MSEntryIDAccessLayout(): JSX.Element {
    const session = state.useState(useStorage.authorization.session);
    const [isInit, setIsInit] = useState(false);

    useEffect(() => {
        if (session === null || session === undefined) {
            if (useCache.getData(sessionKey) === null) {
                useCache.clear();
                useCache.setData(sessionKey, "");
            }

            useAuthorization.authenticate();
        } else {
            if (!isInit) {
                useCache.clear();
                useCache.removeData([sessionKey]);
                useEventBus.emit(ShareAccessEvents.CheckAccess, {
                    isMSEID: true,
                });
                setIsInit(true);
            }
        }
    }, [session, isInit]);

    return (
        <div className="app_loading app_loading-abs">
            <Loader text="Loading..." />
        </div>
    );
}

function AppLayout(): JSX.Element {
    const classes = useStyles();

    const filesToShow = state.useState(useStorage.sharing.filesToShow);
    const downloadLink = state.useState(useStorage.sharing.downloadLink);

    // INIT COMMANDBAR ACTIONS
    useEffect(() => {
        shareAccessState.commandBar.actionsFactory.set(() => (
            <ShareAccessCommandBar />
        ));

        return () => {
            shareAccessState.commandBar.actionsFactory.set(null);
        };
    }, []);

    useEffect(() => {
        if (downloadLink) {
            useEventBus.emit(ShareAccessEvents.DownloadByLink, {
                link: downloadLink,
            });
        }
    }, [downloadLink]);

    useEffect(() => {
        const activeItem = shareAccessState.activeItem.get();

        if (activeItem !== null && activeItem !== undefined) {
            useEventBus.emit(ShareAccessEvents.ChangeActiveItem, {
                activeItem,
            });
        }
    }, []);

    return (
        <main
            id="app__main"
            className={mergeClasses(
                classes.appMain,
                "app__main share-access-main-layout",
            )}
        >
            <CommandBar
                actionsFactoryState={shareAccessState.commandBar.actionsFactory}
            />

            <div className="app__main-content">
                <ShareAccessFileExplorer filesToShow={filesToShow} />
                <AppPanel
                    activeTabId={shareAccessState.panel.activeTabId}
                    isOpen={shareAccessState.panel.isOpen}
                    tabs={shareAccessState.panel.tabs}
                />
            </div>
        </main>
    );
}

export default Layout;
