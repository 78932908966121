import React, {
    useRef,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from "react";
import {
    Skeleton,
    SkeletonItem,
    Subtitle1,
    Text,
    makeStyles,
    tokens,
} from "@fluentui/react-components";
import { onJumpToTimecode, getSeconds } from "../../../utils";
import "./scenes.css";
import {
    SceneInsight,
    ShotInsight,
} from "../../../../../../application/cognitiveMetadata/cognitiveMetadataPort";
import { state } from "../../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../../videoExtendedViewLocalState";
import { useViewExtendedStyles } from "../../VideoExtendedView";

const useStyles = makeStyles({
    caption: {
        color: tokens.colorNeutralForeground4,
        width: "100%",
    },
    itemActive: {
        boxShadow: " 0 0 0 2px " + tokens.colorBrandBackground,
    },
    skeleton: {
        height: "100%",
    },
});

type ScenesProps = {
    src: string;
    scenes: SceneInsight[];
    shots: ShotInsight[];
    videoRef: React.RefObject<HTMLVideoElement>;
};

function getShots(
    scenes: SceneInsight[],
    shotsInsight: ShotInsight[],
): [string[], ShotInsight[][]] {
    const screenShotsTimeSet: Set<string> = new Set();
    const shotsBySceneStart: Map<string, ShotInsight[]> = new Map();

    scenes.forEach(scene => {
        screenShotsTimeSet.add(scene.appearances[0].startTime);
    });

    shotsInsight.forEach(shot => {
        const shotStart = shot.appearances[0].startTime;
        screenShotsTimeSet.add(shotStart);

        shot.keyFrames.forEach(k => {
            k.appearances.forEach(a => {
                screenShotsTimeSet.add(a.startTime);
            });
        });

        const scene = scenes.find(scene => {
            const sceneStartSeconds = getSeconds(
                scene.appearances[0].startTime,
            );
            const sceneEndSeconds = getSeconds(scene.appearances[0].endTime);
            const shotStartSeconds = getSeconds(shotStart);
            return (
                shotStartSeconds >= sceneStartSeconds &&
                shotStartSeconds <= sceneEndSeconds
            );
        });

        if (scene) {
            const sceneStart = scene.appearances[0].startTime;
            const shotsForScene = shotsBySceneStart.get(sceneStart) || [];
            shotsForScene.push(shot);
            shotsBySceneStart.set(sceneStart, shotsForScene);
        }
    });

    const shots: ShotInsight[][] = scenes.map(
        scene => shotsBySceneStart.get(scene.appearances[0].startTime) || [],
    );

    return [Array.from(screenShotsTimeSet), shots];
}

function Scenes(props: ScenesProps): JSX.Element | null {
    const classesViewExtended = useViewExtendedStyles();
    const classes = useStyles();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const thumbVideoRef = useRef<HTMLVideoElement>(null);
    const viewInsights = state.useState(videoExtendedLocalState.viewInsights);
    const thumbnails = state.useState(videoExtendedLocalState.thumbnails);
    const [metadataLoaded, setMetadataLoaded] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [suspended, setSuspended] = useState<boolean>(false);
    const [indexScreenShoot, setIndexScreenShoot] = useState<number>(0);

    const [activeScene, setActiveScene] = useState(0);
    const [activeShot, setActiveShot] = useState(0);
    const [activeKeyframe, setActiveKeyframe] = useState(0);

    const [screenShotsTimeArray, shots] = useMemo(
        () => getShots(props.scenes, props.shots),
        [props.scenes, props.shots],
    );

    const setVideoTime = useCallback((timeCode: string): void => {
        const video = thumbVideoRef.current;

        if (video) {
            video.currentTime = getSeconds(timeCode);
        }
    }, []);

    useEffect(() => {
        if (
            indexScreenShoot < screenShotsTimeArray.length &&
            metadataLoaded &&
            dataLoaded &&
            suspended
        ) {
            setVideoTime(screenShotsTimeArray[indexScreenShoot]);
        }
    }, [
        metadataLoaded,
        dataLoaded,
        suspended,
        screenShotsTimeArray,
        indexScreenShoot,
        shots,
        setVideoTime,
    ]);

    const updateScenes = useCallback(
        (
            e: React.MouseEvent<HTMLElement>,
            timeCode: string,
            sceneIndex: number,
            shotIndex: number,
            keyframeIndex: number,
        ): void => {
            setActiveScene(sceneIndex);
            setActiveShot(shotIndex);
            setActiveKeyframe(keyframeIndex);
            onJumpToTimecode(e, timeCode, props.videoRef);
        },
        [props.videoRef],
    );

    const renderItem = useCallback(
        (
            title: string,
            index: number,
            timeCode: string,
            activeItem: number,
            indexScene: number,
            indexShot: number,
            indexKeyframe: number,
        ): JSX.Element => {
            const hasThumbnail = thumbnails && thumbnails[timeCode];

            return (
                <div
                    title={title}
                    role="presentation"
                    className={`scenes__item ${
                        activeItem === index ? "scenes__item_active" : ""
                    }`}
                    key={`${title}-${index}-${timeCode}`}
                    onClick={(e): void => {
                        updateScenes(
                            e,
                            timeCode,
                            indexScene,
                            indexShot,
                            indexKeyframe,
                        );
                    }}
                    style={
                        hasThumbnail
                            ? {
                                  backgroundImage: `url(${thumbnails[timeCode]})`,
                              }
                            : {}
                    }
                >
                    {!hasThumbnail ? (
                        <Skeleton className={classes.skeleton}>
                            <SkeletonItem className={classes.skeleton} />
                        </Skeleton>
                    ) : null}
                </div>
            );
        },
        [classes.skeleton, thumbnails, updateScenes],
    );

    const getSnapshot = useCallback((): void => {
        const video = thumbVideoRef.current;
        const canvas = canvasRef.current;
        const time = screenShotsTimeArray[indexScreenShoot];

        if (canvas === null || video === null) {
            return;
        }

        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        const context = canvas.getContext("2d");

        if (context !== null) {
            context.drawImage(video, 0, 0);

            const thumbnail = canvas.toDataURL("image/png");

            videoExtendedLocalState.thumbnails.set({
                ...thumbnails,
                [time.toString()]: thumbnail,
            });

            setIndexScreenShoot(prevIndex => prevIndex + 1);
        }
    }, [indexScreenShoot, screenShotsTimeArray, thumbnails]);

    const renderScenes = useCallback(
        (items: SceneInsight[], activeItem: number, prefix: string) =>
            items.map((i, index) =>
                renderItem(
                    `${prefix} #${i.value}: ${i.appearances[0].startTime} - ${i.appearances[0].endTime}`,
                    index,
                    i.appearances[0].startTime,
                    activeItem,
                    index,
                    0,
                    0,
                ),
            ),
        [renderItem],
    );

    if (!viewInsights.has("scenes")) {
        return null;
    }

    return (
        <div className="scenes">
            <div className="scenes__canvas">
                <canvas ref={canvasRef}></canvas>
                <video
                    muted
                    controls
                    ref={thumbVideoRef}
                    src={props.src}
                    onLoadedMetadata={(): void => setMetadataLoaded(true)}
                    onLoadedData={(): void => setDataLoaded(true)}
                    onSuspend={(): void => setSuspended(true)}
                    onSeeked={getSnapshot}
                    crossOrigin="anonymous"
                />
            </div>
            <Subtitle1 className={classesViewExtended.title}>
                {props.scenes.length} Scenes
            </Subtitle1>
            <div className="scenes__container">
                {renderScenes(props.scenes, activeScene, "Scene")}
            </div>

            {shots &&
            shots.length > 0 &&
            shots[activeScene] &&
            shots[activeScene][activeShot] ? (
                <>
                    <div className="scenes__container">
                        <Text className={classes.caption}>
                            {shots[activeScene].length} shots in this scene
                        </Text>
                        {shots[activeScene].map((i, index) =>
                            renderItem(
                                `Shot: #${i.value} ${i.appearances[0].startTime} - ${i.appearances[0].endTime}`,
                                index,
                                i.appearances[0].startTime,
                                activeShot,
                                activeScene,
                                index,
                                0,
                            ),
                        )}
                    </div>

                    {shots[activeScene][activeShot].keyFrames !== undefined ? (
                        <div className="scenes__container">
                            <Text className={classes.caption}>
                                {
                                    shots[activeScene][activeShot].keyFrames
                                        .length
                                }{" "}
                                keyframes in this shot
                            </Text>
                            {shots[activeScene][activeShot].keyFrames.map(
                                (i, index) =>
                                    renderItem(
                                        `KeyFrame: #${i.value} ${i.appearances[0].startTime} - ${i.appearances[0].endTime}`,
                                        index,
                                        i.appearances[0].startTime,
                                        activeKeyframe,
                                        activeScene,
                                        activeShot,
                                        index,
                                    ),
                            )}
                        </div>
                    ) : null}
                </>
            ) : null}
        </div>
    );
}

export default Scenes;
