import { Facet } from "../../domain/facet/facet";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import { FacetsPort } from "./facetsPort";
import { AirErrorService } from "../airError/airErrorService";
import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";

export class FacetService {
    private readonly _facetPort: FacetsPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        facetPort: FacetsPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._facetPort = facetPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    async fetch(): Promise<Option<Facet[]>> {
        try {
            this._errorService.clear(AirErrorKind.FetchFacets);
            this._storagePort.facets.isQueryInProgress.set(true);
            const facets = await this._facetPort.fetch();

            this._storagePort.facets.facets.set(facets);
            this._storagePort.facets.isQueryInProgress.set(false);

            return this._storagePort.facets.facets.get();
        } catch (error) {
            this._storagePort.facets.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchFacets,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}
