import { Field, makeStyles } from "@fluentui/react-components";
import React, { useCallback, useEffect, useState } from "react";
import Form from "../Form/Form";
import ComboboxWithTags from "../Form/ComboboxWithTags/ComboboxWithTags";
import { useFiles } from "../../../application/useCases/useFiles";
import { useStorage } from "../../../application/useCases/useStorage";
import { TagKind, Tag, TagGroup } from "../../../domain/airFile/details";
import { state } from "../../state/stateAdapter";
import Loader from "../Loader/Loader";

const useStyles = makeStyles({
    field: {
        "& > label": {
            fontWeight: 600,
        },
    },
});

function sortTags(tags: Tag[]): Tag[] {
    const orderTagSource = [TagKind.AI.toString(), TagKind.User.toString()];

    return tags
        .sort((a: Tag, b: Tag) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        })
        .sort((a: Tag, b: Tag) => {
            return (
                orderTagSource.indexOf(a.tagSource) -
                orderTagSource.indexOf(b.tagSource)
            );
        });
}

function getInitData(tagGroups: TagGroup[] | null): Map<number, Tag[]> {
    const tags = new Map();

    if (tagGroups !== null) {
        tagGroups.forEach(i => {
            if (i.tags !== undefined && i.tags != null) {
                tags.set(i.id, sortTags(i.tags));
            }
        });
    }

    return tags;
}

function areMapsEqual(
    map1: Map<number, Tag[]>,
    map2: Map<number, Tag[]>,
): boolean {
    if (map1.size !== map2.size) {
        return false;
    }

    for (const [key, value1] of map1) {
        if (!map2.has(key)) {
            return false;
        }

        const value2 = map2.get(key);

        if (Array.isArray(value1) && Array.isArray(value2)) {
            if (
                !areArraysEqual(
                    value1.map(i => i.name),
                    value2.map(i => i.name),
                )
            ) {
                return false;
            }
        } else if (value1 !== value2) {
            return false;
        }
    }

    return true;
}

function areArraysEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

type EditTagsPanelProps = {
    title: string;
    subTitle?: string;
    titleIcon?: JSX.Element;
    onClose: () => void;
    onSave: (
        data: {
            groupId: number;
            tags: string[];
        }[],
    ) => void;
    initData: TagGroup[];
};

function EditTagsForm(props: EditTagsPanelProps): JSX.Element | null {
    const classes = useStyles();
    const tagGroups = state.useState(useStorage.files.tagGroups);
    const isTagGroupsQueryInProgress = state.useState(
        useStorage.files.isTagGroupsQueryInProgress,
    );
    const initData = getInitData(props.initData);
    const [tempData, setTempData] = useState(initData);

    useEffect(() => {
        useFiles.getTagGroups();
    }, []);

    const saveChanges = useCallback(async (): Promise<void> => {
        if (tagGroups !== undefined && tagGroups !== null) {
            const dataTags = tagGroups.map(i => ({
                groupId: i.id,
                tags: tempData.get(i.id)?.map(i => i.name) || [],
            }));
            props.onSave(dataTags);
        }
        props.onClose();
    }, [tempData, props, tagGroups]);

    if (isTagGroupsQueryInProgress) {
        return <Loader text="Loading..." />;
    }

    return (
        <Form
            buttons={[
                {
                    title: "Save",
                    onClick: saveChanges,
                    appearance: "primary",
                    disabled: areMapsEqual(initData, tempData),
                },
                {
                    title: "Cancel",
                    onClick: props.onClose,
                    appearance: "subtle",
                },
            ]}
            subTitle={props.subTitle}
            title={props.title}
            titleIcon={props.titleIcon}
        >
            <>
                {tagGroups &&
                    tagGroups.map(i => (
                        <Field
                            key={i.id}
                            label={i.name}
                            className={classes.field}
                        >
                            <ComboboxWithTags
                                tagGroup={i}
                                data={tempData.get(i.id) ?? []}
                                onChangeData={(tags: Tag[]): void => {
                                    if (tags.length === 0) {
                                        tempData.delete(i.id);
                                    } else {
                                        tempData.set(i.id, tags);
                                    }
                                    setTempData(new Map([...tempData]));
                                }}
                                placeholder={`Search tags for ${i.name}`}
                            />
                        </Field>
                    ))}
            </>
        </Form>
    );
}

export default EditTagsForm;
