import { UploaderPort } from "../../application/uploader/ports";
import { UploadLocation } from "../../domain/uploadLocation/uploadLocation";
import { apiCall, validateRes } from "../api/api";

export class UploaderAdapter implements UploaderPort {
    async fetchLocations(): Promise<UploadLocation[]> {
        const res = await apiCall("/api/upload/locations", {
            method: "GET",
        });

        return await validateRes(res);
    }
}
