import { createState } from "@persevie/statemanjs";
import { StorageItemPort } from "../../../../application/storageItemPort";
import { CollectionInfo } from "../../../../domain/collection/collections";
import { AirFile } from "../../../../domain/airFile/airFile";

interface CollectionsLocalStatePort {
    newCollectionInfoName: StorageItemPort<string | null>;

    newCollectionInfoStatus: StorageItemPort<"private" | "public">;

    transition: StorageItemPort<boolean>;

    breadcrumbsPath: StorageItemPort<AirFile[]>;

    selectedCollection: StorageItemPort<CollectionInfo | null>;
}

const newCollectionInfoNameState = createState<string | null>(null);

const newCollectionInfoStatusState = createState<"private" | "public">(
    "private",
);

const transitionState = createState<boolean>(false);

const breadcrumbsPathState = createState<AirFile[]>([]);

const selectedCollectionState = createState<CollectionInfo | null>(null);

class CollectionsLocalStateAdapter implements CollectionsLocalStatePort {
    readonly newCollectionInfoName: StorageItemPort<string | null> =
        newCollectionInfoNameState;

    readonly newCollectionInfoStatus: StorageItemPort<"private" | "public"> =
        newCollectionInfoStatusState;

    readonly transition: StorageItemPort<boolean> = transitionState;

    readonly breadcrumbsPath: StorageItemPort<AirFile[]> = breadcrumbsPathState;

    readonly selectedCollection: StorageItemPort<CollectionInfo | null> =
        selectedCollectionState;
}

export const collectionsLocalState = new CollectionsLocalStateAdapter();
