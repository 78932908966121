import React, { useCallback, useState } from "react";
import {
    TagsAddGroupParams,
    TagsGroup,
} from "../../../../../../application/tags/TagsPort";
import { useCommonFluentuiStyles } from "../../../../../styles/griffel";
import {
    Field,
    Input,
    Switch,
    makeStyles,
    Text,
    Subtitle2,
    mergeClasses,
    tokens,
} from "@fluentui/react-components";
import { SaveRegular, TagMultiple24Regular } from "@fluentui/react-icons";
import { useTags } from "../../../../../../application/useCases/useTags";
import Form from "../../../../../components/Form/Form";
import { state } from "../../../../../state/stateAdapter";
import { useStorage } from "../../../../../../application/useCases/useStorage";

const useStyles = makeStyles({
    form: {
        maxWidth: "410px",
    },
    swithcField: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    labelDisabled: {
        color: tokens.colorNeutralStrokeAccessible,
    },
});

type TagsGroupsFormPanelProps = {
    item?: TagsGroup;
    onClose: () => void;
    onApply?: (data: TagsGroup) => void;
};

function isEqual(data: unknown | null, initData: unknown | null): boolean {
    if (
        (data === null && initData === null) ||
        (data === undefined && initData === undefined)
    ) {
        return true;
    }
    return JSON.stringify(data) === JSON.stringify(initData);
}

export function RendedSwitchField(props: {
    value: boolean;
    name: string;
    title: string;
    text: string;
    updateData: (
        name: string,
        value: string | number | undefined | boolean,
    ) => void;
}): JSX.Element {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.swithcField}>
                <Subtitle2
                    wrap={false}
                    className={props.value ? "" : classes.labelDisabled}
                >
                    {props.title}
                </Subtitle2>
                <Switch
                    checked={props.value}
                    onChange={(_e, data): void => {
                        props.updateData(props.name, data.checked);
                    }}
                />
            </div>
            <Text className={props.value ? "" : classes.labelDisabled}>
                {props.text}
            </Text>
        </div>
    );
}

const initData = {
    id: undefined,
    name: "",
    numberOfTags: undefined,
    isVisibleInPanel: true,
    // isVisibleInEdit: boolean;
    isSearchable: true,
};

function TagsGroupsFormPanel(props: TagsGroupsFormPanelProps): JSX.Element {
    const classes = useStyles();
    const [data, setData] = useState(props.item ?? initData);
    const commonClasses = useCommonFluentuiStyles();
    const isQueryInProgress = state.useState(useStorage.tags.isQueryInProgress);
    const isFormValid = data && data.name.length > 0;

    const saveChanges = useCallback(async () => {
        if (isFormValid && data !== undefined) {
            if (data.id) {
                await useTags.editGroup(data);
            } else {
                await useTags.addGroup(data as TagsAddGroupParams);
            }

            if (useStorage.airErrors.get().size === 0) {
                props.onApply && props.onApply(data as TagsGroup);
                props.onClose();
            }
        }
    }, [data, isFormValid, props]);

    const updateData = useCallback(
        (name: string, value: string | number | undefined | boolean): void => {
            if (data !== undefined && data[name as keyof TagsGroup] !== value) {
                setData({ ...data, [name]: value });
            }
        },
        [data],
    );

    return (
        <Form
            title={data.id !== undefined ? data.name : "Add new tags group"}
            titleIcon={<TagMultiple24Regular />}
            subTitle={
                data.numberOfTags ? `${data.numberOfTags} tags` : undefined
            }
            buttons={[
                {
                    title: "Save changes",
                    onClick: saveChanges,
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled:
                        isQueryInProgress ||
                        !isFormValid ||
                        isEqual(props.item, data),
                },
                {
                    title: "Cancel",
                    onClick: props.onClose,
                    appearance: "subtle",
                },
            ]}
        >
            <div className={mergeClasses(classes.form, "admin__form")}>
                <Field
                    className={commonClasses.field}
                    label="Tags group name"
                    required
                >
                    <Input
                        size="large"
                        value={data.name}
                        onChange={(_e, d): void => {
                            updateData("name", d.value);
                        }}
                    />
                </Field>
                <RendedSwitchField
                    value={data.isVisibleInPanel}
                    name="isVisibleInPanel"
                    updateData={updateData}
                    title="Visible in Tags Panel"
                    text="Makes the tag group / tags visible in the side detail panel when user is browsing the files."
                />
                {/* <RendedSwitchField
                    value={data.isVisibleInEdit}
                    name="isVisibleInEdit"
                    updateData={updateData}
                    title="Visible in Tags Edit"
                    text="Makes the tags visible in Tags Panel when user is browsing the files and the media details are visible."
                /> */}
                <RendedSwitchField
                    value={data?.isSearchable}
                    name="isSearchable"
                    updateData={updateData}
                    title="Visible in Search Criteria"
                    text="Makes the tags visible in filter panel and is searchable."
                />
            </div>
        </Form>
    );
}

export default TagsGroupsFormPanel;
