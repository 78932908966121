import { useNotification } from "../../../application/useCases/useNotification";
import { useStorage } from "../../../application/useCases/useStorage";
import { useTimeFormatter } from "../../../application/useCases/useTimeFormatter";
import { MiniAppKind } from "../../../domain/miniApp/miniApp";
import { DateFormat } from "../../../domain/timeFormatter/timeFormatter";
import { router, routes } from "../../router";
import { state } from "../../state/stateAdapter";

export const NEW_COMPLETED_JOBS_NOTIFICATION_ID = "newCompletedJobs";

export function notifyUserAboutNewCompletedJobs(
    newCompletedJobsCount: number,
): void {
    if (newCompletedJobsCount > 0) {
        const notificationElement = useNotification.create(
            `You have ${newCompletedJobsCount} new completed jobs`,
            "info",
            true,
            [
                {
                    as: "link",
                    label: "View",
                    onClick: (onClose: () => void): void => {
                        router.goTo({ page: routes.jobs });

                        if (
                            useStorage.appCommon.activeMiniApp.get() ===
                            MiniAppKind.Jobs
                        ) {
                            onClose();
                        }
                    },
                },
            ],
            NEW_COMPLETED_JOBS_NOTIFICATION_ID,
        );

        state.drawerNotifications.update(s => {
            s.set(notificationElement.id, notificationElement);
        });
    } else {
        if (
            state.drawerNotifications
                .get()
                .has(NEW_COMPLETED_JOBS_NOTIFICATION_ID)
        ) {
            state.drawerNotifications.update(s => {
                s.delete(NEW_COMPLETED_JOBS_NOTIFICATION_ID);
            });
        }
    }
}

export function updateLastSync(): void {
    state.jobsMiniApp.lastSyncState.set(
        useTimeFormatter.formatDate(
            Date.now(),
            DateFormat.ShortTimeWithSeconds,
        ),
    );
}
