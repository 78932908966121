import { UserFileUpload } from "../../domain/userFileUpload/userFileUpload";
import { UserFileUploadRepository } from "../../domain/userFileUpload/userFileUploadRepository";
import { apiCall, handleNotOk } from "../api/api";
import { FileChunk } from "../../domain/fileChunk/fileChunk";
import { UserFileMetadata } from "../../domain/userFile/userFile";

export class UserFileUploadRepositoryImpl implements UserFileUploadRepository {
    async createFile(userFileUpload: UserFileUpload): Promise<void> {
        try {
            const payload = {
                leaseId: userFileUpload.leaseId,
                filePath: encodeURIComponent(userFileUpload.path),
                fileSize: userFileUpload.userFile.file.size,
            };

            const res = await apiCall(
                `/api/upload/files/create/${userFileUpload.location.id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    body: JSON.stringify(payload),
                },
            );

            handleNotOk(res);
        } catch (error) {
            console.error(error);

            throw error;
        }
    }

    async uploadChunk(
        locationId: number,
        path: string,
        abortSignal: AbortSignal,
        chunk: FileChunk,
        leaseId: string,
    ): Promise<void> {
        const res = await apiCall(`/api/upload/files/upload/${locationId}`, {
            method: "POST",
            headers: {
                filePath: encodeURIComponent(path),
                chunkId: chunk.id,
                "x-leaseId": leaseId,
                range: `bytes=${chunk.startByte}-${chunk.endByte}`,
            },
            signal: abortSignal,
            body: chunk.chunkData,
        });

        handleNotOk(res);
    }

    async fail(
        leaseId: string,
        size: number,
        path: string,
        locationId: number,
    ): Promise<void> {
        const payload = {
            leaseId: leaseId,
            fileSize: size,
            filePath: encodeURIComponent(path),
        };

        const res = await apiCall(
            `/api/upload/files/upload/${locationId}/fail`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(payload),
            },
        );

        handleNotOk(res);
    }

    async complete(userFileUpload: UserFileUpload): Promise<void> {
        const metadata: Omit<UserFileMetadata, "id"> & {
            ext: string;
            umid?: string;
            camera_model_name?: string;
            camera_serial_number?: string;
        } = {
            name: encodeURIComponent(
                `${userFileUpload.userFile.name}.${userFileUpload.userFile.ext}`,
            ),
            ext: userFileUpload.userFile.ext,
            description: encodeURIComponent(
                userFileUpload.userFile.description,
            ),
        };

        if (userFileUpload.userFile.umid) {
            metadata.umid = userFileUpload.userFile.umid;
        }
        if (userFileUpload.userFile.description) {
            metadata.description = userFileUpload.userFile.description;
        }
        if (userFileUpload.userFile.cameraModelName) {
            metadata.camera_model_name =
                userFileUpload.userFile.cameraModelName;
        }
        if (userFileUpload.userFile.cameraSerialNumber) {
            metadata.camera_serial_number =
                userFileUpload.userFile.cameraSerialNumber;
        }

        const payload = {
            leaseId: userFileUpload.leaseId,
            filePath: encodeURIComponent(userFileUpload.path),
            fileSize: userFileUpload.userFile.file.size,
            chunks: userFileUpload.chunksMap,
            metadata,
        };

        const res = await apiCall(
            `/api/upload/files/upload/${userFileUpload.location.id}/complete`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(payload),
            },
        );

        handleNotOk(res);
    }
}
