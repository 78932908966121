import { StoragePort } from "../ports";
import { AirError, AirErrorInfo, AirErrorKind } from "./airError";
import { AirErrorsStorageValue } from "../storageValues";

export class AirErrorService {
    private readonly _storagePort: StoragePort;

    constructor(storagePort: StoragePort) {
        this._storagePort = storagePort;

        this.createAndSave = this.createAndSave.bind(this);
        this.clear = this.clear.bind(this);
    }

    createAirError(errorKind: AirErrorKind, error: Error): AirError {
        let message;

        if (error.message) {
            message = `${error.message}`;
        } else {
            message = "Something went wrong";
        }

        const errorInfo: AirErrorInfo = {
            date: new Date(),
            message,
        };

        return new AirError(errorKind, errorInfo);
    }

    createAndSave(errorKind: AirErrorKind, error: Error): void {
        const airError = this.createAirError(errorKind, error);

        this._storagePort.airErrors.update(s => {
            s.set(errorKind, airError);
        });
    }

    save(airError: AirError): void {
        this._storagePort.airErrors.update(s => {
            s.set(airError.errorKind, airError);
        });
    }

    clear(errorKind: AirErrorKind): void {
        this._storagePort.airErrors.update(s => {
            s.delete(errorKind);
        });
    }

    get errors(): AirErrorsStorageValue {
        return this._storagePort.airErrors.get();
    }
}
