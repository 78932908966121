import React from "react";
import HierarchyBreadcrumbs from "../../../../components/Breadcrumbs/HierarchyBreadcrumbs";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";
import { fileExplorerLocalState } from "../fileExplorerLocalState";

function FileExplorerBreadcrumbs(): JSX.Element {
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );
    const breadcrumbsPath = state.useState(
        fileExplorerLocalState.breadcrumbsPath,
    );
    const appMode = state.useState(useStorage.fileExplorerMiniApp.mode);

    const isHomeDisabled =
        isFilesQueryInProgress ||
        (appMode === "view" && breadcrumbsPath.length === 0);

    return (
        <HierarchyBreadcrumbs
            filesHierarchy={breadcrumbsPath}
            disabled={isFilesQueryInProgress}
            paramKey="path"
            isHomeDisabled={isHomeDisabled}
        />
    );
}

export default FileExplorerBreadcrumbs;
