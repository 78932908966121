import React, { useEffect, useState } from "react";
import {
    Combobox,
    Option,
    makeStyles,
    tokens,
    useId,
    ComboboxProps,
    Spinner,
} from "@fluentui/react-components";
import { TagGroup, TagKind, Tag } from "../../../../domain/airFile/details";
import ButtonTag from "../ButtonTag/ButtonTag";
import { useTags } from "../../../../application/useCases/useTags";
import { state } from "../../../state/stateAdapter";
import { useStorage } from "../../../../application/useCases/useStorage";

const useStyles = makeStyles({
    listBox: {
        overflowX: "hidden",
    },
    option: {
        display: "grid",
        gridTemplateColumns: "20px auto",
        alignItems: "center",
    },
    tagsList: {
        listStyleType: "none",
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        gridGap: tokens.spacingHorizontalXS,
        flexWrap: "wrap",
        marginBlockEnd: tokens.spacingHorizontalS,
    },
    root: {
        display: "grid",
    },
    inputs: {
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateColumns: "auto max-content",
        columnGap: tokens.spacingHorizontalS,
        justifyContent: "stretch",
        position: "relative",
    },
    spinner: { position: "absolute", right: "16px", top: 0, bottom: 0 },
});

type ComboboxWithTagsProps = {
    data?: Tag[] | null;
    tagGroup: TagGroup;
    onChangeData: (items: Tag[]) => void;
} & ComboboxProps;

function ComboboxWithTags(props: ComboboxWithTagsProps): JSX.Element {
    const comboId = useId("combo-multi");
    const selectedListId = `${comboId}-selection`;
    const [options, setOptions] = useState<Tag[]>();
    const [query, setQuery] = useState<string>("");
    const isQueryTagsInProgress = state.useState(
        useStorage.tags.isQueryTagsInProgress,
    );
    const selectedListRef = React.useRef<HTMLUListElement>(null);
    const comboboxInputRef = React.useRef<HTMLInputElement>(null);
    const [selectedItems, setSelectedItems] = useState<Map<string, Tag>>(
        new Map(props.data ? props.data.map(i => [i.name, i]) : []),
    );

    useEffect(() => {
        useTags.getTags({ groupId: props.tagGroup.id }).then(res => {
            if (
                res !== undefined &&
                res !== null &&
                res.results !== undefined
            ) {
                setOptions(
                    res.results.map(i => ({
                        name: i.name,
                        tagSource: TagKind.User,
                    })),
                );
            } else {
                setOptions([]);
            }
        });
    }, [props.tagGroup]);

    const onItemSelect = (item: string): void => {
        if (
            selectedItems.has(item) &&
            selectedItems.get(item)?.tagSource === TagKind.User
        ) {
            selectedItems.delete(item);
        } else {
            addUserTag(item);
        }

        setSelectedItems(new Map([...selectedItems]));
        props.onChangeData(Array.from(selectedItems.values()));
    };

    const onTagClick = (tag: Tag, index: number): void => {
        selectedItems.delete(tag.name);
        setSelectedItems(new Map([...selectedItems]));

        const indexToFocus = index === 0 ? 1 : index - 1;
        const optionToFocus = selectedListRef.current?.querySelector(
            `#${comboId}-remove-${indexToFocus}`,
        );
        if (optionToFocus) {
            (optionToFocus as HTMLButtonElement).focus();
        } else {
            comboboxInputRef.current?.focus();
        }
        props.onChangeData(Array.from(selectedItems.values()));
    };

    function addUserTag(name: string): void {
        if (!selectedItems.has(name)) {
            selectedItems.set(name, {
                name: name,
                tagSource: TagKind.User,
            });
            setSelectedItems(new Map([...selectedItems]));
        }
    }

    function onEnterPress(e: React.KeyboardEvent<HTMLInputElement>): void {
        if (e.code === "Space") {
            setQuery(query + " ");
        }

        if (e.code === "Enter") {
            if (query.length) {
                addUserTag(query);
                setQuery("");
                props.onChangeData(Array.from(selectedItems.values()));
            }
        }
    }
    const labelledBy =
        selectedItems.size > 0 ? `${comboId} ${selectedListId}` : comboId;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {selectedItems.size > 0 ? (
                <ul
                    id={selectedListId}
                    className={classes.tagsList}
                    ref={selectedListRef}
                >
                    <span id={`${comboId}-remove`} hidden>
                        Remove
                    </span>
                    {Array.from(selectedItems.values()).map(
                        (option: Tag, i) => {
                            return (
                                <li key={option.name + option.tagSource}>
                                    {option.tagSource === TagKind.AI ? (
                                        <ButtonTag
                                            disabled
                                            id={`${comboId}-remove-${i}`}
                                            aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                                            title="You can't remove AI generated tag"
                                        >
                                            {option.name}
                                        </ButtonTag>
                                    ) : (
                                        <ButtonTag
                                            onClick={(): void =>
                                                onTagClick(option, i)
                                            }
                                            id={`${comboId}-remove-${i}`}
                                            aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                                            title="Remove"
                                        >
                                            {option.name}
                                        </ButtonTag>
                                    )}
                                </li>
                            );
                        },
                    )}
                </ul>
            ) : null}

            <div className={classes.inputs}>
                <Combobox
                    disabled={props.disabled || isQueryTagsInProgress}
                    {...props}
                    ref={comboboxInputRef}
                    aria-labelledby={labelledBy}
                    onChange={(e): void => {
                        setQuery(e.target.value);
                    }}
                    onKeyUp={onEnterPress}
                    value={query}
                    multiselect={true}
                    selectedOptions={Array.from(selectedItems.keys())}
                    listbox={{ className: classes.listBox }}
                >
                    {options !== undefined &&
                        options !== null &&
                        options
                            .filter(
                                a =>
                                    a.name
                                        .toLowerCase()
                                        .search(query.toLowerCase()) !== -1,
                            )
                            .map(i => (
                                <Option
                                    key={i.name}
                                    text={i.name}
                                    value={i.name}
                                    className={classes.option}
                                    onClick={(): void => onItemSelect(i.name)}
                                >
                                    {i.name}
                                </Option>
                            ))}
                </Combobox>
                {isQueryTagsInProgress ? (
                    <Spinner size="extra-small" className={classes.spinner} />
                ) : null}
            </div>
        </div>
    );
}

export default ComboboxWithTags;
