import React, {
    useState,
    RefObject,
    useCallback,
    useMemo,
    useEffect,
    SyntheticEvent,
} from "react";
import SeekBar from "../../../SeekBar/SeekBar";
import { Subtitle1, Button } from "@fluentui/react-components";
import ExtendedViewOverflow from "../ExtendedViewOverflow/ExtendedViewOverflow";
import ExtendedViewEmptySearch from "../ExtendedViewEmptySearch/ExtendedViewEmptySearch";
import { ChevronUpRegular, ChevronDownRegular } from "@fluentui/react-icons";
import { ExtendedViewData } from "../../../models";
import { searchData, sortUniqData } from "../../../utils";
import { state } from "../../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../../videoExtendedViewLocalState";
import { useViewExtendedStyles } from "../../VideoExtendedView";

export type ExtendedViewSectionProps = {
    duration: number;
    title: string;
    videoRef: RefObject<HTMLVideoElement>;
    data?: ExtendedViewData[];
    renderItem: (
        i: ExtendedViewData,
        activeItem: ExtendedViewData,
        onClick: (item: ExtendedViewData) => void,
    ) => JSX.Element;
    btnMoreView?: string;
    renderDetails?: (activeItem: ExtendedViewData) => JSX.Element;
    isPlayerPlaying: boolean;
    onPrevInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        setCurrentIndex: (index: number) => void,
    ) => void;
    onPauseInPlayer: () => void;
    onPlayInPlayer: () => void;
    onNextInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        currentIndex: number,
        setCurrentIndex: (index: number) => void,
    ) => void;
    showTime: (
        e: React.MouseEvent<HTMLElement>,
        setSeekTime: (time: number) => void,
    ) => void;
    onClickTimeLine: (
        e: React.MouseEvent<HTMLElement>,
        seekTime: number,
    ) => void;
};

function ExtendedViewSection(
    props: ExtendedViewSectionProps,
): JSX.Element | null {
    const classesViewExtended = useViewExtendedStyles();
    const sortedData = useMemo(
        () => sortUniqData(props.data ?? []),
        [props.data],
    );
    const query = state.useState(videoExtendedLocalState.query);
    const data = searchData(sortedData, query);
    const [activeItem, setActiveItem] = useState(data[0]);
    const [isOverflow, setIsOverflow] = useState(true);
    const [hiddenCount, setHiddenCount] = useState(0);
    const [tempQuery, setTempQuery] = useState(query);

    useEffect(() => {
        if (query !== tempQuery) {
            setTempQuery(query);
            setActiveItem(data[0]);
        }
    }, [tempQuery, data, query]);

    const onSetHiddenCount = useCallback((count: number): void => {
        setHiddenCount(count);
    }, []);

    const toggleOverflow = useCallback((): void => {
        setIsOverflow(!isOverflow);
    }, [isOverflow]);

    if ((props.data ?? []).length && query.length > 0 && data.length === 0) {
        return <ExtendedViewEmptySearch title={props.title} />;
    }

    if ((props.data ?? []).length === 0) {
        return null;
    }

    return (
        <div className="extended-view__section">
            <div className="extended-view__section-title">
                <Subtitle1 className={classesViewExtended.title}>
                    {data.length} {props.title}
                </Subtitle1>
                {hiddenCount > 1 && data.length && (
                    <Button
                        onClick={toggleOverflow}
                        appearance="transparent"
                        className={classesViewExtended.chevron}
                        icon={
                            isOverflow ? (
                                <ChevronDownRegular />
                            ) : (
                                <ChevronUpRegular />
                            )
                        }
                    />
                )}
            </div>

            <ExtendedViewOverflow
                data={data}
                isOverflow={isOverflow}
                toggleOverflow={toggleOverflow}
                onSetHiddenCount={onSetHiddenCount}
                renderItem={(i): JSX.Element =>
                    props.renderItem(i, activeItem, i => setActiveItem(i))
                }
                btnMoreView={props.btnMoreView}
            />

            {activeItem && activeItem.appearances && (
                <>
                    {data.length > 0 && props.renderDetails !== undefined
                        ? props.renderDetails(activeItem)
                        : null}
                    <SeekBar
                        videoRef={props.videoRef}
                        duration={props.duration}
                        appearances={activeItem.appearances}
                        isPlayerPlaying={props.isPlayerPlaying}
                        onPrevInPlayer={props.onPrevInPlayer}
                        onPauseInPlayer={props.onPauseInPlayer}
                        onPlayInPlayer={props.onPlayInPlayer}
                        onNextInPlayer={props.onNextInPlayer}
                        showTime={props.showTime}
                        onClickTimeLine={props.onClickTimeLine}
                    />
                </>
            )}
        </div>
    );
}

export default ExtendedViewSection;
