import React from "react";
import { useCollections } from "../../../../../application/useCases/useCollections";
import CollectionsDialog from "../CollectionsDialog/CollectionsDialog";
import { state } from "../../../../state/stateAdapter";
import { AirFile } from "../../../../../domain/airFile/airFile";
import { useStorage } from "../../../../../application/useCases/useStorage";

type CreateCollectionDialogProps = {
    isOpen: boolean;
    onAfterApply?: () => void;
    onClose?: () => void;
    filesId?: number[];
};

function CreateCollectionDialog(
    props: CreateCollectionDialogProps,
): JSX.Element {
    return (
        <CollectionsDialog
            title="Add new collection"
            isOpen={props.isOpen}
            defaultName={null}
            defaultStatus="private"
            onApply={async (
                name: string,
                status: "private" | "public",
            ): Promise<void> => {
                await useCollections.create({
                    assets: props.filesId ?? [],
                    title: name,
                    accessMode: status,
                    userName: useStorage.authorization.user!.get()!.name,
                });

                if (props.onAfterApply) {
                    props.onAfterApply();
                }
            }}
            onClose={props.onClose}
        />
    );
}

export function openCreateCollectionDialog(
    filesSelectionFiles: AirFile[],
): void {
    state.appDialog.set(() => (
        <CreateCollectionDialog
            isOpen={true}
            onClose={(): void => {
                state.appDialog.set(null);
            }}
            filesId={filesSelectionFiles.map(i => i.id)}
        />
    ));
}

export default CreateCollectionDialog;
