import React from "react";

type ImportInputsProps = {
    selectFilesLabelRef: React.RefObject<HTMLInputElement>;
    selectFoldersLabelRef: React.RefObject<HTMLInputElement>;
    selectCameraCardLabelRef: React.RefObject<HTMLInputElement>;
    onAddData: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onAddCameraCard: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function ImportInputs(props: ImportInputsProps): JSX.Element {
    return (
        <>
            <input
                ref={props.selectFilesLabelRef}
                style={{ display: "none" }}
                type="file"
                onClick={(e): void => e.stopPropagation()}
                multiple
                onChange={props.onAddData}
            />
            <input
                ref={props.selectFoldersLabelRef}
                style={{ display: "none" }}
                type="file"
                onClick={(e): void => e.stopPropagation()}
                multiple
                onChange={props.onAddData}
                {...{
                    webkitdirectory: "true",
                    mozdirectory: "true",
                    directory: "true",
                    nwdirectory: "true",
                }}
            />
            <input
                ref={props.selectCameraCardLabelRef}
                style={{ display: "none" }}
                type="file"
                onClick={(e): void => e.stopPropagation()}
                multiple
                onChange={props.onAddCameraCard}
                {...{
                    webkitdirectory: "true",
                    mozdirectory: "true",
                    directory: "true",
                    nwdirectory: "true",
                }}
            />
        </>
    );
}

export default ImportInputs;
