import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableSelectionCell,
    useTableFeatures,
    useTableSelection,
} from "@fluentui/react-components";
import {
    AddRegular,
    ArrowStepIn24Regular,
    ArrowStepOut24Regular,
    Checkmark24Regular,
    SaveRegular,
    Subtract24Regular,
} from "@fluentui/react-icons";
import React, { useCallback, useEffect, useState } from "react";
import { TagsGroup } from "../../../../../../application/tags/TagsPort";
import { useStorage } from "../../../../../../application/useCases/useStorage";
import { useTags } from "../../../../../../application/useCases/useTags";
import { state } from "../../../../../state/stateAdapter";
import { useCommonFluentuiStyles } from "../../../../../styles/griffel";
import AdminLayout from "../../AdminLayout/AdminLayout";
import TagsGroupsFormPanel from "../TagsGroupsFormPanel/TagsGroupsFormPanel";
import Loader from "../../../../../components/Loader/Loader";
import { columnSizes, columns } from "./TagsGroupsColumns";

export function renderIsVisibleIcon(isVisible: boolean): JSX.Element {
    return isVisible ? <Checkmark24Regular /> : <Subtract24Regular />;
}

function openAddGroupDialog(): void {
    state.appDialogPanel.set(() => (
        <TagsGroupsFormPanel
            onClose={(): void => {
                state.appDialogPanel.set(null);
            }}
        />
    ));
}

async function updateOrderGroups(data: Map<number, TagsGroup>): Promise<void> {
    await useTags.updateOrderGroups({
        idsInOrder: Array.from(data.values()).map(i => i.id),
    });
}

export function openTagsGroupSettings(tagGroup: TagsGroup): void {
    state.appDialogPanel.set(() => (
        <TagsGroupsFormPanel
            item={tagGroup}
            onClose={(): void => {
                state.appDialogPanel.set(null);
            }}
        />
    ));
}

function TagsGroups(): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();
    const tagGroups = state.useState(useStorage.tags.groups);
    const isQueryInProgress = state.useState(useStorage.tags.isQueryInProgress);
    const [data, setData] = useState<Map<number, TagsGroup>>(new Map());
    const items = Array.from(data.values()) ?? [];
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

    const { getRows } = useTableFeatures(
        {
            columns,
            items,
        },
        [
            useTableSelection({
                selectionMode: "single",
                selectedItems: [selectedIndex],
            }),
        ],
    );

    const rows = getRows(row => {
        const selected = selectedIndex == row.rowId;

        return {
            ...row,
            onClick: (_e: React.MouseEvent): void => {
                setSelectedIndex(row.rowId as number);
            },
            onKeyDown: (e: React.KeyboardEvent): void => {
                if (e.key === " ") {
                    e.preventDefault();
                    setSelectedIndex(row.rowId as number);
                }
            },
            selected,
            appearance: selected ? ("brand" as const) : ("none" as const),
        };
    });

    useEffect(() => {
        useTags.fetchGroups().then(res => {
            if (res !== null && res !== undefined) {
                setData(new Map(res.map((i, index) => [index, i])));
            }
        });

        const groupUnsub = useStorage.tags.groups.subscribe(() => {
            if (useStorage.tags.groups !== null) {
                setData(
                    new Map(
                        Array.from(useStorage.tags.groups.get().values()).map(
                            (i, index) => [index, i],
                        ),
                    ),
                );
            }
        });
        return () => {
            groupUnsub();
        };
    }, []);

    const moveUp = useCallback(() => {
        if (selectedIndex > 0 && data.get(selectedIndex) !== undefined) {
            const upper = data.get(selectedIndex - 1);
            const current = data.get(selectedIndex);
            if (upper !== undefined && current !== undefined) {
                data.set(selectedIndex - 1, current);
                data.set(selectedIndex, upper);

                setData(data);
                setSelectedIndex(selectedIndex - 1);
            }
        }
    }, [data, selectedIndex]);

    const moveDown = useCallback(() => {
        if (selectedIndex < data.size) {
            const lower = data.get(selectedIndex + 1);
            const current = data.get(selectedIndex);
            if (lower !== undefined && current !== undefined) {
                data.set(selectedIndex + 1, current);
                data.set(selectedIndex, lower);

                setData(data);
                setSelectedIndex(selectedIndex + 1);
            }
        }
    }, [selectedIndex, data]);

    if (tagGroups === null || data === undefined) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            title="Tags groups"
            buttons={[
                {
                    title: "Add new Tags group",
                    icon: <AddRegular />,
                    onClick: openAddGroupDialog,
                    disabled: isQueryInProgress,
                },
                {
                    title: "Move up",
                    onClick: moveUp,
                    disabled: isQueryInProgress,
                    icon: <ArrowStepOut24Regular />,
                },
                {
                    title: "Move down",
                    onClick: moveDown,
                    disabled: isQueryInProgress,
                    icon: <ArrowStepIn24Regular />,
                },
                {
                    title: "Save order tags group",
                    onClick: () => updateOrderGroups(data),
                    disabled: isQueryInProgress,
                    icon: <SaveRegular />,
                },
            ]}
        >
            <div className="table-container">
                <Table className="table">
                    <TableHeader>
                        <TableRow>
                            <TableSelectionCell type="radio" hidden />
                            {columns &&
                                columns.map(i => (
                                    <TableHeaderCell
                                        key={i.columnId}
                                        style={
                                            columnSizes.has(i.columnId)
                                                ? {
                                                      width: `${columnSizes.get(
                                                          i.columnId,
                                                      )}px`,
                                                  }
                                                : undefined
                                        }
                                    >
                                        {i.renderHeaderCell()}
                                    </TableHeaderCell>
                                ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {rows !== undefined &&
                            rows.map(
                                ({
                                    item,
                                    selected,
                                    onClick,
                                    onKeyDown,
                                    appearance,
                                }) => (
                                    <TableRow
                                        key={item.id}
                                        onClick={onClick}
                                        onKeyDown={onKeyDown}
                                        aria-selected={selected}
                                        appearance={appearance}
                                        className={commonClasses.tableRow}
                                    >
                                        <TableSelectionCell
                                            checked={selected}
                                            type="radio"
                                            radioIndicator={{
                                                "aria-label": "Select row",
                                            }}
                                        />
                                        {columns &&
                                            columns.map(i => (
                                                <TableCell key={i.columnId}>
                                                    {i.renderCell(item)}
                                                </TableCell>
                                            ))}
                                    </TableRow>
                                ),
                            )}
                    </TableBody>
                </Table>
            </div>
        </AdminLayout>
    );
}

export default TagsGroups;
