import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogTrigger,
    Button,
    Field,
    Text,
    Input,
    makeStyles,
    tokens,
    shorthands,
} from "@fluentui/react-components";
import React, { useCallback, useState } from "react";
import { state } from "../../../../../state/stateAdapter";
import { Tag } from "../../../../../../application/tags/TagsPort";
import { Dismiss24Regular } from "@fluentui/react-icons";
import ButtonTag from "../../../../../components/Form/ButtonTag/ButtonTag";
import { useTags } from "../../../../../../application/useCases/useTags";

const useStyles = makeStyles({
    dialog: {
        maxWidth: "440px",
    },
    content: {
        display: "grid",
        rowGap: tokens.spacingVerticalL,
        marginBottom: tokens.spacingVerticalS,
    },
    tags: {
        display: "flex",
        flexWrap: "wrap",
        ...shorthands.gap(tokens.spacingVerticalXS),
        maxHeight: "200px",
        ...shorthands.overflow("auto"),
    },
});

type TagsMergeDialogProps = {
    tags: Tag[];
    groupId: number;
    updateTags: (groupId: number) => Promise<void>;
};

function TagsMergeDialog(props: TagsMergeDialogProps): JSX.Element {
    const classes = useStyles();
    const [name, setName] = useState<string>("");

    const mergeTags = useCallback(async () => {
        await useTags.mergeTags({
            sourceTagIds: props.tags.map(i => i.id),
            targetName: name,
            tagGroupId: props.groupId,
        });

        await props.updateTags(props.groupId);
    }, [name, props]);

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    state.appDialog.set(null);
                }
            }}
            open={state.appDialog.get() !== null}
        >
            <DialogSurface
                className={classes.dialog}
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        Merge Tags
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <Text>
                            You’re about to merge {props.tags.length} tags.
                        </Text>
                        <div className={classes.tags}>
                            {props.tags.map(i => (
                                <ButtonTag disabled key={i.id}>
                                    {i.name}
                                </ButtonTag>
                            ))}
                        </div>

                        <Field label="Merged tag name" size="large">
                            <Input
                                value={name}
                                onChange={(_e, d): void => {
                                    setName(d.value);
                                }}
                            />
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                onClick={mergeTags}
                                disabled={!name.length}
                            >
                                Save
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default TagsMergeDialog;
