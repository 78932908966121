import { useState, useLayoutEffect } from "react";

export function debounce<F extends (...params: unknown[]) => void>(
    fn: F,
    delay: number,
): F {
    let timeoutID: string | number | undefined;
    return function (this: unknown, ...args: unknown[]) {
        clearTimeout(timeoutID);
        timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
    } as F;
}

function useIsMobileView(): boolean {
    const [width, setWidth] = useState(window.innerWidth);

    useLayoutEffect(() => {
        const handleWindowSizeChange = debounce((): void => {
            setWidth(window.innerWidth);
        }, 500);

        window.addEventListener("resize", handleWindowSizeChange);

        return (): void => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return width <= 600;
}

export default useIsMobileView;
