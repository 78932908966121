import { AirArchiveConfig } from "../../domain/airArchive/airArchive";
import { AirArchiveRepository } from "../../domain/airArchive/airArchiveRepository";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";

class AirArchiveService {
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;
    private readonly _airArchiveRepository: AirArchiveRepository;

    constructor(
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
        airArchiveRepository: AirArchiveRepository,
    ) {
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
        this._airArchiveRepository = airArchiveRepository;
    }

    async fetchConfig(): Promise<AirArchiveConfig | undefined> {
        try {
            this._storagePort.airArchive.isFetchConfigQueryInProgress.set(true);

            const config = await this._airArchiveRepository.fetchConfig();

            this._storagePort.airArchive.config.set(config);
            this._storagePort.airArchive.isFetchConfigQueryInProgress.set(
                false,
            );

            return this._storagePort.airArchive.config.get();
        } catch (error) {
            this._storagePort.airArchive.isFetchConfigQueryInProgress.set(
                false,
            );

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchAirArchiveConfig,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async updateConfig(
        newConfig: AirArchiveConfig,
    ): Promise<AirArchiveConfig | undefined> {
        try {
            this._storagePort.airArchive.isUpdateConfigQueryInProgress.set(
                true,
            );

            await this._airArchiveRepository.updateConfig(newConfig);

            this._storagePort.airArchive.config.set(newConfig);
            this._storagePort.airArchive.isUpdateConfigQueryInProgress.set(
                false,
            );

            return this._storagePort.airArchive.config.get();
        } catch (error) {
            this._storagePort.airArchive.isUpdateConfigQueryInProgress.set(
                false,
            );

            const airError = this._errorService.createAirError(
                AirErrorKind.UpdateAirArchiveConfig,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}

export default AirArchiveService;
