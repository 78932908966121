import { useFiles } from "../../../../application/useCases/useFiles";
import { useStorage } from "../../../../application/useCases/useStorage";
import {
    AirFile,
    SortKey,
    createBreadcrumbs,
    prepareFilesToShow,
    sortFilesByType,
} from "../../../../domain/airFile/airFile";
import { defaultDataParams } from "../../../../domain/dataParams/dataParams";
import { Filters } from "../../../../domain/filter/filters";
import { state } from "../../../state/stateAdapter";
import filtersTab from "./FileExplorerFilters/fileExplorerFiltersTab";
import { fileExplorerLocalState } from "./fileExplorerLocalState";
import { fileExplorerManager } from "./fileExplorerManager";
import { Option } from "../../../../domain/types/types";
import fileDetailsTab from "../../../components/FileDetails/fileDetailsTab";
import { appManager } from "../../../appManager/appManager";
import fileExplorerFiltersTab from "./FileExplorerFilters/fileExplorerFiltersTab";
import { FilesViewModeKind } from "../../../models";
import { calculateOffset } from "../../../../utility";
import { router } from "../../../router";
import {
    FilesFetchParams,
    FilesSearchParams,
} from "../../../../application/files/filesPort";
import { useCognitiveMetadata } from "../../../../application/useCases/useCognitiveMetadata";
import { FileExplorerUrlParams } from "../shared/types";

/***
 *  @returns isSearchPerformed
 */
async function defineFiltersChanging(data: {
    filters: Filters | null;
    filtersParamsStr: string | null;
}): Promise<boolean> {
    if (data.filtersParamsStr === null || data.filtersParamsStr.length === 0) {
        useStorage.files.activeFilters.set(null);
        useStorage.fileExplorerMiniApp.selection.set(new Map());
        return false;
    } else {
        await changeFiltersEventHandler({ newFilters: data.filters });
        return true;
    }
}

export async function updateUrlEventHandler(data: {
    urlParams: FileExplorerUrlParams;
    prevUrlParams: FileExplorerUrlParams;
}): Promise<void> {
    let isFilesLoaded = false;
    let isSearchPerformed = false;
    let isFilesPrepared = false;
    let isSelectionUpdated = false;
    let isBreadcrumbsUpdated = false;

    if (data.urlParams.activeDirectory !== data.prevUrlParams.activeDirectory) {
        useStorage.fileExplorerMiniApp.activeDirectory.set(
            data.urlParams.activeDirectory,
        );

        if (!isSelectionUpdated) {
            useStorage.fileExplorerMiniApp.selection.set(new Map());
        }
        isSelectionUpdated = true;

        if (!isFilesLoaded) {
            await useFiles.getFiles({
                folderHierarchy: data.urlParams.activeDirectory ?? undefined,
            });

            isFilesLoaded = true;
        }

        if (
            (!isSearchPerformed &&
                data.urlParams.query !== null &&
                data.urlParams.query !== undefined &&
                data.urlParams.query.length > 0) ||
            (data.urlParams.filters !== null &&
                data.urlParams.filters !== undefined &&
                data.urlParams.filters.size > 0) ||
            state.appPanel.tabs.get().has(filtersTab.id)
        ) {
            const offset =
                data.urlParams.page !== null && data.urlParams.pageSize !== null
                    ? calculateOffset(
                          data.urlParams.page,
                          data.urlParams.pageSize,
                      )
                    : 0;

            await useFiles.search({
                path: data.urlParams.activeDirectory ?? undefined,
                query: data.urlParams.query ?? undefined,
                limit: data.urlParams.pageSize ?? undefined,
                offset,
                sortKey: data.urlParams.sortKey ?? undefined,
                isSortDesc: data.urlParams.isSortDesc ?? undefined,
                filters: data.urlParams.filters ?? undefined,
            });

            isSearchPerformed = true;
        }
    }

    if (
        data.urlParams.filtersParamsStr !== data.prevUrlParams.filtersParamsStr
    ) {
        useStorage.files.activeFilters.set(data.urlParams.filters);

        if (!isSearchPerformed) {
            isSearchPerformed = await defineFiltersChanging({
                filters: data.urlParams.filters,
                filtersParamsStr: data.urlParams.filtersParamsStr,
            });
        }
    }

    if (data.urlParams.query !== data.prevUrlParams.query) {
        useStorage.files.searchQuery.set(data.urlParams.query);

        if (!isSearchPerformed) {
            if (
                (data.urlParams.query !== null &&
                    data.urlParams.query !== undefined &&
                    data.urlParams.query.length > 0) ||
                (data.urlParams.filters !== null &&
                    data.urlParams.filters !== undefined &&
                    data.urlParams.filters.size > 0) ||
                state.appPanel.tabs.get().has(filtersTab.id)
            ) {
                const offset =
                    data.urlParams.page !== null &&
                    data.urlParams.pageSize !== null
                        ? calculateOffset(
                              data.urlParams.page,
                              data.urlParams.pageSize,
                          )
                        : 0;

                await useFiles.search({
                    path: data.urlParams.activeDirectory ?? undefined,
                    query: data.urlParams.query ?? undefined,
                    limit: data.urlParams.pageSize ?? undefined,
                    offset,
                    sortKey: data.urlParams.sortKey ?? undefined,
                    isSortDesc: data.urlParams.isSortDesc ?? undefined,
                    filters: data.urlParams.filters ?? undefined,
                });

                isSearchPerformed = true;
            }
        }
    }

    if (
        data.urlParams.sortKey !== data.prevUrlParams.sortKey ||
        data.urlParams.isSortDesc !== data.prevUrlParams.isSortDesc
    ) {
        useStorage.fileExplorerMiniApp.dataParams.update(s => {
            s.sortKey = data.urlParams.sortKey;
            s.isSortDesc = data.urlParams.isSortDesc;
        });

        if (
            (data.urlParams.query !== null &&
                data.urlParams.query !== undefined &&
                data.urlParams.query.length > 0) ||
            (data.urlParams.filters !== null &&
                data.urlParams.filters !== undefined &&
                data.urlParams.filters.size > 0)
        ) {
            if (!isSearchPerformed) {
                refreshEventHandler();

                const offset =
                    data.urlParams.page !== null &&
                    data.urlParams.pageSize !== null
                        ? calculateOffset(
                              data.urlParams.page,
                              data.urlParams.pageSize,
                          )
                        : 0;

                await useFiles.search({
                    path: data.urlParams.activeDirectory ?? undefined,
                    query: data.urlParams.query ?? undefined,
                    limit: data.urlParams.pageSize ?? undefined,
                    offset,
                    sortKey: data.urlParams.sortKey ?? undefined,
                    isSortDesc: data.urlParams.isSortDesc ?? undefined,
                    filters: data.urlParams.filters ?? undefined,
                });

                isSearchPerformed = true;
            }
        } else {
            if (!isFilesPrepared) {
                const sorted = data.urlParams.sortKey
                    ? sortFilesByType(
                          state.unwrap(useStorage.files.validFiles),
                          data.urlParams.sortKey as SortKey,
                          data.urlParams.isSortDesc,
                      )
                    : state.unwrap(useStorage.files.validFiles);

                useStorage.files.filesToShow.set(
                    prepareFilesToShow(
                        sorted,
                        data.urlParams.filters ?? undefined,
                        data.urlParams.page,
                        data.urlParams.pageSize,
                    ),
                );

                isFilesPrepared = true;
            }
        }
    }

    if (
        data.urlParams.page !== data.prevUrlParams.page ||
        data.urlParams.pageSize !== data.prevUrlParams.pageSize
    ) {
        useStorage.fileExplorerMiniApp.selection.set(new Map());
        useStorage.fileExplorerMiniApp.dataParams.update(s => {
            s.page = data.urlParams.page;
            s.pageSize = data.urlParams.pageSize;
        });

        if (
            (data.urlParams.query !== null &&
                data.urlParams.query !== undefined &&
                data.urlParams.query.length > 0) ||
            (data.urlParams.filters !== null &&
                data.urlParams.filters !== undefined &&
                data.urlParams.filters.size > 0)
        ) {
            if (!isSearchPerformed) {
                const offset =
                    data.urlParams.page !== null &&
                    data.urlParams.pageSize !== null
                        ? calculateOffset(
                              data.urlParams.page,
                              data.urlParams.pageSize,
                          )
                        : 0;

                await useFiles.search({
                    path: data.urlParams.activeDirectory ?? undefined,
                    query: data.urlParams.query ?? undefined,
                    limit: data.urlParams.pageSize ?? undefined,
                    offset,
                    sortKey: data.urlParams.sortKey ?? undefined,
                    isSortDesc: data.urlParams.isSortDesc ?? undefined,
                    filters: data.urlParams.filters ?? undefined,
                });

                isSearchPerformed = true;
            }
        } else {
            if (!isFilesPrepared) {
                const sorted = data.urlParams.sortKey
                    ? sortFilesByType(
                          state.unwrap(useStorage.files.validFiles),
                          data.urlParams.sortKey as SortKey,
                          data.urlParams.isSortDesc,
                      )
                    : state.unwrap(useStorage.files.validFiles);

                useStorage.files.filesToShow.set(
                    prepareFilesToShow(
                        sorted,
                        data.urlParams.filters ?? undefined,
                        data.urlParams.page,
                        data.urlParams.pageSize,
                    ),
                );

                isFilesPrepared = true;
            }
        }
    }

    if (data.urlParams.viewMode !== data.prevUrlParams.viewMode) {
        state.fileExplorerMiniApp.filesViewMode.set(
            data.prevUrlParams.viewMode,
        );
    }

    if (data.urlParams.activeItem !== data.prevUrlParams.activeItem) {
        useStorage.fileExplorerMiniApp.activeItem.set(
            data.urlParams.activeItem,
        );

        if (!isBreadcrumbsUpdated) {
            if (
                data.urlParams.activeDirectory === undefined ||
                data.urlParams.activeDirectory === null
            ) {
                fileExplorerLocalState.breadcrumbsPath.set([]);
            } else {
                const breadcrumbs = createBreadcrumbs(
                    data.urlParams.activeDirectory,
                    useStorage.files.files.get() || [],
                );
                fileExplorerLocalState.breadcrumbsPath.set(breadcrumbs);
            }

            isBreadcrumbsUpdated = true;
        }

        if (data.urlParams.activeItem && data.urlParams.activeItem.length) {
            if (!state.appPanel.tabs.get().has(fileDetailsTab.id)) {
                appManager.addTab(fileDetailsTab);
            }

            const details = await useFiles.getDetails({
                id: parseInt(data.urlParams.activeItem),
            });

            if (details?.fileType?.toLowerCase() === "video") {
                await useCognitiveMetadata.getCognitiveMetadata({
                    id: details.id,
                });
            }
        } else {
            if (state.appPanel.tabs.get().has(fileDetailsTab.id)) {
                appManager.deleteTab(fileDetailsTab.id);
            }
        }
    }

    if (!isBreadcrumbsUpdated) {
        if (
            data.urlParams.activeDirectory === undefined ||
            data.urlParams.activeDirectory === null
        ) {
            fileExplorerLocalState.breadcrumbsPath.set([]);
        } else {
            const breadcrumbs = createBreadcrumbs(
                data.urlParams.activeDirectory,
                useStorage.files.files.get() || [],
            );
            fileExplorerLocalState.breadcrumbsPath.set(breadcrumbs);
        }

        isBreadcrumbsUpdated = true;
    }
}

export async function changeActiveDirectoryEventHandler(
    data: FilesSearchParams & FilesFetchParams,
): Promise<void> {
    useStorage.fileExplorerMiniApp.activeDirectory.set(data.path);
    useStorage.fileExplorerMiniApp.selection.set(new Map());

    const files = await useFiles.getFiles({
        folderHierarchy: data.path,
        ids: data.ids,
    });

    if (data.filters !== undefined || data.query !== undefined) {
        await useFiles.search({
            path: data.path ?? "",
            query: data.query,
            limit: data.limit,
            offset: data.offset,
            sortKey: data.sortKey,
            isSortDesc: data.isSortDesc,
            filters: data.filters,
        });
    }

    if (data.path === undefined) {
        fileExplorerLocalState.breadcrumbsPath.set([]);
    } else {
        const breadcrumbs = createBreadcrumbs(data.path, files || []);
        fileExplorerLocalState.breadcrumbsPath.set(breadcrumbs);
    }
}

export async function changeFiltersEventHandler(data: {
    newFilters: Filters | null;
}): Promise<void> {
    useStorage.files.activeFilters.set(data.newFilters);

    useStorage.fileExplorerMiniApp.selection.set(new Map());

    const activeDirectory =
        useStorage.fileExplorerMiniApp.activeDirectory.get();
    const dataParams = useStorage.fileExplorerMiniApp.dataParams.get();
    const query = useStorage.files.searchQuery.get();

    const offset =
        dataParams.page !== null && dataParams.pageSize !== null
            ? calculateOffset(dataParams.page, dataParams.pageSize)
            : 0;

    await useFiles.search({
        path: activeDirectory ?? "",
        query: query ?? undefined,
        limit: dataParams.pageSize ?? undefined,
        offset,
        sortKey: dataParams.sortKey ?? undefined,
        isSortDesc: dataParams.isSortDesc ?? undefined,
        filters: data.newFilters ?? undefined,
    });
}

export async function sortEventHandler(data: {
    sortKey: string | undefined;
    isSortDesc: boolean;
}): Promise<void> {
    const activeFilters = useStorage.files.activeFilters.get();
    const dataParams = useStorage.fileExplorerMiniApp.dataParams.get();
    const query = useStorage.files.searchQuery.get();

    useStorage.fileExplorerMiniApp.selection.set(new Map());
    useStorage.fileExplorerMiniApp.dataParams.update(s => {
        s.sortKey = data.sortKey;
        s.isSortDesc = data.isSortDesc;
    });

    if (
        (query !== null && query !== undefined && query.length > 0) ||
        (activeFilters !== null &&
            activeFilters !== undefined &&
            activeFilters.size > 0)
    ) {
        refreshEventHandler();
    } else {
        const sorted = data.sortKey
            ? sortFilesByType(
                  state.unwrap(useStorage.files.validFiles),
                  data.sortKey as SortKey,
                  data.isSortDesc,
              )
            : state.unwrap(useStorage.files.validFiles);

        useStorage.files.filesToShow.set(
            prepareFilesToShow(
                sorted,
                activeFilters,
                dataParams.page,
                dataParams.pageSize,
            ),
        );
    }
}

export async function refreshEventHandler(): Promise<void> {
    useStorage.fileExplorerMiniApp.selection.set(new Map());

    const activeDirectory =
        useStorage.fileExplorerMiniApp.activeDirectory.get();
    const activeFilters = useStorage.files.activeFilters.get();
    const dataParams = useStorage.fileExplorerMiniApp.dataParams.get();
    const query = useStorage.files.searchQuery.get();

    await useFiles.getFiles({
        folderHierarchy: activeDirectory ?? undefined,
    });

    if (activeDirectory === null || activeDirectory === undefined) {
        fileExplorerLocalState.breadcrumbsPath.set([]);
    } else {
        const files = useStorage.files.files.get();
        const breadcrumbs = createBreadcrumbs(activeDirectory, files);
        fileExplorerLocalState.breadcrumbsPath.set(breadcrumbs);
    }

    if (
        (query !== null && query !== undefined && query.length > 0) ||
        (activeFilters !== null &&
            activeFilters !== undefined &&
            activeFilters.size > 0)
    ) {
        const offset =
            dataParams.page !== null && dataParams.pageSize !== null
                ? calculateOffset(dataParams.page, dataParams.pageSize)
                : 0;

        await useFiles.search({
            path: activeDirectory ?? "",
            query: query ?? undefined,
            limit: dataParams.pageSize ?? undefined,
            offset,
            sortKey: dataParams.sortKey ?? undefined,
            isSortDesc: dataParams.isSortDesc ?? undefined,
            filters: activeFilters ?? undefined,
        });
    } else {
        if (state.appPanel.tabs.get().has(filtersTab.id)) {
            await useFiles.search({
                path: activeDirectory ?? "",
            });
        }

        if (
            dataParams.isSortDesc !== defaultDataParams.isSortDesc ||
            dataParams.page !== defaultDataParams.page ||
            dataParams.pageSize !== defaultDataParams.pageSize ||
            dataParams.sortKey !== defaultDataParams.sortKey
        ) {
            await sortEventHandler({
                isSortDesc: dataParams.isSortDesc,
                sortKey: dataParams.sortKey,
            });
        }
    }

    await changeSelectionEventHandler({
        files: null,
        selection: null,
    });
}

export async function initEventHandler(): Promise<void> {
    const urlParams = await fileExplorerManager.updateUrlParams();

    if (useStorage.files.searchQuery.get() !== urlParams.query) {
        useStorage.files.searchQuery.set(urlParams.query);
    }

    if (
        useStorage.fileExplorerMiniApp.activeDirectory.get() !==
        urlParams.activeDirectory
    ) {
        useStorage.fileExplorerMiniApp.activeDirectory.set(
            urlParams.activeDirectory,
        );
    }

    if (
        useStorage.fileExplorerMiniApp.activeItem.get() !== urlParams.activeItem
    ) {
        useStorage.fileExplorerMiniApp.activeItem.set(urlParams.activeItem);
        changeActiveItemEventHandler({ activeItem: urlParams.activeItem });
    }

    if (urlParams.activeItem && urlParams.activeItem.length) {
        if (!state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.addTab(fileDetailsTab);
        }
    } else {
        if (state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.deleteTab(fileDetailsTab.id);
        }
    }

    if (
        urlParams.filters !== null &&
        urlParams.filters.size > 0 &&
        !state.appPanel.tabs.get().has(fileExplorerFiltersTab.id)
    ) {
        appManager.addTab(fileExplorerFiltersTab);
    }

    useStorage.fileExplorerMiniApp.dataParams.set({
        pageSize: urlParams.pageSize,
        sortKey: urlParams.sortKey,
        isSortDesc: urlParams.isSortDesc,
        page: urlParams.page,
    });
    useStorage.fileExplorerMiniApp.activeItem.set(urlParams.activeItem);
    useStorage.fileExplorerMiniApp.activeDirectory.set(
        urlParams.activeDirectory,
    );
    useStorage.files.activeFilters.set(urlParams.filters);
    state.fileExplorerMiniApp.filesViewMode.set(urlParams.viewMode);

    await refreshEventHandler();
}

export async function changeSearchQueryEventHandler(data: {
    query: Option<string>;
}): Promise<void> {
    useStorage.files.searchQuery.set(data.query);

    await refreshEventHandler();
}

export async function resetFiltersEventHandler(): Promise<void> {
    useStorage.files.activeFilters.set(null);
    useStorage.fileExplorerMiniApp.selection.set(new Map());

    await refreshEventHandler();
}

export async function changePaginationEventHandler(data: {
    page: number;
    pageSize: number;
}): Promise<void> {
    useStorage.fileExplorerMiniApp.selection.set(new Map());
    useStorage.fileExplorerMiniApp.dataParams.update(s => {
        s.page = data.page;
        s.pageSize = data.pageSize;
    });

    await refreshEventHandler();
}

export async function changeViewModeEventHandler(data: {
    mode: FilesViewModeKind;
}): Promise<void> {
    state.fileExplorerMiniApp.filesViewMode.set(data.mode);
}

export async function changeActiveItemEventHandler(data: {
    activeItem: Option<string>;
}): Promise<void> {
    useStorage.fileExplorerMiniApp.activeItem.set(data.activeItem);

    fileExplorerLocalState.breadcrumbsPath.set(
        createBreadcrumbs(
            useStorage.fileExplorerMiniApp.activeDirectory.get() ?? "",
            useStorage.files.files.get() || [],
        ),
    );

    if (data.activeItem && data.activeItem.length) {
        if (!state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.addTab(fileDetailsTab);
        }

        const details = await useFiles.getDetails({
            id: parseInt(data.activeItem),
        });

        if (details?.fileType?.toLowerCase() === "video") {
            await useCognitiveMetadata.getCognitiveMetadata({
                id: details.id,
            });
        }
    } else {
        if (state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.deleteTab(fileDetailsTab.id);
        }
    }
}

export async function changeSelectionEventHandler(data: {
    files: Option<AirFile[]>;
    selection: Option<string[]>;
}): Promise<void> {
    let files: AirFile[] = [];

    if (data.files !== null && data.files !== undefined) {
        files = data.files;
    } else {
        files =
            useStorage.fileExplorerMiniApp.mode.get() === "view"
                ? useStorage.files.filesToShow.get()
                : useStorage.files.searchFilesToShow.get();
    }

    let urlSelection: string[] = [];

    if (data.selection !== null && data.selection !== undefined) {
        urlSelection = data.selection;
    } else {
        const selectionStr = router
            .getUrlEntities()
            .searchParams.get("selection");
        urlSelection = selectionStr ? selectionStr.split(",") : [];
    }

    if (urlSelection === null || urlSelection.length === 0) {
        useStorage.fileExplorerMiniApp.selection.set(new Map());
    } else {
        const selection = new Map();
        urlSelection.forEach(i => {
            const file = files.find(f => f.hierarchy === i);
            if (file) {
                selection.set(file.hierarchy, file);
            }
        });
        useStorage.fileExplorerMiniApp.selection.set(selection);
    }
}
