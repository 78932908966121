import React from "react";
import { AirFile } from "../../../domain/airFile/airFile";
import { CheckboxOnChangeData } from "@fluentui/react-components";
import TilePreview from "./TilePreview/TilePreview";
import TileIcon from "./TileIcon/TileIcon";
import { formatDate } from "../../utility/formatters";

export type TileProps = {
    isSelectable: boolean;
    isFile: boolean;
    selected: boolean;
    item: AirFile;
    link: string;
    onSelect: (
        e: React.ChangeEvent<Element> | React.KeyboardEvent<Element>,
        item: AirFile,
    ) => void;
    onSingleSelect: (
        e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
        item: AirFile,
    ) => void;
};

function TileFile(props: TileProps): JSX.Element {
    if (props.item && props.item.thumbnailUrl !== null && props.isFile) {
        return (
            <TilePreview
                isSelectable={props.isSelectable}
                selected={props.selected}
                link={props.link}
                onChangeCheckbox={(
                    ev: React.ChangeEvent<HTMLInputElement>,
                    _data: CheckboxOnChangeData,
                ): void => props.onSelect(ev, props.item as AirFile)}
                onClickContainer={(e): void =>
                    props.onSingleSelect(e, props.item as AirFile)
                }
                title={props.item.title}
                image={props.item.thumbnailUrl}
                caption={formatDate(props.item.dateUpdated)}
            />
        );
    }

    return (
        <TileIcon
            isSelectable={props.isSelectable}
            selected={props.selected}
            link={props.link}
            filePath={props.item.filePath}
            onClickContainer={(
                e:
                    | React.MouseEvent<Element, MouseEvent>
                    | React.KeyboardEvent<Element>,
            ): void => {
                props.onSingleSelect(e, props.item);
            }}
            onChangeCheckbox={(
                ev: React.ChangeEvent<HTMLInputElement>,
                _data: CheckboxOnChangeData,
            ): void => {
                props.onSelect(ev, props.item);
            }}
            isFile={props.isFile}
            title={props.item.title}
            caption={props.item && formatDate(props.item.dateUpdated)}
        />
    );
}

export default TileFile;
