type FacetType =
    | "EnumFacetSetting"
    | "RangeEnumFacetSetting"
    | "RangeFacetSetting";

export type Facet = {
    values: string | null;
    name: string | null;
    title: string | null;
    type: FacetType | null;
};

export type FacetValueItem = {
    count: number;
    name: string;
    id: number | null;
};

export type FacetValue = {
    from: string | null;
    name: string;
    to: string | null;
    values: FacetValueItem[];
};

export function retainNonEmptyFacets(facetValues: FacetValue[]): FacetValue[] {
    return facetValues.filter(i => {
        if (
            (i.name === "dateUpdated" || i.name === "dateCreated") &&
            (i.from !== null || i.to !== null)
        ) {
            return i;
        }

        if (i.values !== null && i.values.length > 0) {
            return i;
        }
    });
}

export function getFacetName(facetValueItem: FacetValueItem): string {
    return facetValueItem.id !== undefined && facetValueItem.id !== null
        ? facetValueItem.id.toString()
        : facetValueItem.name;
}
