import React from "react";
import { Caption1, mergeClasses } from "@fluentui/react-components";
import { TimelineDataItem, useTimelineStyles } from "../Timeline";

export default function TimelineText(props: {
    data?: TimelineDataItem[];
    hightLights: string[];
    italic: boolean;
}): JSX.Element | null {
    const classes = useTimelineStyles();

    if (props.data === undefined) {
        return null;
    }

    return (
        <>
            {props.data.map(i => {
                let text = i.value;

                if (props.hightLights) {
                    props.hightLights.forEach((keyword: string): void => {
                        text = text.replace(keyword, "<strong>$&</strong>");
                    });
                }

                return (
                    <Caption1
                        className={mergeClasses(classes.text, "timeline__text")}
                        key={i.value}
                        italic={props.italic}
                    >
                        <span dangerouslySetInnerHTML={{ __html: text }} />{" "}
                    </Caption1>
                );
            })}
        </>
    );
}
