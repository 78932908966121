import React from "react";
import { PanelTab } from "../../models";
import { appManager } from "../../appManager/appManager";
import FileDetails from "./FileDetails";

const fileDetailsTab: PanelTab = {
    id: "details",
    title: "Details",
    content: <FileDetails />,
    onClose: () => {
        appManager.clearActiveItem();
        appManager.deleteTab("details");
    },
};

export default fileDetailsTab;
