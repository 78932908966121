import React from "react";
import { state } from "../../state/stateAdapter";

function AppSearch(): JSX.Element {
    const activeAppSearchFactory = state.useState(state.activeAppSearchFactory);

    if (
        activeAppSearchFactory !== null &&
        activeAppSearchFactory !== undefined
    ) {
        return activeAppSearchFactory();
    }

    return <></>;
}

export default AppSearch;
