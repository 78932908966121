import {
    SharingCreateParams,
    SharingCreateRes,
    SharingGetSharingDetailsParams,
    SharingGetSharingDetailsRes,
    SharingGetDownloadLinkParams,
    SharingGetDownloadLinkRes,
    SharingGetFilesParams,
    SharingPort,
    SharingGetFileDetailsParams,
} from "../../application/sharing/sharingPort";
import { AirFile } from "../../domain/airFile/airFile";
import { AirFileDetails } from "../../domain/airFile/details";
import { apiCall, validateRes } from "../api/api";

export class SharingAdapter implements SharingPort {
    async create(params: SharingCreateParams): Promise<SharingCreateRes> {
        const res = await apiCall("/api/share-link/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async getSharingDetails(
        params: SharingGetSharingDetailsParams,
    ): Promise<SharingGetSharingDetailsRes> {
        const res = await apiCall(
            `/api/share-link/details?shareUrl=${params.shareUrl}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
            true, // ignoreAuthCheck,
        );

        return await validateRes(res);
    }

    async getFiles(params: SharingGetFilesParams): Promise<AirFile[]> {
        const res = await apiCall(
            "/api/share-link/list",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(params),
            },
            !params.isMSEID,
        );

        return await validateRes(res);
    }

    async getDownloadLink(
        params: SharingGetDownloadLinkParams,
    ): Promise<SharingGetDownloadLinkRes> {
        const res = await apiCall(
            "/api/share-link/download-local/link",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(params),
            },
            !params.isMSEID,
        );

        return await validateRes(res);
    }
    async getFileDetais(
        params: SharingGetFileDetailsParams,
    ): Promise<AirFileDetails> {
        const res = await apiCall(
            "/api/share-link/file-details",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(params),
            },
            !params.isMSEID,
        );

        return await validateRes(res);
    }
}
