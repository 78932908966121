import React, { Fragment, useCallback, useState } from "react";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";
import {
    AccordionToggleEvent,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionPanel,
    Text,
    Button,
    ProgressBar,
    Caption1,
    Toolbar,
    ToolbarButton,
    ToolbarGroup,
} from "@fluentui/react-components";
import {
    UserFileUpload,
    formUploadDestination,
} from "../../../../../domain/userFileUpload/userFileUpload";
import { ArrowClockwiseRegular, DismissRegular } from "@fluentui/react-icons";
import { formatDuration } from "../../../../utility/formatters";
import "./uploads.scss";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";
import { useUploader } from "../../../../../application/useCases/useUploader";
import { formatSize } from "../../../../../utility";
import ConfirmationDialog from "../ConfirmatioDialog/ConfirmationDialog";
import { AlertOff } from "../../../../components/BundledIcons";

enum UploadsItemKind {
    Uploading = "Uploading",
    Completed = "Completed",
    Pending = "Pending",
}

const initialOpenedItems: Set<UploadsItemKind> = new Set([
    UploadsItemKind.Uploading,
]);

type CardProps = {
    file: UserFileUpload;
};

function onCancelUpload(file: UserFileUpload): void {
    state.appDialog.set(() => (
        <ConfirmationDialog
            title="Cancel upload"
            confirmationCb={function (): void {
                useUploader.cancelUpload(file);
            }}
            message={`Are you sure you want to cancel the uploading of the "${file.userFile.fullFileName}" file?`}
        />
    ));
}

function onDismiss(file: UserFileUpload, type: "completed" | "aborted"): void {
    useStorage.uploaderMiniApp.upload.update(s => {
        s[type].delete(file.leaseId);
    });
}

function UploadingCard(props: CardProps): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    const getEta = useCallback((): string => {
        if (props.file.uploadedBytes === 0) {
            return "Starting";
        }

        if (props.file.eta.length > 0) {
            return props.file.eta;
        }

        return "Almost done";
    }, [props.file.eta, props.file.uploadedBytes]);

    return (
        <div className="upload-card">
            <div className="upload-card__section_r">
                <header className="upload-card__section_c upload-card__section_c-center">
                    <Text className={commonClasses.brandTxt}>
                        {props.file.userFile.fullFileName}
                    </Text>

                    <Button
                        icon={<DismissRegular />}
                        appearance="subtle"
                        title="Cancel"
                        onClick={(): void => {
                            onCancelUpload(props.file);
                        }}
                    />
                </header>

                <Caption1 className="txt_b-a">
                    Path:{" "}
                    <span className={commonClasses.brandTxt}>
                        {formUploadDestination(
                            props.file.location,
                            props.file.path,
                        )}
                    </span>
                </Caption1>
            </div>

            {props.file.abortReason !== null ? (
                <div className="upload-card__section_r">
                    <ProgressBar value={props.file.progress} color="error" />

                    <div className="upload-card__section_c upload-card__section_c-start">
                        <Caption1 className="txt_b-a">
                            {props.file.abortReason}
                        </Caption1>

                        <Caption1>Retry in {props.file.retryTimer}s</Caption1>
                    </div>
                </div>
            ) : (
                <div className="upload-card__section_r">
                    <ProgressBar value={props.file.progress} />
                    <Caption1>
                        {Math.round(props.file.progress * 100)}%
                    </Caption1>
                </div>
            )}

            <div className="upload-card__section_c upload-card__section_c-start">
                <Caption1>
                    {props.file.uploadedBytes > 0
                        ? formatSize(props.file.uploadedBytes)
                        : 0}{" "}
                    of {props.file.userFile.size}
                </Caption1>

                {!props.file.eta.toLowerCase().includes("infinity") &&
                props.file.abortReason === null ? (
                    <Caption1>ETA: {getEta()}</Caption1>
                ) : null}
            </div>
        </div>
    );
}

function CompletedCard(props: CardProps): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    const took = formatDuration(props.file.endTime - props.file.startTime);

    return (
        <div className="upload-card">
            <div className="upload-card__section_r">
                <header className="upload-card__section_c upload-card__section_c-center">
                    <Text className={commonClasses.brandTxt}>
                        {props.file.userFile.fullFileName}
                    </Text>

                    <Button
                        icon={<DismissRegular />}
                        appearance="subtle"
                        title="Dismiss"
                        onClick={(): void => {
                            onDismiss(props.file, "completed");
                        }}
                    />
                </header>

                <Caption1 className="txt_b-a">
                    Path:{" "}
                    <span className={commonClasses.brandTxt}>
                        {formUploadDestination(
                            props.file.location,
                            props.file.path,
                        )}
                    </span>
                </Caption1>
            </div>

            {took.length > 0 ? (
                <div>
                    <Caption1>Took: {took}</Caption1>
                </div>
            ) : null}
        </div>
    );
}

function PendingCard(props: CardProps): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    return (
        <div className="upload-card">
            <div className="upload-card__section_r">
                <header className="upload-card__section  upload-card__section_c-center">
                    <Text className={commonClasses.brandTxt}>
                        {props.file.userFile.fullFileName}
                    </Text>

                    <Button
                        icon={<DismissRegular />}
                        appearance="subtle"
                        title="Cancel"
                        onClick={(): void => {
                            onCancelUpload(props.file);
                        }}
                    />
                </header>

                <Caption1 className="txt_b-a">
                    Path:{" "}
                    <span className={commonClasses.brandTxt}>
                        {formUploadDestination(
                            props.file.location,
                            props.file.path,
                        )}
                    </span>
                </Caption1>
            </div>

            <div>
                <ProgressBar />
            </div>

            <div className="upload-card__section_c upload-card__section_c-start">
                <Caption1>
                    {props.file.uploadedBytes > 0
                        ? formatSize(props.file.uploadedBytes)
                        : 0}{" "}
                    of {props.file.userFile.size}
                </Caption1>

                <Caption1>Pending</Caption1>
            </div>
        </div>
    );
}

function AbortedCard(props: CardProps): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    const took = formatDuration(props.file.endTime - props.file.startTime);

    return (
        <div className="upload-card">
            <div className="upload-card__section_c  upload-card__section_c-center">
                <Text className={commonClasses.brandTxt}>
                    {props.file.userFile.fullFileName}
                </Text>

                <Button
                    icon={<ArrowClockwiseRegular />}
                    appearance="subtle"
                    title="Retry"
                    onClick={(): void => {
                        useUploader.retryUpload(props.file);
                    }}
                />

                <Button
                    icon={<DismissRegular />}
                    appearance="subtle"
                    title="Dismiss"
                    onClick={(): void => {
                        onDismiss(props.file, "aborted");
                    }}
                />
            </div>

            <Caption1 className="txt_b-a">
                Path:{" "}
                <span className={commonClasses.brandTxt}>
                    {formUploadDestination(
                        props.file.location,
                        props.file.path,
                    )}
                </span>
            </Caption1>

            <Caption1 className="txt_b-a">{props.file.abortReason}</Caption1>

            <div className="upload-card__section_c upload-card__section_c-start">
                <Caption1>
                    {props.file.uploadedBytes > 0
                        ? formatSize(props.file.uploadedBytes)
                        : 0}{" "}
                    of {props.file.userFile.size}
                </Caption1>

                {took.length > 0 ? (
                    <div>
                        <Caption1>Took: {took}</Caption1>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

function Uploads(): JSX.Element {
    const uploads = state.useState(useStorage.uploaderMiniApp.upload);

    const [openedItems, setOpenedItems] =
        useState<Set<UploadsItemKind>>(initialOpenedItems);

    const handleToggle = (
        _e: AccordionToggleEvent<HTMLElement>,
        data: unknown,
    ): void => {
        const item = (
            data as { value: number }
        ).value.toString() as UploadsItemKind;

        const newOpenedItems = new Set(openedItems);

        if (newOpenedItems.has(item)) {
            newOpenedItems.delete(item);
        } else {
            newOpenedItems.add(item);
        }

        setOpenedItems(newOpenedItems);
    };

    const onClear = useCallback((): void => {
        useStorage.uploaderMiniApp.upload.update(s => {
            s.completed.clear();
        });
    }, []);

    return (
        <div>
            <Toolbar aria-label="Notifications Command bar">
                <ToolbarGroup className="app-notification__toolbar">
                    <ToolbarButton
                        aria-label="Dismiss all notifications"
                        appearance="subtle"
                        icon={<AlertOff />}
                        onClick={onClear}
                        disabled={uploads.completed.size === 0}
                    >
                        Dismiss all
                    </ToolbarButton>
                </ToolbarGroup>
            </Toolbar>

            <Accordion
                multiple
                openItems={Array.from(openedItems.values())}
                onToggle={handleToggle}
                className="uploads"
            >
                {uploads.uploading.size > 0 ? (
                    <AccordionItem value={UploadsItemKind.Uploading}>
                        <AccordionHeader>
                            Uploading {uploads.uploading.size} file(s)
                        </AccordionHeader>
                        <AccordionPanel>
                            <div
                                style={{
                                    display: "grid",
                                    gridAutoFlow: "row",
                                    rowGap: "8px",
                                    gridAutoRows: "max-content",
                                }}
                            >
                                {uploads.uploading.size > 0 ? (
                                    [...uploads.uploading.values()].map(i => (
                                        <Fragment key={i.leaseId + i.path}>
                                            <UploadingCard file={i} />
                                        </Fragment>
                                    ))
                                ) : (
                                    <Text>No uploads</Text>
                                )}
                            </div>
                        </AccordionPanel>
                    </AccordionItem>
                ) : null}

                {uploads.completed.size > 0 ? (
                    <AccordionItem value={UploadsItemKind.Completed}>
                        <AccordionHeader>
                            Completed {uploads.completed.size} file(s)
                        </AccordionHeader>
                        <AccordionPanel>
                            <div
                                style={{
                                    display: "grid",
                                    gridAutoFlow: "row",
                                    rowGap: "8px",
                                    gridAutoRows: "max-content",
                                }}
                            >
                                {uploads.completed.size > 0 ? (
                                    [...uploads.completed.values()].map(i => (
                                        <Fragment key={i.leaseId + i.path}>
                                            <CompletedCard file={i} />
                                        </Fragment>
                                    ))
                                ) : (
                                    <Text>No uploads</Text>
                                )}
                            </div>
                        </AccordionPanel>
                    </AccordionItem>
                ) : null}

                {uploads.pending.size > 0 ? (
                    <AccordionItem value={UploadsItemKind.Pending}>
                        <AccordionHeader>
                            Pending {uploads.pending.size} file(s)
                        </AccordionHeader>
                        <AccordionPanel>
                            <div
                                style={{
                                    display: "grid",
                                    gridAutoFlow: "row",
                                    rowGap: "8px",
                                    gridAutoRows: "max-content",
                                }}
                            >
                                {uploads.pending.size > 0 ? (
                                    [...uploads.pending.values()].map(i => (
                                        <Fragment key={i.leaseId + i.path}>
                                            <PendingCard file={i} />
                                        </Fragment>
                                    ))
                                ) : (
                                    <Text>No uploads</Text>
                                )}
                            </div>
                        </AccordionPanel>
                    </AccordionItem>
                ) : null}

                {uploads.aborted.size > 0 ? (
                    <AccordionItem value={UploadsItemKind.Pending}>
                        <AccordionHeader>
                            Aborted {uploads.aborted.size} file(s)
                        </AccordionHeader>
                        <AccordionPanel>
                            <div
                                style={{
                                    display: "grid",
                                    gridAutoFlow: "row",
                                    rowGap: "8px",
                                    gridAutoRows: "max-content",
                                }}
                            >
                                {uploads.aborted.size > 0 ? (
                                    [...uploads.aborted.values()].map(i => (
                                        <Fragment key={i.leaseId + i.path}>
                                            <AbortedCard file={i} />
                                        </Fragment>
                                    ))
                                ) : (
                                    <Text>No uploads</Text>
                                )}
                            </div>
                        </AccordionPanel>
                    </AccordionItem>
                ) : null}
            </Accordion>
        </div>
    );
}

export default Uploads;
