import React, {
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useEventBus } from "../../../../application/useCases/useEventBus";
import { ShareAccessEvents } from "../../shareAccessEventBus";
import { state } from "../../../state/stateAdapter";
import { useStorage } from "../../../../application/useCases/useStorage";
import Loader from "../../../components/Loader/Loader";
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Divider,
    Subtitle2,
    Text,
    Toolbar,
    ToolbarButton,
    makeStyles,
    shorthands,
} from "@fluentui/react-components";
import { RotateLeftRegular, ResizeRegular } from "@fluentui/react-icons";
import FileInformation from "../../../components/FileDetails/FileInformation/FileInformation";
import FileTags from "../../../components/FileDetails/FileTags/FileTags";
import FullScreenImage from "../../../components/FullScreenImage/FullscreenImage";
import shareAccessState from "../../shareAccessAppState";

import "./shareAccessDetails.css";

const useStyles = makeStyles({
    header: {
        display: "grid",
        paddingLeft: 0,
        paddingRight: 0,
    },
    toolbar: {
        ...shorthands.padding(0),
    },
    accordionHeader: {
        "& > button": {
            ...shorthands.border(0),
            ...shorthands.padding(0),
        },
    },
    accordionPanel: {
        paddingBottom: "20px",
        ...shorthands.margin(0),
    },
    toolbarBtn: {
        "@media screen and (max-width: 460px)": {
            minWidth: 0,
            ...shorthands.padding("5px"),
            "& span": {
                "--fui-Button__icon--spacing": 0,
            },
        },
    },
});

function onRotate(id: number): void {
    const image = document.getElementById(`${id}`);

    if (image) {
        const currentRotation =
            parseInt(image.style.transform.replace(/[^0-9-]/g, "")) || 0;
        const newRotation = currentRotation - 90;
        image.style.transform = `rotate(${newRotation}deg)`;
    }
}

type FileDetailsPreviewProps = {
    videoRef?: RefObject<HTMLVideoElement>;
};

function FilePreview(props: FileDetailsPreviewProps): JSX.Element | null {
    const fileDetails = state.useState(useStorage.sharing.fileDetails);

    if (
        fileDetails &&
        fileDetails.mediaPreview !== null &&
        fileDetails.mediaPreview.thumbnailUrl
    ) {
        return (
            <div className="file-details__preview">
                {fileDetails.fileType !== null &&
                fileDetails.fileType.toLocaleLowerCase() === "video" ? (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                        controls
                        className="file-details__thumbnail"
                        poster={fileDetails.mediaPreview.thumbnailUrl}
                        ref={props.videoRef}
                        key={
                            fileDetails.mediaPreview.previewUrl ??
                            fileDetails.mediaPreview.thumbnailUrl
                        }
                        src={
                            fileDetails.mediaPreview.previewUrl ??
                            fileDetails.mediaPreview.thumbnailUrl
                        }
                    />
                ) : (
                    <img
                        className="file-details__thumbnail"
                        src={fileDetails.mediaPreview.thumbnailUrl}
                        alt="item thumbnail"
                        id={`${fileDetails.id}`}
                    />
                )}
            </div>
        );
    }

    return null;
}

function ShareAccessDetails(): JSX.Element {
    const classes = useStyles();
    const fileDetails = state.useState(useStorage.sharing.fileDetails);
    const isGetFileDetailsQueryInProgress = state.useState(
        useStorage.sharing.isGetFileDetailsQueryInProgress,
    );
    const videoRef = useRef<HTMLVideoElement>(null);
    const activeItem = state.useState(shareAccessState.activeItem);
    const files = state.useState(useStorage.sharing.sharedFiles);
    const [isFullScreenImageOpen, setIsFullScreenImageOpen] = useState(false);
    const [isInit, setIsInit] = useState(false);

    const item = files.find(i => i.id.toString() === activeItem);

    const toggleFullScreenImage = useCallback((): void => {
        setIsFullScreenImageOpen(!isFullScreenImageOpen);
    }, [isFullScreenImageOpen]);

    useEffect(() => {
        if (activeItem !== null) {
            useEventBus.emit(ShareAccessEvents.GetFileDetails, {
                id: activeItem,
            });
            setIsInit(true);
        }
    }, [activeItem]);

    if (isGetFileDetailsQueryInProgress || !isInit) {
        return (
            <div className="app_loading">
                <Loader text="Getting details..." />
            </div>
        );
    }

    if (!item || !fileDetails) {
        return (
            <Text
                style={{
                    height: "100%",
                    width: "100%",
                    display: "grid",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                File not found
            </Text>
        );
    }

    const isNoTags =
        fileDetails.tagGroups == null || fileDetails.tagGroups.length === 0;

    const informationId = "information";
    const tagsId = "tags";

    const defaultOpenItems = [informationId];

    if (!isNoTags) {
        defaultOpenItems.push(tagsId);
    }

    return (
        <div className="file-details">
            <div className="file-details__header">
                <Text truncate block wrap={false} title={fileDetails.title}>
                    {fileDetails.title}
                </Text>

                <FilePreview videoRef={videoRef} />

                <Toolbar className={classes.toolbar}>
                    {fileDetails.mediaPreview !== null &&
                    fileDetails.mediaPreview.thumbnailUrl &&
                    fileDetails.fileType !== null &&
                    fileDetails.fileType.toLocaleLowerCase() !== "video" ? (
                        <>
                            <ToolbarButton
                                appearance="subtle"
                                icon={<RotateLeftRegular />}
                                onClick={(): void => onRotate(fileDetails.id)}
                                title="Rotate preview"
                            />

                            {isFullScreenImageOpen ? (
                                <FullScreenImage
                                    path={fileDetails.mediaPreview.thumbnailUrl}
                                    title={fileDetails.title}
                                    isOpen={isFullScreenImageOpen}
                                    toggleFullScreenImage={
                                        toggleFullScreenImage
                                    }
                                />
                            ) : (
                                <ToolbarButton
                                    appearance="subtle"
                                    onClick={toggleFullScreenImage}
                                    icon={<ResizeRegular />}
                                    title="Enlarge preview"
                                />
                            )}
                        </>
                    ) : null}
                </Toolbar>

                <Divider />
            </div>
            <div className="file-details__body">
                <Accordion
                    multiple
                    collapsible
                    defaultOpenItems={defaultOpenItems}
                >
                    <AccordionItem value="information">
                        <AccordionHeader
                            expandIconPosition="end"
                            className={classes.accordionHeader}
                        >
                            <Subtitle2>Information</Subtitle2>
                        </AccordionHeader>
                        <AccordionPanel className={classes.accordionPanel}>
                            <FileInformation fileDetails={fileDetails} />
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem
                        value="tags"
                        disabled={isNoTags}
                        title={isNoTags ? "No tags" : ""}
                    >
                        <AccordionHeader
                            expandIconPosition="end"
                            className={classes.accordionHeader}
                        >
                            <Subtitle2>Tags</Subtitle2>
                        </AccordionHeader>
                        <AccordionPanel className={classes.accordionPanel}>
                            <FileTags fileDetails={fileDetails} />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
}

export default ShareAccessDetails;
