import { formatSize } from "../../utility";
import { getExtInfo } from "../mime/mime";
import { MimeRepository } from "../mime/mimeRepository";
import { DateFormat, TimeFormatter } from "../timeFormatter/timeFormatter";
import { UserFileRepository } from "./userFileRepository";

export type UserFile = {
    id: string;
    file: File;
    name: string;
    type: string;
    ext: string;
    thmbnl: string;
    size: string;
    lastModified: string;
    description: string;
    sourcePath: string;
    fullFilePath: string;
    umid?: string;
    cameraModelName?: string;
    cameraSerialNumber?: string;
    isCameraCardFile?: boolean;
    fullFileName: string;
};

export type UserFileMetadata = Pick<UserFile, "id" | "description" | "name">;

export type FilesCollection<T> = {
    ignored: T;
    passed: T;
};

enum SortKey {
    Name = "name",
    Type = "type",
    Size = "size",
    LastModified = "lastModified",
}

export function sortFiles(
    files: UserFile[],
    columnKey: SortKey,
    isSortedDescending?: boolean,
): UserFile[] {
    return [...files].sort((a: UserFile, b: UserFile) => {
        let compare: number | undefined;

        switch (columnKey) {
            case SortKey.Name:
                compare = `${a.name}.${a.ext}`.localeCompare(
                    `${b.name}.${b.ext}`,
                    undefined,
                    { sensitivity: "base" },
                );
                break;
            case SortKey.Type:
                compare = a.type.localeCompare(b.type);
                break;
            case SortKey.Size:
                compare = a.file.size - b.file.size;
                break;
            case SortKey.LastModified:
                compare = a.file.lastModified - b.file.lastModified;
                break;
        }

        return isSortedDescending ? -(compare || 0) : compare || 0;
    });
}

export function checkNewUserFileNameForUnique(
    userFileNameList: UserFile[],
    fileForUpdate: UserFile,
    fileName: string,
): boolean {
    return !userFileNameList
        .map(i => `${i.name}.${i.ext}`)
        .includes(`${fileName}.${fileForUpdate.ext}`);
}

function getFileName(fullName: string): string {
    const splitedName = fullName.split(".")[0].length
        ? fullName.split(".")
        : fullName.split(".").slice(1);

    if (splitedName.length > 1) {
        return splitedName.slice(0, splitedName.length - 1).join(".");
    } else {
        return splitedName[0];
    }
}

function filterIgnoredFiles(
    files: File[],
    userFileRepository: UserFileRepository,
    ignoreList: string[],
): FilesCollection<File[]> {
    const ignored: File[] = [];
    const passed: File[] = [];

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file && !userFileRepository.isIgnoredFile(file.name, ignoreList)) {
            passed.push(file);
        } else {
            ignored.push(file);
        }
    }

    return { ignored, passed };
}

function validateFiles(files: FileList, addDataMethod: AddDataMethod): File[] {
    const sourcePaths = new Set<string>();
    const validFiles: File[] = [];

    for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const sourcePath =
            addDataMethod === AddDataMethod.DragNDrop
                ? (file as File & { path: string }).path
                : (file as File & { webkitRelativePath: string })
                      .webkitRelativePath;

        const effectiveSourcePath =
            sourcePath.length === 0 ? file.name : sourcePath;

        if (!sourcePaths.has(effectiveSourcePath) && file.size > 0) {
            validFiles.push(file);
            sourcePaths.add(effectiveSourcePath);
        }
    }

    return validFiles;
}

export enum AddDataMethod {
    CameraCard,
    DragNDrop,
    Files,
}

function formGenericUserFiles(
    files: File[],
    mimeRepository: MimeRepository,
    userFileRepository: UserFileRepository,
    addDataMethod: AddDataMethod,
    timeFormatter: TimeFormatter,
): UserFile[] {
    return files.map((file, i) => {
        const ext = file.name.split(".").pop() || "";

        const originalSourcePath =
            addDataMethod === AddDataMethod.DragNDrop
                ? (file as File & { path: string }).path
                : (file as File & { webkitRelativePath: string })
                      .webkitRelativePath;
        let sourcePath = originalSourcePath;

        if (originalSourcePath.length > 0) {
            const sourcePathSplit = sourcePath.split("/");
            sourcePath = sourcePathSplit
                .slice(0, sourcePathSplit.length - 1)
                .join("/");
        }

        const extInfo = getExtInfo(ext, file.name);
        const thmbnl = userFileRepository.getIconPath(extInfo.type);

        return {
            id: `${originalSourcePath}-${file.name}-${file.size}-${i}`,
            file,
            name: getFileName(file.name),
            type: extInfo.category,
            ext,
            thmbnl,
            size: formatSize(file.size),
            lastModified: timeFormatter.formatDate(
                file.lastModified,
                DateFormat.FullMonthDayYear,
            ),
            umid: "",
            description: "",
            sourcePath,
            cameraModelName: "",
            cameraSerialNumber: "",
            isCameraCardFile: addDataMethod === AddDataMethod.CameraCard,
            fullFilePath:
                sourcePath.length > 0 ? originalSourcePath : file.name,
            fullFileName: file.name,
        };
    });
}

async function getThmbnl(
    element: Element,
    files: File[],
    addDataMethod: AddDataMethod,
): Promise<string> {
    return new Promise((resolve, reject) => {
        const info = element.lastElementChild?.getAttribute("uri");
        if (!info) {
            reject();
            return;
        }

        const infoParts = info.split("/");
        const infoFileName = infoParts[infoParts.length - 1];
        const infoFileExtension = infoFileName.split(".").pop()?.toLowerCase();

        if (infoFileExtension !== "xml") {
            const file =
                addDataMethod === AddDataMethod.DragNDrop
                    ? files.find(i =>
                          (i as File & { path: string }).path.includes(
                              infoFileName,
                          ),
                      )
                    : files.find(i =>
                          i.webkitRelativePath.includes(infoFileName),
                      );

            if (file) {
                const reader = new FileReader();

                reader.onload = (): void => {
                    resolve(reader.result as string);
                };
                reader.onerror = (): void => reject();
                reader.readAsDataURL(file);
            } else {
                reject();
            }
        } else {
            resolve("");
        }
    });
}

async function formCameraCardUserFiles(
    document: Document,
    files: File[],
    mimeRepository: MimeRepository,
    userFileRepository: UserFileRepository,
    addDataMethod: AddDataMethod,
    timeFormatter: TimeFormatter,
): Promise<UserFile[]> {
    const cameraSerial =
        document
            .getElementsByTagName("Properties")[0]
            .getElementsByTagName("System")[0]
            .getAttribute("systemId") ?? "";
    const materials = document
        .getElementsByTagName("Contents")[0]
        .getElementsByTagName("Material");

    const userFiles: UserFile[] = [];
    const fileMap: { [name: string]: File } = {};

    for (const file of files) {
        fileMap[file.name] = file;
    }

    for (let i = 0; i < materials.length; i++) {
        const material = materials[i];
        const name = material.getAttribute("uri")?.split(/[\\/\\]/gm)[2];

        if (!name || !fileMap[name]) continue;

        const f = fileMap[name];
        const ct = mimeRepository.getContentType(
            material.getAttribute("type") ?? "",
        );

        const splittedName = name.split(".");
        const fallbackExt = splittedName[splittedName.length - 1];

        const ext = ct
            ? mimeRepository.getExtension(ct) || fallbackExt
            : material.getAttribute("type") || fallbackExt;

        const extInfo = getExtInfo(ext, name);
        const type = ct ? extInfo.category : fallbackExt;

        const sourcePath =
            addDataMethod === AddDataMethod.DragNDrop
                ? (f as File & { path: string }).path
                : (f as File & { webkitRelativePath: string })
                      .webkitRelativePath;

        const thmbnl =
            (await getThmbnl(material, files, addDataMethod)) ||
            userFileRepository.getIconPath(extInfo.type);

        userFiles.push({
            file: f,
            size: formatSize(f.size),
            lastModified: timeFormatter.formatDate(
                f.lastModified,
                DateFormat.FullMonthDayYear,
            ),
            id: `${sourcePath}-${f.name}-${f.size}-${i}`,
            umid: material.getAttribute("umid") ?? "",
            name: getFileName(name),
            type: type ?? "Unknown",
            ext,
            thmbnl,
            description: "",
            cameraSerialNumber: cameraSerial,
            cameraModelName: "",
            isCameraCardFile: true,
            sourcePath,
            fullFilePath: sourcePath.length > 0 ? sourcePath : f.name,
            fullFileName: f.name,
        });
    }

    return userFiles;
}

export async function formUserFiles(
    userFileRepository: UserFileRepository,
    mimeRepository: MimeRepository,
    timeFormatter: TimeFormatter,
    fileList: FileList,
    addDataMethod: AddDataMethod,
    ignoreList: string[],
): Promise<FilesCollection<UserFile[] | null>> {
    let document;
    const needToCheckCameraCard =
        addDataMethod === AddDataMethod.CameraCard ||
        addDataMethod === AddDataMethod.DragNDrop;

    if (needToCheckCameraCard) {
        document = await userFileRepository.parseAsCameraCard(
            fileList,
            addDataMethod,
        );
    }

    const validFiles = validateFiles(fileList, addDataMethod);

    if (document !== null && document !== undefined && needToCheckCameraCard) {
        return {
            passed: await formCameraCardUserFiles(
                document,
                validFiles,
                mimeRepository,
                userFileRepository,
                addDataMethod,
                timeFormatter,
            ),
            ignored: [],
        };
    } else {
        const filteredFiles = filterIgnoredFiles(
            validFiles,
            userFileRepository,
            ignoreList,
        );

        return {
            ignored: formGenericUserFiles(
                filteredFiles.ignored,
                mimeRepository,
                userFileRepository,
                addDataMethod,
                timeFormatter,
            ),
            passed: formGenericUserFiles(
                filteredFiles.passed,
                mimeRepository,
                userFileRepository,
                addDataMethod,
                timeFormatter,
            ),
        };
    }
}
