import React, { useCallback, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import {
    Field,
    Input,
    Skeleton,
    SkeletonItem,
    Tooltip,
    makeStyles,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import "./colorField.css";
import { useCommonFluentuiStyles } from "../../../styles/griffel";
import { getBrandTokensFromPalette } from "../../../theme/themeDesigner/themeDesigner";
import { useNotification } from "../../../../application/useCases/useNotification";

type ColorFieldProps = {
    name: string;
    value?: string;
    label: string;
    onChange: (name: string, color: string) => void;
};

const useStyles = makeStyles({
    tooltip: {
        ...shorthands.padding(tokens.spacingHorizontalXS),
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    },
});

export function isValidColor(color: string | undefined | null): boolean {
    if (color === undefined || color === null) {
        return false;
    }

    return /^#([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6})$/i.test(color);
}

function ColorField(props: ColorFieldProps): JSX.Element {
    const [isPaleteGenerating, setIsPaleteGenerating] = useState(false);
    const commonClasses = useCommonFluentuiStyles();
    const styles = useStyles();
    const [tempColor, setTempColor] = useState<string | undefined>(props.value);
    const pallete =
        props.value && isValidColor(props.value)
            ? getBrandTokensFromPalette(props.value)
            : [];

    const updatePallete = useCallback(() => {
        if (tempColor !== undefined && isValidColor(tempColor)) {
            const newPallete = getBrandTokensFromPalette(tempColor);

            if (newPallete[80] !== tempColor) {
                useNotification.show(
                    "Your selected color was not available. We've chosen the closest available match.",
                    "warning",
                );
                setIsPaleteGenerating(false);
                setTempColor(newPallete[80]);
                props.onChange(props.name, newPallete[80]);
            } else {
                setIsPaleteGenerating(false);
                props.onChange(props.name, tempColor);
            }
        } else {
            useNotification.show(
                "Your selected color is not valid HEX.",
                "error",
            );
        }
    }, [props, tempColor]);

    const onChangeColor = useCallback((color: string): void => {
        setTempColor(color);
        setIsPaleteGenerating(true);
    }, []);

    const onSubmit = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key.toLowerCase() === "enter") {
                updatePallete();
            }
        },
        [updatePallete],
    );

    useEffect(() => {
        if (props.value) {
            setTempColor(props.value);
        }
    }, [props.value]);

    return (
        <>
            <div className="color-picker">
                <Tooltip
                    relationship="label"
                    withArrow
                    content={{
                        children: (
                            <div>
                                <HexColorPicker
                                    color={tempColor}
                                    className="color-picker__picker"
                                    onChange={onChangeColor}
                                    onBlur={updatePallete}
                                />
                            </div>
                        ),
                        className: styles.tooltip,
                    }}
                >
                    <div
                        className="color-picker__preview"
                        style={
                            tempColor
                                ? {
                                      backgroundColor: tempColor,
                                  }
                                : {}
                        }
                    ></div>
                </Tooltip>

                <Field
                    label={props.label}
                    validationState={
                        !isValidColor(props.value) ? "error" : "none"
                    }
                    className={commonClasses.field}
                >
                    <Input
                        size="large"
                        name={props.name}
                        value={tempColor}
                        onChange={(_e, d): void => onChangeColor(d.value)}
                        onKeyUp={onSubmit}
                        onBlur={updatePallete}
                    />
                </Field>
            </div>
            <div className="color-picker__pallete">
                {isPaleteGenerating === true ? (
                    <Skeleton>
                        <SkeletonItem size={40} />
                    </Skeleton>
                ) : (
                    Object.entries(pallete).map(([key, value]) => (
                        <div
                            className="pallete__item"
                            key={key}
                            style={{ backgroundColor: value }}
                            title={value}
                        />
                    ))
                )}
            </div>
        </>
    );
}

export default ColorField;
