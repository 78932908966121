import React, { useEffect } from "react";
import { state } from "../../../../state/stateAdapter";
import IgnoredPathsSettings from "./IgnoredPathsSettings/IgnoredPathsSettings";
import { UploaderAdminHash, createTempUploaderConfig } from "./shared";
import { adminState } from "../adminState/adminState";
import "./uploaderSettings.scss";
import { uploaderSettingsState } from "./uploaderSettingsState";
import CommonSettings from "./CommonSettings/CommonSettings";

function UploaderSettings(): JSX.Element {
    const urlEntities = state.useState(adminState.urlEntities);

    useEffect(() => {
        uploaderSettingsState.tempUploaderSettings.set(
            createTempUploaderConfig(),
        );
    }, []);

    if (urlEntities && urlEntities.hash[0] === UploaderAdminHash.IgnoredPaths) {
        return <IgnoredPathsSettings />;
    }

    return <CommonSettings />;
}

export default UploaderSettings;
