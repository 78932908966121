import React from "react";
import { router } from "../../../../router";
import TileIcon from "../../../../components/Tiles/TileIcon/TileIcon";
import { CollectionInfo } from "../../../../../domain/collection/collections";
import { protectedDataParamsKeys } from "../../../../../domain/dataParams/dataParams";

type CollectionTilesProps = {
    items: CollectionInfo[];
};

export default function CollectionTiles(
    props: CollectionTilesProps,
): JSX.Element {
    return (
        <div className="tiles">
            {props.items.map(i => (
                <TileIcon
                    key={i.id}
                    title={i.title}
                    caption={i.userName}
                    selected={false}
                    isSelectable={false}
                    isFile={false}
                    filePath=""
                    link={router.createRoute({
                        search: {
                            items: [
                                {
                                    key: "id",
                                    value: i.id,
                                },
                            ],
                            protect: [...protectedDataParamsKeys, "viewMode"],
                        },
                    })}
                />
            ))}
        </div>
    );
}
