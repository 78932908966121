import React from "react";
import { PanelTab } from "../../../models";
import { shareAccessManager } from "../../shareAccessManager";
import ShareAccessDetails from "./ShareAccessDetails";

const shareAccessDetailsTab: PanelTab = {
    id: "details",
    title: "Details",
    content: <ShareAccessDetails />,
    onClose: () => {
        shareAccessManager.clearActiveItem();
        shareAccessManager.deleteTab("details");
    },
};

export default shareAccessDetailsTab;
