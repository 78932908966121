import React from "react";
import Collections from "./Collections/Collections";
import { state } from "../../state/stateAdapter";
import Admin from "./Admin/Admin";
import { useStorage } from "../../../application/useCases/useStorage";
import { MiniAppKind } from "../../../domain/miniApp/miniApp";
import FileExplorer from "./FileExplorer/FileExplorer";
import Uploader from "./Uploader/Uploader";
import Jobs from "./Jobs/Jobs";

function MiniApp(): JSX.Element {
    const openedAppPage = state.useState(useStorage.appCommon.activeMiniApp);

    switch (openedAppPage) {
        case MiniAppKind.Explorer:
            return <FileExplorer />;

        case MiniAppKind.Collections:
            return <Collections />;

        case MiniAppKind.Admin:
            return <Admin />;

        case MiniAppKind.Uploader:
            return <Uploader />;

        case MiniAppKind.Jobs:
            return <Jobs />;

        default:
            throw new Error("[MINI_APP]: Wrong path");
    }
}

export default MiniApp;
