import { apiCall, handleNotOk, validateRes } from "../api/api";
import {
    Jobs,
    JobsFetchAllParams,
    JobsPort,
    JobsViewParams,
} from "../../application/jobs/jobsPort";
import qs from "qs";

export class JobsAdapter implements JobsPort {
    async fetchLatest(excludeSignalR: boolean): Promise<Jobs> {
        // TODO: Temp solution (excludeSignalR)
        const res = await apiCall(
            `/api/jobs/latest?excludeSignalR=${excludeSignalR}`,
            {
                method: "GET",
            },
        );

        return await validateRes(res);
    }

    async view(
        params: JobsViewParams,
    ): Promise<Record<"countNewCompletedJobs", number>> {
        const res = await apiCall("/api/jobs/viewed", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async fetchAll(params: JobsFetchAllParams): Promise<Jobs> {
        const res = await apiCall(`/api/jobs?${qs.stringify(params)}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async downloadLink(jobId: number): Promise<string> {
        const res = await apiCall(`/api/jobs/${jobId}/download-link`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        handleNotOk(res);

        return await res.text();
    }
}
