import {
    DataGrid,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    TableColumnDefinition,
    createTableColumn,
    Switch,
    SwitchOnChangeData,
} from "@fluentui/react-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    AIR_ARCHIVE_ITEMS,
    AirArchiveConfig,
    AirArchiveItem,
    DEFAULT_AIR_ARCHIVE_CONFIG,
} from "../../../../../domain/airArchive/airArchive";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";
import "./airArchive.css";
import Loader from "../../../../components/Loader/Loader";
import { useAirArchive } from "../../../../../application/useCases/useAirArchive";
import { SaveRegular } from "@fluentui/react-icons";
import AdminLayout from "../AdminLayout/AdminLayout";

type AirArchiveItemUI = AirArchiveItem & { enabled: boolean };

function AirArchive(): JSX.Element {
    const airArchiveConfig = state.useState(useStorage.airArchive.config);
    const isFetchConfigQueryInProgress = state.useState(
        useStorage.airArchive.isFetchConfigQueryInProgress,
    );
    const isUpdateConfigQueryInProgress = state.useState(
        useStorage.airArchive.isUpdateConfigQueryInProgress,
    );
    const [tempConfig, setTempConfig] = useState<
        AirArchiveConfig | undefined
    >();

    const items: AirArchiveItemUI[] = useMemo(() => {
        if (tempConfig === undefined) {
            return [];
        }

        return AIR_ARCHIVE_ITEMS.map(i => {
            return {
                ...i,
                enabled: tempConfig.enabledExtensions.includes(i.fileExtension),
            };
        });
    }, [tempConfig]);

    const onChangeExtractionEnabled = useCallback(
        () =>
            setTempConfig(temp => ({
                ...temp!,
                extractionEnabled: !temp!.extractionEnabled,
            })),
        [],
    );

    const onChangeEnabled = useCallback(
        (data: SwitchOnChangeData, item: AirArchiveItemUI) => {
            setTempConfig(temp => ({
                ...temp!,
                enabledExtensions: data.checked
                    ? [...temp!.enabledExtensions, item.fileExtension]
                    : temp!.enabledExtensions.filter(
                          e => e !== item.fileExtension,
                      ),
            }));
        },
        [],
    );

    const columns: TableColumnDefinition<AirArchiveItemUI>[] = [
        createTableColumn<AirArchiveItemUI>({
            columnId: "archiveFormat",
            renderHeaderCell: () => "Archive format",
            renderCell: item => item.archiveFormat,
        }),
        createTableColumn<AirArchiveItemUI>({
            columnId: "compressionFormat",
            renderHeaderCell: () => "Compression format",
            renderCell: item => item.compressionFormat,
        }),
        createTableColumn<AirArchiveItemUI>({
            columnId: "fileExstensions",
            renderHeaderCell: () => "File extensions",
            renderCell: item => item.fileExtension,
        }),
        createTableColumn<AirArchiveItemUI>({
            columnId: "enabled",
            renderHeaderCell: () => "Enabled",
            renderCell: item => (
                <Switch
                    checked={item.enabled}
                    onChange={(_ev, data): void => onChangeEnabled(data, item)}
                />
            ),
        }),
    ];

    const onSave = useCallback(() => {
        useAirArchive.updateConfig(tempConfig!);
    }, [tempConfig]);

    useEffect(() => {
        useAirArchive
            .fetchConfig()
            .then(c => setTempConfig(c ?? DEFAULT_AIR_ARCHIVE_CONFIG));
    }, []);

    if (
        tempConfig === undefined ||
        isFetchConfigQueryInProgress ||
        isUpdateConfigQueryInProgress
    ) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            buttons={[
                {
                    title: "Save changes",
                    icon: <SaveRegular />,
                    appearance: "primary",
                    onClick: onSave,
                    disabled:
                        JSON.stringify(airArchiveConfig) ===
                        JSON.stringify(tempConfig),
                },
            ]}
        >
            <div className="air-archive-settings">
                <Switch
                    checked={tempConfig.extractionEnabled}
                    label="Enable extraction processing"
                    onClick={onChangeExtractionEnabled}
                />

                <DataGrid items={items} columns={columns} sortable>
                    <DataGridHeader>
                        <DataGridRow>
                            {({ renderHeaderCell }): JSX.Element => (
                                <DataGridHeaderCell>
                                    {renderHeaderCell()}
                                </DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody<AirArchiveItemUI>>
                        {({ item, rowId }): JSX.Element => (
                            <DataGridRow<AirArchiveItemUI> key={rowId}>
                                {({ renderCell }): JSX.Element => (
                                    <DataGridCell>
                                        {renderCell(item)}
                                    </DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            </div>
        </AdminLayout>
    );
}

export default AirArchive;
