/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    makeStyles,
    mergeClasses,
    Text,
    Tab,
    TabList,
    shorthands,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { tokens } from "@fluentui/react-theme";
import React, { useEffect, useState } from "react";
import { state } from "../../state/stateAdapter";
import "./appPanel.scss";
import { appManager } from "../../appManager/appManager";
import { StorageRepository } from "../../../domain/storage/storageRepository";
import { AppPanelTabsStateValue } from "../../state/stateValues";

const useStyles = makeStyles({
    panel: {
        backgroundColor: tokens.colorNeutralBackground3,
        transitionProperty: "width, opacity",
        transitionDuration: "0.15s, 0.25s",
        transitionTimingFunction: "cubic-bezier(0.65, 0.05, 0.36, 1)",
        transitionDelay: "0s",
        width: "0px",
        opacity: 0,
        position: "relative",
        height: "100%",
        ...shorthands.overflow("hidden"),
        ...shorthands.borderLeft("1px", "solid", tokens.colorNeutralStroke1),
    },
    panelOpened: {
        opacity: 1,
        width: "400px",
        "@media screen and (max-width: 600px)": {
            position: "absolute",
            right: 0,
            width: "calc(100vw)",
            zIndex: 10,
        },
    },
    panelClosed: {
        width: 0,
        opacity: 0,

        "@media screen and (max-width: 600px)": {
            position: "absolute",
            right: 0,
        },
    },
    tabs: {
        display: "grid",
        gridAutoFlow: "column",
        width: "100%",
    },
    tab: {
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.borderRadius(0, 0, 0, 0),

        ":hover": {
            backgroundColor: tokens.colorNeutralBackground3,
        },
    },
    unactiveTab: {
        backgroundColor: tokens.colorSubtleBackgroundSelected,
    },
    body: {
        backgroundColor: tokens.colorNeutralBackground3,
        height: "calc(100% - 44px)",
        paddingTop: "12px",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "12px",
    },
});

type AppPanelProps = {
    activeTabId: StorageRepository<string>;
    isOpen: StorageRepository<boolean>;
    tabs: StorageRepository<AppPanelTabsStateValue>;
};

function AppPanel(props: AppPanelProps): JSX.Element {
    const classes = useStyles();
    const activeTabId = state.useState(props.activeTabId);
    const isOpen = state.useState(props.isOpen);
    const tabs = state.useState(props.tabs);

    const [appPanelStatusClassName, setAppPanelStatusClassName] = useState(
        mergeClasses(
            classes.panel,
            isOpen ? classes.panelOpened : classes.panelClosed,
        ),
    );

    const tabsArray = Array.from(tabs.values());

    useEffect(() => {
        setAppPanelStatusClassName(
            mergeClasses(
                classes.panel,
                isOpen ? classes.panelOpened : classes.panelClosed,
            ),
        );
    }, [isOpen, classes.panel, classes.panelClosed, classes.panelOpened]);

    useEffect(() => {
        if (state.appPanel.isOpen.get() && tabs.size === 0) {
            state.appPanel.isOpen.set(false);
        }
    }, [tabs.size]);

    return (
        <aside className={appPanelStatusClassName}>
            <TabList
                className={classes.tabs}
                defaultSelectedValue={activeTabId}
                onTabSelect={(_, data): void => {
                    state.appPanel.activeTabId.set(data.value as string);
                }}
                selectedValue={activeTabId}
                appearance="transparent"
            >
                {tabsArray.map(tab => (
                    <Tab
                        value={tab.id}
                        key={tab.id}
                        className={mergeClasses(
                            classes.tab,
                            tab.id !== activeTabId ? classes.unactiveTab : "",
                        )}
                    >
                        <div className="app-panel__tab">
                            <Text truncate wrap={false}>
                                {tab.title}
                            </Text>

                            <span
                                className="app-panel__tab-close"
                                title="close tab"
                                onClick={(e): void => {
                                    e.stopPropagation();
                                    appManager.deleteTab(tab.id);

                                    if (tab.onClose) {
                                        tab.onClose();
                                    }
                                }}
                            >
                                <DismissRegular />
                            </span>
                        </div>
                    </Tab>
                ))}
            </TabList>
            <div className={classes.body}>
                {tabsArray.map(tab => (
                    <div
                        key={tab.id}
                        className={mergeClasses(
                            "app-panel__tab-body",
                            tab.id === activeTabId
                                ? "app-panel__tab-body_active"
                                : "",
                        )}
                    >
                        {tab.content}
                    </div>
                ))}
            </div>
        </aside>
    );
}

export default AppPanel;
