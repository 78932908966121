import {
    Toolbar,
    ToolbarGroup,
    ToolbarButton,
    makeStyles,
    shorthands,
    tokens,
    Overflow,
    Button,
    Menu,
    MenuList,
    MenuPopover,
    MenuTrigger,
    useOverflowMenu,
    MenuDivider,
    useIsOverflowGroupVisible,
    MenuItem,
    MenuItemProps,
    useIsOverflowItemVisible,
    OverflowItem,
} from "@fluentui/react-components";
import {
    DismissRegular,
    PanelRightContractRegular,
    PanelLeftExpandRegular,
    DeleteRegular,
    TextBulletListSquareEditRegular,
    CloudRegular,
    MoreHorizontal20Filled,
    EyeOffRegular,
} from "@fluentui/react-icons";
import React from "react";
import { state } from "../../../../state/stateAdapter";
import AddDataMenu from "../AddDataMenu/AddDataMenu";
import {
    clearSelection,
    removeSelectedFiles,
    toggleAppPanel,
} from "../shared/actions";
import ChangeMetadataDialogPanel from "../ChangeMetadata/ChangeMetadataDialogPanel";
import { uploaderState } from "../shared/state";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { appManager } from "../../../../appManager/appManager";
import uploadDestinationTab from "../UploadDestination/uploadDestinationTab";
import { showIgnoredFiles } from "../IgnoredFilesDialog/IgnoredFilesDialog";

const useStyles = makeStyles({
    toolBar: {
        ...shorthands.overflow("hidden"),
        display: "grid",
        ...shorthands.padding("4px", "0"),
        gridAutoFlow: "column",
        justifyContent: "space-between",
    },
    toggleButton: {
        "& span": {
            color: `${tokens.colorNeutralForeground1}`,
        },
        ":hover": {
            "& span": {
                color: `${tokens.colorBrandForeground1}`,
            },
        },
    },
});

interface ToolbarOverflowMenuItemProps extends Omit<MenuItemProps, "id"> {
    id: string;
}

const ToolbarOverflowMenuItem: React.FC<
    ToolbarOverflowMenuItemProps
> = props => {
    const { id } = props;
    const locations = state.useState(useStorage.uploaderMiniApp.locations);
    const filesSelection = state.useState(uploaderState.selection);
    const tabs = state.useState(state.appPanel.tabs, {
        notifyCondition: s => s.size !== state.appPanel.tabs.get().size,
    });
    const isOpen = state.useState(state.appPanel.isOpen);

    const isVisible = useIsOverflowItemVisible(id);
    const ignoredFiles = useStorage.uploaderMiniApp.ignoredUserFiles.get();

    if (isVisible) {
        return null;
    }

    if (id.includes("upload") && locations !== null && locations.length > 0) {
        return (
            <MenuItem
                icon={<CloudRegular />}
                onClick={(): void => appManager.toggleTab(uploadDestinationTab)}
            >
                Upload to
            </MenuItem>
        );
    }

    if (
        id.includes("ignoredFiles") &&
        ignoredFiles !== null &&
        ignoredFiles.length > 0
    ) {
        return (
            <MenuItem
                icon={<EyeOffRegular />}
                onClick={(): void => showIgnoredFiles()}
            >
                Ignored files
            </MenuItem>
        );
    }

    if (id.includes("changeMetadata")) {
        return (
            <MenuItem
                icon={<TextBulletListSquareEditRegular />}
                onClick={(): void => {
                    state.appDialogPanel.set(() => (
                        <ChangeMetadataDialogPanel
                            items={Array.from(filesSelection.values())}
                        />
                    ));
                }}
            >
                Change properties
            </MenuItem>
        );
    }

    if (id.includes("remove")) {
        return (
            <MenuItem icon={<DeleteRegular />} onClick={removeSelectedFiles}>
                Remove selected
            </MenuItem>
        );
    }

    if (id.includes("selection")) {
        return (
            <>
                {filesSelection.size > 0 ? (
                    <MenuItem
                        icon={<DismissRegular />}
                        onClick={clearSelection}
                    >
                        {`Selected ${filesSelection.size} item(s)`}
                    </MenuItem>
                ) : null}
            </>
        );
    }

    if (id.includes("toggleAppPanel")) {
        return (
            <MenuItem
                onClick={toggleAppPanel}
                disabled={tabs.size === 0}
                icon={
                    isOpen ? (
                        <PanelRightContractRegular />
                    ) : (
                        <PanelLeftExpandRegular style={{ rotate: "-180deg" }} />
                    )
                }
            >
                {isOpen ? "Close" : "Open"} app panel
            </MenuItem>
        );
    }

    return null;
};

const ToolbarMenuOverflowDivider: React.FC<{
    id: string;
}> = props => {
    const isGroupVisible = useIsOverflowGroupVisible(props.id);

    if (isGroupVisible === "visible") {
        return null;
    }

    return <MenuDivider />;
};

type ToolbarOverflowItemProps = {
    children: JSX.Element;
    overflowId: string;
    overflowGroupId?: string;
};

function ToolbarOverflowItem(props: ToolbarOverflowItemProps): JSX.Element {
    return (
        <OverflowItem id={props.overflowId} groupId={props.overflowGroupId}>
            {props.children}
        </OverflowItem>
    );
}

const OverflowMenu: React.FC<{
    itemIds: Array<Array<string>>;
}> = ({ itemIds }) => {
    const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    icon={<MoreHorizontal20Filled />}
                    aria-label="More items"
                    appearance="subtle"
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {itemIds.map((group, groupIndx) => {
                        const isLast = groupIndx === itemIds.length - 1;
                        return (
                            <React.Fragment key={group.join()}>
                                {group.map(i => (
                                    <ToolbarOverflowMenuItem key={i} id={i} />
                                ))}

                                {!isLast && (
                                    <ToolbarMenuOverflowDivider
                                        id={`${groupIndx + 1}`}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

function UploaderCommandBar(): JSX.Element {
    const filesSelection = state.useState(uploaderState.selection);
    const tabs = state.useState(state.appPanel.tabs);
    const isOpen = state.useState(state.appPanel.isOpen);
    const classes = useStyles();
    const locations = state.useState(useStorage.uploaderMiniApp.locations);

    const ignoredFiles = useStorage.uploaderMiniApp.ignoredUserFiles.get();

    return (
        <>
            <Overflow padding={90}>
                <Toolbar
                    aria-label="Uploader command bar"
                    className={classes.toolBar}
                >
                    <ToolbarGroup className="command-bar__main-container">
                        <AddDataMenu appearance="subtle" />

                        {filesSelection.size > 0 ? (
                            <>
                                <ToolbarOverflowItem
                                    overflowId="changeMetadata"
                                    overflowGroupId="0"
                                >
                                    <ToolbarButton
                                        name="changeMetadata"
                                        appearance="subtle"
                                        icon={
                                            <TextBulletListSquareEditRegular />
                                        }
                                        onClick={(): void => {
                                            state.appDialogPanel.set(() => (
                                                <ChangeMetadataDialogPanel
                                                    items={Array.from(
                                                        filesSelection.values(),
                                                    )}
                                                />
                                            ));
                                        }}
                                    >
                                        Change properties
                                    </ToolbarButton>
                                </ToolbarOverflowItem>

                                {locations !== null && locations.length > 0 ? (
                                    <ToolbarOverflowItem
                                        overflowId="upload"
                                        overflowGroupId="0"
                                    >
                                        <ToolbarButton
                                            name="upload"
                                            appearance="subtle"
                                            icon={<CloudRegular />}
                                            onClick={(): void =>
                                                appManager.addTab(
                                                    uploadDestinationTab,
                                                )
                                            }
                                        >
                                            Upload to
                                        </ToolbarButton>
                                    </ToolbarOverflowItem>
                                ) : null}

                                <ToolbarOverflowItem
                                    overflowId="remove"
                                    overflowGroupId="1"
                                >
                                    <ToolbarButton
                                        name="remove"
                                        appearance="subtle"
                                        icon={<DeleteRegular />}
                                        onClick={removeSelectedFiles}
                                    >
                                        Remove selected
                                    </ToolbarButton>
                                </ToolbarOverflowItem>

                                <ToolbarOverflowItem
                                    overflowId="selection"
                                    overflowGroupId="1"
                                >
                                    <ToolbarButton
                                        name="selection"
                                        appearance="subtle"
                                        icon={<DismissRegular />}
                                        onClick={clearSelection}
                                    >
                                        {`Selected ${filesSelection.size} item(s)`}
                                    </ToolbarButton>
                                </ToolbarOverflowItem>
                            </>
                        ) : null}

                        {ignoredFiles !== null && ignoredFiles.length > 0 ? (
                            <ToolbarOverflowItem
                                overflowId="ignoredFiles"
                                overflowGroupId="0"
                            >
                                <ToolbarButton
                                    name="ignoredFiles"
                                    onClick={showIgnoredFiles}
                                    title="Show ignored files"
                                    icon={<EyeOffRegular />}
                                >
                                    Show ignored files
                                </ToolbarButton>
                            </ToolbarOverflowItem>
                        ) : null}
                    </ToolbarGroup>

                    <ToolbarGroup className="command-bar__far-container">
                        <ToolbarOverflowItem
                            overflowId="toggleAppPanel"
                            overflowGroupId="2"
                        >
                            <ToolbarButton
                                name="toggleAppPanel"
                                onClick={toggleAppPanel}
                                disabled={tabs.size === 0}
                                title={isOpen ? "Close panel" : "Open panel"}
                                icon={
                                    isOpen ? (
                                        <PanelRightContractRegular />
                                    ) : (
                                        <PanelLeftExpandRegular
                                            style={{ rotate: "-180deg" }}
                                        />
                                    )
                                }
                            />
                        </ToolbarOverflowItem>
                    </ToolbarGroup>

                    <OverflowMenu
                        itemIds={[
                            ["upload", "changeMetadata", "ignoredFiles"],
                            ["remove", "selection"],
                            ["toggleAppPanel"],
                        ]}
                    />
                </Toolbar>
            </Overflow>
        </>
    );
}

export default UploaderCommandBar;
