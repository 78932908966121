import { createState } from "@persevie/statemanjs";
import { StorageItemPort } from "../../../../application/storageItemPort";
import { AirFile } from "../../../../domain/airFile/airFile";

interface FileExplorerLocalStatePort {
    breadcrumbsPath: StorageItemPort<AirFile[]>;
    collectionName: StorageItemPort<string | null>;
    isCollectionsMenuOpen: StorageItemPort<boolean>;
}

const breadcrumbsPathState = createState<AirFile[]>([]);

const collectionNameState = createState(null);

const isCollectionsMenuOpenState = createState(false);

class FileExplorerLocalStateAdapter implements FileExplorerLocalStatePort {
    readonly breadcrumbsPath: StorageItemPort<AirFile[]> = breadcrumbsPathState;

    readonly collectionName: StorageItemPort<string | null> =
        collectionNameState;

    readonly isCollectionsMenuOpen: StorageItemPort<boolean> =
        isCollectionsMenuOpenState;
}

export const fileExplorerLocalState = new FileExplorerLocalStateAdapter();
