import { MainRouteListener, RouteListener } from "./routeListener";

// TODO: refactor, dry
export class RouteListenersManager {
    private _initListener: MainRouteListener | undefined;
    private _staticListeners: Map<string, RouteListener> = new Map();
    private _dynamicListeners: Array<{
        pattern: RegExp;
        listener: RouteListener;
    }> = [];

    init(initListener: MainRouteListener): void {
        this._initListener = initListener;
    }

    add(listener: RouteListener): void {
        if (listener.pathname.includes(":")) {
            const pattern = new RegExp(
                `^${listener.pathname.replace(/:\w+/g, "([^/]+)")}$`,
            );
            this._dynamicListeners.push({ pattern, listener });
        } else {
            this._staticListeners.set(listener.pathname, listener);
        }
    }

    remove(listener: RouteListener): void {
        if (listener.pathname.includes(":")) {
            const pattern = new RegExp(
                `^${listener.pathname.replace(/:\w+/g, "([^/]+)")}$`,
            );
            this._dynamicListeners = this._dynamicListeners.filter(
                dl => dl.pattern.source !== pattern.source,
            );
        } else {
            this._staticListeners.delete(listener.pathname);
        }
    }

    notify(url: URL): void {
        if (this._initListener === undefined) {
            throw new Error("init listener is not set");
        }

        this._initListener.onRouteChange(url);

        const staticListener = this._staticListeners.get(url.pathname);
        if (staticListener) {
            staticListener.onRouteChange(url);
            return;
        }

        for (const { pattern, listener } of this._dynamicListeners) {
            if (pattern.test(url.pathname)) {
                listener.onRouteChange(url);
                break;
            }
        }
    }
}
