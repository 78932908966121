import React from "react";
import { Subtitle1, Text } from "@fluentui/react-components";
import { useViewExtendedStyles } from "../../VideoExtendedView";

export type ExtendedViewEmptySearch = {
    title: string;
};

function ExtendedViewEmptySearch(
    props: ExtendedViewEmptySearch,
): JSX.Element | null {
    const classesViewExtended = useViewExtendedStyles();
    return (
        <div className="extended-view__section">
            <div className="extended-view__section-title">
                <Subtitle1 className={classesViewExtended.title}>
                    {props.title}
                </Subtitle1>
            </div>
            <Text>No {props.title} found</Text>
        </div>
    );
}

export default ExtendedViewEmptySearch;
