import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import {
    Text,
    Switch,
    makeStyles,
    tokens,
    Field,
    Input,
    InputOnChangeData,
    SelectOnChangeData,
    createLightTheme,
    createDarkTheme,
} from "@fluentui/react-components";
import { PeopleCommunityRegular } from "@fluentui/react-icons";
import Loader from "../../../../components/Loader/Loader";
import { state } from "../../../../state/stateAdapter";
import "./branding.css";
import ColorField, {
    isValidColor,
} from "../../../../components/Form/ColorField/ColorField";
import ImageField from "../../../../components/Form/ImageField/ImageField";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { getBrandTokensFromPalette } from "../../../../theme/themeDesigner/themeDesigner";
import { matchTheme, toggleTheme } from "../../../../theme";
import { BrandingConfig } from "../../../../../application/config/config";
import { useAdmin } from "../../../../../application/useCases/useAdmin";

const useStyles = makeStyles({
    title: {
        fontSize: "18px",
        fontWeight: 700,
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: 700,
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
});

function isValidString(value: string | null): boolean {
    return value !== undefined && value !== null && value.length > 0;
}

function toBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => resolve(reader.result);
        reader.onerror = reject;
    });
}

function Branding(): JSX.Element {
    const classes = useStyles();
    const { isDark } = state.useState(state.theme);
    const config = state.useState(useStorage.config.config);
    const commonClasses = useCommonFluentuiStyles();
    const [brandingKey, setBrandingKey] = useState<number>(1);
    const [data, setData] = useState<BrandingConfig>({
        darkModePrimaryColor: config.darkModePrimaryColor,
        icon: config.icon,
        logo: config.logo,
        primaryColor: config.primaryColor,
        title: config.title,
    });
    const isUpdateConfigQueryInProgress = state.useState(
        useStorage.admin.isUpdateConfigQueryInProgress,
    );
    const brandingConfig: BrandingConfig = {
        darkModePrimaryColor: config.darkModePrimaryColor,
        icon: config.icon,
        logo: config.logo,
        primaryColor: config.primaryColor,
        title: config.title,
    };

    const isFormValid =
        data !== undefined &&
        isValidString(data?.title) &&
        (data?.icon === null || isValidString(data?.icon)) &&
        (data?.logo === null || isValidString(data?.logo)) &&
        isValidColor(data?.primaryColor) &&
        isValidColor(data?.darkModePrimaryColor);

    async function saveChanges(): Promise<void> {
        if (isFormValid && data !== undefined) {
            const res = await useAdmin.updateBrandingConfig(data);

            if (res !== undefined && res !== null) {
                useStorage.config.config.set({ ...config, ...res });
                setData({
                    ...res,
                });
                state.theme.set(matchTheme(isDark));
            }
        }
    }

    const discardChanges = useCallback((): void => {
        setData({ ...config });
        setBrandingKey(brandingKey + 1);
    }, [brandingKey, config]);

    const updateData = useCallback(
        (name: string, value: string | undefined): void => {
            if (data !== undefined) {
                if (data[name as keyof BrandingConfig] !== value) {
                    setData({ ...data, [name]: value });
                }
            }
        },
        [data],
    );

    async function updateImage(
        name: string,
        file: File | undefined,
    ): Promise<void> {
        if (file !== undefined) {
            const image = await toBase64(file);

            if (image && data !== undefined) {
                setData({
                    ...data,
                    [name]: image.toString(),
                    [name + "FileName"]: file.name,
                });
            }
        } else {
            if (data !== undefined) {
                setData({
                    ...data,
                    [name]: undefined,
                    [name + "FileName"]: undefined,
                });
            }
        }
    }

    const onChange = useCallback(
        (
            e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
            d: InputOnChangeData | SelectOnChangeData,
        ): void => {
            updateData(e.target.name, d.value);
        },
        [updateData],
    );

    useEffect(() => {
        if (
            isDark &&
            isValidColor(data?.darkModePrimaryColor) &&
            data?.darkModePrimaryColor !== null &&
            data?.darkModePrimaryColor !== undefined
        ) {
            const brandColors = getBrandTokensFromPalette(
                data?.darkModePrimaryColor,
            );
            const newTheme = createDarkTheme(brandColors);
            state.theme.set({
                theme: newTheme,
                isDark: isDark,
            });
        }

        if (
            !isDark &&
            isValidColor(data?.primaryColor) &&
            data?.primaryColor !== null &&
            data?.primaryColor !== undefined
        ) {
            const brandColors = getBrandTokensFromPalette(data?.primaryColor);
            const newTheme = createLightTheme(brandColors);

            state.theme.set({
                theme: newTheme,
                isDark: isDark,
            });
        }

        return () => {
            state.theme.set(matchTheme(isDark));
        };
    }, [data?.darkModePrimaryColor, data?.primaryColor, isDark]);

    if (isUpdateConfigQueryInProgress) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            buttons={[
                {
                    title: "Save for everyone",
                    onClick: saveChanges,
                    icon: <PeopleCommunityRegular />,
                    appearance: "primary",
                    disabled:
                        !isFormValid ||
                        isUpdateConfigQueryInProgress ||
                        JSON.stringify(brandingConfig) === JSON.stringify(data),
                },
                {
                    title: "Discard changes",
                    onClick: discardChanges,
                    appearance: "subtle",
                    disabled:
                        isUpdateConfigQueryInProgress ||
                        JSON.stringify(brandingConfig) === JSON.stringify(data),
                },
            ]}
        >
            <div className="branding">
                <div className="branding__field">
                    <Text block className={classes.title}>
                        Branding
                    </Text>
                    <Text block className={classes.caption}>
                        Change look & feel across the tool
                    </Text>
                </div>
                <div className="brandig__row">
                    <Text block className={classes.subTitle}>
                        Color theme
                    </Text>

                    <ColorField
                        label="Primary color"
                        name="primaryColor"
                        value={data?.primaryColor || ""}
                        onChange={updateData}
                    />

                    <ColorField
                        label="Dark mode primary color"
                        name="darkModePrimaryColor"
                        value={data?.darkModePrimaryColor || ""}
                        onChange={updateData}
                    />
                </div>

                <div>
                    <Switch
                        checked={isDark}
                        label="Dark mode preview"
                        labelPosition="before"
                        onChange={toggleTheme}
                        className={commonClasses.field}
                    />
                </div>

                <Field className="branding__field">
                    <Text block className={classes.subTitle}>
                        Title
                    </Text>
                    <Input
                        name="title"
                        size="large"
                        placeholder="Type your title here"
                        value={data?.title || ""}
                        onChange={onChange}
                    />
                </Field>

                <div className="branding__field">
                    <Text block className={classes.subTitle}>
                        Logotype
                    </Text>
                    <Text block className={classes.caption}>
                        Please upload a SVG, PNG or JPG file with your logo. We
                        suggest to use a square image to fit better.
                    </Text>
                    <ImageField
                        key={brandingKey}
                        value={data.logo ?? undefined}
                        size={1000000}
                        onChange={updateImage}
                        name="logo"
                        accept={["png", "jpeg", "jpg", "svg"]}
                    />
                </div>

                <div className="branding__field">
                    <Text block className={classes.subTitle}>
                        Favicon
                    </Text>
                    <Text block className={classes.caption}>
                        Please upload a SVG, PNG or JPG file with your logo. We
                        suggest to use a square image to fit better.
                    </Text>
                    <ImageField
                        key={brandingKey}
                        value={data.icon ?? undefined}
                        size={1000000}
                        onChange={updateImage}
                        name="icon"
                        accept={["png", "jpeg", "jpg", "svg"]}
                    />
                </div>
            </div>
        </AdminLayout>
    );
}
export default Branding;
