import { Switch } from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import React, { useCallback, useEffect, useState } from "react";
import Loader from "../../../../components/Loader/Loader";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";
import { useAdmin } from "../../../../../application/useCases/useAdmin";
import {
    AIFeatureConfig,
    ConfigKey,
} from "../../../../../application/config/config";

async function getAIFeatureConfig(): Promise<AIFeatureConfig> {
    const res = await useAdmin.getAI();
    return res || { disabled: true };
}

function AI(): JSX.Element {
    const [aiConfig, setAiConfig] = useState<AIFeatureConfig | null>(null);
    const [tempAiConfig, setTempAiConfig] = useState<AIFeatureConfig | null>(
        null,
    );

    const commonClasses = useCommonFluentuiStyles();

    const isAdminUpdateConfigQueryInProgress = state.useState(
        useStorage.admin.isUpdateConfigQueryInProgress,
    );
    const isUpdateConfigQueryInProgress = state.useState(
        useStorage.config.isQueryInProgress,
    );

    const areActionsDisabled = tempAiConfig === aiConfig;

    const onSave = useCallback(async (): Promise<void> => {
        if (tempAiConfig !== null) {
            await useAdmin.updateConfigByKey<AIFeatureConfig>(ConfigKey.AI, {
                disabled: tempAiConfig.disabled,
            });
        }
    }, [tempAiConfig]);

    const onDiscard = useCallback((): void => {
        setTempAiConfig(aiConfig);
    }, [aiConfig]);

    const toggleAI = useCallback(() => {
        setTempAiConfig({
            disabled: tempAiConfig === null ? false : !tempAiConfig.disabled,
        });
    }, [tempAiConfig]);

    useEffect(() => {
        getAIFeatureConfig().then(res => {
            setAiConfig(res);
            setTempAiConfig(res);
        });
    }, []);

    if (
        isAdminUpdateConfigQueryInProgress ||
        isUpdateConfigQueryInProgress ||
        tempAiConfig === null
    ) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            buttons={[
                {
                    title: "Save changes",
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled: areActionsDisabled,
                    onClick: onSave,
                },
                {
                    title: "Discard changes",
                    onClick: onDiscard,
                    appearance: "subtle",
                    disabled: areActionsDisabled,
                },
            ]}
        >
            <form className="admin__form">
                <section className="common-settings-section">
                    <section className="common-settings-fields">
                        <div>
                            <div>
                                <Switch
                                    checked={
                                        tempAiConfig === null
                                            ? undefined
                                            : !tempAiConfig.disabled
                                    }
                                    disabled={tempAiConfig === null}
                                    label="Enable AI across the application"
                                    labelPosition="before"
                                    onChange={toggleAI}
                                    className={commonClasses.field}
                                />
                            </div>
                        </div>
                    </section>
                </section>
            </form>
        </AdminLayout>
    );
}

export default AI;
