import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DataGrid,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    DialogActions,
    DialogTrigger,
    Button,
    createTableColumn,
} from "@fluentui/react-components";
import React from "react";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { UserFile } from "../../../../../domain/userFile/userFile";
import { state } from "../../../../state/stateAdapter";
import { ColumnKind, USER_FILE_COLUMNS } from "../shared/userFileColumns";
import { ArrowHookUpLeftRegular } from "@fluentui/react-icons";

function onRemove(item: UserFile): void {
    useStorage.uploaderMiniApp.ignoredUserFiles.set(
        useStorage.uploaderMiniApp.ignoredUserFiles
            .get()!
            .filter(f => f.id !== item.id),
    );
}

function onPutBack(item: UserFile): void {
    useStorage.uploaderMiniApp.userFiles.update(s => {
        if (s !== null && s !== undefined) {
            s.push(item);
            onRemove(item);
        }
    });
}

const columns = [
    USER_FILE_COLUMNS.get(ColumnKind.NameIgnored)!,
    USER_FILE_COLUMNS.get(ColumnKind.Size)!,
    USER_FILE_COLUMNS.get(ColumnKind.LastModified)!,
    createTableColumn<UserFile>({
        columnId: "actions",
        renderHeaderCell: () => {
            return "Actions";
        },
        renderCell: item => {
            return (
                <Button
                    aria-label="Put back"
                    icon={<ArrowHookUpLeftRegular />}
                    onClick={(): void => onPutBack(item)}
                />
            );
        },
    }),
];

function IgnoredFilesDialog(): JSX.Element {
    const ignoredFiles = state.useState(
        useStorage.uploaderMiniApp.ignoredUserFiles,
    );

    if (ignoredFiles === null || ignoredFiles.length === 0) {
        return <></>;
    }

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    state.appDialog.set(null);
                }
            }}
            open={true}
        >
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
                style={{ maxWidth: "80dvw" }}
            >
                <DialogBody>
                    <DialogTitle>Ignored files</DialogTitle>
                    <DialogContent>
                        <div className="app-dialog__content">
                            <DataGrid
                                items={ignoredFiles}
                                columns={columns}
                                sortable
                                getRowId={(item): string => item.file.label}
                            >
                                <DataGridHeader>
                                    <DataGridRow>
                                        {({
                                            renderHeaderCell,
                                        }): JSX.Element => (
                                            <DataGridHeaderCell>
                                                {renderHeaderCell()}
                                            </DataGridHeaderCell>
                                        )}
                                    </DataGridRow>
                                </DataGridHeader>
                                <DataGridBody<UserFile>>
                                    {({ item, rowId }): JSX.Element => (
                                        <DataGridRow<UserFile> key={rowId}>
                                            {({ renderCell }): JSX.Element => (
                                                <DataGridCell>
                                                    {renderCell(item)}
                                                </DataGridCell>
                                            )}
                                        </DataGridRow>
                                    )}
                                </DataGridBody>
                            </DataGrid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export function showIgnoredFiles(): void {
    state.appDialog.set(() => <IgnoredFilesDialog />);
}

export default IgnoredFilesDialog;
