import { MimeRepository } from "./mimeRepository";

export const defaultMimeType = "genericfile";

export type Mime = {
    type: string;
    category: string;
    extension: string;
};

type MimeCollection = Omit<Mime, "extension"> & {
    extensions: string[];
};

const mimeCollection: MimeCollection[] = [
    {
        type: defaultMimeType,
        category: defaultMimeType,
        extensions: ["accdb", "mdb"],
    },
    {
        type: "archive",
        category: "Archive",
        extensions: [
            "7z",
            "ace",
            "arc",
            "arj",
            "dmg",
            "gz",
            "iso",
            "lzh",
            "pkg",
            "rar",
            "sit",
            "tgz",
            "tar",
            "z",
        ],
    },
    {
        type: "audio",
        category: "Audio",
        extensions: [
            "aif",
            "aiff",
            "aac",
            "alac",
            "amr",
            "ape",
            "au",
            "awb",
            "dct",
            "dss",
            "dvf",
            "flac",
            "gsm",
            "m4a",
            "m4p",
            "mid",
            "mmf",
            "mp3",
            "oga",
            "ra",
            "rm",
            "wav",
            "wma",
            "wv",
        ],
    },
    {
        type: "calendar",
        category: "Calendar",
        extensions: ["ical", "icalendar", "ics", "ifb", "vcs"],
    },
    {
        type: "code",
        category: "Code",
        extensions: [
            "abap",
            "ada",
            "adp",
            "ahk",
            "as",
            "as3",
            "asc",
            "ascx",
            "asm",
            "asp",
            "awk",
            "bash",
            "bash_login",
            "bash_logout",
            "bash_profile",
            "bashrc",
            "bat",
            "bib",
            "bsh",
            "build",
            "builder",
            "c",
            "cbl",
            "c++",
            "capfile",
            "cc",
            "cfc",
            "cfm",
            "cfml",
            "cl",
            "clj",
            "cls",
            "cmake",
            "cmd",
            "coffee",
            "config",
            "cpp",
            "cpt",
            "cpy",
            "cs",
            "cshtml",
            "cson",
            "csproj",
            "css",
            "ctp",
            "cxx",
            "d",
            "ddl",
            "di",
            "disco",
            "dml",
            "dtd",
            "dtml",
            "el",
            "emakefile",
            "erb",
            "erl",
            "f",
            "f90",
            "f95",
            "fs",
            "fsi",
            "fsscript",
            "fsx",
            "gemfile",
            "gemspec",
            "gitconfig",
            "go",
            "groovy",
            "gvy",
            "h",
            "h++",
            "haml",
            "handlebars",
            "hbs",
            "hcp",
            "hh",
            "hpp",
            "hrl",
            "hs",
            "htc",
            "hxx",
            "idl",
            "iim",
            "inc",
            "inf",
            "ini",
            "inl",
            "ipp",
            "irbrc",
            "jade",
            "jav",
            "java",
            "js",
            "json",
            "jsp",
            "jsproj",
            "jsx",
            "l",
            "less",
            "lhs",
            "lisp",
            "log",
            "lst",
            "ltx",
            "lua",
            "m",
            "mak",
            "make",
            "manifest",
            "master",
            "md",
            "markdn",
            "markdown",
            "mdown",
            "mkdn",
            "ml",
            "mli",
            "mll",
            "mly",
            "mm",
            "mud",
            "nfo",
            "opml",
            "osascript",
            "p",
            "pas",
            "patch",
            "php",
            "php2",
            "php3",
            "php4",
            "php5",
            "phtml",
            "pl",
            "pm",
            "pod",
            "pp",
            "profile",
            "ps1",
            "ps1xml",
            "psd1",
            "psm1",
            "pss",
            "pt",
            "py",
            "pyw",
            "r",
            "rake",
            "rb",
            "rbx",
            "rc",
            "rdf",
            "re",
            "reg",
            "rest",
            "resw",
            "resx",
            "rhtml",
            "rjs",
            "rprofile",
            "rpy",
            "rss",
            "rst",
            "ruby",
            "rxml",
            "s",
            "sass",
            "scala",
            "scm",
            "sconscript",
            "sconstruct",
            "script",
            "scss",
            "sgml",
            "sh",
            "shtml",
            "sml",
            "svn-base",
            "swift",
            "sql",
            "sty",
            "tcl",
            "tex",
            "textile",
            "tld",
            "tli",
            "tmpl",
            "tpl",
            "vb",
            "vi",
            "vim",
            "vmg",
            "webpart",
            "wsp",
            "wsdl",
            "xhtml",
            "xoml",
            "xsd",
            "xslt",
            "yaml",
            "yaws",
            "yml",
            "zsh",
            "tsx",
        ],
    },
    { type: "contact", category: defaultMimeType, extensions: ["vcf"] },
    { type: "csv", category: "Document", extensions: ["csv"] },
    {
        type: "docx",
        category: "Document",
        extensions: ["doc", "docm", "docx", "docb"],
    },
    { type: "dotx", category: "Document", extensions: ["dot", "dotm", "dotx"] },
    {
        type: "email",
        category: "Email",
        extensions: ["eml", "msg", "ost", "pst"],
    },
    {
        type: "exe",
        category: "Application",
        extensions: [
            "application",
            "appref-ms",
            "apk",
            "app",
            "appx",
            "exe",
            "ipa",
            "msi",
            "xap",
        ],
    },
    { type: "font", category: "Font", extensions: ["ttf", "otf", "woff"] },
    { type: "fluid", category: defaultMimeType, extensions: ["b", "fluid"] },
    { type: "html", category: "HTML", extensions: ["htm", "html", "mht"] },
    {
        type: "link",
        category: "Link",
        extensions: ["lnk", "link", "url", "website", "webloc"],
    },
    { type: "officescript", category: defaultMimeType, extensions: ["osts"] },
    { type: "splist", category: defaultMimeType, extensions: ["listitem"] },
    {
        type: "model",
        category: "Model",
        extensions: [
            "3ds",
            "3mf",
            "blend",
            "cool",
            "dae",
            "df",
            "dwfx",
            "dwg",
            "dxf",
            "fbx",
            "glb",
            "gltf",
            "holo",
            "layer",
            "layout",
            "max",
            "mcworld",
            "mtl",
            "obj",
            "off",
            "ply",
            "skp",
            "stp",
            "stl",
            "t",
            "thl",
            "x",
        ],
    },
    { type: "mpp", category: defaultMimeType, extensions: ["mpp"] },
    { type: "mpt", category: defaultMimeType, extensions: ["mpt"] },
    { type: "one", category: "Document", extensions: ["note", "one"] },
    {
        type: "onetoc",
        category: "Document",
        extensions: ["ms-one-stub", "onetoc", "onetoc2", "onepkg"],
    },
    { type: "pdf", category: "Document", extensions: ["pdf"] },
    {
        type: "photo",
        category: "Image",
        extensions: [
            "arw",
            "bmp",
            "cr2",
            "crw",
            "dic",
            "dicm",
            "dcm",
            "dcm30",
            "dcr",
            "dds",
            "dib",
            "dng",
            "erf",
            "gif",
            "heic",
            "heif",
            "ico",
            "jfi",
            "jfif",
            "jif",
            "jpe",
            "jpeg",
            "jpg",
            "kdc",
            "mrw",
            "nef",
            "orf",
            "pct",
            "pict",
            "png",
            "pns",
            "psb",
            "psd",
            "raw",
            "tga",
            "tif",
            "tiff",
            "wdp",
        ],
    },
    {
        type: "potx",
        category: defaultMimeType,
        extensions: ["pot", "potm", "potx"],
    },
    {
        type: "powerbi",
        category: defaultMimeType,
        extensions: ["pbids", "pbix"],
    },
    {
        type: "ppsx",
        category: defaultMimeType,
        extensions: ["pps", "ppsm", "ppsx"],
    },
    {
        type: "pptx",
        category: defaultMimeType,
        extensions: ["ppt", "pptm", "pptx", "sldx", "sldm"],
    },
    {
        type: "presentation",
        category: "Presentation",
        extensions: ["odp", "gslides", "key"],
    },
    { type: "pub", category: defaultMimeType, extensions: ["pub"] },
    {
        type: "spo",
        category: defaultMimeType,
        extensions: ["aspx", "classifier"],
    },
    {
        type: "spreadsheet",
        category: "Spreadsheet",
        extensions: ["odc", "ods", "gsheet", "numbers", "tsv"],
    },
    {
        type: "rtf",
        category: "Rtf",
        extensions: ["epub", "gdoc", "odt", "rtf", "wri", "pages"],
    },
    {
        type: "sysfile",
        category: "System",
        extensions: [
            "bak",
            "bin",
            "cab",
            "cache",
            "cat",
            "cer",
            "class",
            "dat",
            "db",
            "dbg",
            "dl_",
            "dll",
            "ithmb",
            "jar",
            "kb",
            "ldt",
            "lrprev",
            "pkpass",
            "ppa",
            "ppam",
            "pdb",
            "rom",
            "thm",
            "thmx",
            "vsl",
            "xla",
            "xlam",
            "xlb",
            "xll",
        ],
    },
    {
        type: "txt",
        category: "Text",
        extensions: [
            "dif",
            "diff",
            "readme",
            "out",
            "plist",
            "properties",
            "text",
            "txt",
        ],
    },
    {
        type: "vector",
        category: "Image",
        extensions: [
            "ai",
            "ait",
            "cvs",
            "dgn",
            "gdraw",
            "pd",
            "emf",
            "eps",
            "fig",
            "ind",
            "indd",
            "indl",
            "indt",
            "indb",
            "ps",
            "svg",
            "svgz",
            "wmf",
            "oxps",
            "xps",
            "xd",
            "sketch",
        ],
    },
    {
        type: "video",
        category: "Video",
        extensions: [
            "3g2",
            "3gp",
            "3gp2",
            "3gpp",
            "asf",
            "avi",
            "dvr-ms",
            "flv",
            "m1v",
            "m4v",
            "mkv",
            "mod",
            "mov",
            "mm4p",
            "mp2",
            "mp2v",
            "mp4",
            "mp4v",
            "mpa",
            "mpe",
            "mpeg",
            "mpg",
            "mpv",
            "mpv2",
            "mts",
            "ogg",
            "qt",
            "swf",
            "ts",
            "vob",
            "webm",
            "wlmp",
            "wm",
            "wmv",
            "wmx",
            "mxf",
        ],
    },
    {
        type: "vsdx",
        category: defaultMimeType,
        extensions: ["vdx", "vsd", "vsdm", "vsdx", "vsw", "vdw"],
    },
    {
        type: "vssx",
        category: defaultMimeType,
        extensions: ["vss", "vssm", "vssx"],
    },
    {
        type: "vstx",
        category: defaultMimeType,
        extensions: ["vst", "vstm", "vstx", "vsx"],
    },
    {
        type: "whiteboard",
        category: defaultMimeType,
        extensions: ["whiteboard"],
    },
    {
        type: "xlsx",
        category: defaultMimeType,
        extensions: ["xlc", "xls", "xlsb", "xlsm", "xlsx", "xlw"],
    },
    {
        type: "xltx",
        category: defaultMimeType,
        extensions: ["xlt", "xltm", "xltx"],
    },
    { type: "xml", category: "XML", extensions: ["xaml", "xml", "xsl"] },
    { type: "xsn", category: defaultMimeType, extensions: ["xsn"] },
    { type: "zip", category: "Archive", extensions: ["zip"] },
];

export function getExtInfo(ext: string, name: string): Omit<Mime, "extension"> {
    if (!ext.length) {
        return {
            category:
                defaultMimeType.charAt(0).toUpperCase() +
                defaultMimeType.slice(1),
            type: defaultMimeType,
        };
    }

    for (const m of mimeCollection) {
        if (m.extensions.includes(ext.toLowerCase())) {
            return {
                category: m.category,
                type: m.type,
            };
        }
    }

    return {
        category: name.split(".")[name.split(".").length - 1].toUpperCase(),
        type: defaultMimeType,
    };
}

export function getExtension(
    mimeRepository: MimeRepository,
    typeString: string,
    name: string,
): string {
    const ext = mimeRepository.getExtension(typeString);

    if (ext) {
        return ext;
    }

    const splitedName = name.split(".");
    return splitedName[splitedName.length - 1];
}
