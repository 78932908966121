import qs from "qs";
import {
    IndexedLocationAddParams,
    IndexedLocationConfigureEventParams,
    IndexedLocationFetchStorageAccountsParams,
    IndexedLocationGetParams,
    IndexedLocationGetStorageAccountParams,
    IndexedLocationPort,
    IndexedLocationValidateEventRequest,
} from "../../application/indexedLocation/indexedLocationPort";
import {
    IndexedLocation,
    IndexedLocationStorageAccount,
    IndexedLocationSubscription,
} from "../../application/indexedLocation/indexedLocation";
import { apiCall, handleNotOkWithBody, validateRes } from "../api/api";

export class IndexedLocationAdapter implements IndexedLocationPort {
    async fetchIndexedLocations(): Promise<IndexedLocation[]> {
        const res = await apiCall("/api/admin/locations", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async fetchSubscriptions(): Promise<IndexedLocationSubscription[]> {
        const res = await apiCall("/api/admin/azure/subscriptions", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async fetchStorageAccounts(
        params: IndexedLocationFetchStorageAccountsParams,
    ): Promise<IndexedLocationStorageAccount[]> {
        const res = await apiCall(
            `/api/admin/azure/storage-accounts?${qs.stringify(params)}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );

        return await validateRes(res);
    }

    async getStorageAccount(
        params: IndexedLocationGetStorageAccountParams,
    ): Promise<IndexedLocationStorageAccount> {
        const res = await apiCall(
            `/api/admin/azure/storage-account?${qs.stringify(params)}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );

        return await validateRes(res);
    }

    async getIndexedLocation(
        params: IndexedLocationGetParams,
    ): Promise<IndexedLocation> {
        const res = await apiCall(`/api/admin/locations/${params.locationID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async addIndexedLocation(
        params: IndexedLocationAddParams,
    ): Promise<IndexedLocation> {
        const res = await apiCall("/api/admin/locations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await handleNotOkWithBody(res);
    }

    async editIndexedLocation(
        params: IndexedLocation,
    ): Promise<IndexedLocation> {
        const res = await apiCall(`/api/admin/locations/${params.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async validateEvent(
        params: IndexedLocationGetParams,
    ): Promise<IndexedLocationValidateEventRequest> {
        const res = await apiCall(
            `/api/admin/locations/${params.locationID}/events/validate`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );

        return await res.json();
    }

    async configureEvent(
        params: IndexedLocationConfigureEventParams,
    ): Promise<Response> {
        const res = await apiCall(
            `/api/admin/locations/${params.locationID}/events/configure`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({
                    eventGridSubscriptionResourceId:
                        params.eventGridSubscriptionResourceId,
                }),
            },
        );

        return res;
    }
}
