export type JobDetails = {
    filesTotal: number;
    filesCompleted: number;
    sizeTotal: number;
    sizeCompleted: number;
    avgSpeed: number;
};

export enum JobStatus {
    Enqueued = "Enqueued",
    PreProcessing = "PreProcessing",
    InProgress = "InProgress",
    Completed = "Completed",
    Error = "Error",
    Pending = "Pending",
    Failed = "Failed",
}

export const jobStatusMap = {
    [JobStatus.Enqueued]: JobStatus.Enqueued,
    [JobStatus.PreProcessing]: "Pre processing",
    [JobStatus.InProgress]: "In progress",
    [JobStatus.Completed]: JobStatus.Completed,
    [JobStatus.Error]: JobStatus.Error,
    [JobStatus.Pending]: JobStatus.Pending,
    [JobStatus.Failed]: JobStatus.Failed,
};

export enum JobType {
    Download = "Download",
    ReHydration = "ReHydration",
    RePermission = "RePermission",
    DownloadByLink = "DownloadByLink",
}

export type Job = {
    id: number;
    title: string | null;
    status: JobStatus;
    dateCreated: string | null;
    jobType: JobType;
    message: string;
    url: string | null;
    details: JobDetails | null;
    source: {
        title: string;
        storage: string;
        container: string;
    };
    destination: { title: string };
};
