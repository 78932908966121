import {
    Field,
    Input,
    Divider,
    Subtitle2,
    Button,
    Switch,
    Caption1,
    Text,
} from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import React from "react";
import { useTimeFormatter } from "../../../../../../application/useCases/useTimeFormatter";
import { AdminActiveMenuKind } from "../../../../../models";
import { router } from "../../../../../router";
import { useCommonFluentuiStyles } from "../../../../../styles/griffel";
import AdminLayout from "../../AdminLayout/AdminLayout";
import { UploaderAdminHash } from "../shared";
import "./commonSettings.css";
import { uploaderSettingsState } from "../uploaderSettingsState";
import { useStorage } from "../../../../../../application/useCases/useStorage";
import {
    ConfigKey,
    UploaderConfig,
} from "../../../../../../application/config/config";
import { useAdmin } from "../../../../../../application/useCases/useAdmin";
import Loader from "../../../../../components/Loader/Loader";
import { state } from "../../../../../state/stateAdapter";
import { useConfig } from "../../../../../../application/useCases/useConfig";

function CommonSettings(): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();
    const tempUploaderSettings = state.useState(
        uploaderSettingsState.tempUploaderSettings,
    );
    const isGetConfigQueryInProgress = state.useState(
        useStorage.config.isQueryInProgress,
    );
    const isUpdateConfigQueryInProgress = state.useState(
        useStorage.admin.isUpdateConfigQueryInProgress,
    );
    const isEqual =
        JSON.stringify(tempUploaderSettings) ===
        JSON.stringify(useStorage.config.config.get().features.uploader);

    if (
        isGetConfigQueryInProgress ||
        isUpdateConfigQueryInProgress ||
        tempUploaderSettings === null
    ) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            title={"Upload"}
            buttons={[
                {
                    title: "Save changes",
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled: isEqual,
                    onClick: (): void => {
                        useAdmin
                            .updateConfigByKey<UploaderConfig>(
                                ConfigKey.Uploader,
                                tempUploaderSettings,
                            )
                            .then(r => {
                                if (r) {
                                    useConfig.updateConfigByKey(
                                        ConfigKey.Uploader,
                                        r,
                                    );
                                } else {
                                    useConfig.getConfig();
                                }
                            });
                    },
                },
                {
                    title: "Discard changes",
                    appearance: "subtle",
                    disabled: isEqual,
                    onClick: (): void => {
                        uploaderSettingsState.tempUploaderSettings.set(
                            JSON.parse(
                                JSON.stringify(
                                    useStorage.config.config.get().features
                                        .uploader,
                                ),
                            ),
                        );
                    },
                },
            ]}
        >
            <form className="admin__form">
                <article className="common-settings-section">
                    <section className="common-settings-fields">
                        <Field
                            label="Maximum number of simultaneous uploads"
                            className={commonClasses.field}
                        >
                            <Input
                                placeholder="Default: 5"
                                size="large"
                                name="simultaneousUploads"
                                type="number"
                                value={tempUploaderSettings.uploading.maxConcurrentUploads.toString()}
                                onChange={(_, data): void => {
                                    uploaderSettingsState.tempUploaderSettings.update(
                                        s => {
                                            if (s) {
                                                s.uploading.maxConcurrentUploads =
                                                    +data.value;
                                            }
                                        },
                                    );
                                }}
                            />
                        </Field>

                        <Field
                            label="Number of upload retries"
                            className={commonClasses.field}
                        >
                            <Input
                                placeholder="Default: 3"
                                size="large"
                                name="retries"
                                type="number"
                                value={tempUploaderSettings.uploading.retryCount.toString()}
                                onChange={(_, data): void => {
                                    uploaderSettingsState.tempUploaderSettings.update(
                                        s => {
                                            if (s) {
                                                s.uploading.retryCount =
                                                    +data.value;
                                            }
                                        },
                                    );
                                }}
                            />
                        </Field>

                        <Field
                            label="Retry delays (in seconds)"
                            className={commonClasses.field}
                        >
                            <Input
                                placeholder="Default: 10"
                                size="large"
                                name="retryDelays"
                                type="text"
                                value={
                                    tempUploaderSettings.uploading.retryDelay
                                }
                                onChange={(_, data): void => {
                                    uploaderSettingsState.tempUploaderSettings.update(
                                        s => {
                                            if (s) {
                                                s.uploading.retryDelay =
                                                    data.value;
                                            }
                                        },
                                    );
                                }}
                            />
                        </Field>
                    </section>
                </article>

                <Divider />

                <div className="download-locations__item">
                    <div className="download-locations__item-info">
                        <Subtitle2>Ignored paths</Subtitle2>
                        <Text>
                            Configure files to ignore by name, extension or path
                        </Text>
                    </div>
                    <Button
                        size="large"
                        as="a"
                        href={router.createRoute({
                            hash: UploaderAdminHash.IgnoredPaths,
                            search: {
                                items: [
                                    {
                                        key: "activeMenu",
                                        value: AdminActiveMenuKind.Uploader,
                                    },
                                ],
                                protect: [],
                            },
                        })}
                        data-route
                    >
                        Configure
                    </Button>
                </div>

                <Divider />

                <Switch
                    className={commonClasses.field}
                    label="Parse Sony Camera Cards"
                    checked={tempUploaderSettings.parsingCameraCard}
                    onChange={(_, data): void => {
                        uploaderSettingsState.tempUploaderSettings.update(s => {
                            if (s) {
                                s.parsingCameraCard = data.checked;
                            }
                        });
                    }}
                />

                {/* <Divider />

                <Switch
                    className={commonClasses.field}
                    disabled
                    label="Add metadata during upload"
                    checked={uploaderSettings.addMetadata}
                /> */}

                <Divider />

                <section className="common-settings-fields">
                    <Field
                        label="Folder naming convention"
                        className={commonClasses.field}
                    >
                        <Input
                            placeholder="Ex: '[folder] YYYY-MM-DD hh_mm', 'DD_MM', '[folder]'"
                            size="large"
                            name="folderConvention"
                            type="text"
                            value={tempUploaderSettings.folderNamingConvention}
                            onChange={(_, data): void => {
                                uploaderSettingsState.tempUploaderSettings.update(
                                    s => {
                                        if (s) {
                                            s.folderNamingConvention =
                                                data.value;
                                        }
                                    },
                                );
                            }}
                        />
                    </Field>

                    {tempUploaderSettings.folderNamingConvention.length > 0 ? (
                        <Caption1>
                            Preview:{" "}
                            {useTimeFormatter.formatDate(
                                Date.now(),
                                tempUploaderSettings.folderNamingConvention,
                            )}
                        </Caption1>
                    ) : (
                        <></>
                    )}
                </section>
            </form>
        </AdminLayout>
    );
}

export default CommonSettings;
