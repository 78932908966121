import {
    DownloadLocationAzureFiles,
    DownloadLocationType,
    DownloadLocationServiceAccount,
} from "../../application/downloadLocation/downloadLocation";
import {
    DownloadLocationAddAzureLocationParams,
    DownloadLocationCreateServiceAccountParams,
    DownloadLocationDeleteServiceAccountParams,
    DownloadLocationEditServiceAccountParams,
    DownloadLocationFetchAzureLocationParams,
    DownloadLocationFetchByTypeParams,
    DownloadLocationFetchParams,
    DownloadLocationPort,
    DownloadLocationsFetchLocationsListParams,
} from "../../application/downloadLocation/downloadLocationPort";

import { apiCall, handleNotOk, validateRes } from "../api/api";

export class DownloadLocationAdapter implements DownloadLocationPort {
    async fetchLocationsList(): Promise<DownloadLocationsFetchLocationsListParams> {
        const res = await apiCall("/api/admin/download-locations/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async fetchAzureLocations(): Promise<DownloadLocationAzureFiles[]> {
        const res = await apiCall(
            `/api/admin/download-locations/${DownloadLocationType.AzureFiles}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );

        return await validateRes(res);
    }

    async fetchAzureLocation(
        params: DownloadLocationFetchAzureLocationParams,
    ): Promise<DownloadLocationAzureFiles> {
        const res = await apiCall(
            `/api/admin/download-locations/${DownloadLocationType.AzureFiles}/${params.id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );

        return await validateRes(res);
    }

    async editAzureLocation(
        params: DownloadLocationAzureFiles,
    ): Promise<DownloadLocationAzureFiles> {
        const res = await apiCall(
            `/api/admin/download-locations/${params.locationType}/${params.id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(params),
            },
        );

        return await validateRes(res);
    }

    async addAzureLocation(
        params: DownloadLocationAddAzureLocationParams,
    ): Promise<DownloadLocationAzureFiles> {
        const res = await apiCall(
            `/api/admin/download-locations/${params.locationType}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(params),
            },
        );

        return await validateRes(res);
    }

    async fetchByType(
        params: DownloadLocationFetchByTypeParams,
    ): Promise<DownloadLocationFetchParams> {
        const res = await apiCall(
            `/api/admin/download-locations/${params.locationType}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );

        return await validateRes(res);
    }

    async editByType(
        params: DownloadLocationFetchParams,
    ): Promise<DownloadLocationFetchParams> {
        const res = await apiCall(
            `/api/admin/download-locations/${params.locationType}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(params),
            },
        );

        return await validateRes(res);
    }

    async createServiceAccount(
        params: DownloadLocationCreateServiceAccountParams,
    ): Promise<DownloadLocationServiceAccount> {
        const res = await apiCall("/api/admin/service-accounts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async editServiceAccount(
        params: DownloadLocationEditServiceAccountParams,
    ): Promise<DownloadLocationServiceAccount> {
        const res = await apiCall(`/api/admin/service-accounts/${params.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async deleteServiceAccount(
        params: DownloadLocationDeleteServiceAccountParams,
    ): Promise<void> {
        const res = await apiCall(`/api/admin/service-accounts/${params.id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        handleNotOk(res);
    }
}
