import React, { useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Text } from "@fluentui/react-components";
import "./dropzone.scss";
import { onDragNDroppData } from "../shared/actions";

function Dropzone(): JSX.Element {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const dropZoneRef = useRef<HTMLDivElement>(null);

    const toggleVisibility = (): void => {
        if (dropZoneRef && dropZoneRef.current) {
            dropZoneRef.current.classList.toggle("dropzone-wrap_hidden");
        }
    };

    useEffect(() => {
        const dropZoneCurrentRef = dropZoneRef.current;

        document.body.addEventListener("dragenter", toggleVisibility);
        document.body.addEventListener("dragleave", toggleVisibility);
        document.body.addEventListener("drop", toggleVisibility);

        return (): void => {
            document.body.removeEventListener("dragenter", toggleVisibility);
            document.body.removeEventListener("dragleave", toggleVisibility);
            document.body.removeEventListener("drop", toggleVisibility);

            if (dropZoneRef && dropZoneCurrentRef) {
                dropZoneCurrentRef.classList.add("dropzone-wrap_hidden");
            }
        };
    }, []);

    useEffect(() => {
        if (acceptedFiles.length) {
            onDragNDroppData(acceptedFiles);
        }
    }, [acceptedFiles]);

    return (
        <article
            ref={dropZoneRef}
            className="dropzone-wrap dropzone-wrap_hidden"
        >
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <Text size={600}>Drop your files here</Text>
            </div>
        </article>
    );
}

export default Dropzone;
