import React from "react";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";
import TagsGroups from "./TagsGroups/TagsGroups";
import TagsList from "./TagsList/TagsList";

function Tags(): JSX.Element {
    const id = state.useState(useStorage.adminMiniApp.id);

    if (id !== undefined && id !== null && parseInt(id)) {
        return <TagsList groupId={parseInt(id)} />;
    }

    return <TagsGroups />;
}

export default Tags;
