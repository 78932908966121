export type InsightLabel = {
    key: string;
    predefined?: boolean;
    text: string;
};

export type InsightCollectionLabel = Omit<InsightLabel, "predefined"> & {
    insightLabels: InsightLabel[];
};

export const predefinedInsightsViewList: InsightLabel[] = [
    { predefined: true, key: "faces", text: "People" },
    { predefined: true, key: "keywords", text: "Keywords" },
    { predefined: true, key: "labels", text: "Labels" },
    { predefined: true, key: "namedEntities", text: "Named entities" },
    { predefined: true, key: "emotions", text: "Emotions" },
    { predefined: true, key: "sentiments", text: "Sentiments" },
    { predefined: true, key: "shots", text: "Keyframes" },
    { predefined: true, key: "topics", text: "Topics" },
    { predefined: true, key: "scenes", text: "Scenes" },
    { predefined: true, key: "ocr", text: "OCR" },
    { predefined: true, key: "audioEffects", text: "Audio effects" },
];

export const predefinedTimelineViewList: InsightLabel[] = [
    { predefined: true, key: "text", text: "Transcript" },
];
