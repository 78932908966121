import {
    CognitiveMetadata,
    CognitiveMetadataGetParams,
    CognitiveMetadataPort,
} from "../../application/cognitiveMetadata/cognitiveMetadataPort";
import { apiCall, validateRes } from "../api/api";

export class CognitiveMetadataAdapter implements CognitiveMetadataPort {
    async getCognitiveMetadata(
        params: CognitiveMetadataGetParams,
    ): Promise<CognitiveMetadata> {
        const res = await apiCall(
            `/api/files/cognitive-metadata?id=${params.id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );

        return await validateRes(res);
    }
}
