import qs from "qs";
import {
    CollectionsAddParams,
    CollectionsPort,
    CollectionsDeleteParams,
    CollectionsEditParams,
    CollectionsCreateParams,
    CollectionsFetchByIdParams,
    CollectionsFetchParams,
} from "../../application/collections/collectionsPort";
import {
    CollectionInfo,
    CollectionItem,
} from "../../domain/collection/collections";
import { apiCall, handleNotOk, validateRes } from "../api/api";

export class CollectionsAdapter implements CollectionsPort {
    async fetch(params: CollectionsFetchParams): Promise<CollectionInfo[]> {
        const res = await apiCall(
            `/api/collections/list?${qs.stringify(params)}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );

        return await validateRes(res);
    }

    async fetchById(
        params: CollectionsFetchByIdParams,
    ): Promise<CollectionItem> {
        const res = await apiCall(`/api/collections?id=${params.id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async create(params: CollectionsCreateParams): Promise<CollectionInfo> {
        const res = await apiCall("/api/collections", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async add(params: CollectionsAddParams): Promise<void> {
        const res = await apiCall("/api/collections/files", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        handleNotOk(res);
    }

    async edit(params: CollectionsEditParams): Promise<CollectionInfo> {
        const res = await apiCall("/api/collections", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async delete(params: CollectionsDeleteParams): Promise<void> {
        const res = await apiCall("/api/collections", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        handleNotOk(res);
    }

    async deleteFiles(params: CollectionsAddParams): Promise<void> {
        const res = await apiCall("/api/collections/files", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        handleNotOk(res);
    }
}
