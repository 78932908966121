import React from "react";
import { getColor } from "../../Insights/Emotions/Emotions";
import { TimelineDataItem } from "../Timeline";

export default function TimelineEmotions(props: {
    data?: TimelineDataItem[];
}): JSX.Element | null {
    if (props.data === undefined) {
        return null;
    }

    return (
        <div>
            {props.data.map(i => {
                return (
                    <div
                        key={i.value}
                        role="presentation"
                        className={`emoticon emoticon_${i.value.toLowerCase()}`}
                        style={{ color: getColor(i.value) }}
                    >
                        {i.value}
                    </div>
                );
            })}
        </div>
    );
}
