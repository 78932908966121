import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import { DownloadLocationListStorageValue } from "../storageValues";
import {
    DownloadLocationAzureFiles,
    DownloadLocationServiceAccount,
} from "./downloadLocation";
import {
    DownloadLocationAddAzureLocationParams,
    DownloadLocationCreateServiceAccountParams,
    DownloadLocationDeleteServiceAccountParams,
    DownloadLocationEditServiceAccountParams,
    DownloadLocationFetchAzureLocationParams,
    DownloadLocationFetchByTypeParams,
    DownloadLocationFetchParams,
    DownloadLocationPort,
} from "./downloadLocationPort";

export class DownloadLocationService {
    private readonly _downloadLocationPort: DownloadLocationPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        downloadLocationPort: DownloadLocationPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._downloadLocationPort = downloadLocationPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    async fetchLocationsList(): Promise<
        Option<DownloadLocationListStorageValue>
    > {
        try {
            this._errorService.clear(AirErrorKind.FetchDownloadLocations);
            this._storagePort.downloadLocation.isFetchDownloadLocationQueryInProgress.set(
                true,
            );
            const res = await this._downloadLocationPort.fetchLocationsList();

            this._storagePort.downloadLocation.downloadLocationsList.set(
                new Map(res.map(i => [i.type, i.isEnabled])),
            );
            this._storagePort.downloadLocation.isFetchDownloadLocationQueryInProgress.set(
                false,
            );

            return this._storagePort.downloadLocation.downloadLocationsList.get();
        } catch (error) {
            this._storagePort.downloadLocation.isFetchDownloadLocationQueryInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchDownloadLocations,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async fetchAzureLocations(): Promise<Option<DownloadLocationAzureFiles[]>> {
        try {
            this._storagePort.downloadLocation.isFetchAzureLocationsQueryInProgress.set(
                true,
            );
            this._errorService.clear(AirErrorKind.FetchDownloadLocationsAzure);
            const res = await this._downloadLocationPort.fetchAzureLocations();
            this._storagePort.downloadLocation.azureFilesDownloadLocations.set(
                new Map(res.map(i => [i.id, { ...i, type: i.locationType }])),
            );
            this._storagePort.downloadLocation.isFetchAzureLocationsQueryInProgress.set(
                false,
            );

            return res;
        } catch (error) {
            this._storagePort.downloadLocation.isFetchAzureLocationsQueryInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchDownloadLocationsAzure,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async fetchAzureLocation(
        params: DownloadLocationFetchAzureLocationParams,
    ): Promise<Option<DownloadLocationAzureFiles>> {
        try {
            this._storagePort.downloadLocation.isFetchAzureLocationQueryInProgress.set(
                true,
            );
            this._errorService.clear(AirErrorKind.FetchDownloadLocationAzure);
            const res = await this._downloadLocationPort.fetchAzureLocation(
                params,
            );
            this._storagePort.downloadLocation.isFetchAzureLocationQueryInProgress.set(
                false,
            );

            return res;
        } catch (error) {
            this._storagePort.downloadLocation.isFetchAzureLocationQueryInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchDownloadLocationAzure,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async editAzureLocation(
        params: DownloadLocationAzureFiles,
    ): Promise<Option<DownloadLocationAzureFiles>> {
        try {
            this._storagePort.downloadLocation.isEditAzureLocationQueryInProgress.set(
                true,
            );
            this._errorService.clear(AirErrorKind.EditDownloadLocationAzure);
            const res = await this._downloadLocationPort.editAzureLocation(
                params,
            );
            this._storagePort.downloadLocation.azureFilesDownloadLocations.update(
                s => {
                    s.set(res.id, {
                        ...res,
                        type: res.locationType,
                    });
                },
            );
            this._storagePort.downloadLocation.isEditAzureLocationQueryInProgress.set(
                false,
            );
            this._notificationService.show("Azure Location updated", "success");

            return res;
        } catch (error) {
            this._storagePort.downloadLocation.isEditAzureLocationQueryInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.EditDownloadLocationAzure,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async addAzureLocation(
        params: DownloadLocationAddAzureLocationParams,
    ): Promise<Option<DownloadLocationAzureFiles>> {
        try {
            this._storagePort.downloadLocation.isAddAzureLocationQueryInProgress.set(
                true,
            );
            this._errorService.clear(AirErrorKind.AddDownloadLocationAzure);
            const res = await this._downloadLocationPort.addAzureLocation(
                params,
            );
            this._storagePort.downloadLocation.azureFilesDownloadLocations.update(
                s => {
                    s.set(res.id, {
                        ...res,
                        type: res.locationType,
                    });
                },
            );
            this._storagePort.downloadLocation.isAddAzureLocationQueryInProgress.set(
                false,
            );
            this._notificationService.show("Azure Location added", "success");

            return res;
        } catch (error) {
            this._storagePort.downloadLocation.isAddAzureLocationQueryInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.AddDownloadLocationAzure,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async fetchByType(
        params: DownloadLocationFetchByTypeParams,
        // ): Promise<Option<DownloadLocationItemsStorageValue>> {
    ): Promise<Option<DownloadLocationFetchParams>> {
        try {
            this._storagePort.downloadLocation.isFetchByTypeQueryInProgress.set(
                true,
            );
            this._storagePort.downloadLocation.downloadLocationItems.update(
                s => {
                    s.delete(params.locationType);
                },
            );
            this._errorService.clear(AirErrorKind.FetchDownloadLocations);

            const res = await this._downloadLocationPort.fetchByType(params);

            this._storagePort.downloadLocation.downloadLocationItems.update(
                s => {
                    s.set(params.locationType, res);
                },
            );
            this._storagePort.downloadLocation.isFetchByTypeQueryInProgress.set(
                false,
            );

            // return this._storagePort.downloadLocation.downloadLocationItems.get();
            return res;
        } catch (error) {
            this._storagePort.downloadLocation.isFetchByTypeQueryInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchDownloadLocations,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async editByType(
        params: DownloadLocationFetchParams,
    ): Promise<Option<DownloadLocationFetchParams>> {
        try {
            this._storagePort.downloadLocation.isEditByTypeQueryInProgress.set(
                true,
            );
            this._errorService.clear(AirErrorKind.EditDownloadLocations);
            const res = await this._downloadLocationPort.editByType(params);
            this._storagePort.downloadLocation.downloadLocationsList.update(
                s => {
                    s.set(params.locationType, params.enabled);
                },
            );
            this._storagePort.downloadLocation.isEditByTypeQueryInProgress.set(
                false,
            );
            this._notificationService.show(
                "Download Location updated",
                "success",
            );

            return res;
        } catch (error) {
            this._storagePort.downloadLocation.isEditByTypeQueryInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.EditDownloadLocations,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async createServiceAccount(
        params: DownloadLocationCreateServiceAccountParams,
    ): Promise<Option<DownloadLocationServiceAccount>> {
        try {
            this._storagePort.downloadLocation.isCreateServiceAccountInProgress.set(
                true,
            );
            this._errorService.clear(AirErrorKind.CreateServiceAccount);

            const res = await this._downloadLocationPort.createServiceAccount(
                params,
            );

            this._storagePort.downloadLocation.isCreateServiceAccountInProgress.set(
                false,
            );
            this._notificationService.show("Service account added", "success");

            return res;
        } catch (error) {
            this._storagePort.downloadLocation.isCreateServiceAccountInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.CreateServiceAccount,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async editServiceAccount(
        params: DownloadLocationEditServiceAccountParams,
    ): Promise<Option<DownloadLocationServiceAccount>> {
        try {
            this._storagePort.downloadLocation.isEditServiceAccountInProgress.set(
                true,
            );
            this._errorService.clear(AirErrorKind.EditServiceAccount);
            const res = await this._downloadLocationPort.editServiceAccount(
                params,
            );
            this._storagePort.downloadLocation.isEditServiceAccountInProgress.set(
                false,
            );
            this._notificationService.show(
                "Service account updated",
                "success",
            );

            return res;
        } catch (error) {
            this._storagePort.downloadLocation.isEditServiceAccountInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.EditServiceAccount,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async deleteServiceAccount(
        params: DownloadLocationDeleteServiceAccountParams,
    ): Promise<void> {
        try {
            this._storagePort.downloadLocation.isDeleteServiceAccountInProgress.set(
                true,
            );
            this._errorService.clear(AirErrorKind.DeleteServiceAccount);
            await this._downloadLocationPort.deleteServiceAccount(params);
            this._storagePort.downloadLocation.isDeleteServiceAccountInProgress.set(
                false,
            );
            this._notificationService.show(
                "Service account deleted",
                "success",
            );
        } catch (error) {
            this._storagePort.downloadLocation.isDeleteServiceAccountInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.DeleteServiceAccount,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}
