import { TagsAdapter } from "../../infrastructure/tags/tagsAdapter";
import { TagsService } from "../tags/TagsService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useTags = new TagsService(
    new TagsAdapter(),
    useError,
    useStorage,
    useNotification,
);
