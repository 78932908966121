import { IndexedLocation } from "../../../../../application/indexedLocation/indexedLocation";
import { IndexedLocationAddParams } from "../../../../../application/indexedLocation/indexedLocationPort";
import { Option } from "../../../../../domain/types/types";

// TODO: extend domain instead
type BaseIndexedLocation = Omit<IndexedLocation, "storageName" | "id">;

function validateBaseIndexedLocation(
    baseIndexedLocation: BaseIndexedLocation,
): boolean {
    return (
        baseIndexedLocation.title.length > 0 &&
        baseIndexedLocation.containerName.length > 0 &&
        baseIndexedLocation.accessKey.length > 0 &&
        baseIndexedLocation.subscription !== null &&
        baseIndexedLocation.subscription.length > 0
    );
}

export function validateExistedIndexedLocation(
    indexedLocation: Option<IndexedLocation>,
): boolean {
    return (
        indexedLocation !== undefined &&
        indexedLocation !== null &&
        validateBaseIndexedLocation(indexedLocation) &&
        indexedLocation.storageName.length > 0
    );
}

export function validateNewIndexedLocation(
    indexedLocation: Option<IndexedLocationAddParams>,
): boolean {
    return (
        indexedLocation !== undefined &&
        indexedLocation !== null &&
        validateBaseIndexedLocation(
            indexedLocation as unknown as BaseIndexedLocation,
        ) &&
        indexedLocation.resourceId.length > 0
    );
}
