import React, { useEffect } from "react";
import { Text } from "@fluentui/react-components";
import shareAccessState from "./shareAccessAppState";
import { router } from "../router";
import { shareAccessRouterListener } from "./shareAccessRouterListener";
import { useEventBus } from "../../application/useCases/useEventBus";
import {
    ShareAccessEvents,
    changeActiveDirectoryEventHandler,
    changeActiveItemEventHandler,
    changeViewModeEventHandler,
    checkAccessEventHandler,
    downloadByLinkEventHandler,
    downloadItemEventHandler,
    getFileDetailsEventHandler,
    getFilesEventHandler,
    initSharedAppEventHandler,
    updateViewModeEventHandler,
} from "./shareAccessEventBus";
import { state } from "../state/stateAdapter";
import { useStorage } from "../../application/useCases/useStorage";
import Loader from "../components/Loader/Loader";
import NotificationToastsContainer from "../components/NotificationToastsContainer/NotificationToastsContainer";
import Layout from "./Layout/Layout";
import ShareAccessHeader from "./ShareAccessHeader/ShareAccessHeader";
import { useNotification } from "../../application/useCases/useNotification";

type ShareAccessProps = {
    shareUrl: string;
};

function onHideModalNotification(id: string): void {
    useNotification.hide(id);
}

function onAutoHideModalNotification(id: string): void {
    useNotification.hide(id);
}

function ShareAccess(props: ShareAccessProps): JSX.Element {
    const isAppInit = state.useState(shareAccessState.isAppInit);
    const sharingDetails = state.useState(useStorage.sharing.sharingDetails);
    const shareUrl = state.useState(useStorage.sharing.shareUrl);

    // REGISTER/UNREGISTER EVENTS
    useEffect(() => {
        useEventBus.on({
            event: ShareAccessEvents.InitApp,
            callback: initSharedAppEventHandler,
            needAwait: true,
            priority: 5,
        });

        useEventBus.on({
            event: ShareAccessEvents.CheckAccess,
            callback: checkAccessEventHandler,
            needAwait: true,
            priority: 5,
        });

        useEventBus.on({
            event: ShareAccessEvents.GetFiles,
            callback: getFilesEventHandler,
            needAwait: true,
            priority: 5,
        });

        useEventBus.on({
            event: ShareAccessEvents.ChangeActiveDirectory,
            callback: changeActiveDirectoryEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: ShareAccessEvents.ChangeActiveItem,
            callback: changeActiveItemEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: ShareAccessEvents.ChangeViewMode,
            callback: changeViewModeEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: ShareAccessEvents.UpdateViewMode,
            callback: updateViewModeEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: ShareAccessEvents.DownloadItem,
            callback: downloadItemEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: ShareAccessEvents.DownloadByLink,
            callback: downloadByLinkEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: ShareAccessEvents.GetFileDetails,
            callback: getFileDetailsEventHandler,
            needAwait: false,
            priority: 5,
        });

        return () => {
            useEventBus.off(ShareAccessEvents.InitApp);
            useEventBus.off(ShareAccessEvents.CheckAccess);
            useEventBus.off(ShareAccessEvents.GetFiles);
            useEventBus.off(ShareAccessEvents.ChangeActiveDirectory);
            useEventBus.off(ShareAccessEvents.ChangeActiveItem);
            useEventBus.off(ShareAccessEvents.ChangeViewMode);
            useEventBus.off(ShareAccessEvents.UpdateViewMode);
            useEventBus.off(ShareAccessEvents.DownloadItem);
            useEventBus.off(ShareAccessEvents.DownloadByLink);
            useEventBus.off(ShareAccessEvents.GetFileDetails);
        };
    }, []);

    // INIT APP
    useEffect(() => {
        if (!isAppInit) {
            useEventBus.emit(ShareAccessEvents.InitApp, {
                shareUrl: props.shareUrl,
            });
        }
    }, [isAppInit, props.shareUrl]);

    // REGISTER/UNREGISTER ROUTE CHANGE LISTENER
    useEffect(() => {
        router.routeListenersManager.add(shareAccessRouterListener);

        return () => {
            router.routeListenersManager.remove(shareAccessRouterListener);
        };
    }, []);

    if (!isAppInit) {
        return (
            <div className="app_loading">
                <Loader text="Initializing AIR Share Access..." />
            </div>
        );
    }

    if (!sharingDetails || shareUrl === null) {
        return (
            <>
                <ShareAccessHeader />
                <div className="app__layout">
                    <Text>Wrong lik</Text>
                </div>
            </>
        );
    }

    return (
        <>
            <ShareAccessHeader />
            <div className="app__layout">
                <Layout sharingDetails={sharingDetails} />
                <NotificationToastsContainer
                    className="app__notification-toasts-container"
                    notificationsStorage={useStorage.notifications}
                    onHide={onHideModalNotification}
                    onAutoHide={onAutoHideModalNotification}
                    above
                />
            </div>
        </>
    );
}

export default ShareAccess;
