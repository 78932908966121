import React from "react";
import { Spinner } from "@fluentui/react-components";
import "./loader.css";

type LoaderProps = {
    text: string;
    size?:
        | "small"
        | "tiny"
        | "extra-small"
        | "medium"
        | "large"
        | "extra-large"
        | "huge";
    className?: string;
};

function Loader(props: LoaderProps): JSX.Element {
    return (
        <Spinner
            size={props.size}
            className={`loader ${props.className ?? ""}`}
            appearance="primary"
            label={props.text}
        />
    );
}

export default Loader;
