import { apiCall, validateRes } from "../api/api";
import { FacetsPort } from "../../application/facets/facetsPort";
import { Facet } from "../../domain/facet/facet";

export class FacetsAdapter implements FacetsPort {
    async fetch(): Promise<Facet[]> {
        const res = await apiCall("/api/facets", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }
}
