import React, { useCallback, useEffect, useState } from "react";
import { Switch, Field, Input } from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import SelectUsersField from "../../../../../components/Form/SelectUsersField/SelectUsersField";
import SliderSize from "../../../../../components/SliderSize/SliderSize";
import AdminLayout from "../../AdminLayout/AdminLayout";
import { useCommonFluentuiStyles } from "../../../../../styles/griffel";
import { protectedDataParamsKeys } from "../../../../../../domain/dataParams/dataParams";
import { AdminActiveMenuKind } from "../../../../../models";
import { router } from "../../../../../router";
import { useDownloadLocation } from "../../../../../../application/useCases/useDownloadLocation";
import Loader from "../../../../../components/Loader/Loader";
import { DownloadLocationFetchParams } from "../../../../../../application/downloadLocation/downloadLocationPort";
import {
    downloadLocationButesLimitMin,
    downloadLocationButesLimitStep,
    downloadLocationButesLimitMax,
} from "../DownloadLocations";
import {
    DownloadLocationPermission,
    DownloadLocationType,
} from "../../../../../../application/downloadLocation/downloadLocation";
import DownloadLocationLabels from "../downloadLocationLabel";

function isEqual(data: unknown | null, initData: unknown | null): boolean {
    if (
        (data === null && initData === null) ||
        (data === undefined && initData === undefined)
    ) {
        return true;
    }
    return JSON.stringify(data) === JSON.stringify(initData);
}

function LocalDownloadLocation(): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();
    const [isQueryInProgress, setIsQueryInProgress] = useState<boolean>(true);
    const [data, setData] = useState<DownloadLocationFetchParams>();
    const [initData, setInitData] = useState<DownloadLocationFetchParams>();
    const [formKey, setFormKey] = useState<number>(0);
    const isFormValid =
        data &&
        data.permissions &&
        data.permissions.length > 0 &&
        data.title.length > 0;

    const updateData = useCallback(
        (
            name: string,
            value:
                | string
                | number
                | undefined
                | boolean
                | DownloadLocationPermission[],
        ): void => {
            if (
                data !== undefined &&
                data[name as keyof DownloadLocationFetchParams] !== value
            ) {
                setData({ ...data, [name]: value });
            }
        },
        [data],
    );

    const saveChanges = useCallback(async () => {
        if (isFormValid && data !== null) {
            try {
                setIsQueryInProgress(true);

                if (data.locationType === null) {
                    data.locationType = DownloadLocationType.Local;
                }

                if (data.title === null) {
                    data.title = DownloadLocationLabels.Local;
                }

                await useDownloadLocation.editByType(data);
                router.goTo({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.DownloadLoactions,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                });
            } catch (err) {
                setIsQueryInProgress(false);
            }
        }
    }, [data, isFormValid]);

    function discardChanges(): void {
        if (initData !== undefined) {
            setData({ ...initData });
            setFormKey(formKey + 1);
        }
    }

    useEffect(() => {
        if (data === undefined) {
            setIsQueryInProgress(true);
            useDownloadLocation
                .fetchByType({
                    locationType: DownloadLocationType.Local,
                })
                .then((res): void => {
                    if (res !== null && res !== undefined) {
                        setData({ ...res });
                        setInitData({ ...res });
                    }
                    setIsQueryInProgress(false);
                });
        }
    }, [data]);

    if (data === undefined || isQueryInProgress) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            buttons={[
                {
                    title: "Save changes",
                    onClick: saveChanges,
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled:
                        isQueryInProgress ||
                        !isFormValid ||
                        isEqual(initData, data),
                },
                {
                    title: "Discard changes",
                    onClick: discardChanges,
                    appearance: "subtle",
                    disabled: isQueryInProgress || isEqual(initData, data),
                },
            ]}
            backLink={{
                href: router.createRoute({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.DownloadLoactions,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                }),
                title: "Back to Download Locations",
            }}
            title={DownloadLocationLabels.Local}
        >
            <form className="admin__form" key={formKey}>
                <Switch
                    className={commonClasses.field}
                    checked={data.enabled}
                    onChange={(_e, data): void => {
                        updateData("enabled", data.checked);
                    }}
                    label={`Enable ${
                        data.title ? data.title : DownloadLocationLabels.Local
                    }`}
                />

                <Field className={commonClasses.field} label="Label" required>
                    <Input
                        size="large"
                        value={data.title}
                        disabled={!data.enabled}
                        onChange={(_e, d): void => {
                            updateData("title", d.value);
                        }}
                        required
                    />
                </Field>

                <Field
                    className={commonClasses.field}
                    label="List of AD security groups who could download content locally"
                    required
                >
                    <SelectUsersField
                        disabled={!data.enabled}
                        onChange={(permissions): void => {
                            updateData("permissions", permissions);
                        }}
                        data={data?.permissions}
                    />
                </Field>

                <Field className={commonClasses.field}>
                    <SliderSize
                        title="Limit size of content to be downloaded locally"
                        disabled={!data.enabled}
                        min={downloadLocationButesLimitMin}
                        step={downloadLocationButesLimitStep}
                        max={downloadLocationButesLimitMax}
                        value={data.bytesLimit}
                        onChange={(value: number): void => {
                            updateData("bytesLimit", value);
                        }}
                    />
                </Field>
            </form>
        </AdminLayout>
    );
}

export default LocalDownloadLocation;
