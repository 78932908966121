import React from "react";
import {
    Checkbox,
    Link,
    Text,
    Caption1,
    makeStyles,
    shorthands,
    tokens,
    mergeClasses,
} from "@fluentui/react-components";
import { CheckboxOnChangeData } from "@fluentui/react-components";
import "../tile.css";
import "./tilePreview.scss";

const useStyles = makeStyles({
    tile: {
        ...shorthands.border(
            "2px",
            "solid",
            tokens.colorNeutralForegroundInverted,
        ),
        "&:hover": {
            backgroundColor: tokens.colorNeutralBackground1Selected,
        },
    },
    tileSelected: {
        backgroundColor: tokens.colorNeutralBackground1Selected,
    },
    checkbox: {
        "& > div": {
            backgroundColor: tokens.colorNeutralForegroundStaticInverted,
        },
    },
    textColor: {
        color: tokens.colorNeutralForegroundStaticInverted,
    },
    link: {
        display: "flex",
        ...shorthands.padding("12px", "8px"),
    },
});

type TilePreviewProps = {
    isSelectable: boolean;
    caption: string | null;
    title: string;
    image: string;
    selected: boolean;
    onChangeCheckbox?: (
        ev: React.ChangeEvent<HTMLInputElement>,
        _data: CheckboxOnChangeData,
    ) => void;
    link: string;
    onClickContainer: (
        e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
    ) => void;
};

function TilePreview(props: TilePreviewProps): JSX.Element {
    const classes = useStyles();

    return (
        <div
            className={mergeClasses(
                "tile",
                "tile-preview",
                classes.tile,
                props.selected ? "tile_selected" : "",
                props.selected ? classes.tileSelected : "",
            )}
            style={{
                backgroundImage: `url('${props.image}')`,
            }}
            role="presentation"
            onClick={props.onClickContainer}
        >
            {props.isSelectable ? (
                <div className="tile__checkbox">
                    <Checkbox
                        className={classes.checkbox}
                        checked={props.selected}
                        onChange={props.onChangeCheckbox}
                    />
                </div>
            ) : null}
            <Link
                appearance="subtle"
                href={props.link}
                data-route
                className={mergeClasses(classes.link, "tile-preview__link")}
            >
                <div className={classes.textColor}>
                    <Text truncate block wrap={false} title={props.title}>
                        {props.title}
                    </Text>
                </div>
                {props.caption !== null && (
                    <Caption1 className={classes.textColor}>
                        {props.caption}
                    </Caption1>
                )}
            </Link>
        </div>
    );
}

export default TilePreview;
