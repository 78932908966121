import React, { Fragment, useEffect } from "react";
import {
    Subtitle1,
    Subtitle2,
    Text,
    Button,
    PresenceBadge,
    Divider,
} from "@fluentui/react-components";
import LocalDownloadLocation from "./LocalDownloadLocation/LocalDownloadLocation";
import OneDriveLocation from "./OneDriveLocation/OneDriveLocation";
import SharePointLocation from "./SharePointLocation/SharePointLocation";
import "./downloadLocations.css";
import { state } from "../../../../state/stateAdapter";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { router } from "../../../../router";
import { protectedDataParamsKeys } from "../../../../../domain/dataParams/dataParams";
import AzureLocation from "./AzureLocation/AzureLocation";
import { useDownloadLocation } from "../../../../../application/useCases/useDownloadLocation";
import Loader from "../../../../components/Loader/Loader";
import { DownloadLocationType } from "../../../../../application/downloadLocation/downloadLocation";
import DownloadLocationLabels from "./downloadLocationLabel";

export const downloadLocationButesLimitMin = 104857600;
export const downloadLocationButesLimitMax = 10737418240;
export const downloadLocationButesLimitStep = 104857600;

const items = [
    {
        type: DownloadLocationType.Local,
        title: DownloadLocationLabels.Local,
        description:
            "Download single file or archive of multiple files to desktop via browser",
    },
    {
        type: DownloadLocationType.OneDrive,
        title: DownloadLocationLabels.OneDrive,
        description: "Copy files to logged in user’s  Microsoft OneDrive",
    },
    {
        type: DownloadLocationType.SharePoint,
        title: DownloadLocationLabels.SharePoint,
        description: "Copy content to Sharepoint Document Library ",
    },
    {
        type: DownloadLocationType.AzureFiles,
        title: DownloadLocationLabels.AzureFiles,
        description: "Copy content to Azure files",
    },
];

function DownloadLocations(): JSX.Element | null {
    const type = state.useState(useStorage.adminMiniApp.type);
    const isQueryInProgress = state.useState(
        useStorage.downloadLocation.isFetchDownloadLocationQueryInProgress,
    );
    const downloadLocations = state.useState(
        useStorage.downloadLocation.downloadLocationsList,
    );

    useEffect(() => {
        useDownloadLocation.fetchLocationsList();
    }, []);

    if (isQueryInProgress || downloadLocations === undefined) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    if (type !== undefined) {
        if (type == DownloadLocationType.Local) {
            return <LocalDownloadLocation />;
        }

        if (type == DownloadLocationType.SharePoint) {
            return <SharePointLocation />;
        }

        if (type == DownloadLocationType.OneDrive) {
            return <OneDriveLocation />;
        }

        if (type == DownloadLocationType.AzureFiles) {
            return <AzureLocation />;
        }

        // if (type === DownloadLocationType.Resilio) {
        //     return <ResilioLocation />;
        // }
    }

    return (
        <>
            <div />
            <div className="admin__container">
                <div className="admin__title">
                    <Subtitle1>Download Locations</Subtitle1>
                </div>
                <div className="download-locations__list">
                    {items.map((i, index) => (
                        <Fragment key={i.type}>
                            <div className="download-locations__item">
                                <div className="download-locations__item-info">
                                    <Subtitle2>
                                        {i.title}{" "}
                                        <PresenceBadge
                                            size="small"
                                            status={
                                                downloadLocations.get(i.type)
                                                    ? "available"
                                                    : "offline"
                                            }
                                        />
                                    </Subtitle2>
                                    <Text>{i.description}</Text>
                                </div>
                                <Button
                                    size="large"
                                    as="a"
                                    data-route
                                    href={router.createRoute({
                                        search: {
                                            items: [
                                                {
                                                    key: "type",
                                                    value: i.type.toString(),
                                                },
                                            ],
                                            protect: [
                                                ...protectedDataParamsKeys,
                                                "activeMenu",
                                            ],
                                        },
                                    })}
                                >
                                    Configure
                                </Button>
                            </div>
                            {items.length - 1 > index ? <Divider /> : null}
                        </Fragment>
                    ))}
                </div>
            </div>
        </>
    );
}

export default DownloadLocations;
