import React, {
    useState,
    RefObject,
    useCallback,
    useMemo,
    useEffect,
    SyntheticEvent,
} from "react";
import SeekBar from "../../../SeekBar/SeekBar";
import { Subtitle1, Button, Caption1 } from "@fluentui/react-components";
import { ChevronUpRegular, ChevronDownRegular } from "@fluentui/react-icons";
import ExtendedViewOverflow from "../ExtendedViewOverflow/ExtendedViewOverflow";
import ExtendedViewEmptySearch from "../ExtendedViewEmptySearch/ExtendedViewEmptySearch";
import { ExtendedViewData } from "../../../models";
import { CommonType } from "../../../../../../application/cognitiveMetadata/cognitiveMetadataPort";
import Tag from "../../../../Tag/Tag";
import { searchData, sortUniqData } from "../../../utils";
import { state } from "../../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../../videoExtendedViewLocalState";
import { useViewExtendedStyles } from "../../VideoExtendedView";

export type ExtendedViewTopicsProps = {
    duration: number;
    title: string;
    videoRef: RefObject<HTMLVideoElement>;
    iab: ExtendedViewData[];
    iptc: ExtendedViewData[];
    isPlayerPlaying: boolean;
    onPrevInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        setCurrentIndex: (index: number) => void,
    ) => void;
    onPauseInPlayer: () => void;
    onPlayInPlayer: () => void;
    onNextInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        currentIndex: number,
        setCurrentIndex: (index: number) => void,
    ) => void;
    showTime: (
        e: React.MouseEvent<HTMLElement>,
        setSeekTime: (time: number) => void,
    ) => void;
    onClickTimeLine: (
        e: React.MouseEvent<HTMLElement>,
        seekTime: number,
    ) => void;
};

type Topic = {
    value: string;
    parent: string | null;
    child: Set<string>;
    item?: ExtendedViewData;
};

function ExtendedViewTopics(
    props: ExtendedViewTopicsProps,
): JSX.Element | null {
    const classesViewExtended = useViewExtendedStyles();
    const query = state.useState(videoExtendedLocalState.query);
    const [activeItem, setActiveItem] = useState<CommonType>();
    const [isOverflow, setIsOverflow] = useState(true);
    const [hiddenCount, setHiddenCount] = useState(0);
    const [tempQuery, setTempQuery] = useState(query);
    const sortedDataIab = useMemo(
        () =>
            sortUniqData([
                ...new Map(props.iab.map(a => [a.value, a])).values(),
            ]),
        [props.iab],
    );
    const dataIab = searchData(sortedDataIab, query);
    const sortedDataIptc = useMemo(
        () => sortUniqData(props.iptc),
        [props.iptc],
    );
    const dataIptc = searchData(sortedDataIptc, query);
    const topics: Map<string, Topic> = dataIptc.reduce(
        (acc: Map<string, Topic>, curItem: CommonType) => {
            const topics = curItem.value.split("/");

            if (!acc.has("root")) {
                acc.set("root", {
                    parent: null,
                    child: new Set(),
                    value: "root",
                });
            }

            topics.forEach((topic, index) => {
                if (index === 0) {
                    acc.get("root")?.child.add(topic);
                }

                const curChild = topics[index + 1];
                let child: Set<string> = new Set();

                if (curChild !== undefined) {
                    if (acc.get(topic)) {
                        const topicChild = acc.get(topic)?.child.add(curChild);

                        if (topicChild !== undefined) {
                            child = topicChild;
                        }
                    } else {
                        child.add(curChild);
                    }
                }

                acc.set(topic, {
                    item: curItem,
                    value: topic,
                    parent: index === 0 ? "root" : topics[index - 1],
                    child: child,
                });
            });

            return acc;
        },
        new Map<string, Topic>(),
    );

    const countTags: number = Array.from(topics.values()).reduce(
        (acc: number, t: Topic) => {
            if (t.child.size === 0) {
                return acc + 1;
            }

            return acc;
        },
        dataIab.length,
    );

    useEffect(() => {
        if (tempQuery !== query) {
            setActiveItem(dataIab[0] ?? dataIptc[0]);
            setTempQuery(query);
        }
    }, [activeItem, dataIab, dataIptc, query, tempQuery]);

    const renderChildTopic = useCallback(
        (childKeys: Set<string> | undefined): React.ReactElement[] | null => {
            const elements: React.ReactElement[] = [];

            if (childKeys === undefined) {
                return null;
            }

            childKeys.forEach((topicKey: string) => {
                const topic = topics.get(topicKey);

                if (topic !== undefined) {
                    elements.push(
                        <div key={topicKey} className="extended-view__topics">
                            {topic.child.size ? (
                                <Caption1>
                                    <span className="txt_no-wrap">
                                        {topic.value} /
                                    </span>
                                </Caption1>
                            ) : (
                                topic.item !== undefined && (
                                    <Tag
                                        item={{ value: topic.value }}
                                        isActive={
                                            activeItem?.value ===
                                            topic.item.value
                                        }
                                        onClick={(): void => {
                                            if (topic.item !== undefined) {
                                                setActiveItem(topic.item);
                                            }
                                        }}
                                    />
                                )
                            )}
                            {topic.child && renderChildTopic(topic.child)}
                        </div>,
                    );
                }
            });
            return elements;
        },
        [activeItem, topics],
    );

    const toggleOverflow = useCallback((): void => {
        setIsOverflow(!isOverflow);
    }, [isOverflow]);

    const onSetHiddenCount = useCallback((count: number): void => {
        setHiddenCount(count);
    }, []);

    const totalCount = dataIab.length === 0 && dataIptc.length;

    if (
        query.length > 0 &&
        totalCount === 0 &&
        props.iab.length | props.iptc.length
    ) {
        return <ExtendedViewEmptySearch title={props.title} />;
    }

    if (totalCount === 0) {
        return null;
    }

    return (
        <div className="extended-view__section">
            <div className="extended-view__section-title">
                <Subtitle1 className={classesViewExtended.title}>
                    {countTags} {props.title}
                </Subtitle1>
                {hiddenCount > 1 && (
                    <Button
                        onClick={toggleOverflow}
                        appearance="transparent"
                        icon={
                            isOverflow ? (
                                <ChevronDownRegular />
                            ) : (
                                <ChevronUpRegular />
                            )
                        }
                    />
                )}
            </div>

            {topics.get("root") && renderChildTopic(topics.get("root")?.child)}

            <ExtendedViewOverflow
                data={dataIab}
                isOverflow={isOverflow}
                toggleOverflow={toggleOverflow}
                onSetHiddenCount={onSetHiddenCount}
                renderItem={(i): JSX.Element => (
                    <Tag
                        item={i}
                        isActive={i.value === activeItem?.value}
                        onClick={(): void => {
                            setActiveItem(i);
                        }}
                    />
                )}
            />

            {activeItem && activeItem.appearances && (
                <SeekBar
                    videoRef={props.videoRef}
                    duration={props.duration}
                    appearances={activeItem.appearances}
                    isPlayerPlaying={props.isPlayerPlaying}
                    onPrevInPlayer={props.onPrevInPlayer}
                    onPauseInPlayer={props.onPauseInPlayer}
                    onPlayInPlayer={props.onPlayInPlayer}
                    onNextInPlayer={props.onNextInPlayer}
                    showTime={props.showTime}
                    onClickTimeLine={props.onClickTimeLine}
                />
            )}
        </div>
    );
}

export default ExtendedViewTopics;
