import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Subtitle1,
    makeStyles,
    shorthands,
} from "@fluentui/react-components";
import React from "react";
import FileInformation from "../FileInformation/FileInformation";
import { FilePreview } from "../FileDetails";
import { AirFileDetails } from "../../../../domain/airFile/details";
import "./editTagsPanel.css";
import { useFiles } from "../../../../application/useCases/useFiles";
import EditTagsForm from "../../EditTagsForm/EditTagsForm";
import {
    FilesEditTagsParams,
    FilesTagGroupParam,
} from "../../../../application/files/filesPort";
import { ClipboardTextEdit24Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    accordionHeader: {
        "& > button": {
            ...shorthands.border(0),
            ...shorthands.padding(0),
        },
    },
    accordionPanel: {
        paddingBottom: "20px",
        ...shorthands.margin(0),
    },
});

type EditTagsPanelProps = {
    onClose: () => void;
    airFileDetails: AirFileDetails;
};

async function onSave(params: FilesEditTagsParams): Promise<void> {
    await useFiles.editTags(params);
    await useFiles.getDetails({
        id: params.assetId,
    });
}

function EditTagsPanel(props: EditTagsPanelProps): JSX.Element | null {
    const classes = useStyles();

    return (
        <div
            className={`edit-tags-panel ${
                props.airFileDetails ? "" : "edit-tags-panel_multiply"
            }`}
        >
            {props.airFileDetails && (
                <div className="edit-tags-panel__info">
                    <div>
                        <FilePreview />
                    </div>
                    <div className="edit-tags-panel__accordion">
                        <Accordion
                            multiple
                            collapsible
                            defaultOpenItems={["information"]}
                        >
                            <AccordionItem value="information">
                                <AccordionHeader
                                    expandIconPosition="end"
                                    className={classes.accordionHeader}
                                >
                                    <Subtitle1>Information</Subtitle1>
                                </AccordionHeader>
                                <AccordionPanel
                                    className={classes.accordionPanel}
                                >
                                    <FileInformation
                                        fileDetails={props.airFileDetails}
                                    />
                                </AccordionPanel>
                            </AccordionItem>
                            {/* <AccordionItem value="exif" disabled>
                                <AccordionHeader
                                    expandIconPosition="end"
                                    className={classes.accordionHeader}
                                >
                                    <Subtitle1>EXIF</Subtitle1>
                                </AccordionHeader>
                                <AccordionPanel
                                    className={classes.accordionPanel}
                                ></AccordionPanel>
                            </AccordionItem> */}
                        </Accordion>
                    </div>
                </div>
            )}
            <div className="edit-tags-panel__form">
                <EditTagsForm
                    title="Edit tags"
                    titleIcon={<ClipboardTextEdit24Regular />}
                    initData={props.airFileDetails.tagGroups ?? []}
                    onClose={props.onClose}
                    onSave={(tagGroups: FilesTagGroupParam[]): void => {
                        onSave({
                            tagGroups: tagGroups,
                            assetId: props.airFileDetails.id,
                        });
                    }}
                />
            </div>
        </div>
    );
}

export default EditTagsPanel;
