import React, { useRef } from "react";
import {
    Checkbox,
    Link,
    Text,
    Caption1,
    CheckboxOnChangeData,
    makeStyles,
    tokens,
    mergeClasses,
} from "@fluentui/react-components";
import IconItem from "../../IconItem/IconItem";

import "../tile.css";
import "./tileIcon.scss";

const useStyles = makeStyles({
    tile: {
        "&:hover": {
            backgroundColor: tokens.colorNeutralBackground1Selected,
        },
    },
    tileSelected: {
        backgroundColor: tokens.colorNeutralBackground1Selected,
    },
    checkbox: {
        "& > div": {
            backgroundColor: tokens.colorNeutralForegroundStaticInverted,
        },
    },
    caption: {
        color: tokens.colorNeutralForeground3,
        marginTop: "-5px",
    },
});

type TileIconProps = {
    selected: boolean;
    isSelectable: boolean;
    caption: string | null;
    title: string;
    onChangeCheckbox?: (
        ev: React.ChangeEvent<HTMLInputElement>,
        _data: CheckboxOnChangeData,
    ) => void;
    onClickContainer?: (
        e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
    ) => void;
    isFile: boolean;
    filePath: string;
    link: string;
};

function TileIcon(props: TileIconProps): JSX.Element {
    const containerRef = useRef(null);
    const classes = useStyles();

    return (
        <div
            className={mergeClasses(
                "tile",
                "tile-icon",
                classes.tile,
                props.selected ? "tile_selected" : "",
                props.selected ? classes.tileSelected : "",
            )}
        >
            {props.isSelectable ? (
                <div className="tile__checkbox">
                    <Checkbox
                        className={classes.checkbox}
                        checked={props.selected}
                        onChange={props.onChangeCheckbox}
                    />
                </div>
            ) : null}

            <div
                className="tile-icon__container"
                role="presentation"
                ref={containerRef}
                onClick={(e): void => {
                    if (
                        props.isSelectable &&
                        containerRef.current === e.target &&
                        props.onClickContainer !== undefined
                    ) {
                        props.onClickContainer(e);
                    }
                }}
            >
                <Link
                    appearance="subtle"
                    href={props.link}
                    data-route
                    className="tile-icon__link"
                >
                    <span
                        className={`tile-icon__icon-container ${
                            props.isFile ? "tile-icon__icon-container_bg" : ""
                        }`}
                    >
                        <IconItem
                            filePath={props.filePath}
                            className="tile-icon__icon"
                            isFile={props.isFile}
                        />
                    </span>
                    <br />
                    <span className="tile-icon__title">
                        <Text
                            truncate
                            block
                            wrap={false}
                            title={props.title}
                            align="center"
                        >
                            {props.title}
                        </Text>
                    </span>
                </Link>
                {props.caption ? (
                    <Caption1 className={classes.caption} block align="center">
                        <span>{props.caption}</span>
                    </Caption1>
                ) : null}
            </div>
        </div>
    );
}

export default TileIcon;
