import React, { SyntheticEvent } from "react";
import { Text } from "@fluentui/react-components";
import { AirFile } from "../../../domain/airFile/airFile";
import Loader from "../Loader/Loader";
import TileFile from "./TileFile";

import "./tiles.css";

type TilesProps = {
    isQueryInProgress: boolean;
    error: string | undefined;
    activeDirectory: string;
    files: AirFile[];
    filesSelection: Map<string, unknown>;
    selectable: boolean;
    onSelect: (e: SyntheticEvent, item: unknown) => void;
    onSingleSelect: (
        e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
        item: unknown,
    ) => void;
    isFile: (item: AirFile) => boolean;
    createLink: (item: AirFile, isFile: boolean) => string;
};

function Tiles(props: TilesProps): JSX.Element {
    if (props.isQueryInProgress) {
        return <Loader text="Fetching files..." />;
    }

    if (props.error) {
        return <Text>Error {props.error}</Text>;
    }

    if (props.files.length === 0) {
        return <></>;
    }

    return (
        <div className="tiles">
            {props.files.map((item: AirFile) => {
                const isFile = props.isFile(item);
                const link = props.createLink(item, isFile);

                return (
                    <TileFile
                        key={item.id}
                        item={item}
                        onSelect={props.onSelect}
                        onSingleSelect={props.onSingleSelect}
                        selected={props.filesSelection.has(item.hierarchy)}
                        isSelectable={props.selectable}
                        isFile={isFile}
                        link={link}
                    />
                );
            })}
        </div>
    );
}

export default Tiles;
