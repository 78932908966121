import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { FacetService } from "../facets/facetService";
import { FacetsAdapter } from "../../infrastructure/facets/facetsAdapter";
import { useStorage } from "./useStorage";

export const useFacets = new FacetService(
    new FacetsAdapter(),
    useError,
    useStorage,
    useNotification,
);
