import qs from "qs";
import { AuthorizationPort } from "../../application/authorization/authorizationPort";
import { AuthorizationConfig } from "../../application/authorization/authorizationConfig";
import { UserCredentials } from "../../domain/user/userCredentials";
import { getBaseUrl, validateRes } from "../api/api";

export class AuthorizationAdapter implements AuthorizationPort {
    private readonly redirectUri = `${window.location.protocol}//${window.location.host}/sign-in-callback`;

    async getAuthorizationConfig(
        redirectUri: string = this.redirectUri,
    ): Promise<AuthorizationConfig> {
        const res = await fetch(
            getBaseUrl(`/api/token/request?redirectUri=${redirectUri}`),
        );

        return await validateRes(res);
    }

    async getUserCredentials(state: string): Promise<UserCredentials> {
        const query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        const payload = {
            state,
            code: query.code,
            redirectUri: this.redirectUri,
        };

        const res = await fetch(getBaseUrl("/api/token"), {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
        });

        const validRes: UserCredentials = await validateRes(res);
        validRes.code = query.code?.toString() ?? "";

        return validRes;
    }

    async signIn(token: string, state: string): Promise<void> {
        await fetch(getBaseUrl("/api/authentication/track"), {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "X-Session-Id": state,
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({ actionType: "SignIn" }),
        });
    }

    async signOut(token: string, state: string): Promise<void> {
        await fetch(getBaseUrl("/api/authentication/track"), {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "X-Session-Id": state,
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({ actionType: "SignOut" }),
        });
    }
}
