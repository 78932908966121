import React, { useCallback, useEffect } from "react";
import { state } from "../../../state/stateAdapter";
import { useStorage } from "../../../../application/useCases/useStorage";
import { router } from "../../../router";
import Loader from "../../../components/Loader/Loader";
import CollectionsCommandBar from "./CollectionsCommandBar/CollectionsCommandBar";
import { collectionsRouteListener } from "./collectionsRouteListener";
import { collectionsManager } from "./collectionsManager";
import { useCollections } from "../../../../application/useCases/useCollections";
import HierarchyBreadcrumbs from "../../../components/Breadcrumbs/HierarchyBreadcrumbs";
import CollectionsData from "./CollectionsData/CollectionsData";
import { TableColumnDefinition } from "@fluentui/react-components";
import {
    AirFile,
    prepareFilesToShow,
    sortFilesByType,
} from "../../../../domain/airFile/airFile";
import { CollectionItem } from "../../../../domain/collection/collections";
import { collectionsLocalState } from "./collectionsLocalState";
import {
    collectionFilesColumns,
    collectionsListColumns,
} from "./collectionsColumns";
import { appManager } from "../../../appManager/appManager";
import { Option } from "../../../../domain/types/types";
import { DataParams } from "../../../../domain/dataParams/dataParams";
import {
    ActiveItemStorageValue,
    ItemStorageValue,
} from "../../../../application/storageValues";
import { AirErrorKind } from "../../../../application/airError/airError";
import fileDetailsTab from "../../../components/FileDetails/fileDetailsTab";
import { SortKey } from "../../../../domain/airFile/airFile";
import { useFiles } from "../../../../application/useCases/useFiles";
import { useEventBus } from "../../../../application/useCases/useEventBus";
import { UIEvents } from "../../../uiEvents";
import { changeSelectionEventHandler } from "./collectionsEventsHandlers";

function isCollectionsInit(): boolean {
    return (
        useStorage.collectionsMiniApp.isDataInit.get() &&
        useStorage.collectionsMiniApp.isStateInit.get()
    );
}

async function handleSortParams(dataParams: DataParams): Promise<void> {
    useStorage.fileExplorerMiniApp.selection.set(new Map());

    if (useStorage.collections.id.get() === null) {
        await useCollections.fetch(dataParams);
    } else {
        const sorted = dataParams.sortKey
            ? sortFilesByType(
                  state.unwrap(useStorage.files.validFiles),
                  dataParams.sortKey as SortKey,
                  dataParams.isSortDesc,
              )
            : state.unwrap(useStorage.files.validFiles);
        const activeFilters = useStorage.files.activeFilters.get();

        useStorage.files.files.set(
            prepareFilesToShow(
                sorted,
                activeFilters,
                dataParams.page,
                dataParams.pageSize,
            ),
        );
    }
}

function handleActiveItemTab(activeItem: ActiveItemStorageValue): void {
    if (activeItem && activeItem.length) {
        if (!state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.addTab(fileDetailsTab);
        }
    } else {
        if (state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.deleteTab(fileDetailsTab.id);
        }
    }
}

async function fetchCollectionFiles(id: string): Promise<Option<AirFile[]>> {
    useStorage.files.files.set([]);
    const res = await useCollections.fetchById({ id });
    if (res && res.items.length > 0) {
        return await useFiles.getFiles({
            ids: res.items,
        });
    }
}

async function initCollections(isStateInit: boolean): Promise<void> {
    if (isStateInit) {
        const id = useStorage.collections.id.get();

        if (id !== null && id !== undefined) {
            const files = await fetchCollectionFiles(id);
            const selectionStr = router
                .getUrlEntities()
                .searchParams.get("selection");
            // TODO: use events in collections
            useEventBus.emit(UIEvents.CollectionsChangeSelection, {
                selection: selectionStr ? selectionStr.split(",") : [],
                files: files,
            });
        } else {
            const dataParams = useStorage.collectionsMiniApp.dataParams.get();
            await useCollections.fetch(dataParams);
        }
        useStorage.collectionsMiniApp.isDataInit.set(true);
    }
}

function useCommandBar(
    onRefresh: () => Promise<void>,
    transition: boolean,
    isCollectionsQueryInProgress: boolean,
    isFilesQueryInProgress: boolean,
    item: Option<CollectionItem>,
): void {
    useEffect(() => {
        state.commandBar.actionsFactory.set(() => (
            <CollectionsCommandBar
                onRefresh={onRefresh}
                disabled={
                    transition ||
                    isCollectionsQueryInProgress ||
                    isFilesQueryInProgress
                }
                item={item}
                updateStateAfterDataFetch={updateStateAfterDataFetch}
                isCommandBarForCollections={item === null || item === undefined}
            />
        ));
    }, [
        isCollectionsQueryInProgress,
        isFilesQueryInProgress,
        onRefresh,
        transition,
        item,
    ]);
}

function updateBreadcrumbsPath(
    item: ItemStorageValue = useStorage.collections.item.get(),
    id = useStorage.collections.id.get(),
): void {
    if (
        id !== null &&
        id !== undefined &&
        item !== null &&
        item !== undefined
    ) {
        collectionsLocalState.breadcrumbsPath.set([
            {
                hierarchy: id,
                title: item.title,
            },
        ] as AirFile[]);
    } else {
        collectionsLocalState.breadcrumbsPath.set([]);
    }
}

function updateStateAfterDataFetch(id = useStorage.collections.id.get()): void {
    collectionsLocalState.transition.set(false);
    updateBreadcrumbsPath(undefined, id);
}

function Collections(): JSX.Element {
    const item = state.useState(useStorage.collections.item);
    const errors = state.useState(useStorage.airErrors);
    const isStateInit = state.useState(
        useStorage.collectionsMiniApp.isStateInit,
    );
    const id = state.useState(useStorage.collections.id);
    const activeItem = state.useState(
        useStorage.fileExplorerMiniApp.activeItem,
    );
    const isCollectionsQueryInProgress = state.useState(
        useStorage.collections.isQueryInProgress,
    );
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );
    const transition = state.useState(collectionsLocalState.transition);
    const breadcrumbsPath = state.useState(
        collectionsLocalState.breadcrumbsPath,
    );
    const collection = state.useState(useStorage.collections.item);

    const files = collection
        ? useStorage.files.files.get() ?? []
        : useStorage.collections.list.get();

    const columns = collection
        ? collectionFilesColumns
        : collectionsListColumns;

    const error =
        errors.get(AirErrorKind.PrepareFilesToShow) ??
        errors.get(AirErrorKind.FetchFiles) ??
        errors.get(AirErrorKind.FetchFilesByPath) ??
        errors.get(AirErrorKind.SortFiles) ??
        errors.get(AirErrorKind.SortSearchedFiles) ??
        errors.get(AirErrorKind.FetchCollections) ??
        errors.get(AirErrorKind.FetchCollectionById) ??
        null;

    const isBreadcrumbsDisabled =
        transition || isCollectionsQueryInProgress || isFilesQueryInProgress;

    const isHomeDisabled =
        isBreadcrumbsDisabled || breadcrumbsPath.length === 0;

    const onRefresh = useCallback(async () => {
        useStorage.collectionsMiniApp.selection.set(new Map());

        if (item !== null && item !== undefined) {
            await fetchCollectionFiles(item.id);
        } else {
            await useCollections.fetch(
                useStorage.collectionsMiniApp.dataParams.get(),
            );
        }
    }, [item]);

    useEffect(() => {
        collectionsManager.updateContext(router.url.href);

        router.routeListenersManager.add(collectionsRouteListener);

        const sortUnsub = useStorage.collectionsMiniApp.dataParams.subscribe(
            () => {
                if (isCollectionsInit()) {
                    handleSortParams(
                        useStorage.collectionsMiniApp.dataParams.get(),
                    );
                }
            },
        );

        collectionsManager.init().then(() => {
            useStorage.collectionsMiniApp.isStateInit.set(true);
        });

        return () => {
            router.routeListenersManager.remove(collectionsRouteListener);
            sortUnsub();
            state.commandBar.actionsFactory.set(null);
            useStorage.collectionsMiniApp.isStateInit.set(false);
            useStorage.collectionsMiniApp.isDataInit.set(false);
            collectionsLocalState.transition.set(true);
            appManager.deleteTab(fileDetailsTab.id);
        };
    }, []);

    useCommandBar(
        onRefresh,
        transition,
        isCollectionsQueryInProgress,
        isFilesQueryInProgress,
        item,
    );

    useEffect(() => {
        if (isStateInit) {
            initCollections(isStateInit).then(() => {
                const id = useStorage.collections.id.get();

                updateStateAfterDataFetch(id);
            });
            handleActiveItemTab(
                useStorage.fileExplorerMiniApp.activeItem.get(),
            );
        }
    }, [isStateInit]);

    useEffect(() => {
        if (isCollectionsInit()) {
            handleActiveItemTab(activeItem);
        }
    }, [activeItem]);

    useEffect(() => {
        if (isCollectionsInit()) {
            useStorage.collectionsMiniApp.selection.set(new Map());
            collectionsLocalState.transition.set(true);

            setTimeout(async () => {
                if (id) {
                    await fetchCollectionFiles(id);

                    updateStateAfterDataFetch(id);
                } else {
                    await useCollections.fetch(
                        useStorage.collectionsMiniApp.dataParams.get(),
                    );

                    updateStateAfterDataFetch(id);
                }
            }, 10);
        }
    }, [id]);

    useEffect(() => {
        useEventBus.on({
            event: UIEvents.CollectionsChangeSelection,
            callback: changeSelectionEventHandler,
            needAwait: false,
            priority: 5,
        });

        return () => {
            useEventBus.off(UIEvents.CollectionsChangeSelection);
        };
    }, []);

    if (!isCollectionsInit()) {
        return (
            <div className="layout-container">
                <Loader text="Initializing Collections..." />
            </div>
        );
    }

    if (transition || isCollectionsQueryInProgress || isFilesQueryInProgress) {
        return (
            <div className="layout-container">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <div className="layout">
            <header className="layout__container">
                <HierarchyBreadcrumbs
                    filesHierarchy={breadcrumbsPath}
                    disabled={isBreadcrumbsDisabled}
                    paramKey="id"
                    isHomeDisabled={isHomeDisabled}
                />
            </header>
            <div className="layout__container">
                <CollectionsData
                    error={error}
                    items={(files as unknown[]) ?? []}
                    columns={columns as TableColumnDefinition<unknown>[]}
                    selectable={!!collection}
                />
            </div>
        </div>
    );
}

export default Collections;
