import {
    makeStyles,
    shorthands,
    Toolbar,
    ToolbarGroup,
    ToolbarButton,
    ToolbarRadioGroup,
    ToolbarRadioButton,
} from "@fluentui/react-components";
import {
    ListRegular,
    GridRegular,
    PanelRightContractRegular,
    PanelLeftExpandRegular,
} from "@fluentui/react-icons";
import { tokens } from "@fluentui/react-theme";
import React from "react";
import { FilesViewModeKind } from "../../../models";
import { state } from "../../../state/stateAdapter";
import { useEventBus } from "../../../../application/useCases/useEventBus";
import { ShareAccessEvents } from "../../shareAccessEventBus";
import shareAccessState from "../../shareAccessAppState";
import { useStorage } from "../../../../application/useCases/useStorage";

import "./shareAccessCommandBar.css";

const useStyles = makeStyles({
    toolBar: {
        ...shorthands.overflow("hidden"),
        display: "grid",
        ...shorthands.padding("4px", "0"),
        gridAutoFlow: "column",
        justifyContent: "space-between",
        gridAutoColumns: "max-content",
    },
    toggleButton: {
        "& span": {
            color: `${tokens.colorNeutralForeground1}`,
        },

        ":hover": {
            "& span": {
                color: `${tokens.colorBrandForeground1}`,
            },
        },
    },
    collectionsList: {
        display: "grid",
        height: "calc(6 * 32px)",
        ...shorthands.overflow("scroll"),
    },
});

function onSetFilesViewMode(viewMode: FilesViewModeKind): void {
    useEventBus.emit(ShareAccessEvents.ChangeViewMode, {
        mode: viewMode,
    });
}

function onSelectListView(): void {
    onSetFilesViewMode(FilesViewModeKind.List);
}

function onSelectTileView(): void {
    onSetFilesViewMode(FilesViewModeKind.Tiles);
}

function ShareAccessCommandBar(): JSX.Element {
    const filesToShow = state.useState(useStorage.sharing.filesToShow);
    const filesViewMode = state.useState(shareAccessState.filesViewMode);
    const tabs = state.useState(shareAccessState.panel.tabs);
    const isOpen = state.useState(shareAccessState.panel.isOpen);

    const classes = useStyles();

    return (
        <Toolbar
            aria-label="File explorer Command bar"
            defaultCheckedValues={{
                viewModeBtn:
                    filesViewMode == FilesViewModeKind.List
                        ? ["tableView"]
                        : ["tileView"],
            }}
            className={classes.toolBar}
        >
            <ToolbarGroup className="command-bar__container"></ToolbarGroup>

            <ToolbarGroup className="command-bar__far-container">
                <ToolbarRadioGroup>
                    <ToolbarRadioButton
                        className={classes.toggleButton}
                        aria-label="Table view"
                        name="viewModeBtn"
                        value="tableView"
                        icon={<ListRegular />}
                        appearance="subtle"
                        onClick={onSelectListView}
                        title="List view"
                        disabled={filesToShow.length === 0}
                    />
                    <ToolbarRadioButton
                        className={classes.toggleButton}
                        aria-label="Tile view"
                        name="viewModeBtn"
                        value="tileView"
                        icon={<GridRegular />}
                        appearance="subtle"
                        onClick={onSelectTileView}
                        title="Tiles view"
                        disabled={filesToShow.length === 0}
                    />
                </ToolbarRadioGroup>

                <ToolbarButton
                    name="toggleAppPanel"
                    onClick={(): void => {
                        shareAccessState.panel.isOpen.set(
                            !shareAccessState.panel.isOpen.get(),
                        );
                    }}
                    disabled={tabs.size === 0 || filesToShow.length === 0}
                    title={isOpen ? "Close panel" : "Open panel"}
                    icon={
                        isOpen ? (
                            <PanelRightContractRegular />
                        ) : (
                            <PanelLeftExpandRegular
                                style={{ rotate: "-180deg" }}
                            />
                        )
                    }
                />
            </ToolbarGroup>
        </Toolbar>
    );
}

export default ShareAccessCommandBar;
