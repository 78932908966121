import {
    Config,
    InitConfig,
    UIConfig,
    getDefaultConfig,
} from "../../application/config/config";
import { ConfigPort } from "../../application/config/configPort";
import { apiCall, validateRes } from "../api/api";

export class ConfigAdapter implements ConfigPort {
    private async getInitConfig(): Promise<InitConfig> {
        const res = await apiCall("/api/config/init?release=20240527", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return validateRes(res);
    }

    private async getUIConfig(): Promise<UIConfig> {
        const res = await apiCall("/api/config/ui?release=20240527", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return validateRes(res);
    }

    private mergeConfigs(init: InitConfig, ui: UIConfig): Config {
        return {
            ...getDefaultConfig(),
            ...init,
            ...ui,
        };
    }

    async getConfig(): Promise<Config> {
        const initConfig = await this.getInitConfig();
        const uiConfig = await this.getUIConfig();

        const config = this.mergeConfigs(initConfig, uiConfig);

        // TODO: disabled by SPAIR0001-467
        config.features.shareLinkUiConfiguration.emailNotifications = false;

        return config;
    }
}
