import { BrandVariants } from "@fluentui/react-theme";
import { hex_to_LCH, hexColorsFromPalette } from "./colors/palettes";
import { Palette } from "./colors/types";

export type Vec3 = [number, number, number];

type Options = {
    darkCp?: number;
    lightCp?: number;
    hueTorsion?: number;
};

function to6DigitHex(hex: string): string {
    if (hex.length === 4 || hex.length === 5) {
        return `#${hex.slice(1).padStart(6, "0")}`;
    }

    return hex;
}

export function getBrandTokensFromPalette(
    keyColor: string,
    options: Options = {},
): BrandVariants {
    const hex = to6DigitHex(keyColor);
    const { darkCp = 2 / 3, lightCp = 1 / 3, hueTorsion = 0 } = options;
    const brandPalette: Palette = {
        keyColor: hex_to_LCH(hex),
        darkCp,
        lightCp,
        hueTorsion,
    };
    const hexColors = hexColorsFromPalette(hex, brandPalette, 16, 1);
    return hexColors.reduce((acc: Record<string, string>, hexColor, h) => {
        acc[`${(h + 1) * 10}`] = hexColor;
        return acc;
    }, {}) as BrandVariants;
}
