import React from "react";
import { Text, Avatar } from "@fluentui/react-components";
import { TimelineDataItem } from "../Timeline";

export default function TimelineFaces(props: {
    data?: TimelineDataItem[];
}): JSX.Element | null {
    if (props.data === undefined) {
        return null;
    }

    return (
        <div className="timeline__faces">
            {props.data.map(i => {
                return (
                    <div className="timeline__face" key={i.value}>
                        <Avatar
                            name={i.value}
                            image={{ src: i.image ?? "" }}
                            size={32}
                            color="colorful"
                        />
                        <Text>{i.value}</Text>
                    </div>
                );
            })}
        </div>
    );
}
