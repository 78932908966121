import {
    Field,
    Input,
    InputOnChangeData,
    Textarea,
    makeStyles,
} from "@fluentui/react-components";
import React, { useCallback, useState } from "react";
import Form from "../../../../components/Form/Form";
import { state } from "../../../../state/stateAdapter";
import { TextBulletListSquareEditRegular } from "@fluentui/react-icons";
import { UserFile } from "../../../../../domain/userFile/userFile";
import { useNotification } from "../../../../../application/useCases/useNotification";
import { useUploader } from "../../../../../application/useCases/useUploader";

const useStyles = makeStyles({
    icon: {
        height: "20px",
        width: "20px",
    },
});

type ChangeMetadataDialogPanelProps = {
    items: UserFile[];
    onClose?: () => void;
};

function ChangeMetadataDialogPanel(
    props: ChangeMetadataDialogPanelProps,
): JSX.Element | null {
    const classes = useStyles();
    const metadata = props.items.map(i => {
        return { id: i.id, name: i.name, description: i.description };
    });
    const [tempMetadata, setTempMetadata] = useState(metadata);

    const onChangeMetadata = (): void => {
        state.appDialogPanel.set(null);
        useUploader.updateUserFileMetadata(tempMetadata);
    };

    const onClose = useCallback((): void => {
        state.appDialogPanel.set(null);

        if (props.onClose !== undefined) {
            props.onClose();
        }
    }, [props]);

    const onChangeName = useCallback(
        (_e: React.ChangeEvent, d: InputOnChangeData): void => {
            tempMetadata[0].name = d.value;
            setTempMetadata([...tempMetadata]);
        },
        [tempMetadata],
    );

    const onChangeDescription = useCallback(
        (_e: React.ChangeEvent, d: InputOnChangeData): void => {
            tempMetadata.forEach(i => (i.description = d.value));
            setTempMetadata([...tempMetadata]);
        },
        [tempMetadata],
    );

    const isValidData = useCallback((): boolean => {
        return (
            JSON.stringify(metadata) !== JSON.stringify(tempMetadata) &&
            tempMetadata.every(i => i.name.length > 0)
        );
    }, [metadata, tempMetadata]);

    if (props.items.length === 0) {
        useNotification.show(
            "Please select at least one file to change properties.",
            "error",
        );
        return null;
    }

    const isMultiple = props.items.length > 1;
    const title = isMultiple
        ? `Change properties for ${props.items.length} files`
        : "Change properties";
    const allSameDescription = isMultiple
        ? props.items.every(i => i.description === props.items[0].description)
        : true;

    return (
        <Form
            buttons={[
                {
                    title: "Save",
                    onClick: onChangeMetadata,
                    appearance: "primary",
                    disabled: !isValidData(),
                },
                {
                    title: "Cancel",
                    onClick: onClose,
                    appearance: "subtle",
                },
            ]}
            title={title}
            titleIcon={
                <TextBulletListSquareEditRegular className={classes.icon} />
            }
        >
            <>
                <Field
                    required
                    label="Name"
                    validationMessage={
                        tempMetadata[0].name.length === 0
                            ? "Name is required"
                            : undefined
                    }
                >
                    <Input
                        disabled={isMultiple}
                        defaultValue={
                            isMultiple
                                ? "You can't name multiple files"
                                : tempMetadata[0].name
                        }
                        placeholder="Type the file name"
                        onChange={onChangeName}
                    />
                </Field>

                <Field label="Description">
                    <Textarea
                        resize="vertical"
                        disabled={!allSameDescription}
                        defaultValue={
                            allSameDescription
                                ? tempMetadata[0].description
                                : "You cannot change the description of multiple files if they already have different descriptions."
                        }
                        placeholder="Type the file description"
                        onChange={onChangeDescription}
                    />
                </Field>
            </>
        </Form>
    );
}

export default ChangeMetadataDialogPanel;
