import { createState } from "@persevie/statemanjs";
import { StorageRepository } from "../../domain/storage/storageRepository";
import { FilesViewModeKind } from "../models";
import { AppPanelStatePort, CommandBarStatePort } from "../state/statePort";
import { CommandBarActionsFactoryStateValue } from "../state/stateValues";
import { Option } from "../../domain/types/types";
import { AirFile } from "../../domain/airFile/airFile";

export interface ShareAccessStatePort {
    panel: AppPanelStatePort;
    filesViewMode: StorageRepository<FilesViewModeKind>;
    commandBar: CommandBarStatePort;
    activeDirectory: StorageRepository<Option<string>>;
    breadcrumbsPath: StorageRepository<AirFile[]>;
    activeItem: StorageRepository<string | null>;
    isAppInit: StorageRepository<boolean>;
}

// State defenition

const tabsState = createState(new Map());
const activeTabIdState = createState("");
const isOpenState = createState(false);

const filesViewModeState = createState(FilesViewModeKind.List);

const actionsFactoryState =
    createState<CommandBarActionsFactoryStateValue>(null);

const activeDirectoryState = createState<Option<string>>(null);

const breadcrumbsPathState = createState<AirFile[]>([]);

const activeItemState = createState<string | null>(null);

const isAppInitState = createState<boolean>(false);

// Implementation of SharedAppStatePort interface.
class ShareAccessState implements ShareAccessStatePort {
    readonly panel: AppPanelStatePort = {
        tabs: tabsState,
        activeTabId: activeTabIdState,
        isOpen: isOpenState,
    };

    readonly filesViewMode: StorageRepository<FilesViewModeKind> =
        filesViewModeState;

    readonly commandBar: CommandBarStatePort = {
        actionsFactory: actionsFactoryState,
    };

    readonly activeDirectory: StorageRepository<Option<string>> =
        activeDirectoryState;

    readonly breadcrumbsPath: StorageRepository<AirFile[]> =
        breadcrumbsPathState;

    readonly activeItem: StorageRepository<string | null> = activeItemState;

    readonly isAppInit: StorageRepository<boolean> = isAppInitState;
}

const shareAccessState = new ShareAccessState();

export default shareAccessState;
