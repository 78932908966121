import { JobsAdapter } from "../../infrastructure/jobs/jobsAdapter";
import { JobsService } from "../jobs/jobsService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useJobs = new JobsService(
    new JobsAdapter(),
    useError,
    useStorage,
    useNotification,
);
