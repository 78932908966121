import { DomainEvents } from "../../domain/events/events";
import { useEventBus } from "../useCases/useEventBus";
import { useNotification } from "../useCases/useNotification";

export class UserFileUploadEventHandler {
    constructor() {
        useEventBus.on<{ filePath: string }>({
            event: DomainEvents.FileUploadSkip,
            callback: data => {
                useNotification.show(
                    `The file "${data.filePath}" was skipped for this upload because it is already being uploaded.`,
                    "warning",
                );
            },
            needAwait: false,
            priority: 5,
        });

        useEventBus.on<{ filePath: string }>({
            event: DomainEvents.FileUploadSuccess,
            callback: data => {
                useNotification.show(
                    `The file "${data.filePath}" was uploaded successfully.`,
                    "success",
                );
            },
            needAwait: false,
            priority: 5,
        });

        useEventBus.on<{ filePath: string }>({
            event: DomainEvents.FileUploadError,
            callback: data => {
                useNotification.show(
                    `The file "${data.filePath}" was not uploaded due to an error.`,
                    "error",
                );
            },
            needAwait: false,
            priority: 5,
        });
    }
}
