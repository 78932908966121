import { ConfigAdapter } from "../../infrastructure/config/configAdapter";
import { ConfigService } from "../config/configService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useConfig = new ConfigService(
    new ConfigAdapter(),
    useError,
    useStorage,
    useNotification,
);
