// TODO: Refactor
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
    Field,
    Input,
    InputOnChangeData,
    Select,
    SelectOnChangeData,
    Spinner,
    Switch,
    Tooltip,
} from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import SelectUsersField from "../../../../../components/Form/SelectUsersField/SelectUsersField";
import AdminLayout from "../../AdminLayout/AdminLayout";
import { useCommonFluentuiStyles } from "../../../../../styles/griffel";
import { useIndexedLocation } from "../../../../../../application/useCases/useIndexedLocation";
import {
    IndexedLocationPermission,
    IndexedLocationStorageAccount,
    IndexedLocationSubscription,
} from "../../../../../../application/indexedLocation/indexedLocation";
import { protectedDataParamsKeys } from "../../../../../../domain/dataParams/dataParams";
import { AdminActiveMenuKind } from "../../../../../models";
import { router } from "../../../../../router";
import { validateNewIndexedLocation } from "../validateIndexedLocation";
import { enableActiveContentIndexingHint } from "../shared";

export type IndexedLocationFormData = {
    title: string;
    resourceId: string;
    containerName: string;
    accessKey: string;
    subscription: string;
    isIndexingEnabled: boolean;
    permissions: {
        search: IndexedLocationPermission[];
        download: IndexedLocationPermission[];
        rePermission: IndexedLocationPermission[];
        changeStorageTier: IndexedLocationPermission[];
        upload: IndexedLocationPermission[];
        delete: IndexedLocationPermission[];
    };
};

function isEqual(
    data: IndexedLocationFormData,
    initData: IndexedLocationFormData,
): boolean {
    return JSON.stringify(data) === JSON.stringify(initData);
}

const initData: IndexedLocationFormData = {
    title: "",
    subscription: "",
    resourceId: "",
    accessKey: "",
    containerName: "",
    isIndexingEnabled: false,
    permissions: {
        search: [],
        download: [],
        rePermission: [],
        changeStorageTier: [],
        upload: [],
        delete: [],
    },
};

function IndexedLocationAdd(): JSX.Element {
    const [subscriptions, setSubscriptions] = useState<
        IndexedLocationSubscription[]
    >([]);
    const [storageAccounts, setStorageAccounts] = useState<
        IndexedLocationStorageAccount[]
    >([]);
    const [storageAccount, setStorageAccount] =
        useState<IndexedLocationStorageAccount | null>(null);
    const [isQueryInProgress, setIsQueryInProgress] = useState<boolean>(true);
    const commonClasses = useCommonFluentuiStyles();
    const [data, setData] = useState<IndexedLocationFormData>(initData);
    const [formKey, setFormKey] = useState<number>(0);

    async function onSelectSubscription(
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
        d: InputOnChangeData | SelectOnChangeData,
    ): Promise<void> {
        setIsQueryInProgress(true);
        const res = await useIndexedLocation.fetchStorageAccounts({
            subscriptionId: d.value,
        });
        setIsQueryInProgress(false);

        setData({
            ...data,
            accessKey: "",
            resourceId: "",
            containerName: "",
            subscription: d.value,
        });
        setStorageAccount(null);

        if (res) {
            setStorageAccounts([...res]);
        }
    }

    async function onSelectStorageAccount(
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
        d: InputOnChangeData | SelectOnChangeData,
    ): Promise<void> {
        if (d.value !== undefined && d.value !== null) {
            setIsQueryInProgress(true);
            const res = await useIndexedLocation.getStorageAccount({
                storageAccountId: d.value,
            });
            setIsQueryInProgress(false);

            if (res) {
                setStorageAccount({ ...res });
                setData({
                    ...data,
                    accessKey: res.accessKey,
                    resourceId: res.id,
                });
            }
        }
    }

    const updateData = useCallback(
        (name: string, value: string | number | undefined | boolean): void => {
            if (
                data !== undefined &&
                data[name as keyof IndexedLocationFormData] !== value
            ) {
                setData({ ...data, [name]: value });
            }
        },
        [data],
    );

    const onChange = useCallback(
        (
            e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
            d: InputOnChangeData | SelectOnChangeData,
        ): void => {
            const name = e.target.name as keyof IndexedLocationFormData;

            if (data[name] !== d.value) {
                setData({ ...data, [name]: d.value });
            }
        },
        [data],
    );

    const updatePermission = useCallback(
        (name: string, users: IndexedLocationPermission[]): void => {
            setData({
                ...data,
                permissions: {
                    ...data?.permissions,
                    [name]: users,
                },
            });
        },
        [data],
    );

    function discardChanges(): void {
        setData({ ...initData });
        setFormKey(formKey + 1);
    }

    const isFormValid = validateNewIndexedLocation(data);

    const saveChanges = useCallback(async (): Promise<void> => {
        if (isFormValid) {
            try {
                setIsQueryInProgress(true);
                await useIndexedLocation.addIndexedLocation(data);
                router.goTo({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.IndexedLocations,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                });
            } catch (error) {
                setIsQueryInProgress(false);
            }
        }
    }, [data, isFormValid]);

    useEffect(() => {
        if (subscriptions.length === 0) {
            useIndexedLocation.fetchSubscriptions().then(res => {
                if (res) {
                    setSubscriptions(res);
                }
                setIsQueryInProgress(false);
            });
        }
    }, [subscriptions.length]);

    return (
        <AdminLayout
            title="New Indexed Location"
            backLink={{
                href: router.createRoute({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.IndexedLocations,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                }),
                title: "Back to Indexed Locations",
            }}
            buttons={[
                {
                    title: "Save changes",
                    onClick: saveChanges,
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled:
                        isQueryInProgress ||
                        !isFormValid ||
                        isEqual(initData, data),
                },
                {
                    title: "Discard changes",
                    onClick: discardChanges,
                    appearance: "subtle",
                    disabled: isQueryInProgress || isEqual(initData, data),
                },
            ]}
        >
            <form className="admin__form" key={formKey}>
                <Tooltip
                    content={enableActiveContentIndexingHint}
                    relationship="description"
                >
                    <Switch
                        className={commonClasses.field}
                        checked={data.isIndexingEnabled}
                        onChange={(_e, data): void => {
                            updateData("isIndexingEnabled", data.checked);
                        }}
                        label={`Enable active content indexing`}
                    />
                </Tooltip>
                <Field
                    label="Location name"
                    required
                    className={commonClasses.field}
                >
                    <Input
                        placeholder="Location name"
                        size="large"
                        name="title"
                        value={data?.title ?? ""}
                        type="text"
                        onChange={onChange}
                        autoComplete="off"
                    />
                </Field>

                <Field
                    label="Subscription"
                    required
                    className={commonClasses.field}
                >
                    <Select
                        size="large"
                        name="subscription"
                        value={data?.subscription}
                        onChange={onSelectSubscription}
                        placeholder="Select subscription"
                        disabled={isQueryInProgress}
                    >
                        <option>Select subscription</option>
                        {subscriptions.map(i => (
                            <option key={i.id} value={i.id}>
                                {i.name}
                            </option>
                        ))}
                    </Select>
                    {isQueryInProgress ? (
                        <Spinner
                            size="extra-small"
                            className={commonClasses.fieldSpinner}
                        />
                    ) : null}
                </Field>

                <Field
                    label="Storage account"
                    className={commonClasses.field}
                    required
                >
                    <Select
                        size="large"
                        name="resourceId"
                        value={data?.resourceId}
                        onChange={onSelectStorageAccount}
                        placeholder="Select storage account"
                        disabled={isQueryInProgress || !data?.subscription}
                    >
                        <option>Select storage account</option>
                        {storageAccounts.map(i => (
                            <option key={i.id} value={i.id}>
                                {i.name}
                            </option>
                        ))}
                    </Select>
                    {isQueryInProgress ? (
                        <Spinner
                            size="extra-small"
                            className={commonClasses.fieldSpinner}
                        />
                    ) : null}
                </Field>

                <Field
                    label="Access key"
                    className={commonClasses.field}
                    required
                >
                    <Input
                        placeholder="Acsess key"
                        size="large"
                        name="accessKey"
                        value={data.accessKey}
                        type="text"
                        onChange={onChange}
                        disabled
                    />
                </Field>

                <Field
                    required
                    label="Container name"
                    className={commonClasses.field}
                >
                    <Select
                        size="large"
                        name="containerName"
                        value={data?.containerName}
                        onChange={onChange}
                        placeholder="Container name"
                        disabled={storageAccount === null}
                    >
                        <option>Select container name</option>
                        {storageAccount &&
                            storageAccount.containers.map(i => (
                                <option key={i}>{i}</option>
                            ))}
                    </Select>
                    {isQueryInProgress ? (
                        <Spinner
                            size="extra-small"
                            className={commonClasses.fieldSpinner}
                        />
                    ) : null}
                </Field>

                <Field
                    label="Permissions for Search"
                    className={commonClasses.field}
                >
                    <SelectUsersField
                        data={data?.permissions.search}
                        onChange={(users): void => {
                            updatePermission("search", users);
                        }}
                    />
                </Field>

                {/* <Field
                    label="Permissions for Download"
                    className={commonClasses.field}
                >
                    <SelectUsersField
                        data={data?.permissions.download}
                        onChange={(users): void => {
                            updatePermission("download", users);
                        }}
                    />
                </Field> */}

                <Field
                    label="Full control  / Re-permission"
                    className={commonClasses.field}
                >
                    <SelectUsersField
                        data={data.permissions.rePermission}
                        onChange={(users): void => {
                            updatePermission("rePermission", users);
                        }}
                    />
                </Field>

                <Field
                    label="Change storage tier"
                    className={commonClasses.field}
                >
                    <SelectUsersField
                        data={data.permissions.changeStorageTier}
                        onChange={(users): void => {
                            updatePermission("changeStorageTier", users);
                        }}
                    />
                </Field>

                <Field label="Upload" className={commonClasses.field}>
                    <SelectUsersField
                        data={data.permissions.upload}
                        onChange={(users): void => {
                            updatePermission("upload", users);
                        }}
                    />
                </Field>

                <Field label="Delete" className={commonClasses.field}>
                    <SelectUsersField
                        data={data.permissions.delete}
                        onChange={(users): void => {
                            updatePermission("delete", users);
                        }}
                    />
                </Field>
            </form>
        </AdminLayout>
    );
}

export default IndexedLocationAdd;
