import { GeneratorIdRepositoryImpl } from "../../infrastructure/generatorIdRepositoryImpl/generatorIdRepositoryImpl";
import { MimeRepositoryImpl } from "../../infrastructure/mimeRepositoryImpl/mimeRepositoryImpl";
import { UploadLocationRepositoryImpl } from "../../infrastructure/uploadLocationRepositoryImpl/uploadLocationRepositoryImpl";
import { UploaderAdapter } from "../../infrastructure/uploader/uploaderAdapter";
import { UserFileRepositoryImpl } from "../../infrastructure/userFileRepositoryImpl/userFileRepositoryImpl";
import { UserFileUploadRepositoryImpl } from "../../infrastructure/userFileUploadRepositoryImpl/userFileUploadRepositoryImpl";
import { UploaderService } from "../uploader/service";
import { useError } from "./useError";
import { useEventBus } from "./useEventBus";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";
import { useTimeFormatter } from "./useTimeFormatter";

export const useUploader = new UploaderService(
    new UploaderAdapter(),
    useError,
    useStorage,
    useNotification,
    new UserFileRepositoryImpl(),
    new MimeRepositoryImpl(),
    useTimeFormatter,
    new UserFileUploadRepositoryImpl(),
    new GeneratorIdRepositoryImpl(),
    new UploadLocationRepositoryImpl(),
    useEventBus,
);
