import { Filters } from "../filter/filters";
import { KeyValueObj } from "../types/types";

export function prepareSearchParams(
    path?: string,
    query?: string,
    limit?: number,
    offset?: number,
    sortKey?: string,
    isSortDesc?: boolean,
    filters?: Filters,
): KeyValueObj {
    const searchParams: KeyValueObj = {
        path: path,
        query: query,
        limit: limit,
        offset: offset,
        sortKey: sortKey,
        isSortDesc: isSortDesc,
    };

    if (filters && filters.size > 0) {
        filters.forEach((value, key) => {
            value.forEach((item, index) => {
                let newKey: string;
                if (
                    key.includes("dateUpdated") ||
                    key.includes("dateCreated")
                ) {
                    newKey = key;
                } else {
                    newKey = `${key}[${index}]`;
                }
                searchParams[newKey] = item;
            });
        });
    }

    return searchParams;
}
