import React, { useMemo } from "react";
import { state } from "../../state/stateAdapter";
import "./commandBar.css";
import { useCommonFluentuiStyles } from "../../styles/griffel";
import { mergeClasses } from "@fluentui/react-components";
import { StorageRepository } from "../../../domain/storage/storageRepository";
import { CommandBarActionsFactoryStateValue } from "../../state/stateValues";

type CommandBarProps = {
    actionsFactoryState: StorageRepository<CommandBarActionsFactoryStateValue>;
};

function CommandBar(props: CommandBarProps): JSX.Element {
    const classes = useCommonFluentuiStyles();
    const actionsFactory = state.useState(props.actionsFactoryState);

    const actionContent = useMemo(() => {
        if (actionsFactory) {
            return actionsFactory();
        }
        return null;
    }, [actionsFactory]);

    return (
        <header
            className={mergeClasses(
                classes.borderBottom,
                "command-bar-container",
            )}
        >
            {actionContent}
        </header>
    );
}

export default CommandBar;
