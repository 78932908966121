import { AirArchiveConfig } from "../../domain/airArchive/airArchive";
import { AirArchiveRepository } from "../../domain/airArchive/airArchiveRepository";
import { apiCall, handleNotOk, validateRes } from "../api/api";

export class AirArchiveRepositoryImpl implements AirArchiveRepository {
    async fetchConfig(): Promise<AirArchiveConfig> {
        const res = await apiCall("/api/admin/config/archive", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async updateConfig(config: AirArchiveConfig): Promise<void> {
        const res = await apiCall("/api/admin/config/archive", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(config),
        });

        handleNotOk(res);
    }
}
