import React, { useEffect, useState } from "react";
import { state } from "../../../state/stateAdapter";
import { router } from "../../../router";
import Loader from "../../../components/Loader/Loader";
import FileExplorerPagination from "./FileExplorerPagination/FileExplorerPagination";
import FileExplorerSearch from "./FileExplorerSearch/FileExplorerSearch";
import FileExplorerCommandBar from "./FileExplorerCommandBar/FileExplorerCommandBar";
import FileExplorerContent from "./FileExplorerContent/FileExplorerContent";
import { useEventBus } from "../../../../application/useCases/useEventBus";
import { UIEvents } from "../../../uiEvents";
import {
    changeActiveDirectoryEventHandler,
    changeActiveItemEventHandler,
    changeFiltersEventHandler,
    changePaginationEventHandler,
    changeSearchQueryEventHandler,
    changeSelectionEventHandler,
    changeViewModeEventHandler,
    initEventHandler,
    refreshEventHandler,
    resetFiltersEventHandler,
    sortEventHandler,
} from "./fileExplorerEventsHandlers";
import FileExplorerBreadcrumbs from "./FileExplorerBreadcrumbs/FileExplorerBreadcrumbs";
import { fileExplorerManager } from "./fileExplorerManager";
import { appManager } from "../../../appManager/appManager";
import fileDetailsTab from "../../../components/FileDetails/fileDetailsTab";
import { fileExplorerRouteListener } from "./fileExplorerRouteListener";
import filtersTab from "./FileExplorerFilters/fileExplorerFiltersTab";
import ActiveFilters from "./ActiveFilters/ActiveFilters";
import "./fileExplorer.css";

function FileExplorer(): JSX.Element {
    const [isInit, setIsInit] = useState(false);

    // REGISTER/UNREGISTER EVENTS
    useEffect(() => {
        useEventBus.on({
            event: UIEvents.FileExplorerChangeActiveDirectory,
            callback: changeActiveDirectoryEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerChangeFilters,
            callback: changeFiltersEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerInitState,
            callback: initEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerChangeSort,
            callback: sortEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerChangeSearchQuery,
            callback: changeSearchQueryEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerRefresh,
            callback: refreshEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerResetFilters,
            callback: resetFiltersEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerChangePagination,
            callback: changePaginationEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerChangeViewMode,
            callback: changeViewModeEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerChangeActiveItem,
            callback: changeActiveItemEventHandler,
            needAwait: false,
            priority: 5,
        });

        useEventBus.on({
            event: UIEvents.FileExplorerChangeSelection,
            callback: changeSelectionEventHandler,
            needAwait: false,
            priority: 5,
        });

        return () => {
            useEventBus.off(UIEvents.FileExplorerChangeActiveDirectory);
            useEventBus.off(UIEvents.FileExplorerChangeFilters);
            useEventBus.off(UIEvents.FileExplorerInitState);
            useEventBus.off(UIEvents.FileExplorerChangeSort);
            useEventBus.off(UIEvents.FileExplorerChangeSearchQuery);
            useEventBus.off(UIEvents.FileExplorerRefresh);
            useEventBus.off(UIEvents.FileExplorerResetFilters);
            useEventBus.off(UIEvents.FileExplorerChangePagination);
            useEventBus.off(UIEvents.FileExplorerChangeViewMode);
            useEventBus.off(UIEvents.FileExplorerChangeActiveItem);
            useEventBus.off(UIEvents.FileExplorerChangeSelection);
        };
    }, []);

    // INIT FILE EXPLORER
    useEffect(() => {
        fileExplorerManager.updateContext(router.url.href);

        useEventBus
            .emit(UIEvents.FileExplorerInitState, {})
            .then(() => setIsInit(true));
    }, []);

    // INIT COMMANDBAR ACTIONS
    useEffect(() => {
        state.commandBar.actionsFactory.set(() => <FileExplorerCommandBar />);

        return () => {
            state.commandBar.actionsFactory.set(null);
        };
    }, []);

    // UPDATE APP SEARCH
    useEffect(() => {
        state.activeAppSearchFactory.set(() => <FileExplorerSearch />);

        return () => {
            state.activeAppSearchFactory.set(null);
        };
    }, []);

    // REGISTER/UNREGISTER ROUTE CHANGE LISTENER
    useEffect(() => {
        router.routeListenersManager.add(fileExplorerRouteListener);

        return () => {
            router.routeListenersManager.remove(fileExplorerRouteListener);
        };
    }, []);

    // UNMOUNT
    useEffect(() => {
        return () => {
            appManager.deleteTab(fileDetailsTab.id);
            appManager.deleteTab(filtersTab.id);
        };
    }, []);

    if (!isInit) {
        return (
            <div className="layout-container">
                <Loader text="Initializing File Explorer..." />
            </div>
        );
    }

    return (
        <div className="layout">
            <header className="layout__container file-explorer-header">
                <FileExplorerBreadcrumbs />
                <ActiveFilters />
            </header>

            <div className="layout__container">
                <FileExplorerContent />
            </div>
            <FileExplorerPagination />
        </div>
    );
}

export default FileExplorer;
