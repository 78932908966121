import React, { ChangeEvent } from "react";
import { ChevronLeftRegular, ChevronRightRegular } from "@fluentui/react-icons";
import { Button, Select, SelectOnChangeData } from "@fluentui/react-components";
import "./pagination.scss";

type PaginationProps = {
    total: number;
    page: number;
    pageSizes: number[];
    pageSize: number;
    pageSizeSelectorLabel?: string;
    onPageSizeChange: (pageSize: number) => void;
    onPageChange: (page: number) => void;
    onPrevPage: () => void;
    onNextPage: (total: number) => void;
    hideText?: boolean;
};

function Pagination(props: PaginationProps): JSX.Element {
    const pages = [...Array(props.total).keys()].map(i => i + 1);

    const onPageChange = (
        _e: ChangeEvent<HTMLSelectElement>,
        data: SelectOnChangeData,
    ): void => {
        props.onPageChange(parseInt(data.value));
    };

    const onNextPage = (): void => {
        props.onNextPage(props.total);
    };

    const onPageSizeChange = (
        _e: ChangeEvent<HTMLSelectElement>,
        data: SelectOnChangeData,
    ): void => {
        props.onPageSizeChange(parseInt(data.value));
    };

    return (
        <div className="pagination">
            <div className="pagination__controlls">
                <Button
                    size="small"
                    icon={<ChevronLeftRegular />}
                    disabled={props.page === 1}
                    onClick={props.onPrevPage}
                    appearance="subtle"
                />

                <Select
                    size="small"
                    onChange={onPageChange}
                    value={props.page}
                    title="Page number"
                >
                    {pages.map(page => (
                        <option key={page}>{page}</option>
                    ))}
                </Select>
                <Button
                    size="small"
                    icon={<ChevronRightRegular />}
                    disabled={props.page === props.total}
                    onClick={onNextPage}
                    appearance="subtle"
                />
            </div>

            <div className="pagination__page-size-selector">
                {props.hideText !== true && (
                    <label htmlFor="itemsPerPage" className="hide-mobile">
                        {props.pageSizeSelectorLabel ?? "Shown items per page"}
                    </label>
                )}
                <Select
                    size="small"
                    title="Shown items per page"
                    id="itemsPerPage"
                    onChange={onPageSizeChange}
                    value={props.pageSize}
                >
                    {(props.pageSizes.includes(props.pageSize)
                        ? props.pageSizes
                        : [...props.pageSizes, props.pageSize]
                    ).map(i => (
                        <option key={i}>{i}</option>
                    ))}
                </Select>
            </div>
        </div>
    );
}

export default Pagination;
