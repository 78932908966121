import { useJobs } from "../../application/useCases/useJobs";
import { Job } from "../../domain/job/job";

export async function onDownloadFilesByLink(job: Job): Promise<void> {
    const res = await useJobs.downloadByLink(job.id);

    if (res) {
        const anchor = document.createElement("a");
        anchor.href = res;
        anchor.setAttribute("download", "");
        anchor.style.display = "none";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
}
