import {
    Button,
    TableCellActions,
    TableCellLayout,
    TableColumnDefinition,
    Tooltip,
    createTableColumn,
    Text,
} from "@fluentui/react-components";
import { UserFile } from "../../../../../domain/userFile/userFile";
import {
    TextBulletListSquareEditRegular,
    DeleteRegular,
} from "@fluentui/react-icons";
import React from "react";
import { state } from "../../../../state/stateAdapter";
import ChangeMetadataDialogPanel from "../ChangeMetadata/ChangeMetadataDialogPanel";
import { removeFile } from "./actions";

export enum ColumnKind {
    Name = "Name",
    NameIgnored = "Name ignored",
    Type = "Type",
    Size = "Size",
    LastModified = "Last modified",
    Extension = "Extension",
    SourcePath = "Source path",
    Description = "Description",
}

export const columnSizes = new Map<ColumnKind, number>([
    [ColumnKind.Type, 140],
    [ColumnKind.Size, 140],
    [ColumnKind.LastModified, 200],
    [ColumnKind.Extension, 80],
    [ColumnKind.SourcePath, 120],
    [ColumnKind.Description, 200],
]);

export const USER_FILE_COLUMNS: Map<
    ColumnKind,
    TableColumnDefinition<UserFile>
> = new Map([
    [
        ColumnKind.Name,
        createTableColumn<UserFile>({
            columnId: ColumnKind.Name,
            compare: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            renderHeaderCell: () => (
                <TableCellLayout>
                    <Text>{ColumnKind.Name}</Text>
                </TableCellLayout>
            ),
            renderCell: item => {
                return (
                    <>
                        <TableCellLayout
                            truncate
                            className="cell-layout"
                            style={{
                                paddingRight: `${32 * 2}px`,
                            }}
                            media={
                                item.isCameraCardFile ? (
                                    <Tooltip
                                        content={
                                            <div className="media-tooltip">
                                                <img
                                                    className="media-tooltip__img"
                                                    src={item.thmbnl}
                                                    alt="thumbnail"
                                                />

                                                <Text
                                                    truncate
                                                    block
                                                    wrap={false}
                                                    title={item.name}
                                                >
                                                    {item.name}
                                                </Text>
                                            </div>
                                        }
                                        showDelay={500}
                                        relationship="description"
                                    >
                                        <img
                                            className="image_table-cell thmbnl"
                                            src={item.thmbnl}
                                            alt="thumbnail"
                                        />
                                    </Tooltip>
                                ) : (
                                    <img
                                        className="image_table-cell"
                                        src={item.thmbnl}
                                        alt="thumbnail"
                                    />
                                )
                            }
                        >
                            {item.sourcePath.length > 0 ? (
                                <Tooltip
                                    content={
                                        <div>
                                            <Text block wrap={true}>
                                                Name: {item.name}
                                            </Text>

                                            <br />

                                            <Text block wrap={true}>
                                                Source Path: {item.sourcePath}
                                            </Text>
                                        </div>
                                    }
                                    showDelay={500}
                                    relationship="description"
                                >
                                    <Text
                                        truncate
                                        block
                                        wrap={false}
                                        className="cursor_info"
                                    >
                                        {item.name}
                                    </Text>
                                </Tooltip>
                            ) : (
                                <Text
                                    truncate
                                    block
                                    wrap={false}
                                    title={item.name}
                                >
                                    {item.name}
                                </Text>
                            )}
                        </TableCellLayout>

                        <TableCellActions className="cell-action">
                            <Button
                                icon={<TextBulletListSquareEditRegular />}
                                appearance="subtle"
                                title="Change properties"
                                onClick={(): void => {
                                    state.appDialogPanel.set(() => (
                                        <ChangeMetadataDialogPanel
                                            items={[item]}
                                        />
                                    ));
                                }}
                            ></Button>

                            <Button
                                icon={<DeleteRegular />}
                                appearance="subtle"
                                title="Remove"
                                onClick={(): void => removeFile(item.id)}
                            />
                        </TableCellActions>
                    </>
                );
            },
        }),
    ],
    [
        ColumnKind.NameIgnored,
        createTableColumn<UserFile>({
            columnId: ColumnKind.NameIgnored,
            compare: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            renderHeaderCell: () => (
                <TableCellLayout>
                    <Text>{ColumnKind.Name}</Text>
                </TableCellLayout>
            ),
            renderCell: item => {
                return (
                    <>
                        <TableCellLayout
                            truncate
                            className="cell-layout"
                            media={
                                item.isCameraCardFile ? (
                                    <Tooltip
                                        content={
                                            <div className="media-tooltip">
                                                <img
                                                    className="media-tooltip__img"
                                                    src={item.thmbnl}
                                                    alt="thumbnail"
                                                />

                                                <Text
                                                    truncate
                                                    block
                                                    wrap={false}
                                                    title={item.name}
                                                >
                                                    {item.name}
                                                </Text>
                                            </div>
                                        }
                                        showDelay={500}
                                        relationship="description"
                                    >
                                        <img
                                            className="image_table-cell thmbnl"
                                            src={item.thmbnl}
                                            alt="thumbnail"
                                        />
                                    </Tooltip>
                                ) : (
                                    <img
                                        className="image_table-cell"
                                        src={item.thmbnl}
                                        alt="thumbnail"
                                    />
                                )
                            }
                        >
                            {item.sourcePath.length > 0 ? (
                                <Tooltip
                                    content={
                                        <div>
                                            <Text block wrap={true}>
                                                Name: {item.name}
                                            </Text>

                                            <br />

                                            <Text block wrap={true}>
                                                Source Path: {item.sourcePath}
                                            </Text>
                                        </div>
                                    }
                                    showDelay={500}
                                    relationship="description"
                                >
                                    <Text
                                        truncate
                                        block
                                        wrap={false}
                                        className="cursor_info"
                                    >
                                        {item.name}
                                    </Text>
                                </Tooltip>
                            ) : (
                                <Text
                                    truncate
                                    block
                                    wrap={false}
                                    title={item.name}
                                >
                                    {item.name}
                                </Text>
                            )}
                        </TableCellLayout>
                    </>
                );
            },
        }),
    ],
    [
        ColumnKind.Type,
        createTableColumn<UserFile>({
            columnId: ColumnKind.Type,
            compare: (a, b) => {
                return a.type.localeCompare(b.type);
            },
            renderHeaderCell: () => {
                return ColumnKind.Type;
            },
            renderCell: item => (
                <Text truncate block wrap={false} title={item.type}>
                    {item.type}
                </Text>
            ),
        }),
    ],
    [
        ColumnKind.Size,
        createTableColumn<UserFile>({
            columnId: ColumnKind.Size,
            compare: (a, b) => {
                return a.file.size - b.file.size;
            },
            renderHeaderCell: () => {
                return ColumnKind.Size;
            },
            renderCell: item => (
                <Text truncate block wrap={false} title={item.size}>
                    {item.size}
                </Text>
            ),
        }),
    ],
    [
        ColumnKind.LastModified,
        createTableColumn<UserFile>({
            columnId: ColumnKind.LastModified,
            compare: (a, b) => {
                return a.file.lastModified - b.file.lastModified;
            },
            renderHeaderCell: () => {
                return ColumnKind.LastModified;
            },
            renderCell: item => (
                <Text truncate block wrap={false} title={item.lastModified}>
                    {item.lastModified}
                </Text>
            ),
        }),
    ],
    [
        ColumnKind.Extension,
        createTableColumn<UserFile>({
            columnId: ColumnKind.Extension,
            compare: (a, b) => {
                return a.ext.localeCompare(b.ext);
            },
            renderHeaderCell: () => {
                return ColumnKind.Extension;
            },
            renderCell: item => (
                <Text truncate block wrap={false} title={item.ext}>
                    {item.ext}
                </Text>
            ),
        }),
    ],
    [
        ColumnKind.Description,
        createTableColumn<UserFile>({
            columnId: ColumnKind.Description,
            compare: (a, b) => {
                return a.description.localeCompare(b.description);
            },
            renderHeaderCell: () => {
                return ColumnKind.Description;
            },
            renderCell: item => (
                <Text truncate block wrap={false} title={item.description}>
                    {item.description}
                </Text>
            ),
        }),
    ],
]);
