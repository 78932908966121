import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogTrigger,
    Button,
    Input,
    InputProps,
    Field,
} from "@fluentui/react-components";
import React, { useState } from "react";
import { state } from "../../../../state/stateAdapter";

type CreateFolderDialogProps = {
    createFolderCb: (folderName: string) => void;
};

function isValidFolderName(folderName: string): boolean {
    if (folderName.length > 255) {
        return false;
    }

    if (folderName.startsWith(".")) {
        return false;
    }

    const validChars = /^[a-zA-Z0-9 _-]+$/;
    return validChars.test(folderName);
}

function CreateFolderDialog(props: CreateFolderDialogProps): JSX.Element {
    const [tempFolderName, setTempFolderName] = useState<string>("");
    const [errMessage, setErrMessage] = useState<string>("");

    const onChange: InputProps["onChange"] = (ev, data): void => {
        if (isValidFolderName(data.value)) {
            setErrMessage("");
        } else {
            setErrMessage("Invalid folder name");
        }

        setTempFolderName(data.value);
    };

    const onCreateFolder = (): void => {
        props.createFolderCb(tempFolderName);
    };

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    state.appDialog.set(null);
                }
            }}
            open={true}
        >
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle>Create new folder</DialogTitle>
                    <DialogContent>
                        <div className="app-dialog__content">
                            <div className="app-dialog__row-row">
                                <Field
                                    label="Folder name"
                                    validationMessage={errMessage}
                                >
                                    <Input
                                        placeholder="Enter a folder name"
                                        value={tempFolderName}
                                        onChange={onChange}
                                    />
                                </Field>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Discard</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                onClick={onCreateFolder}
                                disabled={
                                    tempFolderName.length === 0 ||
                                    errMessage.length > 0
                                }
                            >
                                Create
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default CreateFolderDialog;
