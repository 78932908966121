import {
    NotificationAction,
    NotificationElement,
    NotificationPort,
    NotificationStyle,
    NotificationType,
} from "./notificationPort";

const notInitMsg = "Notification port is not initialized";

export class NotificationService {
    private _notificationPort: NotificationPort | null = null;

    constructor() {
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.hideAll = this.hideAll.bind(this);
    }

    private _generateId(time: number, type: string, message: string): string {
        return `${time}-${type}-${message}`;
    }

    init(notificationPort: NotificationPort): void {
        this._notificationPort = notificationPort;
    }

    create(
        message: string,
        style: NotificationStyle,
        autoHide?: boolean,
        actions?: NotificationAction[],
        id?: string,
        type?: NotificationType,
    ): NotificationElement {
        const dateNow = Date.now();

        return {
            message,
            id: id || this._generateId(dateNow, style, message),
            style,
            dateNow,
            autoHide: autoHide || true,
            actions,
            type: type || NotificationType.UserAction,
        };
    }

    show(
        message: string,
        style: NotificationStyle,
        autoHide?: boolean,
        actions?: NotificationAction[],
        type?: NotificationType,
    ): void {
        if (this._notificationPort === null) {
            throw new Error(notInitMsg);
        }

        const element = this.create(
            message,
            style,
            autoHide,
            actions,
            undefined,
            type,
        );

        this._notificationPort.show(element);
    }

    hide(id: string): void {
        if (this._notificationPort === null) {
            throw new Error(notInitMsg);
        }

        this._notificationPort.hide(id);
    }

    hideAll(): void {
        if (this._notificationPort === null) {
            throw new Error(notInitMsg);
        }

        this._notificationPort.hideAll();
    }

    getElement(id: string): NotificationElement | undefined {
        if (this._notificationPort === null) {
            throw new Error(notInitMsg);
        }

        return this._notificationPort.getElement(id);
    }
}
