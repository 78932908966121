import React from "react";
import "./tag.css";
import {
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
    tag: {
        color: tokens.colorNeutralForeground3,
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.transition(
            "background, border, color",
            tokens.durationFaster,
            tokens.curveEasyEase,
        ),
        "&:hover": {
            backgroundColor: tokens.colorNeutralBackground5Hover,
        },
    },
    activeTag: {
        color: tokens.colorNeutralForegroundOnBrand,
        backgroundColor: tokens.colorBrandBackground,
        "&:hover": {
            color: tokens.colorNeutralForegroundOnBrand,
            backgroundColor: tokens.colorBrandBackgroundHover,
        },
    },
});

type TagProps = {
    item: {
        value: string;
    };
    isActive: boolean;
    onClick?: () => void;
};

function Tag(props: TagProps): JSX.Element {
    const classes = useStyles();

    return (
        <div
            role="presentation"
            onClick={props.onClick}
            className={mergeClasses(
                classes.tag,
                "tag",
                props.isActive ? classes.activeTag : "",
            )}
        >
            {props.item.value}
        </div>
    );
}

export default Tag;
