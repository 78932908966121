import {
    SharepointCheckAccessParams,
    SharepointPort,
    SharepointSiteData,
} from "../../application/sharepoint/sharepointPort";
import { apiCall, validateRes } from "../api/api";

export class SharepointAdapter implements SharepointPort {
    async checkAccess(
        params: SharepointCheckAccessParams,
    ): Promise<SharepointSiteData> {
        const res = await apiCall("/api/sharepoint/check-access", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify({ url: params.url.toString() }),
        });

        return await validateRes(res);
    }
}
