import {
    AddRegular,
    MergeRegular,
    SettingsRegular,
} from "@fluentui/react-icons";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Tag, TagsGroup } from "../../../../../../application/tags/TagsPort";
import { useTags } from "../../../../../../application/useCases/useTags";
import {
    dataParamsKeys,
    defaultDataParams,
    protectedDataParamsKeys,
} from "../../../../../../domain/dataParams/dataParams";
import { router } from "../../../../../router";
import AdminLayout from "../../AdminLayout/AdminLayout";
import Loader from "../../../../../components/Loader/Loader";
import { AdminActiveMenuKind } from "../../../../../models";
import { state } from "../../../../../state/stateAdapter";
import TagsGroupsFormPanel from "../TagsGroupsFormPanel/TagsGroupsFormPanel";
import {
    Button,
    Text,
    TableCellActions,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableSelectionCell,
    useTableFeatures,
    useTableSelection,
} from "@fluentui/react-components";
import { renderIsVisibleIcon } from "../TagsGroups/TagsGroups";
import TagFormDialog from "../TagFormDialog/TagFormDialog";
import { useStorage } from "../../../../../../application/useCases/useStorage";
import TagsMergeDialog from "../TagsMergeDialog/TagsMergeDialog";
import Pagination from "../../../../../components/Pagination/Pagination";
import Search from "../../../../../components/Search/Search";
import { calculateOffset } from "../../../../../../utility";
import { EditIcon } from "../../../../../components/BundledIcons";

type EditTagListProps = {
    groupId: number;
};

function onClickCellActions(e: React.MouseEvent<HTMLDivElement>): void {
    e.preventDefault();
}

function onKeyDownCellActions(e: React.KeyboardEvent<HTMLDivElement>): void {
    e.key === " " && e.preventDefault();
}

function onDismiss(): void {
    router.removeSearchParam("query", false);
}

async function onSearch(tempQuery: string): Promise<void> {
    if (tempQuery.length > 0) {
        router.removeSearchParam(dataParamsKeys.page, true);
        router.removeSearchParam("query", true);
        useStorage.adminMiniApp.dataParams.update(s => {
            s.page = defaultDataParams.page;
        });
        useStorage.tags.searchQuery.set(tempQuery);
        router.setSearchParams([{ key: "query", value: tempQuery }]);
    } else {
        router.removeSearchParam("query", false);
    }
}

function onPrevPage(groupId: number, page: number): void {
    if (page > 1) {
        const newPage = page - 1;
        router.setSearchParams([{ key: "page", value: newPage.toString() }]);
        updateTagsState(groupId);
    }
}

function onNextPage(groupId: number, page: number, total: number): void {
    if (page < total) {
        const newPage = page + 1;
        router.setSearchParams([{ key: "page", value: newPage.toString() }]);
        updateTagsState(groupId);
    }
}

function onPageChange(groupId: number, page: number): void {
    router.setSearchParams([{ key: "page", value: page.toString() }]);

    updateTagsState(groupId);
}

function onPageSizeChange(groupId: number, pageSize: number): void {
    router.setSearchParams([
        { key: "pageSize", value: pageSize.toString() },
        { key: "page", value: "1" },
    ]);
    updateTagsState(groupId);
}

function editTag(tag: Tag, groupId: number): void {
    state.appDialog.set(() => (
        <TagFormDialog
            title={`Edit tag: ${tag?.name}`}
            item={tag}
            onApply={async (tag): Promise<void> => {
                await useTags.addOrEditTag({
                    tagGroupId: groupId,
                    id: tag.id,
                    searchable: tag.searchable,
                    name: tag.name,
                });
                await updateTagsState(groupId);
            }}
        />
    ));
}

function addNewTag(groupId: number): void {
    state.appDialog.set(() => (
        <TagFormDialog
            title="Add new tag"
            onApply={async (tag): Promise<void> => {
                await useTags.addOrEditTag({
                    tagGroupId: groupId,
                    searchable: tag.searchable,
                    name: tag.name,
                });
                await updateTagsState(groupId);
            }}
        />
    ));
}

async function updateTagsState(groupId: number): Promise<void> {
    useStorage.tags.selectedTags.set(new Map());
    const dataParams = useStorage.adminMiniApp.dataParams.get();
    const offset =
        dataParams.page !== null && dataParams.pageSize !== null
            ? calculateOffset(dataParams.page, dataParams.pageSize)
            : 0;
    const query = useStorage.tags.searchQuery.get();

    if (query !== null && query !== undefined && query.length > 0) {
        await useTags.searchTags({
            groupId: groupId,
            query: query ?? undefined,
            limit: dataParams.pageSize ?? undefined,
            offset,
        });
    } else {
        await useTags.getTags({
            groupId: groupId,
            limit: dataParams.pageSize ?? undefined,
            offset,
        });
    }
}

function mergeTags(tags: Map<number, Tag>, groupId: number): void {
    if (tags !== null && tags.size > 1) {
        state.appDialog.set(() => (
            <TagsMergeDialog
                tags={Array.from(tags.values())}
                groupId={groupId}
                updateTags={updateTagsState}
            />
        ));
    }
}

export enum ColumnKind {
    Name = "name",
    Searchable = "searchable",
}

export const columnSizes = new Map<string | number, number>([
    [ColumnKind.Searchable, 120],
]);

function getColumns(groupId: number): TableColumnDefinition<Tag>[] {
    return [
        createTableColumn<Tag>({
            columnId: "name",
            renderHeaderCell: () => (
                <TableCellLayout>
                    <Text>Tag name</Text>
                </TableCellLayout>
            ),
            renderCell: item => {
                return (
                    <>
                        <TableCellLayout truncate className="cell-layout">
                            <Text truncate block wrap={false}>
                                {item.name}
                            </Text>
                        </TableCellLayout>
                        <TableCellActions
                            className="cell-action"
                            onClick={onClickCellActions}
                            onKeyDown={onKeyDownCellActions}
                        >
                            <Button
                                icon={<EditIcon />}
                                appearance="subtle"
                                title="Edit tag"
                                onClick={(): void => {
                                    editTag(item, groupId);
                                }}
                            ></Button>
                        </TableCellActions>
                    </>
                );
            },
        }),
        createTableColumn<Tag>({
            columnId: "searchable",
            renderHeaderCell: () => {
                return "Searchable";
            },
            renderCell: item => (
                <Text truncate block wrap={false}>
                    {renderIsVisibleIcon(item.searchable)}
                </Text>
            ),
        }),
    ];
}

const pageSizes = [10, 25, 50, 100];

function TagsList(props: EditTagListProps): JSX.Element {
    const query = state.useState(useStorage.tags.searchQuery);
    const tags = state.useState(useStorage.tags.tags);
    const isQueryInProgress = state.useState(useStorage.tags.isQueryInProgress);
    const selectedTags = state.useState(useStorage.tags.selectedTags);
    const totalTags = state.useState(useStorage.tags.totalTags);
    const [groupInfo, setGroupInfo] = useState<TagsGroup>();
    const items = tags !== null ? Array.from(tags.values()) : [];
    const columns = getColumns(props.groupId);
    const { page, pageSize } = state.useState(
        useStorage.adminMiniApp.dataParams,
    );
    const total = Math.ceil(totalTags / pageSize);

    useEffect(() => {
        async function fetchData(groupId: number): Promise<void> {
            const res = await useTags.getGroup({ groupId });

            if (res !== null && res !== undefined) {
                setGroupInfo(res);
            }
        }

        fetchData(props.groupId);
        updateTagsState(props.groupId);

        return () => {
            useStorage.tags.tags.set(null);
            useStorage.tags.totalTags.set(0);
            useStorage.tags.selectedTags.set(new Map());
        };
    }, [props.groupId]);

    useEffect(() => {
        const searchQueryUnsub = useStorage.tags.searchQuery.subscribe(() => {
            updateTagsState(props.groupId);
        });

        return () => {
            searchQueryUnsub();
        };
    });

    const { getRows, tableRef } = useTableFeatures(
        {
            columns,
            items,
        },
        [
            useTableSelection({
                selectionMode: "multiselect",
                defaultSelectedItems: Array.from(selectedTags.values()).map(
                    i => (i as Tag).id,
                ),
            }),
        ],
    );

    function onAllSelect(
        e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
    ): void {
        const target = e.target as HTMLElement | null;

        if (target && target.tagName === "INPUT") {
            if (tags !== null && (target as HTMLInputElement).checked) {
                useStorage.tags.selectedTags.set(
                    new Map(Array.from(tags.values()).map(i => [i.id, i])),
                );
            } else {
                useStorage.tags.selectedTags.set(new Map());
            }
        }
    }

    function onSelect(e: SyntheticEvent, item: Tag): void {
        const target = e.target as HTMLElement | null;

        if (target && target.tagName === "INPUT") {
            if (selectedTags.has(item.id)) {
                useStorage.tags.selectedTags.update(s => {
                    s.delete(item.id);
                });
            } else {
                useStorage.tags.selectedTags.update(s => {
                    s.set(item.id, item);
                });
            }
            useStorage.tags.selectedTags.set(
                new Map(Array.from(selectedTags.values()).map(i => [i.id, i])),
            );
        }
    }

    const rows = getRows(row => {
        const isSelected = (id: number): boolean => {
            return selectedTags.has(id);
        };

        return {
            ...row,
            onClick: (e: React.MouseEvent, item: Tag) => onSelect(e, item),
            onKeyDown: (e: React.KeyboardEvent, item: Tag): void => {
                if (e.key === " ") {
                    e.preventDefault();
                    onSelect(e, item);
                }
            },
            isSelected,
            getAppearance: (
                id: number,
            ): "none" | "brand" | "neutral" | undefined =>
                isSelected(id) ? ("neutral" as const) : ("none" as const),
        };
    });

    const toggleAllKeydown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === " ") {
            onAllSelect(e);
            e.preventDefault();
        }
    };

    const openTagsGroupSettings = useCallback(() => {
        if (groupInfo !== undefined) {
            state.appDialogPanel.set(() => (
                <TagsGroupsFormPanel
                    item={groupInfo}
                    onClose={(): void => {
                        state.appDialogPanel.set(null);
                    }}
                    onApply={(data): void => {
                        setGroupInfo(data);
                    }}
                />
            ));
        }
    }, [groupInfo]);

    const buttons = [
        {
            title: "Add new Tag",
            icon: <AddRegular />,
            disabled: isQueryInProgress,
            onClick: () => addNewTag(props.groupId),
        },
        {
            title: "Tags group settings",
            onClick: openTagsGroupSettings,
            disabled: isQueryInProgress,
            icon: <SettingsRegular />,
        },
    ];

    if (selectedTags.size > 1) {
        buttons.push({
            title: `Merge ${selectedTags.size} tags`,
            icon: <MergeRegular />,
            disabled: isQueryInProgress,
            onClick: () => mergeTags(selectedTags, props.groupId),
        });
    }

    if (groupInfo === undefined || tags === null) {
        return (
            <div className="admin__loader">
                <Loader text="Fetching tags" />
            </div>
        );
    }

    return (
        <AdminLayout
            title={groupInfo.name}
            backLink={{
                title: "Back to list of Tag Groups",
                href: router.createRoute({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.Tags,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                }),
            }}
            buttons={buttons}
            search={
                <Search
                    query={query}
                    place="Tags"
                    onSearch={onSearch}
                    onDismiss={onDismiss}
                    disabled={isQueryInProgress}
                />
            }
            pagination={
                totalTags > pageSize ? (
                    <Pagination
                        hideText
                        total={total}
                        page={page}
                        onPageChange={(): void =>
                            onPageChange(props.groupId, page)
                        }
                        onPrevPage={(): void => onPrevPage(props.groupId, page)}
                        onNextPage={(): void =>
                            onNextPage(props.groupId, page, total)
                        }
                        pageSizes={pageSizes}
                        pageSize={pageSize}
                        onPageSizeChange={(): void =>
                            onPageSizeChange(props.groupId, pageSize)
                        }
                    />
                ) : null
            }
        >
            {tags.size === 0 ? (
                <Text>Nothing to show</Text>
            ) : (
                <div className="table-container">
                    <Table aria-label="Tags" ref={tableRef} className="table">
                        <TableHeader>
                            <TableRow>
                                <TableSelectionCell
                                    checked={
                                        selectedTags.size === tags.size
                                            ? true
                                            : selectedTags.size > 0
                                            ? "mixed"
                                            : false
                                    }
                                    onClick={(e): void => onAllSelect(e)}
                                    onKeyDown={toggleAllKeydown}
                                    checkboxIndicator={{
                                        "aria-label": "Select all rows ",
                                    }}
                                />
                                {columns.map(i => (
                                    <TableHeaderCell
                                        key={i.columnId}
                                        style={
                                            columnSizes.has(i.columnId)
                                                ? {
                                                      width: `${columnSizes.get(
                                                          i.columnId,
                                                      )}px`,
                                                  }
                                                : undefined
                                        }
                                    >
                                        {i.renderHeaderCell()}
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {rows.map(
                                ({
                                    item,
                                    isSelected,
                                    onClick,
                                    onKeyDown,
                                    getAppearance,
                                }) => (
                                    <TableRow
                                        key={item.id}
                                        onClick={(
                                            e: React.MouseEvent<
                                                Element,
                                                MouseEvent
                                            >,
                                        ): void => onClick(e, item)}
                                        onKeyDown={(e): void =>
                                            onKeyDown(e, item)
                                        }
                                        aria-selected={isSelected(item.id)}
                                        appearance={getAppearance(item.id)}
                                    >
                                        <TableSelectionCell
                                            subtle
                                            checked={isSelected(item.id)}
                                            checkboxIndicator={{
                                                "aria-label": "Select row",
                                            }}
                                        />

                                        {columns.map(i => (
                                            <TableCell key={i.columnId}>
                                                {i.renderCell(item)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ),
                            )}
                        </TableBody>
                    </Table>
                </div>
            )}
        </AdminLayout>
    );
}

export default TagsList;
