export type DataParams = {
    pageSize: number;
    sortKey?: string;
    isSortDesc: boolean;
    page: number;
};

export const dataParamsKeys: Record<keyof DataParams, string> = {
    pageSize: "pageSize",
    sortKey: "sortKey",
    isSortDesc: "isSortDesc",
    page: "page",
};

export const defaultDataParams: DataParams = {
    pageSize: 50,
    isSortDesc: false,
    page: 1,
};

export const defaultDataParamsKeys: Array<keyof DataParams> = Object.keys(
    defaultDataParams,
) as Array<keyof DataParams>;

export const protectedDataParamsKeys: Array<keyof Omit<DataParams, "page">> =
    defaultDataParamsKeys.filter(i => i !== "page") as Array<
        keyof Omit<DataParams, "page">
    >;
