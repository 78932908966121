import { UploadLocation } from "../../domain/uploadLocation/uploadLocation";
import { UploadLocationRepository } from "../../domain/uploadLocation/uploadLocationRepository";
import { apiCall, validateRes } from "../api/api";

export class UploadLocationRepositoryImpl implements UploadLocationRepository {
    async fetchLocations(): Promise<UploadLocation[]> {
        throw new Error("Method not implemented.");
    }

    async fetchLocationFolders(
        locationId: number,
        folderPath: string,
    ): Promise<string[]> {
        const res = await apiCall("/api/upload/folders", {
            method: "GET",
            headers: {
                locationId: `${locationId}`,
                folderPath: encodeURIComponent(folderPath),
            },
        });

        return validateRes(res);
    }
}
