import { useStorage } from "../../../../application/useCases/useStorage";
import { AirFile } from "../../../../domain/airFile/airFile";
import { Option } from "../../../../domain/types/types";
import { router } from "../../../router";

export async function changeSelectionEventHandler(data: {
    files: Option<AirFile[]>;
    selection: Option<string[]>;
}): Promise<void> {
    let files: AirFile[] = [];

    if (data.files !== null && data.files !== undefined) {
        files = data.files;
    } else {
        files =
            useStorage.fileExplorerMiniApp.mode.get() === "search"
                ? useStorage.files.searchFiles.get()
                : useStorage.files.files.get();
    }

    let urlSelection: string[] = [];

    if (data.selection !== null && data.selection !== undefined) {
        urlSelection = data.selection;
    } else {
        const selectionStr = router
            .getUrlEntities()
            .searchParams.get("selection");
        urlSelection = selectionStr ? selectionStr.split(",") : [];
    }

    if (urlSelection === null) {
        useStorage.collectionsMiniApp.selection.set(new Map());
    } else {
        const selection = new Map();
        urlSelection.forEach(i => {
            const file = files.find(f => f.hierarchy === i);
            if (file) {
                selection.set(file.hierarchy, file);
            }
        });
        useStorage.collectionsMiniApp.selection.set(selection);
    }
}
