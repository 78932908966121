import { useAuthorization } from "../../application/useCases/useAuthorization";
import { useStorage } from "../../application/useCases/useStorage";

export function getBaseUrl(url: string): string {
    return process.env.BASE_URL ? process.env.BASE_URL + url : url;
}

export async function apiCall(
    url: string,
    init?: RequestInit,
    skipAuthCheck = false,
): Promise<Response> {
    const session = useStorage.authorization.session.get();
    let commonHeaders = {};

    if (!skipAuthCheck) {
        if (!session || session.sessionId == null || session.token == null) {
            throw new Error("no authorization state found");
        }

        if (useAuthorization.isSessionExpired(session)) {
            await useAuthorization.authenticate();
        }

        commonHeaders = {
            Authorization: `Bearer ${session.token}`,
            "X-Session-Id": session.sessionId,
        };
    }

    const headers = {
        ...commonHeaders,
        ...(init && init.headers),
    };

    const res = await fetch(getBaseUrl(url), { ...init, headers });

    if (res.status === 401) {
        await useAuthorization.signOut();
        await useAuthorization.authenticate();
    }

    return res;
}

function isJsonString(str: string): boolean {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export async function handleNotOkWithBody<T>(res: Response): Promise<T> {
    const resBody = await res.text();
    const isJson = isJsonString(resBody);

    if (!res.ok) {
        if (resBody.length) {
            if (isJson) {
                const json = JSON.parse(resBody);

                if (json.messages) {
                    throw new Error(json.messages.join(", "));
                }

                throw new Error(resBody);
            } else {
                throw new Error(resBody);
            }
        } else {
            throw new Error(`${res.status}: ${res.statusText}`);
        }
    }

    return isJson ? await res.json() : {};
}

export function handleNotOk(res: Response): void {
    if (!res.ok) {
        throw new Error(`${res.status}: ${res.statusText}`);
    }
}

export async function validateRes<T>(res: Response): Promise<T> {
    handleNotOk(res);
    return await res.json();
}
