import React, { useEffect, useRef, useState } from "react";
import MiniApp from "./MiniApp/MiniApp";
import AppHeader from "./AppHeader/AppHeader";
import AppPanel from "./AppPanel/AppPanel";
import CommandBar from "../components/CommandBar/CommandBar";
import { User } from "../../domain/user/user";
import AppDialog from "./AppDialog/AppDialog";
import NotificationToastsContainer from "../components/NotificationToastsContainer/NotificationToastsContainer";
import { Option } from "../../domain/types/types";
import AppNavigation from "./AppNavigation/AppNavigation";
import { makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { useConfig } from "../../application/useCases/useConfig";
import { state } from "../state/stateAdapter";
import { useStorage } from "../../application/useCases/useStorage";
import Loader from "../components/Loader/Loader";
import AppDialogPanel from "./AppDialogPanel/AppDialogPanel";
import { isDarkThemeInCache, matchTheme } from "../theme";
import { useUploader } from "../../application/useCases/useUploader";
import { useNotification } from "../../application/useCases/useNotification";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { Job, JobStatus } from "../../domain/job/job";
import { useJobs } from "../../application/useCases/useJobs";
import { NotificationType } from "../../application/notification/notificationPort";
import { calculateOffset, formatSize } from "../../utility";
import { notifyUserAboutNewCompletedJobs, updateLastSync } from "./shared/jobs";
import { onDownloadFilesByLink } from "../utility/utility";
import { Config } from "../../application/config/config";

const useStyles = makeStyles({
    appMain: {
        backgroundColor: tokens.colorNeutralBackground3,
    },
});

export function changeFavicon(link: string): void {
    let favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;

    if (favicon) {
        favicon.href = link;
    } else {
        favicon = document.createElement("link");
        favicon.rel = "icon";
        favicon.href = link;

        document.head.appendChild(favicon);
    }
}

type AppProps = {
    user: Option<User>;
};

function onLocalDownloadJobDone(job: Job): void {
    let baseMessage: string = "";

    if (job.details === null || job.details.filesTotal === 0) {
        baseMessage = "Some files are";
    } else {
        const size = formatSize(job.details.sizeTotal);
        baseMessage = `${
            job.details.filesTotal > 1
                ? `${job.details.filesTotal} files (${size}) are`
                : `${job.details.filesTotal} file (${size}) is`
        }`;
    }

    const id = job.id.toString();

    const notificationElement = useNotification.create(
        `${baseMessage} ready for download.`,
        "info",
        true,
        [
            {
                as: "link",
                label: "Click to download",
                onClick: (close: () => void): void => {
                    onDownloadFilesByLink(job);
                    close();
                },
            },
        ],
        id,
        NotificationType.System,
    );

    const notifications = useStorage.notifications.get();
    const drawerNotifications = state.drawerNotifications.get();

    // TODO: temporary hack while there is a bug in signalR (the last update is sent 2 times)
    if (!notifications.has(id) && !drawerNotifications.has(id)) {
        useStorage.notifications.update(s => s.set(id, notificationElement));
    }
}

function onHideModalNotification(id: string): void {
    useNotification.hide(id);
}

function onAutoHideModalNotification(id: string): void {
    const element = useNotification.getElement(id);

    if (element !== undefined) {
        if (element.type === NotificationType.System) {
            state.drawerNotifications.update(s => s.set(id, element));
        }
        useNotification.hide(id);
    }
}

async function updateLatestAndNotify(): Promise<void> {
    const latestJobs = await useJobs.fetchLatest();
    updateLastSync();

    if (latestJobs !== undefined && latestJobs !== null) {
        notifyUserAboutNewCompletedJobs(latestJobs.newCompletedJobsIds.length);
    }
}

function useLatestJobsUpdater(): void {
    // eslint-disable-next-line no-undef
    const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        updateLatestAndNotify();
    }, []);

    useEffect(() => {
        intervalIdRef.current && clearInterval(intervalIdRef.current);

        intervalIdRef.current = setInterval(() => {
            updateLatestAndNotify();
        }, 60000);

        return () => {
            intervalIdRef.current && clearInterval(intervalIdRef.current);
        };
    }, []);
}

type JobProgressUpdatedData = {
    filesCompleted: number;
    id: number;
    sizeCompleted: number;
    status: JobStatus;
    message: string;
};

async function subscribeOnJobs(config: Option<Config>): Promise<void> {
    const { pageSize, page } = useStorage.jobs.dataParams.get();

    const allJobs = await useJobs.fetchAll({
        limit: pageSize,
        offset: calculateOffset(page, pageSize),
    });

    if (allJobs !== undefined && allJobs !== null) {
        notifyUserAboutNewCompletedJobs(allJobs.newCompletedJobsIds.length);
    }

    if (config !== undefined && config !== null) {
        const signalREndpoint = config.signalREndpoint;

        if (signalREndpoint && signalREndpoint.length > 0) {
            const session = useStorage.authorization.session.get();
            const user = useStorage.authorization.user.get();

            const connection = new HubConnectionBuilder()
                .withUrl(`${signalREndpoint}?userId=${user!.id}`, {
                    accessTokenFactory: () => session!.token,
                })
                .build();

            connection.on("jobProgressUpdated", data => {
                const { filesCompleted, id, sizeCompleted, status, message } =
                    data as JobProgressUpdatedData;
                const jobsMap = useStorage.jobs.jobsMap.get();

                const jobIndex = jobsMap.get(id);

                if (jobIndex !== undefined) {
                    useStorage.jobs.allJobs.update(jobs => {
                        const job = jobs.results[jobIndex];

                        if (job !== undefined && job.details !== null) {
                            job.details.filesCompleted = filesCompleted;
                            job.details.sizeCompleted = sizeCompleted;
                            job.status = status;
                            job.message = message;

                            if (status === JobStatus.Completed) {
                                onLocalDownloadJobDone(job);
                            }
                        }
                    });
                }
            });

            // TODO: Temp solution (excludeSignalR)
            connection.start().then(() => {
                console.log("signalr is connected");
                useStorage.excludeSignalRJobs.set(true);
            });
        }
    }
}

function App(props: AppProps): JSX.Element {
    const classes = useStyles();
    const isConfigQueryInProgress = state.useState(
        useStorage.config.isQueryInProgress,
    );
    const [isThemeConfig, setIsThemeConfig] = useState(false);
    const config = state.useState(useStorage.config.config);
    const isGetConfigQueryInProgress = state.useState(
        useStorage.config.isQueryInProgress,
    );
    const isFetchLocationsQueryInProgress = state.useState(
        useStorage.uploaderMiniApp.isFetchLocationsQueryInProgress,
    );

    useLatestJobsUpdater();

    useEffect(() => {
        useConfig.getConfig().then(c => {
            subscribeOnJobs(c);
            state.theme.set(matchTheme(isDarkThemeInCache()));
            setIsThemeConfig(true);
        });
    }, []);

    useEffect(() => {
        if (config.title !== null) {
            document.title = config.title;
        }
    }, [config.title]);

    useEffect(() => {
        if (config.icon !== null) {
            changeFavicon(config.icon);
        }
    }, [config.icon]);

    useEffect(() => {
        useUploader.fetchLocations();
    }, []);

    if (
        isConfigQueryInProgress ||
        !isThemeConfig ||
        isGetConfigQueryInProgress ||
        isFetchLocationsQueryInProgress
    ) {
        return <Loader text="Initializing..." />;
    }

    return (
        <>
            <AppHeader user={props.user} />

            <div className="app__layout">
                <AppNavigation />

                <main
                    id="app__main"
                    className={mergeClasses(classes.appMain, "app__main")}
                >
                    <CommandBar
                        actionsFactoryState={state.commandBar.actionsFactory}
                    />

                    <div className="app__main-content">
                        <MiniApp />
                        <AppPanel
                            activeTabId={state.appPanel.activeTabId}
                            isOpen={state.appPanel.isOpen}
                            tabs={state.appPanel.tabs}
                        />
                    </div>
                </main>
                <AppDialogPanel />
                <AppDialog />
                <NotificationToastsContainer
                    className="app__notification-toasts-container"
                    notificationsStorage={useStorage.notifications}
                    onHide={onHideModalNotification}
                    onAutoHide={onAutoHideModalNotification}
                    above
                />
            </div>
        </>
    );
}

export default App;
