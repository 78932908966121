import {
    Button,
    Text,
    Tooltip,
    mergeClasses,
} from "@fluentui/react-components";
import { CopyRegular } from "@fluentui/react-icons";
import React from "react";
import { formatDate, formatDuration } from "../../../utility/formatters";

import "./fileInformation.css";
import { useInterface } from "../../../../application/useCases/useInterface";
import { formatSize } from "../../../../utility";
import { AirFileDetailsStorageValue } from "../../../../application/storageValues";

type InfoItemProps = {
    item: string | undefined | null | number;
    label: string;
    action?: JSX.Element;
    isFullWidht?: boolean;
    isWrap?: boolean;
};

function InfoItem(props: InfoItemProps): JSX.Element | null {
    if (!props.item) {
        return null;
    }

    return (
        <div
            className={mergeClasses(
                "file-information__item",
                props.isFullWidht ? "file-information__item_full-width" : "",
            )}
        >
            <span className="file-information__header">
                {props.label} {props.action}
            </span>
            <Tooltip
                withArrow
                content={props.item}
                relationship="label"
                showDelay={1500}
            >
                <Text truncate block wrap={props.isWrap === true}>
                    {props.item}
                </Text>
            </Tooltip>
        </div>
    );
}

type FileInformationProps = {
    fileDetails: AirFileDetailsStorageValue;
};

function FileInformation(props: FileInformationProps): JSX.Element | null {
    const airFileDetails = props.fileDetails;

    if (airFileDetails == null) {
        return null;
    }

    return (
        <div className="file-information">
            <InfoItem item={airFileDetails.fileType} label="Type" />

            <InfoItem item={airFileDetails.language} label="Language" />

            <InfoItem
                item={
                    airFileDetails.dateUpdated
                        ? formatDate(airFileDetails.dateUpdated)
                        : null
                }
                label="Updated at"
            />

            <InfoItem
                item={
                    airFileDetails.fileSize
                        ? formatSize(airFileDetails.fileSize)
                        : null
                }
                label="Size"
            />

            {airFileDetails.technicalMetadata !== null && (
                <>
                    <InfoItem
                        item={airFileDetails.technicalMetadata.videoFormat}
                        label="Video format"
                    />

                    <InfoItem
                        item={airFileDetails.technicalMetadata.resolution}
                        label="Resolution"
                    />

                    <InfoItem
                        item={airFileDetails.technicalMetadata.bitRate}
                        label="Bitrate"
                    />

                    {airFileDetails.technicalMetadata.duration !== null && (
                        <InfoItem
                            item={formatDuration(
                                airFileDetails.technicalMetadata.duration,
                            )}
                            label="Duration"
                        />
                    )}
                </>
            )}

            {airFileDetails.imageType !== null && (
                <>
                    <InfoItem
                        item={airFileDetails.imageType.format}
                        label="Image format"
                    />
                    <InfoItem
                        item={airFileDetails.imageType.height}
                        label="Height"
                    />

                    <InfoItem
                        item={airFileDetails.imageType.width}
                        label="Width"
                    />
                </>
            )}

            <InfoItem item={airFileDetails.storageTier} label="Storage tier" />

            {airFileDetails.filePath ? (
                <InfoItem
                    isFullWidht={true}
                    item={airFileDetails.filePath}
                    label="Path"
                    isWrap={true}
                    action={
                        <Button
                            className="file-information__header"
                            appearance="transparent"
                            icon={<CopyRegular />}
                            onClick={(): Promise<void> =>
                                useInterface.copyToClipboard(
                                    airFileDetails.filePath,
                                )
                            }
                        />
                    }
                />
            ) : null}
        </div>
    );
}

export default FileInformation;
