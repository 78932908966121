import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    Label,
    Input,
    Select,
    DialogActions,
    DialogTrigger,
    Button,
} from "@fluentui/react-components";
import React, { useEffect } from "react";
import { state } from "../../../../state/stateAdapter";
import { collectionsLocalState } from "../../Collections/collectionsLocalState";

type CollectionsDialogProps = {
    title: string;
    isOpen: boolean;
    defaultName: string | null;
    defaultStatus: "private" | "public";
    onApply: (name: string, status: "private" | "public") => Promise<void>;
    onClose?: () => void;
};

function CollectionsDialog(props: CollectionsDialogProps): JSX.Element {
    const newCollectionInfoName = state.useState(
        collectionsLocalState.newCollectionInfoName,
    );
    const newCollectionInfoStatus = state.useState(
        collectionsLocalState.newCollectionInfoStatus,
    );

    useEffect(() => {
        collectionsLocalState.newCollectionInfoName.set(props.defaultName);
        collectionsLocalState.newCollectionInfoStatus.set(props.defaultStatus);
    }, [props.defaultName, props.defaultStatus]);

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    state.appDialog.set(null);

                    if (props.onClose) {
                        props.onClose();
                    }
                }
            }}
            open={props.isOpen}
        >
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle>{props.title}</DialogTitle>
                    <DialogContent>
                        <div className="app-dialog__content">
                            <div className="app-dialog__row-row">
                                <Label required htmlFor={"edit-name-input"}>
                                    Name
                                </Label>
                                <Input
                                    required
                                    type="text"
                                    id={"edit-name-input"}
                                    defaultValue={
                                        props.defaultName ?? undefined
                                    }
                                    onChange={(_e, data): void => {
                                        collectionsLocalState.newCollectionInfoName.set(
                                            data.value ?? null,
                                        );
                                    }}
                                />
                            </div>
                            <div className="app-dialog__row-row">
                                <Label htmlFor={"status-select"}>
                                    Visibility
                                </Label>
                                <Select
                                    id={"status-select"}
                                    defaultValue={props.defaultStatus}
                                    onChange={(_e, data): void => {
                                        collectionsLocalState.newCollectionInfoStatus.set(
                                            (data.value.toLowerCase() as
                                                | "private"
                                                | "public") ?? null,
                                        );
                                    }}
                                >
                                    <option value={"private"}>Just me</option>
                                    <option value={"public"}>All users</option>
                                </Select>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                disabled={
                                    (newCollectionInfoName === null ||
                                        newCollectionInfoName.length === 0 ||
                                        newCollectionInfoName ===
                                            props.defaultName) &&
                                    newCollectionInfoStatus ===
                                        props.defaultStatus
                                }
                                onClick={(_e): void => {
                                    if (
                                        newCollectionInfoName !== null &&
                                        newCollectionInfoName.length > 0
                                    ) {
                                        props.onApply(
                                            newCollectionInfoName,
                                            newCollectionInfoStatus,
                                        );
                                    }
                                }}
                            >
                                Apply
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default CollectionsDialog;
