import React, { useCallback, useEffect, useState } from "react";
import {
    Switch,
    Field,
    Button,
    Radio,
    RadioGroup,
    Persona,
    makeStyles,
    shorthands,
    tokens,
    mergeClasses,
    Input,
} from "@fluentui/react-components";
import { SaveRegular, PersonAddRegular } from "@fluentui/react-icons";
import SelectUsersField from "../../../../../components/Form/SelectUsersField/SelectUsersField";
import SliderSize from "../../../../../components/SliderSize/SliderSize";
import AdminLayout from "../../AdminLayout/AdminLayout";
import "./serviceAccount.css";
import { useCommonFluentuiStyles } from "../../../../../styles/griffel";
import { protectedDataParamsKeys } from "../../../../../../domain/dataParams/dataParams";
import {
    AdminActiveMenuKind,
    CrossTabId,
    CrossTabMessage,
} from "../../../../../models";
import { router, routes } from "../../../../../router";
import { DownloadLocationFetchParams } from "../../../../../../application/downloadLocation/downloadLocationPort";
import { useDownloadLocation } from "../../../../../../application/useCases/useDownloadLocation";
import Loader from "../../../../../components/Loader/Loader";
import {
    downloadLocationButesLimitMin,
    downloadLocationButesLimitStep,
    downloadLocationButesLimitMax,
} from "../DownloadLocations";
import {
    DownloadLocationServiceAccount,
    DownloadLocationType,
    DownloadLocationPermission,
    DownloadLocationAuthorizationType,
} from "../../../../../../application/downloadLocation/downloadLocation";
import { useAuthorization } from "../../../../../../application/useCases/useAuthorization";
import { useStorage } from "../../../../../../application/useCases/useStorage";
import DownloadLocationLabels from "../downloadLocationLabel";

const useStyles = makeStyles({
    serviceAccount: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
    },
    disabledText: {
        color: tokens.colorNeutralForegroundDisabled,
    },
});

function isEqual(data: unknown | null, initData: unknown | null): boolean {
    if (
        (data === null && initData === null) ||
        (data === undefined && initData === undefined)
    ) {
        return true;
    }
    return JSON.stringify(data) === JSON.stringify(initData);
}

function SharePointLocation(): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();
    const classes = useStyles();
    const [isQueryInProgress, setIsQueryInProgress] = useState<boolean>(true);
    const [data, setData] = useState<DownloadLocationFetchParams>();
    const [serviceAccounts, setServiceAccounts] = useState<
        Map<number, DownloadLocationServiceAccount>
    >(new Map());
    const [initData, setInitData] = useState<DownloadLocationFetchParams>();
    const [formKey, setFormKey] = useState<number>(0);
    const isFormValid =
        data &&
        data.permissions &&
        data.permissions.length > 0 &&
        data.title.length > 0;

    useEffect(() => {
        const handleMessage = (event: MessageEvent): void => {
            if (
                event.origin === window.location.origin &&
                event.data.windowID === CrossTabId.AddServiceAccount
            ) {
                const message: CrossTabMessage = event.data;

                setIsQueryInProgress(false);

                if (message.isOk && message.message !== null) {
                    const sa = JSON.parse(
                        message.message,
                    ) as DownloadLocationServiceAccount;

                    serviceAccounts.set(sa.id, sa);
                    setServiceAccounts(new Map([...serviceAccounts]));
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [serviceAccounts]);

    useEffect(() => {
        if (data === undefined) {
            setIsQueryInProgress(true);
            useDownloadLocation
                .fetchByType({
                    locationType: DownloadLocationType.SharePoint,
                })
                .then((res): void => {
                    if (res !== null && res !== undefined) {
                        // const info = res.get(DownloadLocationType.SharePoint);
                        if (res.title === null) {
                            res.title = DownloadLocationLabels.SharePoint;
                        }

                        setData({ ...res } as DownloadLocationFetchParams);

                        if (
                            res &&
                            res.serviceAccounts !== undefined &&
                            res.serviceAccounts !== null
                        ) {
                            setServiceAccounts(
                                new Map(
                                    res.serviceAccounts.map(i => [i.id, i]),
                                ),
                            );
                        }
                        setInitData({ ...res } as DownloadLocationFetchParams);
                    }
                    setIsQueryInProgress(false);
                });
        }
    }, [data]);

    const saveChanges = useCallback(() => {
        if (isFormValid && data !== null) {
            try {
                setIsQueryInProgress(true);

                if (data.locationType === null) {
                    data.locationType = DownloadLocationType.SharePoint;
                }

                useDownloadLocation.editByType(data);
                router.goTo({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.DownloadLoactions,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                });
            } catch (err) {
                setIsQueryInProgress(false);
            }
        }
    }, [data, isFormValid]);

    function discardChanges(): void {
        if (initData !== undefined) {
            setData({ ...initData });
            setFormKey(formKey + 1);
        }
    }

    const updateData = useCallback(
        (
            name: string,
            value:
                | string
                | number
                | undefined
                | boolean
                | DownloadLocationPermission[],
        ): void => {
            if (
                data !== undefined &&
                data[name as keyof DownloadLocationFetchParams] !== value
            ) {
                setData({ ...data, [name]: value });
            }
        },
        [data],
    );

    // TODO: Move to application
    const addServiceAccount = async (): Promise<void> => {
        setIsQueryInProgress(true);

        const config = await useAuthorization.getAuthorizationConfig(
            window.location.origin + routes.addServiceAccountCallback,
        );

        if (config !== null && config !== undefined) {
            useStorage.downloadLocation.isCreateServiceAccountInProgress.set(
                true,
            );
            window.open(config.uri, "_blank");
        }
    };

    const deleteServiceAccount = useCallback(
        async (id: number): Promise<void> => {
            setIsQueryInProgress(true);
            await useDownloadLocation.deleteServiceAccount({ id });
            serviceAccounts.delete(id);
            setServiceAccounts(new Map(serviceAccounts.entries()));
            setIsQueryInProgress(false);
        },
        [serviceAccounts],
    );

    const toggleServiceAccount = useCallback(
        async (id: number, enabled: boolean): Promise<void> => {
            setIsQueryInProgress(true);

            const res = await useDownloadLocation.editServiceAccount({
                id,
                enabled,
            });
            if (res !== undefined && res !== null) {
                serviceAccounts.set(id, res);
                setServiceAccounts(new Map(serviceAccounts.entries()));
            }
            setIsQueryInProgress(false);
        },
        [serviceAccounts],
    );

    if (data === undefined || isQueryInProgress) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            buttons={[
                {
                    title: "Save changes",
                    onClick: saveChanges,
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled:
                        isQueryInProgress ||
                        !isFormValid ||
                        isEqual(initData, data),
                },
                {
                    title: "Discard changes",
                    onClick: discardChanges,
                    appearance: "subtle",
                    disabled: isQueryInProgress || isEqual(initData, data),
                },
            ]}
            backLink={{
                href: router.createRoute({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.DownloadLoactions,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                }),
                title: "Back to Download Locations",
            }}
            title={DownloadLocationLabels.SharePoint}
        >
            <form className="admin__form" key={formKey}>
                <Switch
                    className={commonClasses.field}
                    checked={data.enabled}
                    onChange={(_e, data): void => {
                        updateData("enabled", data.checked);
                    }}
                    label={`Enable ${
                        data.title
                            ? data.title
                            : DownloadLocationLabels.SharePoint
                    }`}
                />

                <Field className={commonClasses.field} label="Label" required>
                    <Input
                        size="large"
                        value={data.title}
                        disabled={!data.enabled}
                        onChange={(_e, d): void => {
                            updateData("title", d.value);
                        }}
                        required
                    />
                </Field>

                <Field
                    className={commonClasses.field}
                    label="List of AD security groups who could download content locally"
                    required
                >
                    <SelectUsersField
                        disabled={!data.enabled}
                        onChange={(permissions): void => {
                            updateData("permissions", permissions);
                        }}
                        data={data?.permissions}
                    />
                </Field>

                <Field required className={commonClasses.field}>
                    <SliderSize
                        title="Limit size of content to be downloaded locally"
                        disabled={!data.enabled}
                        min={downloadLocationButesLimitMin}
                        step={downloadLocationButesLimitStep}
                        max={downloadLocationButesLimitMax}
                        value={data.bytesLimit}
                        onChange={(value: number): void => {
                            updateData("bytesLimit", value);
                        }}
                    />
                </Field>

                <Field
                    className={commonClasses.field}
                    required
                    label="SharePoint download account type"
                >
                    <RadioGroup
                        disabled={!data.enabled}
                        onChange={(_e, data): void => {
                            updateData("authorizationType", data.value);
                        }}
                        value={data.authorizationType}
                    >
                        <Radio
                            value={
                                DownloadLocationAuthorizationType.Application
                            }
                            label="Download content via application registration permissions"
                        />
                        <Radio
                            value={DownloadLocationAuthorizationType.User}
                            label="Service accounts"
                        />
                    </RadioGroup>
                </Field>

                {data.authorizationType ===
                DownloadLocationAuthorizationType.User ? (
                    <Field
                        label="Service accounts list"
                        className={commonClasses.field}
                    >
                        <div className="service-account">
                            {Array.from(serviceAccounts.values()).map(i => (
                                <div
                                    key={i.id}
                                    className={mergeClasses(
                                        classes.serviceAccount,
                                        "service-account__item",
                                    )}
                                >
                                    <Switch
                                        className={commonClasses.field}
                                        checked={i.enabled}
                                        onChange={(_e, data): void => {
                                            toggleServiceAccount(
                                                i.id,
                                                data.checked,
                                            );
                                        }}
                                    />
                                    <Persona
                                        textAlignment="center"
                                        size="extra-large"
                                        name={i.userName}
                                        presence={{
                                            status: i.enabled
                                                ? "available"
                                                : "offline",
                                        }}
                                        primaryText={
                                            <span
                                                className={
                                                    i.enabled
                                                        ? ""
                                                        : classes.disabledText
                                                }
                                            >
                                                {i.userName}
                                            </span>
                                        }
                                        secondaryText={
                                            <span
                                                className={
                                                    i.enabled
                                                        ? ""
                                                        : classes.disabledText
                                                }
                                            >
                                                {i.email}
                                            </span>
                                        }
                                        className={
                                            i.enabled
                                                ? ""
                                                : classes.disabledText
                                        }
                                    />
                                    <Button
                                        disabled={!data.enabled}
                                        onClick={(): void => {
                                            deleteServiceAccount(i.id);
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            ))}
                            <Button
                                disabled={!data.enabled}
                                onClick={addServiceAccount}
                                icon={<PersonAddRegular />}
                            >
                                Add service account
                            </Button>
                        </div>
                    </Field>
                ) : null}
            </form>
        </AdminLayout>
    );
}

export default SharePointLocation;
