// TODO: fix overflow
import React, { Fragment, SyntheticEvent } from "react";
import {
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
    Button,
    Overflow,
    OverflowItem,
    useIsOverflowItemVisible,
    useOverflowMenu,
    Text,
    Link,
} from "@fluentui/react-components";
import { MoreHorizontal20Filled } from "@fluentui/react-icons";
import {
    useBreadcrumbsSharedStyles,
    OverflowMenuDivider,
    BreadcrumbsSeparator,
} from "./breadcrumbsShared";

type CrumbProps = {
    isActive: boolean;
    disabled: boolean;
    children: React.ReactNode;
    as: "a" | "button";
    index?: number;
    onCrumbClick?: (e: SyntheticEvent) => void;
    onActiveCrumbClick?: (e: SyntheticEvent) => void;
};

export function Crumb(props: CrumbProps): JSX.Element {
    const classes = useBreadcrumbsSharedStyles();

    if (props.isActive) {
        return (
            <Text
                weight="bold"
                onClick={props.disabled ? undefined : props.onActiveCrumbClick}
            >
                {props.children}
            </Text>
        );
    }

    return (
        <Link
            as={props.as}
            appearance="subtle"
            data-index={props.index}
            disabled={props.disabled}
            className={classes.link}
            onClick={props.onCrumbClick}
        >
            {props.children}
        </Link>
    );
}

type BreadcrumbsProps = {
    path: string;
    disabled: boolean;
    handleCrumbClickPropagation?: (
        e: SyntheticEvent,
        pathSplit: string[],
    ) => void;
    renderCrumb: (
        folder: string,
        index: number,
        disabled: boolean,
        isLast: boolean,
    ) => JSX.Element;
};

function PathBreadcrumbs(props: BreadcrumbsProps): JSX.Element {
    const { handleCrumbClickPropagation } = props;
    const classes = useBreadcrumbsSharedStyles();
    const folders = props.path.split("/").filter(folder => folder);

    return (
        <Overflow padding={80}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
                className={classes.breadcrumbs}
                onClick={
                    handleCrumbClickPropagation !== undefined && !props.disabled
                        ? (e): void => {
                              handleCrumbClickPropagation(e, folders);
                          }
                        : undefined
                }
            >
                {folders.map((folder, index) => {
                    const isLast = index + 1 === folders.length;
                    const id = folder + index;

                    return (
                        <OverflowItem key={id} id={id}>
                            <Breadcrumb
                                renderCrumb={props.renderCrumb}
                                disabled={props.disabled}
                                folder={folder}
                                index={index}
                                isLast={isLast}
                            />
                        </OverflowItem>
                    );
                })}
                <BreadcrumbsOverflowMenu
                    disabled={props.disabled}
                    renderCrumb={props.renderCrumb}
                    folders={folders}
                />
            </div>
        </Overflow>
    );
}

type BreadcrumbsOverflowMenuProps = Pick<
    BreadcrumbsProps,
    "disabled" | "renderCrumb"
> & {
    folders: string[];
};

function BreadcrumbsOverflowMenu(
    props: BreadcrumbsOverflowMenuProps,
): JSX.Element | null {
    const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    icon={<MoreHorizontal20Filled />}
                    aria-label="More items"
                    appearance="subtle"
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {props.folders.map((folder, index) => {
                        const isLast = index + 1 === props.folders.length;
                        const id = folder + index;

                        return (
                            <Fragment key={id}>
                                <BreadcrumbsOverflowMenuItem
                                    renderCrumb={props.renderCrumb}
                                    disabled={props.disabled}
                                    folder={folder}
                                    index={index}
                                    isLast={isLast}
                                    id={id}
                                />

                                {!isLast && <OverflowMenuDivider id={id} />}
                            </Fragment>
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}

type BreadcrumbProps = Pick<BreadcrumbsProps, "disabled" | "renderCrumb"> & {
    folder: string;
    index: number;
    isLast: boolean;
};

function Breadcrumb(props: BreadcrumbProps): JSX.Element {
    const classes = useBreadcrumbsSharedStyles();

    return (
        <span className={classes.item}>
            {props.renderCrumb(
                props.folder,
                props.index,
                props.disabled,
                props.isLast,
            )}

            {!props.isLast && (
                <BreadcrumbsSeparator disabled={props.disabled} />
            )}
        </span>
    );
}

type BreadcrumbsOverflowMenuItemProps = BreadcrumbProps &
    Pick<BreadcrumbsProps, "renderCrumb"> & {
        id: string;
    };

function BreadcrumbsOverflowMenuItem(
    props: BreadcrumbsOverflowMenuItemProps,
): JSX.Element | null {
    const isVisible = useIsOverflowItemVisible(props.id);

    if (isVisible) {
        return null;
    }

    return (
        <MenuItem>
            <Breadcrumb
                disabled={props.disabled}
                folder={props.folder}
                index={props.index}
                isLast={props.isLast}
                renderCrumb={props.renderCrumb}
            />
        </MenuItem>
    );
}

export default PathBreadcrumbs;
