import React, { useMemo, RefObject, SyntheticEvent } from "react";
import SeekBar from "../../../SeekBar/SeekBar";
import { Subtitle1, tokens } from "@fluentui/react-components";
import ExtendedViewEmptySearch from "../ExtendedViewEmptySearch/ExtendedViewEmptySearch";
import {
    calculatePercentAppearance,
    searchData,
    sortUniqData,
} from "../../../utils";
import "./emotions.css";
import { state } from "../../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../../videoExtendedViewLocalState";
import { ExtendedViewData } from "../../../models";
import { useViewExtendedStyles } from "../../VideoExtendedView";

export function getColor(text: string): string {
    switch (text) {
        case "Joy":
            return tokens.colorPaletteDarkOrangeForeground1;
        case "Fear":
            return tokens.colorPalettePurpleBorderActive;
        case "Sad":
            return tokens.colorPaletteRoyalBlueBorderActive;
        case "Positive":
            return tokens.colorPaletteGreenForeground1;
        case "Negative":
            return tokens.colorPaletteRedForeground1;
        default:
            return "#303030";
    }
}

export type EmotionsSectionProps = {
    duration: number;
    title: string;
    videoRef: RefObject<HTMLVideoElement>;
    data: ExtendedViewData[];
    isPlayerPlaying: boolean;
    onPrevInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        setCurrentIndex: (index: number) => void,
    ) => void;
    onPauseInPlayer: () => void;
    onPlayInPlayer: () => void;
    onNextInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        currentIndex: number,
        setCurrentIndex: (index: number) => void,
    ) => void;
    showTime: (
        e: React.MouseEvent<HTMLElement>,
        setSeekTime: (time: number) => void,
    ) => void;
    onClickTimeLine: (
        e: React.MouseEvent<HTMLElement>,
        seekTime: number,
    ) => void;
};

function Emotions(props: EmotionsSectionProps): JSX.Element | null {
    const classesViewExtended = useViewExtendedStyles();
    const sortedData = useMemo(() => sortUniqData(props.data), [props.data]);
    const query = state.useState(videoExtendedLocalState.query);
    const data = searchData(sortedData, query);
    const appearances = data
        .map(i => {
            return i.appearances.map(a => ({
                ...a,
                color: getColor(i.value),
            }));
        })
        .flat()
        .sort((a, b) => {
            const nameA = a.startTime.toUpperCase();
            const nameB = b.startTime.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

    if (props.data.length && query.length > 0 && data.length === 0) {
        return <ExtendedViewEmptySearch title={props.title} />;
    }

    if (data.length === 0) {
        return null;
    }

    return (
        <div className="extended-view__section">
            <Subtitle1 className={classesViewExtended.title}>
                {props.title}
            </Subtitle1>
            <div className="extended-view__section-list">
                {data.map((i, index: number) => (
                    <div
                        role="presentation"
                        key={i.value + index}
                        className="extended-view__item"
                    >
                        <div
                            role="presentation"
                            className={`emotions emotions_${i.value.toLowerCase()}`}
                            style={{ color: getColor(i.value) }}
                        >
                            {calculatePercentAppearance(
                                props.duration,
                                i.appearances,
                            )}
                            % {i.value}
                        </div>
                    </div>
                ))}
            </div>
            <SeekBar
                videoRef={props.videoRef}
                duration={props.duration}
                appearances={appearances}
                isPlayerPlaying={props.isPlayerPlaying}
                onPrevInPlayer={props.onPrevInPlayer}
                onPauseInPlayer={props.onPauseInPlayer}
                onPlayInPlayer={props.onPlayInPlayer}
                onNextInPlayer={props.onNextInPlayer}
                showTime={props.showTime}
                onClickTimeLine={props.onClickTimeLine}
            />
        </div>
    );
}

export default Emotions;
