import { useEventBus } from "../../application/useCases/useEventBus";
import { EventBus } from "../../domain/eventBus/eventBus";
import { FilesViewModeKind, PanelTab } from "../models";
import { router } from "../router";
import { ShareAccessEvents } from "./shareAccessEventBus";
import shareAccessState, { ShareAccessStatePort } from "./shareAccessAppState";
import { ShareAccessUrlParams } from "./types";

class ShareAccessManager {
    private readonly _statePort: ShareAccessStatePort;
    private readonly _shareAccessEventBus: EventBus;

    constructor(
        statePort: ShareAccessStatePort,
        shareAccessEventBus: EventBus,
    ) {
        this._statePort = statePort;
        this._shareAccessEventBus = shareAccessEventBus;
    }

    private _prevFileExplorerUrlParams: ShareAccessUrlParams = {
        activeDirectory: null,
        activeItem: null,
        viewMode: FilesViewModeKind.List,
    };

    parseUrl(url: URL): ShareAccessUrlParams {
        const searchParams = url.searchParams;

        const viewMode = searchParams.get("viewMode");

        return {
            activeDirectory: searchParams.get("path"),
            activeItem: searchParams.get("activeItem"),
            viewMode:
                viewMode !== null ? parseInt(viewMode) : FilesViewModeKind.List,
        };
    }

    updateParams(url: URL): void {
        const urlParams = this.parseUrl(url);

        if (
            url.pathname.startsWith("/share/") &&
            this._statePort.isAppInit.get()
        ) {
            if (
                urlParams.activeDirectory !==
                this._prevFileExplorerUrlParams.activeDirectory
            ) {
                this._shareAccessEventBus.emit(
                    ShareAccessEvents.ChangeActiveDirectory,
                    {
                        newActiveDirectory: urlParams.activeDirectory,
                    },
                );
            }

            if (
                urlParams.viewMode !== this._prevFileExplorerUrlParams.viewMode
            ) {
                this._shareAccessEventBus.emit(
                    ShareAccessEvents.UpdateViewMode,
                    {
                        mode: urlParams.viewMode,
                    },
                );
            }

            if (
                urlParams.activeItem !==
                this._prevFileExplorerUrlParams.activeItem
            ) {
                this._shareAccessEventBus.emit(
                    ShareAccessEvents.ChangeActiveItem,
                    {
                        activeItem: urlParams.activeItem,
                    },
                );
            }

            // updating prev
            this._prevFileExplorerUrlParams.activeDirectory =
                urlParams.activeDirectory;

            this._prevFileExplorerUrlParams.viewMode = urlParams.viewMode;

            this._prevFileExplorerUrlParams.activeItem = urlParams.activeItem;
        }
    }

    init(
        activeDirectory: string | null,
        viewMode: FilesViewModeKind,
        activeItem: string | null,
    ): void {
        this._prevFileExplorerUrlParams.activeDirectory = activeDirectory;
        this._prevFileExplorerUrlParams.viewMode = viewMode;
        this._prevFileExplorerUrlParams.activeItem = activeItem;
    }

    async updateUrlParams(): Promise<ShareAccessUrlParams> {
        const urlParams = this.parseUrl(router.url);
        this._prevFileExplorerUrlParams = urlParams;

        return urlParams;
    }

    clearActiveItem(): void {
        this._statePort.activeItem.set(null);
        router.clearSearchByKey("activeItem");
    }

    addTab(tab: PanelTab): void {
        this._statePort.panel.isOpen.set(true);
        this._statePort.panel.activeTabId.set(tab.id);
        this._statePort.panel.tabs.update(s => {
            s.set(tab.id, tab);
        });
    }

    deleteTab(tabId: string): void {
        this._statePort.panel.tabs.update(s => {
            s.delete(tabId);
        });

        if (tabId === this._statePort.panel.activeTabId.get()) {
            this._statePort.panel.activeTabId.set(
                this._statePort.panel.tabs.get().size > 0
                    ? this._statePort.panel.tabs.get().keys().next().value
                    : "",
            );
        }

        if (
            this._statePort.panel.tabs.get().size === 0 &&
            this._statePort.panel.isOpen.get()
        ) {
            this._statePort.panel.isOpen.set(false);
        }
    }

    toggleTab(tab: PanelTab): void {
        if (this._statePort.panel.tabs.get().has(tab.id)) {
            if (this._statePort.panel.activeTabId.get() === tab.id) {
                this.deleteTab(tab.id);
            } else {
                this._statePort.panel.activeTabId.set(tab.id);
            }
        } else {
            this.addTab(tab);
        }
    }

    activateTab(tab: PanelTab): boolean {
        return this._statePort.panel.activeTabId.set(tab.id);
    }
}

export const shareAccessManager = new ShareAccessManager(
    shareAccessState,
    useEventBus,
);
