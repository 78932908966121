import React, { useEffect } from "react";
import { Text } from "@fluentui/react-components";
import { state } from "../../../state/stateAdapter";
import { useStorage } from "../../../../application/useCases/useStorage";
import Loader from "../../../components/Loader/Loader";
import home from "../../../assets/home.svg";
import UploaderCommandBar from "./UploaderCommandBar/UploaderCommandBar";
import Dropzone from "./Dropzone/Dropzone";
import AddDataMenu from "./AddDataMenu/AddDataMenu";
import UploaderContent from "./UploaderContent/UploaderContent";
import { formUploadDestination } from "../../../../domain/userFileUpload/userFileUpload";
import { useCommonFluentuiStyles } from "../../../styles/griffel";
import { uploaderState } from "./shared/state";
import { router } from "../../../router";
import uploadDestinationTab from "./UploadDestination/uploadDestinationTab";
import { appManager } from "../../../appManager/appManager";

function IntroText(): JSX.Element {
    const predefinedDestination = state.useState(
        uploaderState.predefinedDestination,
    );

    const commonClasses = useCommonFluentuiStyles();

    const baseText = "Drag'n'drop files here or select something to upload";

    if (predefinedDestination !== null && predefinedDestination !== undefined) {
        return (
            <Text size={400}>
                {baseText} to upload to{" "}
                <span className={commonClasses.brandTxt}>
                    {formUploadDestination(
                        predefinedDestination.selectedLocation,
                        predefinedDestination.path ?? "",
                    )}
                </span>
            </Text>
        );
    }

    return <Text size={400}>{baseText}</Text>;
}

function Uploader(): JSX.Element {
    const locations = state.useState(useStorage.uploaderMiniApp.locations);
    const userFiles = state.useState(useStorage.uploaderMiniApp.userFiles);
    const isFetchUploaderFeaturesQueryInProgress = state.useState(
        useStorage.uploaderMiniApp.isFetchUploaderFeaturesQueryInProgress,
    );

    useEffect(() => {
        useStorage.uploaderMiniApp.context.set(router.url.href);
    }, []);

    useEffect(() => {
        const urlSearch = new URL(window.location.href);
        const id = urlSearch.searchParams.get("id");
        const title = urlSearch.searchParams.get("title");
        const path = urlSearch.searchParams.get("path");

        if (
            id !== null &&
            id.length > 0 &&
            title !== null &&
            title.length > 0
        ) {
            uploaderState.selection.set(new Map());
            uploaderState.predefinedDestination.set({
                selectedLocation: { id: parseInt(id), title },
                path,
            });
        }

        return () => {
            uploaderState.predefinedDestination.set(null);
            appManager.deleteTab(uploadDestinationTab.id);
        };
    }, []);

    useEffect(() => {
        if (locations !== null && locations.length > 0) {
            state.commandBar.actionsFactory.set(() => <UploaderCommandBar />);

            return () => {
                state.commandBar.actionsFactory.set(null);
            };
        }
    }, [locations]);

    if (isFetchUploaderFeaturesQueryInProgress) {
        <div className="layout-container">
            <Loader text="Fetching uploader features..." />
        </div>;
    }

    if (locations === null || locations.length === 0) {
        return (
            <div className="layout__container">
                <div className="layout-container item_v-centered">
                    <img
                        className="image_no-pointer-events"
                        src={home}
                        alt="uploader"
                    />
                    <Text size={400}>
                        You don&apos;t have any locations yet to use AIR
                        Uploader. Please add some locations first.
                    </Text>
                </div>
            </div>
        );
    }

    return (
        <div className="layout__container">
            {userFiles && userFiles.length > 0 ? (
                <UploaderContent userFiles={userFiles} />
            ) : (
                <div className="layout-container item_v-centered">
                    <img
                        className="image_no-pointer-events"
                        src={home}
                        alt="uploader"
                    />
                    <IntroText />
                    <AddDataMenu appearance="primary" />
                </div>
            )}

            <Dropzone />
        </div>
    );
}

export default Uploader;
