import { createState } from "@persevie/statemanjs";
import { VideoExtendedViewTabKind } from "../models";
import { StorageRepository } from "../../../../domain/storage/storageRepository";
import {
    InsightLabel,
    predefinedInsightsViewList,
    predefinedTimelineViewList,
} from "./shared";

interface VideoExtendedLocalStatePort {
    thumbnails: StorageRepository<{ [x: string]: string }>;
    selectedTab: StorageRepository<unknown>;
    query: StorageRepository<string>;
    viewInsights: StorageRepository<Map<string, InsightLabel>>;
    viewTimeline: StorageRepository<Map<string, InsightLabel>>;
}

const thumbnailsState = createState<{ [x: string]: string }>({});
const selectedTabState = createState<unknown>(
    VideoExtendedViewTabKind.Insights,
);
const queryState = createState<string>("");
const viewInsightsState = createState<Map<string, InsightLabel>>(
    predefinedInsightsViewList.reduce((map, insight) => {
        map.set(insight.key, insight);
        return map;
    }, new Map<string, InsightLabel>()),
);
const viewTimelineState = createState<Map<string, InsightLabel>>(
    predefinedTimelineViewList.reduce((map, insight) => {
        map.set(insight.key, insight);
        return map;
    }, new Map<string, InsightLabel>()),
);

class VideoExtendedLocalStateAdapter implements VideoExtendedLocalStatePort {
    readonly thumbnails = thumbnailsState;
    readonly selectedTab = selectedTabState;
    readonly query = queryState;
    readonly viewInsights = viewInsightsState;
    readonly viewTimeline = viewTimelineState;
}

export const videoExtendedLocalState = new VideoExtendedLocalStateAdapter();
