import { routes } from "../../../router";
import { RouteListener } from "../../../router/routeListener";
import { adminManager } from "./adminManager";

export const adminRouteListener: RouteListener = {
    onRouteChange: (url: URL): void => {
        adminManager.updateState(url);
    },
    pathname: routes.admin,
};
