export enum AirArchiveExtension {
    Rar = "rar",
    Zip = "zip",
    Tar = "tar",
    TarGZip = "tar.gz",
    TarBZip2 = "tar.bz2",
    TarLZip = "tar.lz",
    TarXZ = "tar.xz",
}

export interface AirArchiveItem {
    archiveFormat: string;
    compressionFormat: string;
    fileExtension: AirArchiveExtension;
}

export type AirArchiveConfig = {
    extractionEnabled: boolean;
    enabledExtensions: AirArchiveExtension[];
};

export const DEFAULT_AIR_ARCHIVE_CONFIG: AirArchiveConfig = {
    extractionEnabled: false,
    enabledExtensions: [],
};

export const AIR_ARCHIVE_ITEMS: AirArchiveItem[] = [
    {
        archiveFormat: "Rar",
        compressionFormat: "Rar",
        fileExtension: AirArchiveExtension.Rar,
    },
    {
        archiveFormat: "Zip",
        compressionFormat: "None, DEFLATE, Deflate64, BZip2, LZMA/LZMA2, PPMd",
        fileExtension: AirArchiveExtension.Zip,
    },
    {
        archiveFormat: "Tar",
        compressionFormat: "None",
        fileExtension: AirArchiveExtension.Tar,
    },
    {
        archiveFormat: "Tar.GZip",
        compressionFormat: "DEFLATE",
        fileExtension: AirArchiveExtension.TarGZip,
    },
    {
        archiveFormat: "Tar.BZip2",
        compressionFormat: "BZip2",
        fileExtension: AirArchiveExtension.TarBZip2,
    },
    {
        archiveFormat: "Tar.LZip",
        compressionFormat: "LZMA",
        fileExtension: AirArchiveExtension.TarLZip,
    },
    {
        archiveFormat: "Tar.XZ",
        compressionFormat: "LZMA2",
        fileExtension: AirArchiveExtension.TarXZ,
    },
];
