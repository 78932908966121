export type NotificationStyle = "error" | "info" | "warning" | "success";

export interface NotificationPort {
    show(element: NotificationElement): void;

    hide(id: string): void;

    hideAll(): void;

    getElement(id: string): NotificationElement | undefined;
}

export type NotificationAction = {
    as: "button" | "link";
    label: string;
    onClick: (close: () => void) => void;
};

export enum NotificationType {
    UserAction,
    System,
}

export type NotificationElement = {
    message: string;
    id: string;
    style: NotificationStyle;
    dateNow: number;
    autoHide?: boolean;
    actions?: NotificationAction[];
    type: NotificationType;
};
