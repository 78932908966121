import {
    CollectionInfo,
    CollectionItem,
} from "../../domain/collection/collections";
import { NotificationService } from "../notification/notificationService";

import {
    CollectionsAddParams,
    CollectionsPort,
    CollectionsDeleteParams,
    CollectionsDeleteFilesParams,
    CollectionsEditParams,
    CollectionsCreateParams,
    CollectionsFetchParams,
    CollectionsFetchByIdParams,
} from "./collectionsPort";
import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { StoragePort } from "../ports";
import { FilesService } from "../files/filesService";

export class CollectionsService {
    private readonly _collectionsPort: CollectionsPort;
    private readonly _filesService: FilesService;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        collectionsPort: CollectionsPort,
        filesService: FilesService,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._collectionsPort = collectionsPort;
        this._filesService = filesService;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    async fetch(
        params: CollectionsFetchParams,
    ): Promise<Option<CollectionInfo[]>> {
        try {
            this._errorService.clear(AirErrorKind.FetchCollections);
            this._storagePort.collections.isQueryInProgress.set(true);
            this._storagePort.collections.item.set(null);

            const res = await this._collectionsPort.fetch(params);

            this._storagePort.collections.list.set(res);
            this._storagePort.collections.isQueryInProgress.set(false);

            return this._storagePort.collections.list.get();
        } catch (error) {
            this._storagePort.collections.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchCollections,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async fetchById(
        params: CollectionsFetchByIdParams,
    ): Promise<Option<CollectionItem>> {
        try {
            this._errorService.clear(AirErrorKind.FetchCollectionById);
            this._storagePort.collections.isQueryInProgress.set(true);

            const res = await this._collectionsPort.fetchById(params);

            this._storagePort.collections.item.set(res);

            this._storagePort.collections.isQueryInProgress.set(false);

            return this._storagePort.collections.item.get();
        } catch (error) {
            this._storagePort.collections.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchCollectionById,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async create(
        params: CollectionsCreateParams,
    ): Promise<Option<CollectionInfo>> {
        try {
            this._errorService.clear(AirErrorKind.CreateCollection);
            this._storagePort.collections.isQueryInProgress.set(true);

            const res = await this._collectionsPort.create(params);

            this._storagePort.collections.isQueryInProgress.set(false);

            if (params.assets && params.assets.length > 0) {
                this._notificationService.show(
                    "File(s) successfully added to the new collection",
                    "success",
                );
            } else {
                this._notificationService.show(
                    "Collection has been created",
                    "success",
                );
            }

            return res;
        } catch (error) {
            this._storagePort.collections.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.CreateCollection,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async add(params: CollectionsAddParams): Promise<Option<void>> {
        try {
            this._errorService.clear(AirErrorKind.AddToCollection);
            this._storagePort.collections.isQueryInProgress.set(true);

            await this._collectionsPort.add(params);

            this._storagePort.collections.isQueryInProgress.set(false);
            this._notificationService.show(
                "File(s) successfully added to the collection",
                "success",
            );
        } catch (error) {
            this._storagePort.collections.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.AddToCollection,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async edit(params: CollectionsEditParams): Promise<Option<CollectionInfo>> {
        try {
            this._errorService.clear(AirErrorKind.EditCollection);
            this._storagePort.collections.isQueryInProgress.set(true);

            const res = await this._collectionsPort.edit(params);

            this._storagePort.collections.isQueryInProgress.set(false);

            return res;
        } catch (error) {
            this._storagePort.collections.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.EditCollection,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async delete(params: CollectionsDeleteParams): Promise<void> {
        try {
            this._errorService.clear(AirErrorKind.DeleteCollection);
            this._storagePort.collections.isQueryInProgress.set(true);

            const res = await this._collectionsPort.delete(params);

            this._storagePort.collections.isQueryInProgress.set(false);
            this._notificationService.show(
                "Collection successfully deleted",
                "success",
            );

            return res;
        } catch (error) {
            this._storagePort.collections.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.DeleteCollection,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async deleteFiles(info: CollectionsDeleteFilesParams): Promise<void> {
        try {
            this._errorService.clear(AirErrorKind.DeleteFromCollection);
            this._storagePort.collections.isQueryInProgress.set(true);

            const res = await this._collectionsPort.deleteFiles(info);

            this._storagePort.collections.isQueryInProgress.set(false);

            this._notificationService.show(
                "File(s) successfully deleted from collection",
                "success",
            );

            return res;
        } catch (error) {
            this._storagePort.collections.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.DeleteFromCollection,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}
