import React from "react";
import "./appNotifications.css";
import NotificationToastsContainer from "../../components/NotificationToastsContainer/NotificationToastsContainer";
import { state } from "../../state/stateAdapter";
import {
    Text,
    Toolbar,
    ToolbarButton,
    ToolbarGroup,
} from "@fluentui/react-components";
import noNewNotificationsDark from "../../assets/NoNewNotifications_dark.svg";
import noNewNotificationsLight from "../../assets/NoNewNotifications_light.svg";
import { AlertOff } from "../../components/BundledIcons";

function onHide(id: string): void {
    state.drawerNotifications.update(s => s.delete(id));
}

function NoNotifications(): JSX.Element {
    const isDarkTheme = state.useState(state.theme).isDark;

    return (
        <div className="app-notifications__no-any">
            <Text weight="medium">You&apos;re all caught up!</Text>
            <Text>You have no new notifications</Text>
            <img
                height={124}
                width={124}
                src={
                    isDarkTheme
                        ? noNewNotificationsDark
                        : noNewNotificationsLight
                }
                alt="no new notifications"
            />
        </div>
    );
}

function onClear(): void {
    state.drawerNotifications.update(s => s.clear());
}

function AppNotifications(): JSX.Element {
    const notifications = state.useState(state.drawerNotifications);

    if (notifications.size > 0) {
        return (
            <div className="app-notifications">
                <Toolbar aria-label="Notifications Command bar">
                    <ToolbarGroup className="app-notification__toolbar">
                        <ToolbarButton
                            aria-label="Dismiss all notifications"
                            appearance="subtle"
                            icon={<AlertOff />}
                            onClick={onClear}
                            disabled={notifications.size === 0}
                        >
                            Dismiss all
                        </ToolbarButton>
                    </ToolbarGroup>
                </Toolbar>

                <NotificationToastsContainer
                    notificationsStorage={state.drawerNotifications}
                    onHide={onHide}
                    className="app-notifications__container"
                />
            </div>
        );
    }

    return <NoNotifications />;
}

export default AppNotifications;
