import React from "react";
import { state } from "../../state/stateAdapter";
import "./app-dialog.css";

function AppDialog(): JSX.Element {
    const appDialog = state.useState(state.appDialog);

    if (appDialog !== null && appDialog !== undefined) {
        return appDialog();
    }

    return <></>;
}

export default AppDialog;
