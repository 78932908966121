import { AirErrorKind } from "../../application/airError/airError";
import { AppInterfacePort } from "../../application/appInterface/appInterfacePort";
import { useError } from "../../application/useCases/useError";
import { useNotification } from "../../application/useCases/useNotification";

// TODO: create ui interface adapter instead
export class InterfaceAdapter implements AppInterfacePort {
    navigate(location: string): void {
        window.location = location as string & Location;
    }

    getCurrentLocation(): string {
        return window.location.href;
    }

    reload(): void {
        window.location.reload();
    }

    async copyToClipboard(text: string): Promise<void> {
        if (!navigator.clipboard) {
            throw new Error("Cipboard is not available");
        }

        try {
            await navigator.clipboard.writeText(text);

            useNotification.show("Copied to clipboard", "success");
        } catch (error) {
            const airError = useError.createAirError(
                AirErrorKind.Copy,
                error as Error,
            );
            useNotification.show(airError.toString(), "error");
            useError.save(airError);
        }
    }
}
