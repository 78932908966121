import React from "react";
import {
    Text,
    Button,
    ButtonProps,
    makeStyles,
    tokens,
    shorthands,
    mergeClasses,
} from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    tag: {
        maxWidth: "100%",
        minWidth: "0",
        ...shorthands.padding("1px", "2px", "1px", "6px"),
    },
    tagDisabled: {
        color: tokens.colorNeutralForeground3,
        ...shorthands.borderColor(tokens.colorNeutralStroke2),
        ...shorthands.padding("1px", "6px"),
        "&:hover": {
            color: tokens.colorNeutralForeground3,
            ...shorthands.borderColor(tokens.colorNeutralStroke2),
        },
        "&:active:hover": {
            color: tokens.colorNeutralForeground3,
            ...shorthands.borderColor(tokens.colorNeutralStroke2),
        },
    },
});

function ButtonTag(props: ButtonProps): JSX.Element {
    const classes = useStyles();

    return (
        <Button
            className={mergeClasses(
                classes.tag,
                props.disabled ? classes.tagDisabled : "",
            )}
            {...props}
            size="small"
            shape="circular"
            appearance="primary"
            icon={props.disabled ? null : <Dismiss12Regular />}
            iconPosition="after"
        >
            <Text truncate block wrap={false}>
                {props.children}
            </Text>
        </Button>
    );
}

export default ButtonTag;
