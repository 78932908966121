import qs from "qs";
import { AirFile } from "../../domain/airFile/airFile";
import { apiCall, handleNotOk, validateRes } from "../api/api";
import {
    FilesChangeTierResult,
    FilesDownloadParams,
    FilesPort,
    FilesFetchParams,
    FilesItemPermissions,
    FilesRePermissionParams,
    FilesSearchParams,
    FilesSearchResult,
    FilesGetSharingLinkParams,
    FilesGetItemPermissionParams,
    FilesDownloadToDesktopParams,
    FilesChangeTierParams,
    FilesGetDetailsParams,
    FilesEditTagsParams,
    FilesTagsSearchParams,
    FilesEditBatchTagsParams,
    FilesGetTagGroupsResult,
    FilesSearchTagsResult,
    FilesDownloadToDesktopSingleParams,
    FilesDownloadToDesktopSingleResult,
    FilesGetTiersParams,
    FilesGetTiersResult,
} from "../../application/files/filesPort";
import { AirFileDetails } from "../../domain/airFile/details";
import { SharingLink } from "../../domain/sharingLink/sharingLink";
import { Job } from "../../domain/job/job";

export class FilesAdapter implements FilesPort {
    async fetch(params: FilesFetchParams): Promise<AirFile[]> {
        const payload = {
            folderHierarchy: params.folderHierarchy,
            ids: params.ids,
        };

        const res = await apiCall("/api/files", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(payload),
        });

        return await validateRes(res);
    }

    async getSharingLink(
        params: FilesGetSharingLinkParams,
    ): Promise<SharingLink> {
        const res = await apiCall("/api/files/share", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async getItemPermissions(
        params: FilesGetItemPermissionParams,
    ): Promise<FilesItemPermissions> {
        const res = await apiCall(`/api/files/${params.id}/permissions`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async rePermission(params: FilesRePermissionParams): Promise<void> {
        const res = await apiCall("/api/files/re-permission", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        handleNotOk(res);
    }

    async download(params: FilesDownloadParams): Promise<Job> {
        const res = await apiCall("/api/files/download", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async downloadToDesktop(
        params: FilesDownloadToDesktopParams,
    ): Promise<{ blob: Blob; contentDisposition: string }> {
        const res = await apiCall("/api/files/download-local", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        if (!res.ok) {
            throw new Error(`${res.status}: ${res.statusText}`);
        }

        const contentDisposition = res.headers.get("content-disposition") || "";
        const blob = await res.blob();

        return { blob, contentDisposition };
    }

    async changeTier(
        params: FilesChangeTierParams,
    ): Promise<FilesChangeTierResult> {
        const res = await apiCall("/api/files/tier", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async getDetails(params: FilesGetDetailsParams): Promise<AirFileDetails> {
        const res = await apiCall(`/api/files/details?id=${params.id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async editTags(params: FilesEditTagsParams): Promise<void> {
        const res = await apiCall("/api/tags/apply", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        handleNotOk(res);
    }

    async editBatchTags(params: FilesEditBatchTagsParams): Promise<void> {
        const res = await apiCall("/api/tags/batch-apply", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        handleNotOk(res);
    }

    async getTagGroups(): Promise<FilesGetTagGroupsResult> {
        const res = await apiCall(`/api/tag-groups`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async searchTags(
        params: FilesTagsSearchParams,
    ): Promise<FilesSearchTagsResult> {
        const res = await apiCall(`/api/tags/search?${qs.stringify(params)}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async search(params: FilesSearchParams): Promise<FilesSearchResult> {
        const res = await apiCall(`/api/files/search?${qs.stringify(params)}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        return await validateRes(res);
    }

    async downloadToDesktopSingle(
        params: FilesDownloadToDesktopSingleParams,
    ): Promise<FilesDownloadToDesktopSingleResult> {
        const res = await apiCall("/api/files/download-local/link", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }

    async getTiers(params: FilesGetTiersParams): Promise<FilesGetTiersResult> {
        const res = await apiCall("/api/files/download/details", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await validateRes(res);
    }
}
