import { AdminAdapter } from "../../infrastructure/admin/adminAdapter";
import { AdminService } from "../admin/adminService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useAdmin = new AdminService(
    new AdminAdapter(),
    useError,
    useStorage.admin,
    useNotification,
    useStorage,
);
