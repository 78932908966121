import {
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    Link,
    TableCellActions,
    Text,
    Button,
} from "@fluentui/react-components";
import { ArrowDownloadRegular, InfoRegular } from "@fluentui/react-icons";
import React, { useCallback } from "react";
import { AirErrorKind } from "../../../../application/airError/airError";
import { useStorage } from "../../../../application/useCases/useStorage";
import { protectedDataParamsKeys } from "../../../../domain/dataParams/dataParams";
import { formatSize } from "../../../../utility";
import FilesTable from "../../../components/FilesTable/FilesTable";
import IconItem from "../../../components/IconItem/IconItem";
import Tiles from "../../../components/Tiles/Tiles";
import { FilesViewModeKind } from "../../../models";
import { router } from "../../../router";
import { RouteParamsSearch } from "../../../router/urlEntities";
import { state } from "../../../state/stateAdapter";
import shareAccessState from "../../shareAccessAppState";
import { useEventBus } from "../../../../application/useCases/useEventBus";
import { ShareAccessEvents } from "../../shareAccessEventBus";
import {
    AirFile,
    isFile,
    isContainer,
} from "../../../../domain/airFile/airFile";
import { formatDate } from "../../../utility/formatters";

enum ColumnsId {
    Title = "title",
    FileType = "fileType",
    StorageTier = "storageTier",
    Size = "size",
    DateUpdated = "dateUpdated",
}

const columnSizes = new Map<ColumnsId, number>([
    [ColumnsId.FileType, 140],
    [ColumnsId.StorageTier, 120],
    [ColumnsId.Size, 140],
    [ColumnsId.DateUpdated, 120],
]);

const baseColumns: TableColumnDefinition<AirFile>[] = [
    createTableColumn<AirFile>({
        columnId: ColumnsId.Title,
        renderHeaderCell: () => (
            <TableCellLayout>
                <Text>Title</Text>
            </TableCellLayout>
        ),
        renderCell: item => {
            const isItemFile = isFile(item);

            let cellActionsCount: number = 0;

            if (!isContainer(item)) {
                cellActionsCount++;
            }

            if (isItemFile) {
                cellActionsCount += 2;
            }

            return (
                <>
                    <TableCellLayout
                        truncate
                        className="cell-layout"
                        style={{
                            paddingRight: `${32 * cellActionsCount}px`,
                        }}
                        media={
                            <IconItem
                                filePath={item.filePath}
                                fileType={item.fileType}
                                isFile={isItemFile}
                                className="image_table-cell"
                            />
                        }
                    >
                        {isItemFile ? (
                            <Link
                                appearance="subtle"
                                href={router.createRoute({
                                    search: {
                                        items: [
                                            {
                                                key: "activeItem",
                                                value: item.id.toString(),
                                            },
                                        ],
                                    },
                                })}
                                data-route
                            >
                                <Text truncate block wrap={false}>
                                    {item.title}
                                </Text>
                            </Link>
                        ) : (
                            <Link
                                as="a"
                                href={router.createRoute({
                                    search: {
                                        items: [
                                            {
                                                key: "path",
                                                value: item.hierarchy,
                                            },
                                        ],
                                        protect: [
                                            ...protectedDataParamsKeys,
                                            "viewMode",
                                        ],
                                    },
                                })}
                                appearance="subtle"
                                data-route
                            >
                                <Text truncate block wrap={false}>
                                    {item.title}
                                </Text>
                            </Link>
                        )}
                    </TableCellLayout>

                    <TableCellActions className="cell-action">
                        {isItemFile ? (
                            <>
                                <Button
                                    as="a"
                                    icon={<InfoRegular />}
                                    appearance="subtle"
                                    href={router.createRoute({
                                        search: {
                                            items: [
                                                {
                                                    key: "activeItem",
                                                    value: item.id.toString(),
                                                },
                                            ],
                                        },
                                    })}
                                    data-route
                                    title="File details"
                                />

                                <Button
                                    icon={<ArrowDownloadRegular />}
                                    appearance="subtle"
                                    title="Download item"
                                    onClick={(): void => {
                                        useEventBus.emit(
                                            ShareAccessEvents.DownloadItem,
                                            {
                                                id: item.id,
                                            },
                                        );
                                    }}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </TableCellActions>
                </>
            );
        },
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.FileType,
        renderHeaderCell: () => {
            return "Type";
        },
        renderCell: item => (
            <Text truncate block wrap={false} title={item.fileType}>
                {item.fileType}
            </Text>
        ),
    }),
];

const allCollumns: TableColumnDefinition<AirFile>[] = [
    ...baseColumns,
    createTableColumn<AirFile>({
        columnId: ColumnsId.StorageTier,
        renderHeaderCell: () => {
            return "Storage tier";
        },
        renderCell: item =>
            item.storageTier !== null && isFile(item) ? (
                <Text truncate block wrap={false} title={item.storageTier}>
                    {item.storageTier}
                </Text>
            ) : (
                <></>
            ),
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.Size,
        renderHeaderCell: () => {
            return "Size";
        },
        renderCell: item => (
            <Text truncate block wrap={false} title={item.fileSize?.toString()}>
                {formatSize(item.fileSize ?? 0)}
            </Text>
        ),
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.DateUpdated,
        renderHeaderCell: () => {
            return "Last modified";
        },
        renderCell: item => (
            <Text
                truncate
                block
                wrap={false}
                title={formatDate(item.dateUpdated) || undefined}
            >
                {formatDate(item.dateUpdated)}
            </Text>
        ),
    }),
];

type ShareAccessContentProps = {
    files: AirFile[];
};

function ShareAccessContent(props: ShareAccessContentProps): JSX.Element {
    const activeDirectory = state.useState(shareAccessState.activeDirectory);
    const errors = state.useState(useStorage.airErrors);
    const filesViewMode = state.useState(shareAccessState.filesViewMode);

    const error =
        errors.get(AirErrorKind.SharingGetFilesErr) ??
        errors.get(AirErrorKind.SharingGetFileDetailsErr);
    null;

    const isStorageMode = props.files.some(
        i => i.fileType === "storage" || i.fileType === "container",
    );

    const columns = isStorageMode ? baseColumns : allCollumns;

    const createLink = useCallback((item: AirFile, isFile: boolean): string => {
        const linkParams: RouteParamsSearch = isFile
            ? {
                  items: [
                      {
                          key: "activeItem",
                          value: item.id.toString(),
                      },
                  ],
              }
            : {
                  items: [
                      {
                          key: "path",
                          value: item.hierarchy,
                      },
                  ],
                  protect: [...protectedDataParamsKeys, "viewMode"],
              };
        const link = router.createRoute({ search: linkParams });

        return link;
    }, []);

    if (filesViewMode === FilesViewModeKind.Tiles) {
        return (
            <Tiles
                activeDirectory={activeDirectory || ""}
                isFile={isFile}
                createLink={createLink}
                isQueryInProgress={false}
                error={error ? error.info.message : undefined}
                files={props.files}
                filesSelection={new Map()}
                selectable={false}
                onSelect={(): undefined => undefined}
                onSingleSelect={(): undefined => undefined}
            />
        );
    }

    return (
        <FilesTable
            isQueryInProgress={false}
            error={error ? error.info.message : undefined}
            files={props.files}
            columns={columns as TableColumnDefinition<unknown>[]}
            columnSizes={columnSizes}
        />
    );
}

export default ShareAccessContent;
