import { mimeTypes, defaultMimeType } from "../models/mimes";

function getIconPathService(iconType: string): string {
    return `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${iconType}.svg`;
}

export function getIconPath(ext: string, typeKeys: string[]): string {
    let iconPath = getIconPathService(defaultMimeType);

    if (!ext.length) {
        return iconPath;
    }

    if (ext === "folder") {
        return getIconPathService("folder");
    }

    typeKeys.forEach(t => {
        if (mimeTypes[t].extensions.includes(ext.toLowerCase())) {
            iconPath = getIconPathService(t);
        }
    });

    return iconPath;
}
