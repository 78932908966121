import React from "react";
import { useCommonFluentuiStyles } from "../../../styles/griffel";
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from "@fluentui/react-components";
import { state } from "../../../state/stateAdapter";

type DeleteFilesFromCollectionDialogProps = {
    title: string;
    WarningText: () => JSX.Element;
    onDelete: () => Promise<void>;
};

function DeleteFilesFromCollectionDialog(
    props: DeleteFilesFromCollectionDialogProps,
): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    state.appDialog.set(null);
                }
            }}
            open={true}
        >
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle>{props.title}</DialogTitle>
                    <DialogContent>
                        <props.WarningText />
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                className={commonClasses.dangerBackground}
                                onClick={props.onDelete}
                            >
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default DeleteFilesFromCollectionDialog;
