import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Divider,
    Subtitle2,
    Text,
    Toolbar,
    ToolbarButton,
    makeStyles,
    shorthands,
} from "@fluentui/react-components";
import {
    DocumentLockRegular,
    ResizeRegular,
    RotateLeftRegular,
    ScreenSearch20Regular,
} from "@fluentui/react-icons";
import React, { RefObject, useCallback, useRef, useState } from "react";
import "./fileDetails.css";
import { state } from "../../state/stateAdapter";
import { useStorage } from "../../../application/useCases/useStorage";
import Loader from "../Loader/Loader";
import { onOpenPermissions } from "../../app/MiniApp/shared/PermissionsDialog/PermissionsDialog";
import FullScreenImage from "../FullScreenImage/FullscreenImage";
import VideoExtendedView from "./VideoExtendedView/VideoExtendedView";
import FileInformation from "./FileInformation/FileInformation";
import FileTags from "./FileTags/FileTags";
import { useCognitiveMetadata } from "../../../application/useCases/useCognitiveMetadata";
import { AirFile } from "../../../domain/airFile/airFile";

const useStyles = makeStyles({
    header: {
        display: "grid",
        paddingLeft: 0,
        paddingRight: 0,
    },
    toolbar: {
        ...shorthands.padding(0),
    },
    accordionHeader: {
        "& > button": {
            ...shorthands.border(0),
            ...shorthands.padding(0),
        },
    },
    accordionPanel: {
        paddingBottom: "20px",
        ...shorthands.margin(0),
    },
    toolbarBtn: {
        "@media screen and (max-width: 460px)": {
            minWidth: 0,
            ...shorthands.padding("5px"),
            "& span": {
                "--fui-Button__icon--spacing": 0,
            },
        },
    },
});

function onRotate(id: number): void {
    const image = document.getElementById(`${id}`);

    if (image) {
        const currentRotation =
            parseInt(image.style.transform.replace(/[^0-9-]/g, "")) || 0;
        const newRotation = currentRotation - 90;
        image.style.transform = `rotate(${newRotation}deg)`;
    }
}

type FileDetailsPreviewProps = {
    videoRef?: RefObject<HTMLVideoElement>;
};

export function FilePreview(
    props: FileDetailsPreviewProps,
): JSX.Element | null {
    const airFileDetails = state.useState(useStorage.files.fileDetails);

    if (
        airFileDetails &&
        airFileDetails.mediaPreview !== null &&
        airFileDetails.mediaPreview.thumbnailUrl
    ) {
        return (
            <div className="file-details__preview">
                {airFileDetails.fileType !== null &&
                airFileDetails.fileType.toLocaleLowerCase() === "video" ? (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                        controls
                        className="file-details__thumbnail"
                        poster={airFileDetails.mediaPreview.thumbnailUrl}
                        ref={props.videoRef}
                        key={
                            airFileDetails.mediaPreview.previewUrl ??
                            airFileDetails.mediaPreview.thumbnailUrl
                        }
                        src={
                            airFileDetails.mediaPreview.previewUrl ??
                            airFileDetails.mediaPreview.thumbnailUrl
                        }
                    />
                ) : (
                    <img
                        className="file-details__thumbnail"
                        src={airFileDetails.mediaPreview.thumbnailUrl}
                        alt="item thumbnail"
                        id={`${airFileDetails.id}`}
                    />
                )}
            </div>
        );
    }

    return null;
}

function FileDetails(): JSX.Element | null {
    const classes = useStyles();
    const videoRef = useRef<HTMLVideoElement>(null);
    const activeItem = state.useState(
        useStorage.fileExplorerMiniApp.activeItem,
    );
    const airFileDetails = state.useState(useStorage.files.fileDetails);
    const isFileDetailsQueryInProgress = state.useState(
        useStorage.files.isFileDetailsQueryInProgress,
    );
    const [isExtendedViewOpen, setIsExtendedViewOpen] = useState(false);
    const [isFullScreenImageOpen, setIsFullScreenImageOpen] = useState(false);
    const isFetchCognitiveQueryInProgress = state.useState(
        useStorage.cognitiveMetadata.isQueryInProgress,
    );
    const cognitiveMetadata = state.useState(
        useStorage.cognitiveMetadata.metadata,
    );

    const hasCognitive =
        !useCognitiveMetadata.isEmptyInsights(cognitiveMetadata);

    const toggleFullScreenImage = useCallback((): void => {
        setIsFullScreenImageOpen(!isFullScreenImageOpen);
    }, [isFullScreenImageOpen]);

    if (isFileDetailsQueryInProgress) {
        return <Loader text="Loading..." size="small" />;
    }

    if (!activeItem || !airFileDetails) {
        return (
            <Text
                style={{
                    height: "100%",
                    width: "100%",
                    display: "grid",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                File not found
            </Text>
        );
    }

    return (
        <div className="file-details">
            <div className="file-details__header">
                <Text truncate block wrap={false} title={airFileDetails.title}>
                    {airFileDetails.title}
                </Text>

                <FilePreview videoRef={videoRef} />

                <Toolbar className={classes.toolbar}>
                    <ToolbarButton
                        aria-label="Permissions"
                        appearance="subtle"
                        className={classes.toolbarBtn}
                        icon={<DocumentLockRegular />}
                        onClick={(): void =>
                            onOpenPermissions(
                                airFileDetails as unknown as AirFile,
                            )
                        }
                        title="Edit permissions"
                    >
                        <span className="file-details__btn-text">
                            Permissions
                        </span>
                    </ToolbarButton>

                    {airFileDetails.mediaPreview !== null &&
                    airFileDetails.mediaPreview.thumbnailUrl &&
                    airFileDetails.fileType !== null &&
                    airFileDetails.fileType.toLocaleLowerCase() !== "video" ? (
                        <>
                            <ToolbarButton
                                appearance="subtle"
                                icon={<RotateLeftRegular />}
                                onClick={(): void =>
                                    onRotate(airFileDetails.id)
                                }
                                title="Rotate preview"
                            />

                            {isFullScreenImageOpen ? (
                                <FullScreenImage
                                    path={
                                        airFileDetails.mediaPreview.thumbnailUrl
                                    }
                                    title={airFileDetails.title}
                                    isOpen={isFullScreenImageOpen}
                                    toggleFullScreenImage={
                                        toggleFullScreenImage
                                    }
                                />
                            ) : (
                                <ToolbarButton
                                    appearance="subtle"
                                    onClick={toggleFullScreenImage}
                                    icon={<ResizeRegular />}
                                    title="Enlarge preview"
                                />
                            )}
                        </>
                    ) : null}

                    {airFileDetails.fileType !== null &&
                        airFileDetails.fileType.toLocaleLowerCase() ===
                            "video" && (
                            <>
                                <ToolbarButton
                                    icon={<ScreenSearch20Regular />}
                                    className={classes.toolbarBtn}
                                    disabled={!hasCognitive}
                                    title={
                                        isFetchCognitiveQueryInProgress
                                            ? "Loading..."
                                            : hasCognitive
                                            ? "View insights"
                                            : "No insights"
                                    }
                                    onClick={(): void => {
                                        setIsExtendedViewOpen(true);
                                    }}
                                >
                                    <span className="file-details__btn-text">
                                        Extended view
                                    </span>
                                </ToolbarButton>
                                {isExtendedViewOpen &&
                                    airFileDetails.mediaPreview &&
                                    hasCognitive && (
                                        <VideoExtendedView
                                            airFileDetails={airFileDetails}
                                            cognitiveMetadata={
                                                cognitiveMetadata
                                            }
                                            isOpen={isExtendedViewOpen}
                                            onClose={(): void => {
                                                setIsExtendedViewOpen(false);
                                            }}
                                        />
                                    )}
                            </>
                        )}
                </Toolbar>

                <Divider />
            </div>
            <div className="file-details__body">
                <Accordion
                    multiple
                    collapsible
                    defaultOpenItems={["information", "tags", "personas"]}
                >
                    <AccordionItem value="information">
                        <AccordionHeader
                            expandIconPosition="end"
                            className={classes.accordionHeader}
                        >
                            <Subtitle2>Information</Subtitle2>
                        </AccordionHeader>
                        <AccordionPanel className={classes.accordionPanel}>
                            <FileInformation fileDetails={airFileDetails} />
                        </AccordionPanel>
                    </AccordionItem>
                    {/* <AccordionItem value="exif" disabled>
                        <AccordionHeader
                            expandIconPosition="end"
                            className={classes.accordionHeader}
                        >
                            <Subtitle2>EXIF</Subtitle2>
                        </AccordionHeader>
                        <AccordionPanel
                            className={classes.accordionPanel}
                        ></AccordionPanel>
                    </AccordionItem> */}
                    <AccordionItem value="tags">
                        <AccordionHeader
                            expandIconPosition="end"
                            className={classes.accordionHeader}
                        >
                            <Subtitle2>Tags</Subtitle2>
                        </AccordionHeader>
                        <AccordionPanel className={classes.accordionPanel}>
                            <FileTags
                                fileDetails={airFileDetails}
                                canEdit={true}
                            />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
}

export default FileDetails;
