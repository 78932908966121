import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import {
    CognitiveMetadata,
    CognitiveMetadataGetParams,
    CognitiveMetadataPort,
    CustomInsight,
    Insights,
} from "./cognitiveMetadataPort";
import { AirErrorService } from "../airError/airErrorService";
import { AirErrorKind } from "../airError/airError";
import { Option } from "../../domain/types/types";

export class CognitiveMetadataService {
    private readonly _cognitiveMetadataPort: CognitiveMetadataPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        cognitiveMetadataPort: CognitiveMetadataPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._cognitiveMetadataPort = cognitiveMetadataPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    private _replaceTimeSeparator(custom: {
        common: Record<string, CustomInsight[]>;
        timeline: Record<string, CustomInsight[]>;
    }): {
        common: Record<string, CustomInsight[]>;
        timeline: Record<string, CustomInsight[]>;
    } {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const replaceInObject = (obj: any): void => {
            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    obj[key].forEach((item: any) => replaceInObject(item));
                } else if (typeof obj[key] === "object") {
                    replaceInObject(obj[key]);
                } else if (
                    typeof obj[key] === "string" &&
                    (key === "startTime" || key === "endTime")
                ) {
                    obj[key] = obj[key].replace(/:(?=\d{2}$)/, ".");
                    const split = obj[key].split(":");

                    if (split.length === 2) {
                        obj[key] = `00:${obj[key]}`;
                    }
                }
            }
        };

        replaceInObject(custom.common);
        replaceInObject(custom.timeline);
        return custom;
    }

    isEmptyInsights(cognitiveMetadata: Option<CognitiveMetadata>): boolean {
        if (cognitiveMetadata === null || cognitiveMetadata === undefined) {
            return true;
        }

        const insights = cognitiveMetadata.insights;
        for (const key in insights) {
            if (insights[key as keyof Insights] !== null) {
                return false;
            }
        }

        return true;
    }

    async getCognitiveMetadata(
        params: CognitiveMetadataGetParams,
    ): Promise<Option<CognitiveMetadata>> {
        try {
            this._errorService.clear(AirErrorKind.FetchCognitiveMetadata);
            this._storagePort.cognitiveMetadata.isQueryInProgress.set(true);
            this._storagePort.cognitiveMetadata.metadata.set(null);

            const metadata =
                await this._cognitiveMetadataPort.getCognitiveMetadata(params);

            if (metadata.insights.custom) {
                metadata.insights.custom = this._replaceTimeSeparator(
                    metadata.insights.custom,
                );
            }

            this._storagePort.cognitiveMetadata.metadata.set(metadata);
            this._storagePort.cognitiveMetadata.isQueryInProgress.set(false);

            return this._storagePort.cognitiveMetadata.metadata.get();
        } catch (error) {
            this._storagePort.cognitiveMetadata.isQueryInProgress.set(false);

            if ((error as Error).message.slice(0, 3) == "404") {
                return;
            }

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchCognitiveMetadata,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}
