import React, { useState, useCallback, ChangeEvent } from "react";
import {
    Caption2,
    Text,
    Button,
    Image,
    Link,
    tokens,
    shorthands,
    makeStyles,
    mergeClasses,
    Field,
    Caption1,
} from "@fluentui/react-components";
import { ImageRegular, FolderOpenRegular } from "@fluentui/react-icons";
import { getExt } from "../../IconItem/IconItem";

import "./imageField.css";
import { formatSize } from "../../../../utility";

const useStyles = makeStyles({
    input: {
        "&:focus + .image-field, &:active + .image-field, &:hover + .image-field":
            {
                ...shorthands.borderColor(tokens.colorBrandForeground1),
                backgroundColor: tokens.colorBrandBackground2,
            },
    },
    card: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.border("1px", "dashed", tokens.colorNeutralBackground4),
        paddingTop: tokens.spacingVerticalL,
        paddingBottom: tokens.spacingVerticalL,
        paddingLeft: tokens.spacingHorizontalXXL,
        paddingRight: tokens.spacingHorizontalXXL,
    },
    cardError: {
        ...shorthands.border("1px", "solid", tokens.colorPaletteRedBorder2),
    },
    img: {
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
    },
    error: {
        color: tokens.colorPaletteRedForeground1,
    },
});

type ImageFieldProps = {
    value?: string;
    accept: string[];
    name: string;
    size?: number;
    onChange: (name: string, file: File | undefined) => Promise<void>;
};

function ImageField(props: ImageFieldProps): JSX.Element {
    const inputFileRef = React.useRef<HTMLInputElement | null>(null);
    const [error, setError] = useState<string>();
    const [selectedFile, setSelectedFile] = useState<File>();
    const classes = useStyles();
    const allowedExtensions = props.accept.map(i => i.toLowerCase());

    async function onChangeFile(
        e: ChangeEvent<HTMLInputElement>,
    ): Promise<void> {
        if (e.target.files !== null && e.target.files[0]) {
            const item = e.target.files[0];
            const ext = getExt(item.name);
            let isValid = true;

            setSelectedFile(item);

            if (props.size && item.size > props.size) {
                setError("File size exceeds limit");
                isValid = false;
            }

            if (!allowedExtensions.includes(ext.toLowerCase())) {
                isValid = false;
                setError("Invalid file type");
            }

            if (isValid === true) {
                setError(undefined);
                props.onChange(props.name, item);
            } else {
                props.onChange(props.name, undefined);
            }
        } else {
            props.onChange(props.name, undefined);
        }
    }

    const openFileChoiceDialog = useCallback((): void => {
        if (inputFileRef.current !== null) {
            inputFileRef.current.click();
        }
    }, []);

    return (
        <Field
            className="image-field"
            validationState={error ? "error" : "none"}
            validationMessage={error ? error : null}
        >
            <input
                ref={inputFileRef}
                className={mergeClasses(classes.input, "image-field__input")}
                name={props.name}
                id={props.name}
                type="file"
                onChange={onChangeFile}
                value=""
                accept={props.accept.map(i => `.${i}`).join(",")}
            />
            <label
                className={mergeClasses(
                    classes.card,
                    "image-field__label",
                    error !== undefined ? classes.cardError : "",
                )}
                htmlFor={props.name}
            >
                <div className={mergeClasses(classes.img, "image-field__img")}>
                    {props.value !== undefined ? (
                        <Image
                            src={props.value}
                            alt={props.name}
                            shape="rounded"
                            fit="contain"
                            bordered
                        />
                    ) : (
                        <ImageRegular className="image-field__icon" />
                    )}
                </div>
                <div className="image-field__info">
                    <Text block>
                        Drag and drop or{" "}
                        <Link inline onClick={openFileChoiceDialog}>
                            choose file
                        </Link>{" "}
                        to upload
                    </Text>
                    <Caption2 block>
                        {props.accept ? props.accept.join(", ") : null}
                        {props.size
                            ? " not larger than " + formatSize(props.size)
                            : ""}
                    </Caption2>
                    <Caption1 className="image-filed__file" truncate>
                        {selectedFile
                            ? `${selectedFile.name} (${formatSize(
                                  selectedFile.size,
                              )})`
                            : "No uploaded file"}
                    </Caption1>
                </div>
                <Button
                    onClick={openFileChoiceDialog}
                    icon={<FolderOpenRegular />}
                >
                    Browse
                </Button>
            </label>
        </Field>
    );
}

export default ImageField;
