import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogTrigger,
    Button,
    Text,
} from "@fluentui/react-components";
import React from "react";
import { state } from "../../../../state/stateAdapter";

type ConfirmationDialogProps = {
    title: string;
    message: string;
    confirmationCb: () => void;
};

function ConfirmationDialog(props: ConfirmationDialogProps): JSX.Element {
    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    state.appDialog.set(null);
                }
            }}
            open={true}
        >
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle>{props.title}</DialogTitle>
                    <DialogContent>
                        <div className="app-dialog__content">
                            <div className="app-dialog__row-row">
                                <Text>{props.message}</Text>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Discard</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                className="btn_danger"
                                onClick={props.confirmationCb}
                            >
                                Confirm
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default ConfirmationDialog;
