import { FilesService } from "../files/filesService";
import { useError } from "./useError";
import { FilesAdapter } from "../../infrastructure/files/filesAdapter";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useFiles = new FilesService(
    new FilesAdapter(),
    useError,
    useStorage,
    useNotification,
);
