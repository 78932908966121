export type AirFileDetails = {
    id: number;
    filePath: string;
    title: string;
    fileType: string | null;
    dateUpdated: string | null;
    fileSize: number | null;
    storageTier: string | null;
    language: string | null;
    tagGroups: TagGroup[] | null;
    technicalMetadata: TechnicalMetadata | null;
    imageType: ImageType | null;
    mediaPreview: MediaPreview | null;
};

export type Person = {
    value: string;
    image: string | null;
    title: string | null;
    description: string | null;
    appearances?: Appearance[];
};

export type Appearance = {
    startTime: string;
    endTime: string;
};

export type TechnicalMetadata = {
    videoFormat: string | null;
    resolution: string | null;
    bitRate: string | null;
    duration: number | null;
};

export type MediaPreview = {
    previewUrl: string | null;
    thumbnailUrl: string | null;
    allThumbnails: [];
};

export type ImageType = {
    format: string | null;
    height: number | null;
    width: number | null;
};

export enum TagKind {
    AI = "ai",
    User = "user",
}

export type Tag = {
    name: string;
    tagSource: string;
    appearances?: Appearance[];
};

export type TagGroup = {
    id: number;
    name: string;
    isPredefined: boolean;
    isVisible: boolean;
    tags?: Tag[] | null;
};
