import { calculateOffset } from "../../utility";
import { Filters } from "../filter/filters";
import { Option } from "../types/types";
import { ContainerAirFileType } from "./airFileType";

export type AirFile = {
    id: number;
    hierarchy: string;
    filePath: string;
    title: string;
    locationId: number;
    fileType: string;
    fileTypeId: number;
    parentHierarchy: string;
    dateUpdated: string;
    fileSize: number;
    storageTier: string | null;
    storageName: string;
    containerName: string;
    localPath: string | null;
    thumbnailUrl: string | null;
};

export function filterFilesWithParent(
    files: AirFile[],
    curentHierarchy = "/",
): AirFile[] {
    if (curentHierarchy === "/") {
        return files;
    }

    return (
        files.filter(
            i =>
                i.parentHierarchy === curentHierarchy &&
                i.fileType !== "container" &&
                i.fileType !== "storage",
        ) ?? []
    );
}

export type SortKey =
    | "title"
    | "fileType"
    | "storageTier"
    | "size"
    | "dateUpdated";

export function sortFilesByType(
    files: AirFile[],
    key: SortKey,
    isSortedDescending: boolean,
): AirFile[] {
    return files.sort((a: AirFile, b: AirFile) => {
        if (key === "title") {
            if (a.title.toLowerCase() === b.title.toLowerCase()) {
                return 0;
            }

            return (
                isSortedDescending
                    ? a.title.toLowerCase() < b.title.toLowerCase()
                    : a.title.toLowerCase() > b.title.toLowerCase()
            )
                ? 1
                : -1;
        }

        if (key === "fileType") {
            if (a.fileType === b.fileType) {
                return 0;
            }

            return (
                isSortedDescending
                    ? a.fileType.toLowerCase() < b.fileType.toLowerCase()
                    : a.fileType.toLowerCase() > b.fileType.toLowerCase()
            )
                ? 1
                : -1;
        }

        if (key === "storageTier") {
            if (a.storageTier === b.storageTier) {
                return 0;
            }

            return (
                isSortedDescending
                    ? (a.storageTier ?? "").toLowerCase() <
                      (b.storageTier ?? "").toLowerCase()
                    : (a.storageTier ?? "").toLowerCase() >
                      (b.storageTier ?? "").toLowerCase()
            )
                ? 1
                : -1;
        }

        if (key === "size") {
            if (a.fileSize === b.fileSize) {
                return 0;
            }

            return (
                isSortedDescending
                    ? (a.fileSize ?? 0) < (b.fileSize ?? 0)
                    : (a.fileSize ?? 0) > (b.fileSize ?? 0)
            )
                ? 1
                : -1;
        }

        if (key === "dateUpdated") {
            if (a.dateUpdated === b.dateUpdated) {
                return 0;
            }

            return (
                isSortedDescending
                    ? (a.dateUpdated ?? 0) < (b.dateUpdated ?? 0)
                    : (a.dateUpdated ?? 0) > (b.dateUpdated ?? 0)
            )
                ? 1
                : -1;
        }

        return 0;
    });
}

export function prepareFilesToShow(
    files: AirFile[],
    filtersCollection: Option<Filters>,
    page: number,
    pageSize: number,
): AirFile[] {
    if (!filtersCollection || filtersCollection.size === 0) {
        const offset = calculateOffset(page, pageSize);
        return files.slice(offset, offset + pageSize);
    } else {
        return files;
    }
}

export function sanitizeFilename(content: string): string {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = content.match(filenameRegex);
    const filename = matches ? matches[1] : "file";

    return filename.replace(/['"]/g, "");
}

export function createBreadcrumbs(
    activeDirectory: string,
    files: AirFile[],
): AirFile[] {
    const breadcrumbs: AirFile[] = [];

    const hierarchyIds = activeDirectory.split("/").filter(id => id !== "");

    for (const id of hierarchyIds) {
        const item = files.find(item => item.id === parseInt(id));

        if (item) {
            breadcrumbs.push(item);
        }
    }

    return breadcrumbs;
}

export function isContainer(item: AirFile): boolean {
    return (
        item.fileType === ContainerAirFileType.Storage ||
        item.fileType === ContainerAirFileType.Container
    );
}

export function isFile(item: AirFile): boolean {
    return (
        !isContainer(item) &&
        item.fileType !== ContainerAirFileType.Folder &&
        item.fileType !== ContainerAirFileType.ExtractedArchive
    );
}
