import { DirectoryObjectsAdapter } from "../../infrastructure/directoryObjects/directoryObjectsAdapter";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { DirectoryObjectsService } from "../directoryObjects/directoryObjectsService";
import { useStorage } from "./useStorage";

export const useDirectoryObjects = new DirectoryObjectsService(
    new DirectoryObjectsAdapter(),
    useError,
    useNotification,
    useStorage,
);
