import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogTrigger,
    Button,
    Field,
    Input,
    makeStyles,
    Divider,
    tokens,
} from "@fluentui/react-components";
import React, { useCallback, useState } from "react";
import { state } from "../../../../../state/stateAdapter";
import { Tag } from "../../../../../../application/tags/TagsPort";
import { RendedSwitchField } from "../TagsGroupsFormPanel/TagsGroupsFormPanel";
import { Dismiss24Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    dialog: {
        maxWidth: "440px",
    },
    content: {
        display: "grid",
        rowGap: tokens.spacingVerticalL,
        marginBottom: tokens.spacingVerticalS,
    },
});

type CreateServiceAccountDialogProps = {
    title: string;
    item?: Tag;
    onApply: (tag: Tag) => void;
};

const initData = {
    id: 0,
    name: "",
    searchable: true,
};

function TagFormDialog(props: CreateServiceAccountDialogProps): JSX.Element {
    const [data, setData] = useState<Tag>(props.item ?? initData);
    const classes = useStyles();

    const updateData = useCallback(
        (name: string, value: string | number | undefined | boolean): void => {
            if (data !== undefined && data[name as keyof Tag] !== value) {
                setData({ ...data, [name]: value });
            }
        },
        [data],
    );

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    state.appDialog.set(null);
                }
            }}
            open={state.appDialog.get() !== null}
        >
            <DialogSurface
                className={classes.dialog}
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        {props.title}
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <Field label="Tag name" size="large">
                            <Input
                                value={data.name}
                                onChange={(_e, d): void => {
                                    updateData("name", d.value);
                                }}
                            />
                        </Field>

                        <RendedSwitchField
                            value={data.searchable}
                            name="searchable"
                            updateData={updateData}
                            title="Searchable"
                            text="Makes the tag to be searchable."
                        />

                        <Divider />
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                onClick={(_e): void => {
                                    if (data !== undefined) {
                                        props.onApply(data);
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default TagFormDialog;
