import React, { useState } from "react";
import { DismissRegular, SearchRegular } from "@fluentui/react-icons";
import Search from "../../../../components/Search/Search";
import { state } from "../../../../state/stateAdapter";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { router } from "../../../../router";
import {
    dataParamsKeys,
    defaultDataParams,
} from "../../../../../domain/dataParams/dataParams";
import { MiniAppKind } from "../../../../../domain/miniApp/miniApp";
import { Button, mergeClasses } from "@fluentui/react-components";
import { useStyles } from "../../../AppHeader/AppHeader";
import "./fileExplorerSearch.scss";

function onDismiss(): void {
    router.removeSearchParam("query", false);
}

async function onSearch(tempQuery: string): Promise<void> {
    if (tempQuery.length > 0) {
        router.removeSearchParam(dataParamsKeys.page, true);
        router.removeSearchParam("query", true);
        useStorage.fileExplorerMiniApp.dataParams.update(s => {
            s.page = defaultDataParams.page;
        });
        router.setSearchParams([{ key: "query", value: tempQuery }]);
    } else {
        router.removeSearchParam("query", false);
    }
}

function FileExplorerSearch(): JSX.Element {
    const classesHeader = useStyles();
    const query = state.useState(useStorage.files.searchQuery);
    const [isSearchHidden, setIsSearchHidden] = useState(true);
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );

    return (
        <>
            <div
                className={mergeClasses(
                    classesHeader.header,
                    "file-search__container",
                    isSearchHidden ? "file-search__container_hidden" : "",
                )}
            >
                <Search
                    query={query}
                    place={MiniAppKind.Explorer}
                    onSearch={onSearch}
                    onDismiss={onDismiss}
                    disabled={isFilesQueryInProgress}
                />
                <div className="file-search__btn-toggle">
                    <Button
                        appearance="transparent"
                        shape="square"
                        icon={
                            <DismissRegular
                                className={classesHeader.whiteClr}
                            />
                        }
                        onClick={(): void => {
                            setIsSearchHidden(true);
                        }}
                        title="Search close"
                    />
                </div>
            </div>
            <div className="file-search__btn-toggle">
                <Button
                    appearance="transparent"
                    shape="square"
                    className={mergeClasses(
                        classesHeader.button,
                        classesHeader.commandButton,
                    )}
                    icon={<SearchRegular className={classesHeader.whiteClr} />}
                    onClick={(): void => {
                        setIsSearchHidden(false);
                    }}
                    title="Search"
                />
            </div>
        </>
    );
}

export default FileExplorerSearch;
