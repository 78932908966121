import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import {
    BrandingConfig,
    Config,
    ConfigKey,
    ShareLinkUIConfig,
    UploaderConfig,
} from "./config";
import { ConfigPort } from "./configPort";

export class ConfigService {
    private readonly _configPort: ConfigPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        configPort: ConfigPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._configPort = configPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    async getConfig(): Promise<Option<Config>> {
        try {
            this._errorService.clear(AirErrorKind.FetchConfig);
            this._storagePort.config.isQueryInProgress.set(true);

            const res = await this._configPort.getConfig();

            this._storagePort.config.config.set(res);
            this._storagePort.config.isQueryInProgress.set(false);

            return this._storagePort.config.config.get();
        } catch (error) {
            this._storagePort.config.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchConfig,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async updateConfigByKey(
        configKey: ConfigKey,
        newConfig: UploaderConfig | ShareLinkUIConfig | BrandingConfig,
    ): Promise<void> {
        if (
            configKey !== ConfigKey.Uploader &&
            configKey !== ConfigKey.Sharing &&
            configKey !== ConfigKey.Branding
        ) {
            const airError = this._errorService.createAirError(
                AirErrorKind.AdminUpdateConfig,
                new Error("Invalid config key"),
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }

        this._storagePort.config.config.update(s => {
            if (configKey === ConfigKey.Uploader) {
                s.features.uploader = newConfig as UploaderConfig;
            } else if (configKey === ConfigKey.Sharing) {
                s.features.shareLinkUiConfiguration =
                    newConfig as ShareLinkUIConfig;
            } else if (configKey === ConfigKey.Branding) {
                s = { ...s, ...newConfig };
            }
        });
    }
}
