export function formatBytes(bytes: number, decimals = 2): string {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function formatDate(date?: Date | string | null): string | null {
    if (!date) {
        return null;
    }

    return new Date(date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
    });
}

export function formatDuration(ms: number): string {
    let result = "";

    const hours = Math.floor(ms / 3600000);
    const minutes = Math.floor((ms % 3600000) / 60000);
    const seconds = Math.floor(((ms % 3600000) % 60000) / 1000);

    if (hours > 0) {
        result += `${hours} hour${hours > 1 ? "s" : ""} `;
    }

    if (minutes > 0) {
        result += `${minutes} minute${minutes > 1 ? "s" : ""} `;
    }

    if (seconds > 0) {
        result += `${seconds} second${seconds > 1 ? "s" : ""} `;
    }

    return result.trim();
}
