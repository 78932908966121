import { SharingAdapter } from "../../infrastructure/sharing/sharingAdapter";
import { SharingService } from "../sharing/sharingService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useSharing = new SharingService(
    new SharingAdapter(),
    useError,
    useStorage.sharing,
    useNotification,
);
