import { FolderAddRegular, FolderLinkRegular } from "@fluentui/react-icons";
import React, { useEffect } from "react";
import { useDownloadLocation } from "../../../../../../application/useCases/useDownloadLocation";
import { protectedDataParamsKeys } from "../../../../../../domain/dataParams/dataParams";
import Loader from "../../../../../components/Loader/Loader";
import { AdminActiveMenuKind } from "../../../../../models";
import { router } from "../../../../../router";
import AdminLayout from "../../AdminLayout/AdminLayout";
import {
    Button,
    Card,
    CardFooter,
    Persona,
    Text,
    makeStyles,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import { state } from "../../../../../state/stateAdapter";
import { useStorage } from "../../../../../../application/useCases/useStorage";
import AzureLocationForm from "./AzureLocationForm/AzureLocationForm";

const useStyles = makeStyles({
    list: {
        display: "grid",
        ...shorthands.gap("8px"),
    },
    card: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    disabledText: {
        color: tokens.colorNeutralForegroundDisabled,
    },
});

function AzureLocation(): JSX.Element {
    const classes = useStyles();
    const isQueryInProgress = state.useState(
        useStorage.downloadLocation.isFetchDownloadLocationQueryInProgress,
    );
    const azureFilesDownloadLocations = state.useState(
        useStorage.downloadLocation.azureFilesDownloadLocations,
    );
    const id = state.useState(useStorage.adminMiniApp.id);
    const addLink = router.createRoute({
        search: {
            items: [
                {
                    key: "id",
                    value: "add",
                },
            ],
            protect: [...protectedDataParamsKeys, "activeMenu", "type"],
        },
    });

    useEffect(() => {
        if (
            useStorage.downloadLocation.azureFilesDownloadLocations.get()
                .size === 0
        ) {
            useDownloadLocation.fetchAzureLocations();
        }
    }, []);

    if (isQueryInProgress) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    if (id !== null) {
        return <AzureLocationForm />;
    }

    return (
        <AdminLayout
            buttons={[
                {
                    as: "a",
                    dataRoute: true,
                    title: "Add Azure restore destination",
                    href: addLink,
                    icon: <FolderAddRegular />,
                    appearance: "subtle",
                    disabled: isQueryInProgress,
                },
            ]}
            backLink={{
                href: router.createRoute({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.DownloadLoactions,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                }),
                title: "Back to Download Locations",
            }}
            title="Azure files downloads locations"
        >
            <form className="admin__form">
                <div className={classes.list}>
                    <Text weight="semibold">
                        Azure Files restore destinations
                    </Text>
                    {Array.from(azureFilesDownloadLocations.values()).map(i => (
                        <Card
                            orientation="horizontal"
                            appearance="subtle"
                            key={i.id}
                            className={classes.card}
                        >
                            <Persona
                                presence={{
                                    status: i.enabled ? "available" : "offline",
                                }}
                                textAlignment="center"
                                size="extra-large"
                                avatar={{
                                    icon: <FolderLinkRegular />,
                                    size: 48,
                                }}
                                primaryText={
                                    <span
                                        className={
                                            i.enabled
                                                ? ""
                                                : classes.disabledText
                                        }
                                    >
                                        {i.title}
                                    </span>
                                }
                            />
                            <CardFooter
                                action={
                                    <Button
                                        as="a"
                                        href={router.createRoute({
                                            search: {
                                                items: [
                                                    {
                                                        key: "id",
                                                        value: i.id.toString(),
                                                    },
                                                ],
                                                protect: [
                                                    ...protectedDataParamsKeys,
                                                    "id",
                                                    "activeMenu",
                                                    "type",
                                                ],
                                            },
                                        })}
                                        data-route
                                    >
                                        Edit
                                    </Button>
                                }
                            />
                        </Card>
                    ))}
                </div>
                <div>
                    <Button
                        as="a"
                        size="large"
                        href={addLink}
                        data-route
                        icon={<FolderAddRegular />}
                    >
                        Add Azure restore destination
                    </Button>
                </div>
            </form>
        </AdminLayout>
    );
}

export default AzureLocation;
