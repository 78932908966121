import { useNotification } from "../../application/useCases/useNotification";
import { useSharing } from "../../application/useCases/useSharing";
import { useStorage } from "../../application/useCases/useStorage";
import { createBreadcrumbs } from "../../domain/airFile/airFile";
import { FilesViewModeKind } from "../models";
import { router } from "../router";
import shareAccessDetailsTab from "./Layout/ShareAccessDetails/SharedAppDetailsTab";
import { shareAccessManager } from "./shareAccessManager";
import shareAccessState from "./shareAccessAppState";
import { ShareAccessUrlParams } from "./types";

export enum ShareAccessEvents {
    ChangeActiveDirectory = "ChangeActiveDirectory",
    ChangeActiveItem = "ChangeActiveItem",
    ChangeViewMode = "ChangeViewMode",
    InitApp = "InitApp",
    GetFiles = "GetFiles",
    UpdateViewMode = "UpdateViewMode",
    GetFileDetails = "GetFileDetails",
    DownloadItem = "DownloadItem",
    DownloadByLink = "DownloadByLink",
    CheckAccess = "CheckAccess",
}

function parseUrl(url: URL): ShareAccessUrlParams {
    const searchParams = url.searchParams;

    const viewMode = searchParams.get("viewMode");

    return {
        activeDirectory: searchParams.get("path"),
        activeItem: searchParams.get("activeItem"),
        viewMode:
            viewMode !== null ? parseInt(viewMode) : FilesViewModeKind.List,
    };
}

async function fetchData(params: {
    path: string | null;
    password: string | null;
    shareId: string;
    isMSEID?: boolean;
}): Promise<void> {
    await useSharing.getFiles({
        shareId: params.shareId,
        password: params.password,
        folderHierarchy: params.path ?? "",
        isMSEID: !!params.isMSEID,
    });
}

export async function changeActiveDirectoryEventHandler(data: {
    newActiveDirectory: string | null;
    isMSEID?: boolean;
}): Promise<void> {
    shareAccessState.activeDirectory.set(data.newActiveDirectory);
    const sharingDetails = useStorage.sharing.sharingDetails.get();
    const password = useStorage.sharing.password.get();

    if (!sharingDetails) {
        throw new Error("Sharing details not found");
    }

    await fetchData({
        path: data.newActiveDirectory ?? null,
        password,
        shareId: sharingDetails.shareId,
        isMSEID: data.isMSEID,
    });

    if (
        data.newActiveDirectory === null ||
        data.newActiveDirectory === undefined
    ) {
        shareAccessState.breadcrumbsPath.set([]);
    } else {
        const files = useStorage.sharing.sharedFiles.get();
        const breadcrumbs = createBreadcrumbs(data.newActiveDirectory, files);
        shareAccessState.breadcrumbsPath.set(breadcrumbs);
    }
}

export async function changeActiveItemEventHandler(data: {
    activeItem: string | null;
}): Promise<void> {
    shareAccessState.activeItem.set(data.activeItem);

    if (data.activeItem && data.activeItem.length) {
        if (!shareAccessState.panel.tabs.get().has(shareAccessDetailsTab.id)) {
            shareAccessManager.addTab(shareAccessDetailsTab);
        }
    } else {
        if (shareAccessState.panel.tabs.get().has(shareAccessDetailsTab.id)) {
            shareAccessManager.deleteTab(shareAccessDetailsTab.id);
        }
    }
}

export function changeViewModeEventHandler(data: {
    mode: FilesViewModeKind;
}): void {
    router.setSearchParams([{ key: "viewMode", value: data.mode.toString() }]);
}

export function updateViewModeEventHandler(data: {
    mode: FilesViewModeKind;
}): void {
    shareAccessState.filesViewMode.set(data.mode);
}

export async function initSharedAppEventHandler(data: {
    shareUrl: string;
}): Promise<void> {
    shareAccessState.isAppInit.set(false);
    useStorage.sharing.sharingDetails.set(null);
    useStorage.sharing.password.set(null);
    useStorage.sharing.sharedFiles.set([]);
    useStorage.sharing.fileDetails.set(null);
    useStorage.sharing.shareUrl.set(data.shareUrl);

    const sharedAppUrlParams = parseUrl(router.url);

    shareAccessManager.init(
        sharedAppUrlParams.activeDirectory,
        sharedAppUrlParams.viewMode,
        sharedAppUrlParams.activeItem,
    );

    shareAccessState.filesViewMode.set(sharedAppUrlParams.viewMode);
    shareAccessState.activeDirectory.set(sharedAppUrlParams.activeDirectory);
    shareAccessState.activeItem.set(sharedAppUrlParams.activeItem);

    await useSharing.getSharingDetails({
        shareUrl: data.shareUrl,
    });

    shareAccessState.isAppInit.set(true);
}

export async function getFilesEventHandler(data: {
    isMSEID?: boolean;
}): Promise<void> {
    await changeActiveDirectoryEventHandler({
        newActiveDirectory: shareAccessState.activeDirectory.get() ?? null,
        isMSEID: data.isMSEID,
    });
}

export async function checkAccessEventHandler(data: {
    isMSEID?: boolean;
}): Promise<void> {
    try {
        await getFilesEventHandler({
            isMSEID: data.isMSEID,
        });
        useStorage.sharing.isAccessGranted.set(true);
    } catch (error) {
        useStorage.sharing.isAccessGranted.set(false);
    }
}

export async function getFileDetailsEventHandler(data: {
    id: number;
    isMSEID?: boolean;
}): Promise<void> {
    const sharingDetails = useStorage.sharing.sharingDetails.get();
    const password = useStorage.sharing.password.get();

    if (!sharingDetails) {
        throw new Error("Sharing details not found");
    }

    const isMSEID =
        data.isMSEID !== undefined
            ? data.isMSEID
            : !!useStorage.authorization.session.get();

    shareAccessState.activeItem.get();

    await useSharing.getFileDetais({
        id: data.id,
        shareId: sharingDetails.shareId,
        password: password,
        isMSEID,
    });
}

export async function downloadItemEventHandler(data: {
    id: number;
    isMSEID?: boolean;
}): Promise<void> {
    const sharingDetails = useStorage.sharing.sharingDetails.get();

    if (!sharingDetails) {
        throw new Error("Sharing details not found");
    }

    const isMSEID =
        data.isMSEID !== undefined
            ? data.isMSEID
            : !!useStorage.authorization.session.get();

    const password = useStorage.sharing.password.get();

    await useSharing.getDownloadLink({
        shareId: sharingDetails.shareId,
        id: data.id,
        password,
        isMSEID,
    });
}

export function downloadByLinkEventHandler(data: { link: string }): void {
    const link = document.createElement("a");
    link.href = data.link;
    link.target = "_blank";
    link.download = "";
    link.click();

    useNotification.show("Download started", "success");
}
