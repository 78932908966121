import React, { useState, useCallback } from "react";
import {
    Body1Stronger,
    Caption1,
    Link,
    Button,
    Tooltip,
    Text,
} from "@fluentui/react-components";
import {
    ChevronUp16Regular,
    ChevronDown16Regular,
    InfoRegular,
} from "@fluentui/react-icons";
import { getTextPercentAppearance } from "../../../utils";
import { ExtendedViewData } from "../../../models";
import "./extendedViewDescription.css";
import { useViewExtendedStyles } from "../../VideoExtendedView";

export type ExtendedViewDescriptionProps = {
    item: ExtendedViewData;
    duration: number;
};

export default function ExtendedViewDescription(
    props: ExtendedViewDescriptionProps,
): JSX.Element | null {
    const classesViewExtended = useViewExtendedStyles();
    const [isShowDescription, setIsShowDescription] = useState(false);
    const toggleDescription = useCallback((): void => {
        setIsShowDescription(!isShowDescription);
    }, [isShowDescription]);
    const textApearince = getTextPercentAppearance(
        props.duration,
        props.item.appearances,
    );

    if (props.item.description === null) {
        return null;
    }

    return (
        <div className="extended-view-description">
            <div className="extended-view-description__header">
                <Tooltip
                    relationship="label"
                    withArrow
                    content={
                        <>
                            <Text>{textApearince}</Text>
                            <br />
                            <Caption1>
                                This is an estimated identification and might be
                                incorrect.
                            </Caption1>
                        </>
                    }
                >
                    <Body1Stronger>
                        {props.item.value}{" "}
                        <Button
                            icon={<InfoRegular />}
                            iconPosition="after"
                            size="small"
                            appearance="transparent"
                        />
                    </Body1Stronger>
                </Tooltip>
                <Button
                    className={classesViewExtended.link}
                    appearance="transparent"
                    iconPosition="after"
                    onClick={toggleDescription}
                >
                    Show description
                    {isShowDescription ? (
                        <ChevronUp16Regular />
                    ) : (
                        <ChevronDown16Regular />
                    )}
                </Button>
                <Button
                    as="a"
                    className={classesViewExtended.link}
                    appearance="transparent"
                    href={`https://www.bing.com/search?q=${encodeURIComponent(
                        props.item.value,
                    )}`}
                    target="_blank"
                >
                    Find on Bing
                </Button>
            </div>
            {isShowDescription && (
                <Caption1 className="people-details__description" block>
                    {props.item.description}{" "}
                    {props.item.url && (
                        <Link inline href={props.item.url} target="_blank">
                            read more
                        </Link>
                    )}
                </Caption1>
            )}
        </div>
    );
}
