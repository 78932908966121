import {
    NotificationElement,
    NotificationPort,
} from "../application/notification/notificationPort";
import { StorageItemPort } from "../application/storageItemPort";
import { NotificationsStorageValue } from "../application/storageValues";

export class NotifierAdapter implements NotificationPort {
    private readonly _notificationsStoragePort: StorageItemPort<NotificationsStorageValue>;

    constructor(
        notificationsStoragePort: StorageItemPort<NotificationsStorageValue>,
    ) {
        this._notificationsStoragePort = notificationsStoragePort;
    }

    show(element: NotificationElement): void {
        this._notificationsStoragePort.update(s => {
            s.set(element.id, element);
        });
    }

    hide(id: string): void {
        this._notificationsStoragePort.update(s => {
            s.delete(id);
        });
    }

    hideAll(): void {
        this._notificationsStoragePort.set(new Map());
    }

    getElement(id: string): NotificationElement | undefined {
        return this._notificationsStoragePort.get().get(id);
    }
}
