import { AirFile, filterFilesWithParent } from "../../domain/airFile/airFile";
import { AirFileDetails } from "../../domain/airFile/details";
import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { NotificationService } from "../notification/notificationService";
import {
    SharingCreateParams,
    SharingCreateRes,
    SharingGetSharingDetailsParams,
    SharingGetSharingDetailsRes,
    SharingGetDownloadLinkParams,
    SharingGetDownloadLinkRes,
    SharingGetFilesParams,
    SharingPort,
    SharingGetFileDetailsParams,
} from "./sharingPort";
import { SharingStoragePort } from "./sharingStoragePort";

export class SharingService {
    private readonly _sharingPort: SharingPort;
    private readonly _errorService: AirErrorService;
    private readonly _sharingStoragePort: SharingStoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        sharingPort: SharingPort,
        errorService: AirErrorService,
        sharingStoragePort: SharingStoragePort,
        notificationService: NotificationService,
    ) {
        this._sharingPort = sharingPort;
        this._errorService = errorService;
        this._sharingStoragePort = sharingStoragePort;
        this._notificationService = notificationService;
    }

    async create(
        params: SharingCreateParams,
    ): Promise<Option<SharingCreateRes>> {
        try {
            this._errorService.clear(AirErrorKind.SharingCreateErr);
            this._sharingStoragePort.isCreateQueryInProgress.set(true);

            const data = await this._sharingPort.create(params);

            this._sharingStoragePort.isCreateQueryInProgress.set(false);

            return data;
        } catch (error) {
            this._sharingStoragePort.isCreateQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.SharingCreateErr,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getSharingDetails(
        params: SharingGetSharingDetailsParams,
    ): Promise<Option<SharingGetSharingDetailsRes>> {
        try {
            this._errorService.clear(AirErrorKind.SharingGetSharingDetailsErr);
            this._sharingStoragePort.isGetSharingDetailsQueryInProgress.set(
                true,
            );

            const data = await this._sharingPort.getSharingDetails(params);

            this._sharingStoragePort.sharingDetails.set(data);
            this._sharingStoragePort.isGetSharingDetailsQueryInProgress.set(
                false,
            );

            return data;
        } catch (error) {
            this._sharingStoragePort.isGetSharingDetailsQueryInProgress.set(
                false,
            );

            const airError = this._errorService.createAirError(
                AirErrorKind.SharingGetSharingDetailsErr,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getFiles(params: SharingGetFilesParams): Promise<Option<AirFile[]>> {
        try {
            this._errorService.clear(AirErrorKind.SharingGetFilesErr);
            this._sharingStoragePort.isGetFilesQueryInProgress.set(true);

            const data = await this._sharingPort.getFiles(params);

            const filteredItems = filterFilesWithParent(
                data,
                params.folderHierarchy.length === 0
                    ? "/"
                    : params.folderHierarchy,
            );

            this._sharingStoragePort.filesToShow.set(filteredItems);

            this._sharingStoragePort.sharedFiles.set(data);
            this._sharingStoragePort.isGetFilesQueryInProgress.set(false);

            return data;
        } catch (error) {
            this._sharingStoragePort.isGetFilesQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.SharingGetFilesErr,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);

            throw error; // rethrow the error to be handled by the caller
        }
    }

    async getDownloadLink(
        params: SharingGetDownloadLinkParams,
    ): Promise<Option<SharingGetDownloadLinkRes>> {
        try {
            this._errorService.clear(AirErrorKind.SharingGetDownloadLinkErr);
            this._sharingStoragePort.isGetDownloadLinkQueryInProgress.set(true);

            const data = await this._sharingPort.getDownloadLink(params);

            this._sharingStoragePort.downloadLink.set(data.link);
            this._sharingStoragePort.isGetDownloadLinkQueryInProgress.set(
                false,
            );

            return data;
        } catch (error) {
            this._sharingStoragePort.isGetDownloadLinkQueryInProgress.set(
                false,
            );

            const airError = this._errorService.createAirError(
                AirErrorKind.SharingGetDownloadLinkErr,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getFileDetais(
        params: SharingGetFileDetailsParams,
    ): Promise<Option<AirFileDetails>> {
        try {
            this._errorService.clear(AirErrorKind.SharingGetFileDetailsErr);
            this._sharingStoragePort.isGetFileDetailsQueryInProgress.set(true);

            const data = await this._sharingPort.getFileDetais(params);

            this._sharingStoragePort.fileDetails.set(data);
            this._sharingStoragePort.isGetFileDetailsQueryInProgress.set(false);

            return data;
        } catch (error) {
            this._sharingStoragePort.isGetFileDetailsQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.SharingGetFileDetailsErr,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async downloadAll(): Promise<void> {
        this._sharingStoragePort.isDownloadAllQueryInProgress.set(true);

        setTimeout(() => {
            this._sharingStoragePort.isDownloadAllQueryInProgress.set(false);
        }, 2000);
    }
}
