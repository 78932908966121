import {
    Menu,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    Button,
    MenuButtonProps,
} from "@fluentui/react-components";
import React, { useRef } from "react";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";

import { onAddCameraCard, onAddData } from "../shared/actions";
import { state } from "../../../../state/stateAdapter";
import { useStorage } from "../../../../../application/useCases/useStorage";
import ImportInputs from "../../shared/ImportData/ImportInputs";
import { AddDataIcon } from "../../../../components/BundledIcons";

type AddDataMenuProps = Pick<MenuButtonProps, "appearance">;

function AddDataMenu(props: AddDataMenuProps): JSX.Element {
    const classes = useCommonFluentuiStyles();

    const uploaderFeatures = state.useState(useStorage.config.config, {
        properties: ["features.uploader"],
    }).features.uploader;

    const selectFilesLabelRef = useRef<HTMLInputElement>(null);
    const selectFoldersLabelRef = useRef<HTMLInputElement>(null);
    const selectCameraCardLabelRef = useRef<HTMLInputElement>(null);

    return (
        <div>
            <Menu openOnHover={false}>
                <MenuTrigger>
                    <MenuButton
                        appearance={props.appearance}
                        icon={<AddDataIcon />}
                    >
                        Add data to upload
                    </MenuButton>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <Button
                            className={classes.btnMenuItem}
                            appearance="subtle"
                            onClick={(): void =>
                                selectFilesLabelRef.current?.click()
                            }
                        >
                            Select file(s)
                        </Button>

                        <Button
                            className={classes.btnMenuItem}
                            appearance="subtle"
                            onClick={(): void =>
                                selectFoldersLabelRef.current?.click()
                            }
                        >
                            Select folder(s)
                        </Button>

                        {uploaderFeatures.parsingCameraCard ? (
                            <Button
                                className={classes.btnMenuItem}
                                appearance="subtle"
                                onClick={(): void =>
                                    selectCameraCardLabelRef.current?.click()
                                }
                            >
                                Select camera card folder
                            </Button>
                        ) : null}
                    </MenuList>
                </MenuPopover>
            </Menu>

            <ImportInputs
                selectFilesLabelRef={selectFilesLabelRef}
                selectFoldersLabelRef={selectFoldersLabelRef}
                selectCameraCardLabelRef={selectCameraCardLabelRef}
                onAddData={onAddData}
                onAddCameraCard={onAddCameraCard}
            />
        </div>
    );
}

export default AddDataMenu;
