import React from "react";
import { router } from "../../../../router";
import Pagination from "../../../../components/Pagination/Pagination";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";

const pageSizes = [10, 25, 50, 100];

function FileExplorerPagination(): JSX.Element | null {
    const validFiles = state.useState(useStorage.files.validFiles);
    const { page, pageSize } = state.useState(
        useStorage.fileExplorerMiniApp.dataParams,
        {
            properties: ["page", "pageSize"],
        },
    );
    const searchResult = state.useState(useStorage.files.searchResult);
    const activeFilters = state.useState(useStorage.files.activeFilters);

    const filesLength =
        searchResult !== null &&
        searchResult !== undefined &&
        activeFilters !== null &&
        activeFilters !== undefined &&
        activeFilters.size > 0
            ? searchResult.total
            : validFiles.length;

    const onPrevPage = (): void => {
        if (page > 1) {
            const newPage = page - 1;
            router.setSearchParams([
                { key: "page", value: newPage.toString() },
            ]);
        }
    };

    const onNextPage = (total: number): void => {
        if (page < total) {
            const newPage = page + 1;
            router.setSearchParams([
                { key: "page", value: newPage.toString() },
            ]);
        }
    };

    const onPageChange = (page: number): void => {
        router.setSearchParams([{ key: "page", value: page.toString() }]);
    };

    const onPageSizeChange = (pageSize: number): void => {
        router.setSearchParams([
            { key: "pageSize", value: pageSize.toString() },
            { key: "page", value: "1" },
        ]);
    };

    const total = Math.ceil(filesLength / pageSize);

    if (filesLength < pageSizes[0]) {
        return null;
    }

    return (
        <footer className="layout__container">
            <Pagination
                total={total}
                page={page}
                onPageChange={onPageChange}
                onPrevPage={onPrevPage}
                onNextPage={onNextPage}
                pageSizes={pageSizes}
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
            />
        </footer>
    );
}

export default FileExplorerPagination;
