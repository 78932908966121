import {
    MenuDivider,
    makeStyles,
    mergeClasses,
    useIsOverflowGroupVisible,
} from "@fluentui/react-components";
import { ChevronRight12Regular } from "@fluentui/react-icons";
import { tokens } from "@fluentui/react-theme";
import React from "react";

export const useBreadcrumbsSharedStyles = makeStyles({
    chevron: {
        marginRight: "4px",
        color: tokens.colorNeutralForeground3,
    },
    chevronDisabled: {
        color: tokens.colorNeutralForeground4,
    },
    link: {
        color: tokens.colorNeutralForeground2,
        marginRight: "4px",
    },
    item: {
        "& > *": {
            verticalAlign: "middle",
        },
    },
    breadcrumbs: {
        paddingTop: "2px",
        paddingBottom: "8px",
    },
});

type BreadcrumbsSeparatorProps = {
    disabled: boolean;
};

export function BreadcrumbsSeparator(
    props: BreadcrumbsSeparatorProps,
): JSX.Element {
    const classes = useBreadcrumbsSharedStyles();

    return (
        <ChevronRight12Regular
            className={mergeClasses(
                classes.chevron,
                props.disabled ? classes.chevronDisabled : "",
            )}
        />
    );
}

export function OverflowMenuDivider(props: { id: string }): JSX.Element | null {
    const isGroupVisible = useIsOverflowGroupVisible(props.id);

    if (isGroupVisible === "visible") {
        return null;
    }

    return <MenuDivider />;
}
