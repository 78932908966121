import React from "react";
import {
    Subtitle1,
    Toolbar,
    ToolbarButton,
    tokens,
    shorthands,
    ToolbarButtonProps,
    makeStyles,
    Link,
    Text,
    mergeClasses,
    ToolbarGroup,
} from "@fluentui/react-components";
import { ArrowLeft16Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    toolbar: {
        ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
        ...shorthands.padding("3px", "20px"),
        height: "48px",
        justifyContent: "space-between",
    },
    backLink: {
        color: tokens.colorNeutralForeground1,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: "8px",
        "&:hover": {
            textDecorationLine: "none",
        },
    },
});

export type AdminButton = {
    dataRoute?: boolean;
} & ToolbarButtonProps;

type AdminLayoutProps = {
    buttons?: AdminButton[];
    title?: string;
    backLink?: {
        title: string;
        href: string;
    };
    children: JSX.Element;
    pagination?: JSX.Element | null;
    search?: JSX.Element | null;
};

function AdminLayout(props: AdminLayoutProps): JSX.Element {
    const classes = useStyles();

    return (
        <>
            {props.buttons ? (
                <Toolbar className={classes.toolbar}>
                    <ToolbarGroup>
                        {props.buttons.map(
                            (i: AdminButton): JSX.Element => (
                                <ToolbarButton
                                    key={i.title}
                                    {...i}
                                    data-route={i.dataRoute}
                                >
                                    <Text wrap={false} truncate block>
                                        {i.title}
                                    </Text>
                                </ToolbarButton>
                            ),
                        )}
                    </ToolbarGroup>

                    {props.search ?? null}
                </Toolbar>
            ) : null}
            <div className="admin__container">
                {props.backLink ? (
                    <Link
                        role="presentation"
                        className={mergeClasses(
                            classes.backLink,
                            "admin__back",
                        )}
                        href={props.backLink.href}
                        data-route
                    >
                        <ArrowLeft16Regular />
                        &nbsp;{props.backLink.title}
                    </Link>
                ) : null}

                {props.title ? (
                    <div className="admin__title">
                        <Subtitle1>{props.title}</Subtitle1>
                    </div>
                ) : null}

                {props.children}
            </div>

            {props.pagination ? (
                <div className="admin__container">{props.pagination}</div>
            ) : null}
        </>
    );
}

export default AdminLayout;
