import { EventBus } from "../../domain/eventBus/eventBus";
import { GeneratorIdRepository } from "../../domain/generatorId/generatorIdRepository";
import { MimeRepository } from "../../domain/mime/mimeRepository";
import { TimeFormatter } from "../../domain/timeFormatter/timeFormatter";
import { Option } from "../../domain/types/types";
import { UploadLocation } from "../../domain/uploadLocation/uploadLocation";
import { UploadLocationRepository } from "../../domain/uploadLocation/uploadLocationRepository";
import {
    AddDataMethod,
    FilesCollection,
    UserFile,
    UserFileMetadata,
    formUserFiles,
} from "../../domain/userFile/userFile";
import { UserFileRepository } from "../../domain/userFile/userFileRepository";
import {
    UserFileUpload,
    cancelUpload,
    retryUpload,
    upload,
} from "../../domain/userFileUpload/userFileUpload";
import { UserFileUploadRepository } from "../../domain/userFileUpload/userFileUploadRepository";
import { normalizePath } from "../../utility";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import { UploaderPort } from "./ports";

export class UploaderService {
    private readonly _uploaderPort: UploaderPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;
    private readonly _userFileRepository: UserFileRepository;
    private readonly _mimeRepository: MimeRepository;
    private readonly _timeFormatter: TimeFormatter;
    private readonly _userFileUploadRepository: UserFileUploadRepository;
    private readonly _generatorIdRepository: GeneratorIdRepository;
    private readonly _uploadLocationRepository: UploadLocationRepository;
    private readonly _eventBus: EventBus;
    private readonly _chunkSize = 4194304;

    constructor(
        uploaderPortPort: UploaderPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
        userFileRepository: UserFileRepository,
        mimeRepository: MimeRepository,
        timeFormatter: TimeFormatter,
        userFileUploadRepository: UserFileUploadRepository,
        generatorIdRepository: GeneratorIdRepository,
        uploadLocationRepository: UploadLocationRepository,
        eventBus: EventBus,
    ) {
        this._uploaderPort = uploaderPortPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
        this._userFileRepository = userFileRepository;
        this._mimeRepository = mimeRepository;
        this._timeFormatter = timeFormatter;
        this._userFileUploadRepository = userFileUploadRepository;
        this._generatorIdRepository = generatorIdRepository;
        this._uploadLocationRepository = uploadLocationRepository;
        this._eventBus = eventBus;
    }

    async fetchLocations(): Promise<Option<UploadLocation[]>> {
        try {
            this._storagePort.uploaderMiniApp.isFetchLocationsQueryInProgress.set(
                true,
            );

            const res = await this._uploaderPort.fetchLocations();

            this._storagePort.uploaderMiniApp.locations.set(res);
            this._storagePort.uploaderMiniApp.isFetchLocationsQueryInProgress.set(
                false,
            );

            return this._storagePort.uploaderMiniApp.locations.get();
        } catch (error) {
            this._storagePort.uploaderMiniApp.isFetchLocationsQueryInProgress.set(
                false,
            );

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchLocations,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async addData(
        fileList: FileList,
        addDataMethod: AddDataMethod,
    ): Promise<Option<FilesCollection<UserFile[] | null>>> {
        try {
            this._storagePort.uploaderMiniApp.isAddDataQueryInProgress.set(
                true,
            );

            const ignoreList = this._storagePort.config.config
                .get()
                .features.uploader.ignoredPaths.map(i => i.template);

            const userFiles = await formUserFiles(
                this._userFileRepository,
                this._mimeRepository,
                this._timeFormatter,
                fileList,
                addDataMethod,
                ignoreList,
            );

            this._storagePort.uploaderMiniApp.userFiles.set(userFiles.passed);
            this._storagePort.uploaderMiniApp.ignoredUserFiles.set(
                userFiles.ignored,
            );
            this._storagePort.uploaderMiniApp.isAddDataQueryInProgress.set(
                false,
            );

            return {
                passed: this._storagePort.uploaderMiniApp.userFiles.get(),
                ignored:
                    this._storagePort.uploaderMiniApp.ignoredUserFiles.get(),
            };
        } catch (error) {
            this._storagePort.uploaderMiniApp.isAddDataQueryInProgress.set(
                false,
            );
            const airError = this._errorService.createAirError(
                AirErrorKind.AddData,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    updateUserFileMetadata(
        newMetadata: UserFileMetadata[],
    ): Option<UserFile[]> {
        this._storagePort.uploaderMiniApp.userFiles.update(s => {
            if (s !== null && s !== undefined) {
                newMetadata.forEach(i => {
                    const fileIndex = s.findIndex(f => f.id === i.id);
                    if (fileIndex !== -1) {
                        const fullFileName = `${i.name}.${s[fileIndex].ext}`;

                        s[fileIndex].name = i.name;
                        s[fileIndex].description = i.description;
                        s[fileIndex].fullFileName = fullFileName;

                        if (s[fileIndex].sourcePath.length > 0) {
                            s[fileIndex].fullFilePath = normalizePath(
                                `${s[fileIndex].sourcePath}/${fullFileName}`,
                            );
                        } else {
                            s[fileIndex].fullFilePath = fullFileName;
                        }
                    } else {
                        this._notificationService.show(
                            "User file not found.",
                            "error",
                        );
                    }
                });
            } else {
                this._notificationService.show(
                    "User files not found.",
                    "error",
                );
            }
        });

        return this._storagePort.uploaderMiniApp.userFiles.get();
    }

    async upload(
        userFiles: UserFile[],
        location: UploadLocation,
        folderPath: string,
    ): Promise<void> {
        try {
            const uploaderFeatures =
                this._storagePort.config.config.get().features.uploader
                    .uploading;

            await upload(
                uploaderFeatures.maxConcurrentUploads,
                this._chunkSize,
                userFiles,
                this._storagePort.uploaderMiniApp.upload,
                this._userFileUploadRepository,
                location,
                folderPath,
                uploaderFeatures.retryCount,
                this._generatorIdRepository,
                this._eventBus,
                uploaderFeatures.retryDelay.split(",").map(i => parseInt(i)),
            );
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.Uploading,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async fetchLocationFolders(
        uploadLocation: UploadLocation,
        folder: string,
    ): Promise<Option<string[]>> {
        try {
            this._storagePort.uploaderMiniApp.isFetchDestinationFoldersQueryInProgress.set(
                true,
            );

            const folders =
                await this._uploadLocationRepository.fetchLocationFolders(
                    uploadLocation.id,
                    folder,
                );

            this._storagePort.uploaderMiniApp.isFetchDestinationFoldersQueryInProgress.set(
                false,
            );

            return folders;
        } catch (error) {
            // TODO: finish
            console.error(error);

            this._storagePort.uploaderMiniApp.isFetchDestinationFoldersQueryInProgress.set(
                false,
            );
        }
    }

    cancelUpload(userFileUpload: UserFileUpload): void {
        const uploaderFeatures =
            this._storagePort.config.config.get().features.uploader.uploading;

        cancelUpload(
            userFileUpload,
            this._storagePort.uploaderMiniApp.upload,
            uploaderFeatures.maxConcurrentUploads,
            this._userFileUploadRepository,
            uploaderFeatures.retryCount,
            uploaderFeatures.retryDelay.split(",").map(i => parseInt(i)),
        );
    }

    async retryUpload(userFileUpload: UserFileUpload): Promise<void> {
        try {
            const uploaderFeatures =
                this._storagePort.config.config.get().features.uploader
                    .uploading;

            await retryUpload(
                uploaderFeatures.maxConcurrentUploads,
                this._chunkSize,
                userFileUpload,
                this._storagePort.uploaderMiniApp.upload,
                this._userFileUploadRepository,
                uploaderFeatures.retryCount,
                this._generatorIdRepository,
                this._eventBus,
                uploaderFeatures.retryDelay.split(",").map(i => parseInt(i)),
            );
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.Uploading,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async instantUpload(
        fileList: FileList,
        addDataMethod: AddDataMethod,
        location: UploadLocation,
        folderPath: string,
    ): Promise<void> {
        try {
            const ignoreList = this._storagePort.config.config
                .get()
                .features.uploader.ignoredPaths.map(i => i.template);

            const userFiles = await formUserFiles(
                this._userFileRepository,
                this._mimeRepository,
                this._timeFormatter,
                fileList,
                addDataMethod,
                ignoreList,
            );

            const passed = userFiles.passed;

            if (passed !== null) {
                const uploaderFeatures =
                    this._storagePort.config.config.get().features.uploader
                        .uploading;

                await upload(
                    uploaderFeatures.maxConcurrentUploads,
                    this._chunkSize,
                    passed,
                    this._storagePort.uploaderMiniApp.upload,
                    this._userFileUploadRepository,
                    location,
                    folderPath,
                    uploaderFeatures.retryCount,
                    this._generatorIdRepository,
                    this._eventBus,
                    uploaderFeatures.retryDelay
                        .split(",")
                        .map(i => parseInt(i)),
                );
            }
        } catch (error) {
            // TODO: finish
        }
    }
}
