import { Router } from "./router";

export type AppRoutes = {
    signInHandler: string;
    explorer: string;
    collections: string;
    admin: string;
    addServiceAccountCallback: string;
    uploader: string;
    jobs: string;
    sharingApp: string;
};

export const routes: AppRoutes = {
    signInHandler: "/sign-in-callback",
    explorer: "/explorer",
    collections: "/collections",
    admin: "/admin",
    addServiceAccountCallback:
        "/admin/download-locations/sharepoint/add-service-account-callback",
    uploader: "/uploader",
    jobs: "/jobs",
    sharingApp: "/share",
};

export const privateRoutes = [
    routes.explorer,
    routes.collections,
    routes.admin,
    routes.addServiceAccountCallback,
    routes.uploader,
    routes.jobs,
];

export const router = new Router(routes);
