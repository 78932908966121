import { Appearance } from "../../../domain/airFile/details";
import React, { RefObject, SyntheticEvent, useEffect, useState } from "react";
import { ExtendedViewData } from "./models";

export function getSeconds(time: string): number {
    const [hours, minutes, seconds] = time.split(":");

    return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
}

export function formatSeconds(sec: number): string {
    const date = new Date(0);

    date.setSeconds(sec);

    return date.toISOString().substr(11, 8);
}

export function onJumpToTime(
    event: SyntheticEvent,
    time: number,
    videoRef: RefObject<HTMLVideoElement>,
): void {
    event.preventDefault();
    if (videoRef.current) {
        videoRef.current.currentTime = time;
    }
}

export function useDuration(videoRef: RefObject<HTMLVideoElement>): number {
    const [duration, SetDuration] = useState(0);

    useEffect(() => {
        function updateDuration(): void {
            if (videoRef.current !== null) {
                SetDuration(videoRef.current.duration);
            }
        }

        if (videoRef.current !== null) {
            const videoElement = videoRef.current;

            videoElement.addEventListener("durationchange", updateDuration);
            updateDuration();

            return (): void => {
                videoElement.removeEventListener(
                    "durationchange",
                    updateDuration,
                );
            };
        }
    }, [videoRef]);

    return duration;
}

export function useCurrentTime(
    videoRef: RefObject<HTMLVideoElement>,
    showCurrentTime: boolean,
): number {
    const [currentTime, SetCurrentTime] = useState(0);

    useEffect(() => {
        function updateCurrentTime(): void {
            if (videoRef.current !== null) {
                SetCurrentTime(videoRef.current.currentTime);
            }
        }

        if (videoRef.current !== null && showCurrentTime === true) {
            const videoElement = videoRef.current;

            videoElement.addEventListener("timeupdate", updateCurrentTime);
            updateCurrentTime();

            return (): void => {
                videoElement.removeEventListener(
                    "timeupdate",
                    updateCurrentTime,
                );
            };
        }
    }, [videoRef, showCurrentTime]);

    return currentTime;
}

/**
 *
 * @param duration in seconds
 * @returns percentage of appearance in video
 */
export function calculatePercentAppearance(
    duration: number,
    appearances?: Appearance[],
): number {
    if (appearances === undefined) {
        return 0;
    }

    let countSeconds = 0;

    for (const appearance of appearances) {
        countSeconds +=
            getSeconds(appearance.endTime) - getSeconds(appearance.startTime);

        if (countSeconds >= duration) {
            return 100;
        }
    }

    return Number(((countSeconds / duration) * 100).toFixed(2));
}

export function getTextPercentAppearance(
    duration: number,
    appearances?: Appearance[],
): string | null {
    if (!duration) {
        return null;
    }
    return `Appears in ${calculatePercentAppearance(
        duration,
        appearances,
    )}% of video.`;
}

export function onJumpToTimecode(
    event: React.MouseEvent<HTMLElement>,
    timecode: string,
    videoRef: RefObject<HTMLVideoElement>,
): void {
    event.preventDefault();
    if (videoRef.current) {
        videoRef.current.currentTime = getSeconds(timecode);
    }
}

export function sortUniqData(data: ExtendedViewData[]): ExtendedViewData[] {
    if (data === undefined) {
        return data;
    }

    return [...new Map(data.map(i => [i.value, i])).values()].sort((a, b) => {
        const nameA = a.value.toUpperCase();
        const nameB = b.value.toUpperCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });
}

export function searchData(
    data: ExtendedViewData[],
    query: string,
): ExtendedViewData[] {
    if ((query !== undefined && query.length === 0) || data === undefined) {
        return data;
    }

    return data.filter(
        a => a.value.toLowerCase().search(query.toLowerCase()) !== -1,
    );
}
