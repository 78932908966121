import React, { SyntheticEvent, useState } from "react";
import UserFileTable from "../../../../components/UserFileTable/UserFileTable";
import { UserFile } from "../../../../../domain/userFile/userFile";
import { state } from "../../../../state/stateAdapter";
import { uploaderState } from "../shared/state";
import "./uploaderContent.css";
import {
    USER_FILE_COLUMNS,
    ColumnKind,
    columnSizes,
} from "../shared/userFileColumns";

type UploaderContentProps = {
    userFiles: UserFile[];
};

const columns = [
    USER_FILE_COLUMNS.get(ColumnKind.Name)!,
    USER_FILE_COLUMNS.get(ColumnKind.Type)!,
    USER_FILE_COLUMNS.get(ColumnKind.Size)!,
    USER_FILE_COLUMNS.get(ColumnKind.LastModified)!,
    USER_FILE_COLUMNS.get(ColumnKind.Extension)!,
    USER_FILE_COLUMNS.get(ColumnKind.Description)!,
];

function onAllSelect(
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent,
    items?: UserFile[],
): void {
    const target = e.target as HTMLElement | null;

    if (target && target.tagName === "INPUT") {
        if (items && (target as HTMLInputElement).checked) {
            const selection: Map<string, UserFile> = new Map();
            items.forEach(i => {
                selection.set(i.id, i);
            });

            uploaderState.selection.set(selection);
        } else {
            uploaderState.selection.set(new Map());
        }
    }
}

function onSelect(e: SyntheticEvent, item: UserFile): void {
    const target = e.target as HTMLElement | null;

    if (target && target.tagName === "INPUT") {
        if (uploaderState.selection.get().has(item.id)) {
            uploaderState.selection.update(s => {
                s.delete(item.id);
            });
        } else {
            uploaderState.selection.update(s => {
                s.set(item.id, item);
            });
        }
    }
}

function UploaderContent(props: UploaderContentProps): JSX.Element {
    const [sortState, setSortState] = useState<{
        sortDirection: "ascending" | "descending";
        sortColumn: string;
    }>({
        sortDirection: "ascending",
        sortColumn: ColumnKind.Name,
    });

    const selection = state.useState(uploaderState.selection);

    return (
        <UserFileTable
            columns={columns}
            items={props.userFiles}
            sortState={sortState}
            selectionState={selection}
            onSort={(
                _e: React.SyntheticEvent<Element, Event>,
                state: {
                    sortDirection: "ascending" | "descending";
                    sortColumn: string;
                },
            ): void => {
                setSortState(state);
            }}
            onSelect={onSelect}
            onAllSelect={onAllSelect}
            columnSizes={columnSizes}
        />
    );
}

export default UploaderContent;
