import React, { useState, useCallback } from "react";
import {
    Button,
    Checkbox,
    Subtitle1,
    Input,
    Divider,
} from "@fluentui/react-components";
import { SearchRegular } from "@fluentui/react-icons";
import {
    Facet,
    FacetValue,
    getFacetName,
} from "../../../../../../domain/facet/facet";
import { FilterData } from "../../fileExplorerManager";
import "./fileExplorerFiltersMorePanel.scss";

type FileExplorerFiltersMorePanel = {
    activeDialog: string | null;
    closeDialog: () => void;
    applyDialog: (filtersToUpdate: FilterData[]) => void;
    facetItem: Facet;
    facetValues: FacetValue;
    values: string[];
};

function FileExplorerFiltersMorePanel(
    props: FileExplorerFiltersMorePanel,
): JSX.Element | null {
    const [items, setItems] = useState(props.facetValues.values);
    const [values, setValues] = useState(props.values);

    const onChangeCheckbox = useCallback(
        (name: string): void => {
            const index = values.indexOf(name);

            if (index === -1) {
                setValues([...values, name]);
            } else {
                setValues(values.filter(f => f !== name));
            }
        },
        [values],
    );

    const filterFacets = useCallback(
        (text: string): void => {
            const newItems = props.facetValues.values.filter(
                item =>
                    item.name.toLowerCase().indexOf(text.toLowerCase()) > -1,
            );

            setItems(newItems);
        },
        [props.facetValues.values],
    );

    return (
        <div className="filter-dialog">
            <div className="filter-dialog__header">
                <Subtitle1>All {props.facetItem.title}</Subtitle1>

                <Input
                    className="filter-dialog__search"
                    placeholder="Filter by text"
                    appearance="underline"
                    contentBefore={<SearchRegular />}
                    onChange={(_e, name): void => {
                        filterFacets(name.value);
                    }}
                />
                <Checkbox
                    label={"All " + props.facetItem.title}
                    className="filters__checkbox"
                    checked={
                        values.length === 0
                            ? false
                            : values.length === props.facetValues.values.length
                            ? true
                            : "mixed"
                    }
                    onChange={(_e, data): void => {
                        if (data.checked == true) {
                            setValues(items.map(f => f.name));
                        } else {
                            setValues([]);
                        }
                    }}
                />
                <Divider />
            </div>
            <div className="filter-dialog__main">
                {items.map((i, index) => (
                    <Checkbox
                        key={i.name + index}
                        className="filters__checkbox"
                        label={`${i.name} (${i.count})`}
                        disabled={i.count === 0}
                        checked={values.includes(getFacetName(i))}
                        onChange={(): void => {
                            onChangeCheckbox(getFacetName(i));
                        }}
                    />
                ))}
            </div>
            <div className="filter-dialog__footer">
                <Button
                    appearance="primary"
                    onClick={(): void => {
                        const p: FilterData[] = [];

                        items.forEach(i => {
                            if (
                                props.values.includes(getFacetName(i)) !==
                                values.includes(getFacetName(i))
                            ) {
                                p.push({
                                    filterGroup: props.facetItem.name ?? "",
                                    filterName: `${getFacetName(i)}`,
                                });
                            }
                        });

                        props.applyDialog(p);
                        props.closeDialog();
                    }}
                >
                    Apply
                </Button>
                <Button
                    onClick={(): void => {
                        setValues([]);
                    }}
                >
                    Clear all
                </Button>
            </div>
        </div>
    );
}

export default FileExplorerFiltersMorePanel;
