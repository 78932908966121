import React, {
    RefObject,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from "react";

import ExtendedViewTags from "./ExtendedViewTags/ExtendedViewTags";
import ExtendedViewGroupTags from "./ExtendedViewGroupTags/ExtendedViewGroupTags";
import ExtendedViewPeople from "./ExtendedViewPeople/ExtendedViewPeople";
import Emotions from "./Emotions/Emotions";
import ExtendedViewTopics from "./ExtendedViewTopics/ExtendedViewTopics";
import { Subtitle1 } from "@fluentui/react-components";
import { Insights } from "../../../../../application/cognitiveMetadata/cognitiveMetadataPort";
import { ExtendedViewData } from "../../models";
import { state } from "../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../videoExtendedViewLocalState";
import { onJumpToTime } from "../../utils";

type InsightsProps = {
    data: Insights;
    duration: number;
    videoRef: RefObject<HTMLVideoElement>;
    isPlayerPlaying: boolean;
};

function InsightsTab(props: InsightsProps): JSX.Element | null {
    const viewInsights = state.useState(videoExtendedLocalState.viewInsights);
    const [currentTime, setCurrentTime] = useState(0);

    const insightsList = [...viewInsights];

    const onPrevInPlayer = useCallback(
        (
            e: SyntheticEvent,
            timeTips: number[],
            setCurrentIndex: (index: number) => void,
        ) => {
            let index = 0;
            for (let i = 0; i < timeTips.length; i++) {
                if (timeTips[i] < currentTime) {
                    index = i;
                }
            }
            onJumpToTime(
                e,
                parseFloat(timeTips[index].toFixed(6)),
                props.videoRef,
            );
            setCurrentIndex(index);
        },
        [currentTime, props.videoRef],
    );

    const onPauseInPlayer = useCallback(() => {
        if (props.videoRef.current) {
            props.videoRef.current.pause();
        }
    }, [props.videoRef]);

    const onPlayInPlayer = useCallback(() => {
        if (props.videoRef.current) {
            props.videoRef.current.play();
        }
    }, [props.videoRef]);

    const onNextInPlayer = useCallback(
        (
            e: SyntheticEvent,
            timeTips: number[],
            currentIndex: number,
            setCurrentIndex: (index: number) => void,
        ) => {
            let index = timeTips.length - 1;
            for (let i = 0; i < timeTips.length; i++) {
                if (timeTips[i] > currentTime) {
                    index = i;
                    break;
                }
            }
            onJumpToTime(
                e,
                parseFloat(timeTips[index].toFixed(6)),
                props.videoRef,
            );
            setCurrentIndex(index);
        },
        [currentTime, props.videoRef],
    );

    const showTime = useCallback(
        (
            e: React.MouseEvent<HTMLElement>,
            setSeekTime: (time: number) => void,
        ): void => {
            const left =
                Math.floor(
                    e.pageX - e.currentTarget.getBoundingClientRect().x,
                ) + 1;

            setSeekTime((props.duration / e.currentTarget.clientWidth) * left);
        },
        [props.duration],
    );

    const onClickTimeLine = useCallback(
        (e: React.MouseEvent<HTMLElement>, seekTime: number): void => {
            onJumpToTime(e, seekTime, props.videoRef);
        },
        [props.videoRef],
    );

    useEffect(() => {
        function updateCurrentTime(): void {
            if (props.videoRef.current !== null) {
                setCurrentTime(props.videoRef.current.currentTime);
            }
        }

        if (props.videoRef.current !== null) {
            const videoElement = props.videoRef.current;

            videoElement.addEventListener("timeupdate", updateCurrentTime);
            updateCurrentTime();

            return (): void => {
                videoElement.removeEventListener(
                    "timeupdate",
                    updateCurrentTime,
                );
            };
        }
    }, [props.videoRef]);

    if (props.videoRef === null || props.data === null) {
        return <Subtitle1>No insights found for this video</Subtitle1>;
    }

    return (
        <div className="extended-view__insights">
            {insightsList.map(listItem => {
                const insightsListKey = listItem[0];
                const insightsListItem = listItem[1];

                if (insightsListKey === "faces" && props.data.faces) {
                    return (
                        <ExtendedViewPeople
                            key={insightsListKey}
                            title={insightsListItem.text}
                            data={props.data.faces}
                            videoRef={props.videoRef}
                            duration={props.duration}
                            isPlayerPlaying={props.isPlayerPlaying}
                            onPrevInPlayer={onPrevInPlayer}
                            onPauseInPlayer={onPauseInPlayer}
                            onPlayInPlayer={onPlayInPlayer}
                            onNextInPlayer={onNextInPlayer}
                            showTime={showTime}
                            onClickTimeLine={onClickTimeLine}
                        />
                    );
                }

                if (insightsListKey === "keywords" && props.data.keywords) {
                    return (
                        <ExtendedViewTags
                            key={insightsListKey}
                            title="Keywords"
                            data={props.data.keywords}
                            videoRef={props.videoRef}
                            duration={props.duration}
                            isPlayerPlaying={props.isPlayerPlaying}
                            onPrevInPlayer={onPrevInPlayer}
                            onPauseInPlayer={onPauseInPlayer}
                            onPlayInPlayer={onPlayInPlayer}
                            onNextInPlayer={onNextInPlayer}
                            showTime={showTime}
                            onClickTimeLine={onClickTimeLine}
                        />
                    );
                }

                if (insightsListKey === "labels") {
                    return (
                        <ExtendedViewTags
                            key={insightsListKey}
                            title="Labels"
                            data={props.data.labels}
                            videoRef={props.videoRef}
                            duration={props.duration}
                            isPlayerPlaying={props.isPlayerPlaying}
                            onPrevInPlayer={onPrevInPlayer}
                            onPauseInPlayer={onPauseInPlayer}
                            onPlayInPlayer={onPlayInPlayer}
                            onNextInPlayer={onNextInPlayer}
                            showTime={showTime}
                            onClickTimeLine={onClickTimeLine}
                        />
                    );
                }

                if (insightsListKey === "namedEntities") {
                    return (
                        <ExtendedViewGroupTags
                            key={insightsListKey}
                            title="Named entities"
                            groups={[
                                {
                                    title: "People",
                                    data: props.data.namedPeople ?? [],
                                    countOverflow: 0,
                                    isOverflow: true,
                                },
                                {
                                    title: "Locations",
                                    data: props.data.namedLocations ?? [],
                                    countOverflow: 0,
                                    isOverflow: true,
                                },
                                {
                                    title: "Brands",
                                    data: props.data.brands ?? [],
                                    countOverflow: 0,
                                    isOverflow: true,
                                },
                            ]}
                            videoRef={props.videoRef}
                            duration={props.duration}
                            isPlayerPlaying={props.isPlayerPlaying}
                            onPrevInPlayer={onPrevInPlayer}
                            onPauseInPlayer={onPauseInPlayer}
                            onPlayInPlayer={onPlayInPlayer}
                            onNextInPlayer={onNextInPlayer}
                            showTime={showTime}
                            onClickTimeLine={onClickTimeLine}
                        />
                    );
                }

                if (insightsListKey === "emotions" && props.data.emotions) {
                    return (
                        <Emotions
                            key={insightsListKey}
                            title="Emotions"
                            data={props.data.emotions}
                            videoRef={props.videoRef}
                            duration={props.duration}
                            isPlayerPlaying={props.isPlayerPlaying}
                            onPrevInPlayer={onPrevInPlayer}
                            onPauseInPlayer={onPauseInPlayer}
                            onPlayInPlayer={onPlayInPlayer}
                            onNextInPlayer={onNextInPlayer}
                            showTime={showTime}
                            onClickTimeLine={onClickTimeLine}
                        />
                    );
                }

                if (insightsListKey === "sentiments" && props.data.sentiments) {
                    return (
                        <Emotions
                            key={insightsListKey}
                            title="Sentiments"
                            data={props.data.sentiments.filter(
                                (i: ExtendedViewData) => i.value !== "Neutral",
                            )}
                            videoRef={props.videoRef}
                            duration={props.duration}
                            isPlayerPlaying={props.isPlayerPlaying}
                            onPrevInPlayer={onPrevInPlayer}
                            onPauseInPlayer={onPauseInPlayer}
                            onPlayInPlayer={onPlayInPlayer}
                            onNextInPlayer={onNextInPlayer}
                            showTime={showTime}
                            onClickTimeLine={onClickTimeLine}
                        />
                    );
                }

                if (
                    insightsListKey === "topics" &&
                    props.data.topicsIab &&
                    props.data.topicsIptc
                ) {
                    return (
                        <ExtendedViewTopics
                            key={insightsListKey}
                            title="Topics"
                            iab={props.data.topicsIab}
                            iptc={props.data.topicsIptc}
                            videoRef={props.videoRef}
                            duration={props.duration}
                            isPlayerPlaying={props.isPlayerPlaying}
                            onPrevInPlayer={onPrevInPlayer}
                            onPauseInPlayer={onPauseInPlayer}
                            onPlayInPlayer={onPlayInPlayer}
                            onNextInPlayer={onNextInPlayer}
                            showTime={showTime}
                            onClickTimeLine={onClickTimeLine}
                        />
                    );
                }

                if (
                    insightsListKey === "audioEffects" &&
                    props.data.audioEffects
                ) {
                    return (
                        <ExtendedViewTags
                            key={insightsListKey}
                            title="Audio effects"
                            data={props.data.audioEffects}
                            videoRef={props.videoRef}
                            duration={props.duration}
                            isPlayerPlaying={props.isPlayerPlaying}
                            onPrevInPlayer={onPrevInPlayer}
                            onPauseInPlayer={onPauseInPlayer}
                            onPlayInPlayer={onPlayInPlayer}
                            onNextInPlayer={onNextInPlayer}
                            showTime={showTime}
                            onClickTimeLine={onClickTimeLine}
                        />
                    );
                }

                if (props.data.custom) {
                    return (
                        <ExtendedViewTags
                            key={insightsListKey}
                            title={insightsListItem.text}
                            data={props.data.custom.common[insightsListKey]}
                            videoRef={props.videoRef}
                            duration={props.duration}
                            isPlayerPlaying={props.isPlayerPlaying}
                            onPrevInPlayer={onPrevInPlayer}
                            onPauseInPlayer={onPauseInPlayer}
                            onPlayInPlayer={onPlayInPlayer}
                            onNextInPlayer={onNextInPlayer}
                            showTime={showTime}
                            onClickTimeLine={onClickTimeLine}
                        />
                    );
                }
            })}
        </div>
    );
}

export default InsightsTab;
