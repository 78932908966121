import {
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    Link,
    TableCellActions,
    Button,
    Text,
} from "@fluentui/react-components";
import {
    EditRegular,
    DeleteRegular,
    InfoRegular,
    ShareRegular,
    DocumentLockRegular,
    FolderArrowLeftRegular,
} from "@fluentui/react-icons";
import React from "react";
import {
    AirFile,
    isContainer,
    isFile,
} from "../../../../domain/airFile/airFile";
import { CollectionInfo } from "../../../../domain/collection/collections";
import IconItem from "../../../components/IconItem/IconItem";
import { router } from "../../../router";
import { formatDate } from "../../../utility/formatters";
import { useStorage } from "../../../../application/useCases/useStorage";
import { onOpenPermissions } from "../shared/PermissionsDialog/PermissionsDialog";
import CollectionsDialog from "../shared/CollectionsDialog/CollectionsDialog";
import { state } from "../../../state/stateAdapter";
import { useCollections } from "../../../../application/useCases/useCollections";
import { CollectionsEditParams } from "../../../../application/collections/collectionsPort";
import DeleteFilesFromCollectionDialog from "./DeleteFilesFromCollectionDialog";
import SharingLinkDialog from "../shared/SharingLinkDialog/SharingLinkDialog";
import { formatSize } from "../../../../utility";
import { ContainerAirFileType } from "../../../../domain/airFile/airFileType";

async function onEditCollection(newInfo: CollectionsEditParams): Promise<void> {
    await useCollections.edit(newInfo);
    await useCollections.fetch(useStorage.collectionsMiniApp.dataParams.get());
}

function onEdit(item: CollectionInfo): void {
    state.appDialog.set(() => (
        <CollectionsDialog
            title="Edit collection"
            isOpen={true}
            defaultName={item.title}
            defaultStatus={item.accessMode}
            onApply={async (
                title: string,
                status: "private" | "public",
            ): Promise<void> => {
                await onEditCollection({
                    id: item.id,
                    title,
                    accessMode: status,
                });
            }}
        />
    ));
}

function onDelete(item: CollectionInfo): void {
    state.appDialog.set(() => (
        <DeleteFilesFromCollectionDialog
            title="Delete collection"
            WarningText={(): JSX.Element => (
                <>
                    You going to delete &quot;
                    <strong>{item.title}</strong>&quot; collection.
                </>
            )}
            onDelete={async (): Promise<void> => {
                await useCollections.delete({
                    id: item.id,
                });
                await useCollections.fetch(
                    useStorage.collectionsMiniApp.dataParams.get(),
                );
            }}
        />
    ));
}

function onShareLink(item: AirFile): void {
    state.appDialog.set(() => (
        <SharingLinkDialog sharingItem={item} isOpen={true} />
    ));
}

enum ColumnsId {
    Title = "title",
    FileType = "fileType",
    StorageTier = "storageTier",
    Size = "size",
    DateUpdated = "dateUpdated",
    Name = "name",
    UserName = "userName",
    Status = "status",
}

export const columnSizes = new Map<ColumnsId, number>([
    [ColumnsId.FileType, 140],
    [ColumnsId.StorageTier, 120],
    [ColumnsId.Size, 140],
    [ColumnsId.DateUpdated, 120],
    [ColumnsId.Status, 120],
]);

export const collectionsListColumns: TableColumnDefinition<CollectionInfo>[] = [
    createTableColumn<CollectionInfo>({
        columnId: ColumnsId.Name,
        renderHeaderCell: () => (
            <TableCellLayout>
                <Text>Name</Text>
            </TableCellLayout>
        ),
        renderCell: item => {
            return (
                <>
                    <TableCellLayout
                        truncate
                        className="cell-layout"
                        style={{
                            paddingRight: `${32 * 2}px`,
                        }}
                        media={
                            <IconItem
                                filePath={""}
                                isFile={false}
                                className="image_table-cell"
                            />
                        }
                    >
                        <Link
                            as="a"
                            href={router.createRoute({
                                search: {
                                    items: [
                                        {
                                            key: "id",
                                            value: item.id,
                                        },
                                    ],
                                    protect: ["activeItem"],
                                },
                            })}
                            appearance="subtle"
                            data-route
                        >
                            <Text
                                truncate
                                block
                                wrap={false}
                                title={item.title}
                            >
                                {item.title}
                            </Text>
                        </Link>
                    </TableCellLayout>

                    {useStorage.authorization.user.get() !== null &&
                    useStorage.authorization.user.get()!.name ===
                        item.userName ? (
                        <TableCellActions className="cell-action">
                            <Button
                                appearance="subtle"
                                icon={<EditRegular />}
                                onClick={(): void => onEdit(item)}
                                title="Edit collection"
                            />

                            <Button
                                icon={<DeleteRegular />}
                                appearance="subtle"
                                onClick={(): void => onDelete(item)}
                                title="Delete collection"
                            />
                        </TableCellActions>
                    ) : null}
                </>
            );
        },
    }),
    createTableColumn<CollectionInfo>({
        columnId: ColumnsId.UserName,
        renderHeaderCell: () => {
            return "Username";
        },
        renderCell: item => (
            <Text truncate block wrap={false} title={item.userName}>
                {item.userName}
            </Text>
        ),
    }),
    createTableColumn<CollectionInfo>({
        columnId: ColumnsId.Status,
        renderHeaderCell: () => {
            return "Visibility";
        },
        renderCell: item => (
            <Text truncate block wrap={false} title={item.accessMode}>
                {item.accessMode === "public" ? "All users" : "Just me"}
            </Text>
        ),
    }),
];

export const collectionFilesColumns: TableColumnDefinition<AirFile>[] = [
    createTableColumn<AirFile>({
        columnId: ColumnsId.Title,
        renderHeaderCell: () => (
            <TableCellLayout>
                <Text>Title</Text>
            </TableCellLayout>
        ),
        renderCell: item => {
            const isItemFile = isFile(item);
            const isExtractedArchive =
                item.fileType === ContainerAirFileType.ExtractedArchive;
            const isSharingDisabled =
                isItemFile || isExtractedArchive
                    ? false
                    : item.storageTier === null ||
                      item.storageTier.toLowerCase() === "archive";

            const user = useStorage.authorization.user.get();

            const shareLinkUiConfiguration =
                useStorage.config.config.get().features
                    .shareLinkUiConfiguration;

            const isAtLeastOneSharingOptionEnabled =
                shareLinkUiConfiguration.domainOnly ||
                shareLinkUiConfiguration.password ||
                shareLinkUiConfiguration.plainLink;

            const isSharingEnabled =
                isAtLeastOneSharingOptionEnabled &&
                shareLinkUiConfiguration.enabled &&
                user &&
                user.downloadLocations.length > 0;

            return (
                <>
                    <TableCellLayout
                        truncate
                        className="cell-layout"
                        media={
                            <IconItem
                                filePath={item.filePath}
                                fileType={item.fileType}
                                isFile={isItemFile}
                                className="image_table-cell"
                            />
                        }
                    >
                        {isItemFile ? (
                            <Link
                                appearance="subtle"
                                href={router.createRoute({
                                    search: {
                                        items: [
                                            {
                                                key: "activeItem",
                                                value: item.id.toString(),
                                            },
                                        ],
                                    },
                                })}
                                data-route
                            >
                                <Text truncate block wrap={false}>
                                    {item.title}
                                </Text>
                            </Link>
                        ) : (
                            <Link
                                as="a"
                                href={router.createRoute({
                                    page: "explorer",
                                    search: {
                                        items: [
                                            {
                                                key: "path",
                                                value: item.hierarchy,
                                            },
                                        ],
                                        protect: ["activeItem"],
                                    },
                                })}
                                appearance="subtle"
                                data-route
                            >
                                <Text truncate block wrap={false}>
                                    {item.title}
                                </Text>
                            </Link>
                        )}
                    </TableCellLayout>

                    <TableCellActions className="cell-action">
                        {!isContainer(item) ? (
                            <Button
                                aria-label="Permissions"
                                appearance="subtle"
                                icon={<DocumentLockRegular />}
                                onClick={(): void => onOpenPermissions(item)}
                                title="Edit permissions"
                            />
                        ) : (
                            <></>
                        )}

                        {isItemFile ? (
                            <>
                                <Button
                                    aria-label="Go to destination"
                                    appearance="subtle"
                                    icon={<FolderArrowLeftRegular />}
                                    title="Go to destination"
                                    as="a"
                                    href={router.createRoute({
                                        page: "explorer",
                                        search: {
                                            items: [
                                                {
                                                    key: "path",
                                                    value: item.parentHierarchy,
                                                },
                                                {
                                                    key: "activeItem",
                                                    value: item.id.toString(),
                                                },
                                            ],
                                        },
                                    })}
                                    data-route
                                />

                                <Button
                                    as="a"
                                    icon={<InfoRegular />}
                                    appearance="subtle"
                                    href={router.createRoute({
                                        search: {
                                            items: [
                                                {
                                                    key: "activeItem",
                                                    value: item.id.toString(),
                                                },
                                            ],
                                        },
                                    })}
                                    data-route
                                    title="File details"
                                />
                            </>
                        ) : (
                            <></>
                        )}

                        {isSharingEnabled &&
                        (!isContainer(item) || isExtractedArchive) ? (
                            <Button
                                icon={<ShareRegular />}
                                appearance="subtle"
                                disabled={isSharingDisabled}
                                onClick={(): void => onShareLink(item)}
                                title={
                                    isSharingDisabled
                                        ? `You cannot share items with "${item.storageTier}" storage tier`
                                        : "Share"
                                }
                            />
                        ) : (
                            <></>
                        )}
                    </TableCellActions>
                </>
            );
        },
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.FileType,
        renderHeaderCell: () => {
            return "Type";
        },
        renderCell: item => (
            <Text className="txt_truncated" title={item.fileType}>
                {item.fileType}
            </Text>
        ),
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.StorageTier,
        renderHeaderCell: () => {
            return "Storage tier";
        },
        renderCell: item =>
            item.storageTier !== null && isFile(item) ? (
                <Text truncate block wrap={false} title={item.storageTier}>
                    {item.storageTier}
                </Text>
            ) : (
                <></>
            ),
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.Size,
        renderHeaderCell: () => {
            return "Size";
        },
        renderCell: item => (
            <Text truncate block wrap={false} title={item.fileSize?.toString()}>
                {formatSize(item.fileSize ?? 0)}
            </Text>
        ),
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.DateUpdated,
        renderHeaderCell: () => {
            return "Last modified";
        },
        renderCell: item => (
            <Text
                truncate
                block
                wrap={false}
                title={formatDate(item.dateUpdated) || undefined}
            >
                {formatDate(item.dateUpdated)}
            </Text>
        ),
    }),
];
