import { routes } from "../../../router";
import { RouteListener } from "../../../router/routeListener";
import { fileExplorerManager } from "./fileExplorerManager";

export const fileExplorerRouteListener: RouteListener = {
    onRouteChange: (url: URL): void => {
        fileExplorerManager.updateParams(url);
    },
    pathname: routes.explorer,
};
