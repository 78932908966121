import React, { useCallback, useState, useMemo } from "react";
import {
    Avatar,
    Subtitle2,
    Body1,
    Caption1,
    Button,
    Link,
    mergeClasses,
} from "@fluentui/react-components";
import {
    ChevronUp16Regular,
    ChevronDown16Regular,
} from "@fluentui/react-icons";
import { getTextPercentAppearance } from "../utils";
import "./peopleDetails.css";
import { FaceInsight } from "../../../../application/cognitiveMetadata/cognitiveMetadataPort";
import { useViewExtendedStyles } from "../VideoExtendedView/VideoExtendedView";
import { Person } from "../../../../domain/airFile/details";

type PeopleDetailsProps = {
    duration: number;
    item: FaceInsight | Person;
    isSmallSize?: boolean;
};

function PeopleDetails(props: PeopleDetailsProps): JSX.Element {
    const classesViewExtended = useViewExtendedStyles();
    const isUnknown = props.item.value.toLowerCase().indexOf("unknown") === -1;
    const [isShowDescription, setIsShowDescription] = useState(false);
    const textApearince = useMemo(
        () => getTextPercentAppearance(props.duration, props.item.appearances),
        [props.duration, props.item.appearances],
    );
    const toggleDescription = useCallback((): void => {
        setIsShowDescription(!isShowDescription);
    }, [isShowDescription]);

    return (
        <div
            className={`people-details ${
                props.isSmallSize === true ? "people-details_small" : ""
            }`}
        >
            <div className="people-details__cols">
                <Avatar
                    image={{ src: props.item.image ?? "" }}
                    size={64}
                    name={props.item.value}
                    color="colorful"
                />
                <div className="people-details__info">
                    <Subtitle2
                        truncate
                        block
                        wrap={false}
                        title={props.item.value}
                    >
                        {props.item.value}
                    </Subtitle2>
                    {props.item.title && (
                        <Body1
                            truncate
                            block
                            wrap={false}
                            title={props.item.title}
                        >
                            {props.item.title}
                        </Body1>
                    )}
                    {textApearince ? (
                        <Caption1
                            truncate
                            block
                            wrap={false}
                            title={textApearince}
                        >
                            {textApearince}
                        </Caption1>
                    ) : null}
                </div>

                {props.item.description && (
                    <Button
                        className={mergeClasses(
                            classesViewExtended.link,
                            "people-details__info-btn",
                        )}
                        appearance="transparent"
                        iconPosition="after"
                        onClick={toggleDescription}
                    >
                        Show biography
                        {isShowDescription ? (
                            <ChevronUp16Regular />
                        ) : (
                            <ChevronDown16Regular />
                        )}
                    </Button>
                )}

                {isUnknown && (
                    <Button
                        as="a"
                        className={mergeClasses(
                            classesViewExtended.link,
                            "people-details__info-btn",
                        )}
                        appearance="transparent"
                        href={`https://www.bing.com/search?q=${encodeURIComponent(
                            props.item.value,
                        )}`}
                        target="_blank"
                    >
                        Find on Bing
                    </Button>
                )}
            </div>

            {isShowDescription && props.item.description !== null && (
                <Caption1 className="people-details__description" block>
                    {props.item.description}{" "}
                    <Link
                        inline
                        href={`https://www.bing.com/search?q=${encodeURIComponent(
                            props.item.value,
                        )}`}
                        target="_blank"
                    >
                        read more
                    </Link>
                </Caption1>
            )}
        </div>
    );
}

export default PeopleDetails;
