import { state } from "../state/stateAdapter";
import {
    BrandVariants,
    createDarkTheme,
    createLightTheme,
} from "@fluentui/react-components";
import { AppTheme } from "../models";
import { useStorage } from "../../application/useCases/useStorage";
import { getBrandTokensFromPalette } from "./themeDesigner/themeDesigner";

const spThemeVariants: BrandVariants = {
    10: "#05303d",
    20: "#063948",
    30: "#074153",
    40: "#08485b",
    50: "#084e63",
    60: "#095268",
    70: "#09566D",
    80: "#115f76",
    90: "#18677f",
    100: "#327d94",
    110: "#4b92a8",
    120: "#72acbe",
    130: "#99c6d3",
    140: "#b0d3d3",
    150: "#c6e0e8",
    160: "#dbecf1",
};

const themeCacheKey = "isDarkTheme";

export function isDarkThemeInCache(): boolean {
    const cachedTheme = localStorage.getItem(themeCacheKey);

    if (cachedTheme === null) {
        return false;
    }

    return cachedTheme === "true";
}

export function cacheTheme(theme: boolean): void {
    localStorage.setItem(themeCacheKey, `${theme}`);
}

export function matchTheme(isDarkTheme: boolean): AppTheme {
    const { primaryColor, darkModePrimaryColor } =
        useStorage.config.config.get();

    const lightTheme = createLightTheme(
        primaryColor
            ? getBrandTokensFromPalette(primaryColor)
            : spThemeVariants,
    );
    const darkTheme = createDarkTheme(
        darkModePrimaryColor
            ? getBrandTokensFromPalette(darkModePrimaryColor)
            : spThemeVariants,
    );

    return isDarkTheme
        ? { theme: darkTheme, isDark: isDarkTheme }
        : { theme: lightTheme, isDark: isDarkTheme };
}

export function toggleTheme(): void {
    const themeValue = !isDarkThemeInCache();
    state.theme.set(matchTheme(themeValue));
    cacheTheme(themeValue);
}
