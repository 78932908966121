import React, {
    useState,
    RefObject,
    useCallback,
    useMemo,
    SyntheticEvent,
} from "react";
import {
    Button,
    Tooltip,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import {
    Previous16Regular,
    Next16Regular,
    Play16Regular,
    Pause16Regular,
    bundleIcon,
    Next16Filled,
    Pause16Filled,
    Play16Filled,
    Previous16Filled,
} from "@fluentui/react-icons";
import { getSeconds, formatSeconds, useCurrentTime } from "../utils";
import "./seekBar.css";

const useStyles = makeStyles({
    timeline: {
        backgroundColor: tokens.colorNeutralStencil1Alpha,
        ":hover .seek-bar__tooltip:after": {
            ...shorthands.borderColor(
                tokens.colorNeutralBackground1,
                "transparent",
                "transparent",
                "transparent",
            ),
        },
    },
    time: {
        backgroundColor: tokens.colorNeutralStrokeAccessible,
    },
});

export const BundledPrevBtn = bundleIcon(Previous16Filled, Previous16Regular);
export const BundledPauseBtn = bundleIcon(Pause16Filled, Pause16Regular);
export const BundledPlayBtn = bundleIcon(Play16Filled, Play16Regular);
export const BundledNextBtn = bundleIcon(Next16Filled, Next16Regular);

type SeekBarProps = {
    videoRef: RefObject<HTMLVideoElement>;
    appearances: {
        startTime: string;
        endTime: string;
        color?: string;
    }[];
    duration: number;
    isPlayerPlaying: boolean;
    onPrevInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        setCurrentIndex: (index: number) => void,
    ) => void;
    onPauseInPlayer: () => void;
    onPlayInPlayer: () => void;
    onNextInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        currentIndex: number,
        setCurrentIndex: (index: number) => void,
    ) => void;
    showTime: (
        e: React.MouseEvent<HTMLElement>,
        setSeekTime: (time: number) => void,
    ) => void;
    onClickTimeLine: (
        e: React.MouseEvent<HTMLElement>,
        seekTime: number,
    ) => void;
};

function getWidth(
    duration: number,
    startTime: string,
    endTime: string,
): string {
    return `${Math.max(
        (100 * (getSeconds(endTime) - getSeconds(startTime))) / duration,
        0.1,
    )}%`;
}

function SeekBar(props: SeekBarProps): JSX.Element {
    const classes = useStyles();
    const [ref, setRef] = useState<HTMLElement | null>(null);
    const [showCurrentTime, setShowCurrentTime] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [seekTime, setSeekTime] = useState<number>(0);
    const currentTime = useCurrentTime(props.videoRef, showCurrentTime);
    const timeTips = useMemo(
        () =>
            props.appearances.map(i =>
                parseFloat(getSeconds(i.startTime).toFixed(6)),
            ),
        [props.appearances],
    );

    const appearanceStyles = useMemo(
        () =>
            props.appearances.map(appearance => ({
                width: getWidth(
                    props.duration,
                    appearance.startTime,
                    appearance.endTime,
                ),
                left: `${
                    (getSeconds(appearance.startTime) * 100) / props.duration
                }%`,
                backgroundColor: appearance.color,
            })),
        [props.appearances, props.duration],
    );

    const onPlayNext = useCallback(
        (e: React.MouseEvent<HTMLElement>): void => {
            props.onNextInPlayer(e, timeTips, currentIndex, setCurrentIndex);
        },
        [currentIndex, props, timeTips],
    );

    const onPlayPrev = useCallback(
        (e: React.MouseEvent<HTMLElement>): void => {
            props.onPrevInPlayer(e, timeTips, setCurrentIndex);
        },
        [props, timeTips],
    );

    const showTime = useCallback(
        (e: React.MouseEvent<HTMLElement>): void => {
            props.showTime(e, setSeekTime);
        },
        [props],
    );

    const onClickTimeLine = useCallback(
        (e: React.MouseEvent<HTMLElement>): void => {
            props.onClickTimeLine(e, seekTime);
        },
        [props, seekTime],
    );

    const isPrevDisabled = currentTime <= timeTips[0];
    const isNextDisabled =
        currentTime >= timeTips[timeTips.length - 1] || timeTips.length === 0;

    return (
        <div
            className="seek-bar"
            onFocus={(): void => setShowCurrentTime(true)}
            onMouseEnter={(): void => setShowCurrentTime(true)}
            onBlur={(): void => setShowCurrentTime(false)}
            onMouseLeave={(): void => setShowCurrentTime(false)}
        >
            <Tooltip
                withArrow
                mountNode={ref}
                content={formatSeconds(seekTime)}
                relationship="label"
                positioning={{ target: ref }}
            >
                <div>
                    <div
                        role="presentation"
                        className={mergeClasses(
                            classes.timeline,
                            "seek-bar__timeline",
                        )}
                        onMouseMove={showTime}
                        onClick={onClickTimeLine}
                    >
                        <div
                            ref={setRef}
                            className="seek-bar__tooltip"
                            style={{
                                left: `${(seekTime * 100) / props.duration}%`,
                            }}
                        ></div>
                        {appearanceStyles.map((style, index) => (
                            <div
                                key={index}
                                role="presentation"
                                className={mergeClasses(
                                    classes.time,
                                    "seek-bar__time",
                                )}
                                style={style}
                            />
                        ))}
                    </div>
                    <div
                        className="seek-bar__progress"
                        style={{
                            width: `${(currentTime * 100) / props.duration}%`,
                        }}
                    />
                </div>
            </Tooltip>
            <div className="seek-bar__btns">
                <Button
                    size="small"
                    appearance="subtle"
                    onClick={onPlayPrev}
                    icon={<BundledPrevBtn />}
                    title="Play previous"
                    disabled={isPrevDisabled}
                />

                {props.isPlayerPlaying ? (
                    <Button
                        size="small"
                        appearance="subtle"
                        onClick={props.onPauseInPlayer}
                        icon={<BundledPauseBtn />}
                        title="Pause"
                    />
                ) : (
                    <Button
                        size="small"
                        appearance="subtle"
                        onClick={props.onPlayInPlayer}
                        icon={<BundledPlayBtn />}
                        title="Play"
                    />
                )}

                <Button
                    size="small"
                    appearance="subtle"
                    onClick={onPlayNext}
                    icon={<BundledNextBtn />}
                    title="Play next"
                    disabled={isNextDisabled}
                />
            </div>
        </div>
    );
}

export default SeekBar;
