import { AirArchiveRepositoryImpl } from "../../infrastructure/airArchiveRepositoryImpl/airArchiveRepositoryImpl";
import AirArchiveService from "../airArchive/airArchiveService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useAirArchive = new AirArchiveService(
    useError,
    useStorage,
    useNotification,
    new AirArchiveRepositoryImpl(),
);
