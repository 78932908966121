import React, { RefObject, SyntheticEvent } from "react";
import ExtendedViewSection from "../ExtendedViewSection/ExtendedViewSection";
import { ExtendedViewData } from "../../../models";
import Tag from "../../../../Tag/Tag";

type ExtendedViewTagsProps = {
    duration: number;
    title: string;
    videoRef: RefObject<HTMLVideoElement>;
    data?: ExtendedViewData[];
    isPlayerPlaying: boolean;
    onPrevInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        setCurrentIndex: (index: number) => void,
    ) => void;
    onPauseInPlayer: () => void;
    onPlayInPlayer: () => void;
    onNextInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        currentIndex: number,
        setCurrentIndex: (index: number) => void,
    ) => void;
    showTime: (
        e: React.MouseEvent<HTMLElement>,
        setSeekTime: (time: number) => void,
    ) => void;
    onClickTimeLine: (
        e: React.MouseEvent<HTMLElement>,
        seekTime: number,
    ) => void;
};

function ExtendedViewTags(props: ExtendedViewTagsProps): JSX.Element | null {
    return (
        <ExtendedViewSection
            title={props.title}
            data={props.data}
            duration={props.duration}
            videoRef={props.videoRef}
            renderItem={(
                i: ExtendedViewData,
                activeItem: ExtendedViewData,
                setActiveItem: (i: ExtendedViewData) => void,
            ): JSX.Element => (
                <Tag
                    item={i}
                    isActive={
                        activeItem !== undefined && i.value === activeItem.value
                    }
                    onClick={(): void => {
                        setActiveItem(i);
                    }}
                />
            )}
            isPlayerPlaying={props.isPlayerPlaying}
            onPrevInPlayer={props.onPrevInPlayer}
            onPauseInPlayer={props.onPauseInPlayer}
            onPlayInPlayer={props.onPlayInPlayer}
            onNextInPlayer={props.onNextInPlayer}
            showTime={props.showTime}
            onClickTimeLine={props.onClickTimeLine}
        />
    );
}

export default ExtendedViewTags;
