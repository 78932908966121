/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import { SaveRegular } from "@fluentui/react-icons";
import {
    Field,
    Input,
    Text,
    Divider,
    Select,
    InputOnChangeData,
} from "@fluentui/react-components";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";
import { state } from "../../../../state/stateAdapter";
import { useStorage } from "../../../../../application/useCases/useStorage";
import Loader from "../../../../components/Loader/Loader";
import { convertSize } from "../../../../../utility";
import { useNotification } from "../../../../../application/useCases/useNotification";
import { useAdmin } from "../../../../../application/useCases/useAdmin";
import {
    PreviewInfo,
    PreviewUpdatePreviewParams,
    Properties,
    PropertiesQuota,
    Provider,
    ProviderValue,
} from "../../../../../application/admin/adminFeaturePreview";

const providers: Provider[] = [
    {
        value: ProviderValue.Ams,
        title: "Azure Media Services",
    },
    {
        value: ProviderValue.Bitmovin,
        title: "Bitmovin",
    },
    {
        value: ProviderValue.Ffmpeg,
        title: "Ffmpeg",
    },
];

const providerValueMap = new Map(
    providers.map(d => {
        return [d.title, { title: d.title, value: d.value }];
    }),
);
const providerTitileMap = new Map(
    providers.map(d => {
        return [d.value, { title: d.title, value: d.value }];
    }),
);

const providerNotFoundErrMsg = "Provider not found";

async function getPreview(): Promise<PreviewInfo | undefined> {
    const res = await useAdmin.getPreview();

    if (res) {
        const provider = { ...providerTitileMap.get(res.provider) } as
            | Provider
            | undefined;

        if (provider === undefined) {
            useNotification.show(providerNotFoundErrMsg, "error");
            return;
        }

        if (
            res.properties &&
            res.properties.quota &&
            res.properties.quota.type &&
            res.properties.quota.type === "size"
        ) {
            res.properties.quota.value = convertSize(
                res.properties.quota.value,
                "bytesToGigabytes",
            );
        }

        return {
            provider,
            properties: res.properties,
        };
    }
}

type PropertiesFormProps = {
    tempProvider: Provider;
    tempProperties: Properties;

    setTempProperties: React.Dispatch<
        React.SetStateAction<Properties | undefined>
    >;
};

const defaultBitmovinQuotaType = "count";

const defaultFfmpegQuotaType = "size";

function arePropertiesValid(
    tempProvider: Provider | undefined,
    provider: Provider | undefined,
    tempProperties: Properties | undefined,
    properties: Properties | undefined,
): boolean {
    if (!tempProvider || !tempProperties) {
        return false;
    }

    if (tempProvider.value === ProviderValue.Ams) {
        return true;
    }

    const isQuotaValid =
        tempProperties.quota !== undefined &&
        tempProperties.quota !== null &&
        tempProperties.quota.type !== undefined &&
        tempProperties.quota.type !== null &&
        tempProperties.quota.type.length > 0 &&
        tempProperties.quota.value !== undefined &&
        tempProperties.quota.value !== null &&
        tempProperties.quota.value.toString().length > 0 &&
        tempProperties.quota.value > 0;

    const areQuotaValuesSame =
        tempProvider?.value === provider?.value &&
        properties?.quota.value === tempProperties.quota.value;

    if (tempProvider.value === ProviderValue.Bitmovin) {
        const areBitmovinPropertiesValid =
            tempProperties.storageName !== undefined &&
            tempProperties.storageName !== null &&
            tempProperties.storageName.length > 0 &&
            tempProperties.containerName !== undefined &&
            tempProperties.containerName !== null &&
            tempProperties.containerName.length > 0 &&
            tempProperties.connectionString !== undefined &&
            tempProperties.connectionString !== null &&
            tempProperties.connectionString.length > 0 &&
            tempProperties.apiKey !== undefined &&
            tempProperties.apiKey !== null &&
            tempProperties.apiKey.length > 0 &&
            isQuotaValid;

        const areBitmovinPropertiesSame =
            properties?.apiKey === tempProperties.apiKey &&
            properties?.connectionString === tempProperties.connectionString &&
            properties?.containerName === tempProperties.containerName &&
            properties?.storageName === tempProperties.storageName &&
            properties?.quota.type === tempProperties.quota.type &&
            properties?.quota.value === tempProperties.quota.value;

        return areBitmovinPropertiesValid && !areBitmovinPropertiesSame;
    }

    if (tempProvider.value === ProviderValue.Ffmpeg) {
        const areFfmpegPropertiesValid = isQuotaValid;

        return areFfmpegPropertiesValid && !areQuotaValuesSame;
    }

    return false;
}

function PropertiesForm(props: PropertiesFormProps): JSX.Element | null {
    const commonClasses = useCommonFluentuiStyles();

    const updateApiKeyProperty = (
        _: ChangeEvent<HTMLInputElement> | null,
        data: InputOnChangeData,
    ): void => {
        if (props.tempProperties) {
            props.setTempProperties({
                ...props.tempProperties,
                apiKey: data.value,
            });
        }
    };

    const updateConnectionStringProperty = (
        _: ChangeEvent<HTMLInputElement> | null,
        data: InputOnChangeData,
    ): void => {
        if (props.tempProperties) {
            props.setTempProperties({
                ...props.tempProperties,
                connectionString: data.value,
            });
        }
    };

    const updateContainerNameProperty = (
        _: ChangeEvent<HTMLInputElement> | null,
        data: InputOnChangeData,
    ): void => {
        if (props.tempProperties) {
            props.setTempProperties({
                ...props.tempProperties,
                containerName: data.value,
            });
        }
    };

    const updateStorageNameProperty = (
        _: ChangeEvent<HTMLInputElement> | null,
        data: InputOnChangeData,
    ): void => {
        if (props.tempProperties) {
            props.setTempProperties({
                ...props.tempProperties,
                storageName: data.value,
            });
        }
    };

    const updateQuotaValueProperty = (
        _: ChangeEvent<HTMLInputElement> | null,
        data: InputOnChangeData,
    ): void => {
        if (props.tempProperties) {
            props.setTempProperties({
                ...props.tempProperties,
                quota: {
                    ...props.tempProperties.quota,
                    value: parseInt(data.value),
                },
            });
        }
    };

    if (props.tempProvider === undefined) {
        return null;
    }

    if (props.tempProvider.value === ProviderValue.Bitmovin) {
        return (
            <>
                <Divider />

                <article className="common-settings-section">
                    <Text as="h3" weight="semibold" className="txt_no-margin">
                        {props.tempProvider.title} properties
                    </Text>

                    <section className="common-settings-fields">
                        <Field
                            label="Storage Account Name"
                            className={commonClasses.field}
                        >
                            <Input
                                placeholder="Type your storage account name"
                                size="large"
                                name="storageAccountName"
                                type="text"
                                value={props.tempProperties?.storageName || ""}
                                onChange={updateStorageNameProperty}
                            />
                        </Field>

                        <Field
                            label="Container"
                            className={commonClasses.field}
                        >
                            <Input
                                placeholder="Type your container name"
                                size="large"
                                name="containerName"
                                type="text"
                                value={
                                    props.tempProperties?.containerName || ""
                                }
                                onChange={updateContainerNameProperty}
                            />
                        </Field>

                        <Field
                            label="Connection string for Storage Account"
                            className={commonClasses.field}
                        >
                            <Input
                                placeholder="Type your connection string for Storage Account"
                                size="large"
                                name="connectionString"
                                type="text"
                                value={
                                    props.tempProperties?.connectionString || ""
                                }
                                onChange={updateConnectionStringProperty}
                            />
                        </Field>

                        <Field
                            label="Bitmovin API Key"
                            className={commonClasses.field}
                        >
                            <Input
                                placeholder="Type your Bitmovin API Key"
                                size="large"
                                name="bitmovinApiKey"
                                type="text"
                                value={props.tempProperties?.apiKey || ""}
                                onChange={updateApiKeyProperty}
                            />
                        </Field>

                        <Field
                            label="Simultaneous video processing count "
                            className={commonClasses.field}
                        >
                            <Input
                                placeholder="Default: 15"
                                size="large"
                                name="quotaSize"
                                type="number"
                                value={
                                    props.tempProperties?.quota.value.toString() ||
                                    "15"
                                }
                                onChange={updateQuotaValueProperty}
                                defaultValue="15"
                                title="Default: 15"
                            />
                        </Field>
                    </section>
                </article>
            </>
        );
    }

    if (props.tempProvider.value === ProviderValue.Ffmpeg) {
        return (
            <>
                <Divider />

                <article className="common-settings-section">
                    <Text as="h3" weight="semibold" className="txt_no-margin">
                        {props.tempProvider.title} properties
                    </Text>

                    <section className="common-settings-fields">
                        <Field
                            label="Max size for simultaneous video processing (GiB)"
                            className={commonClasses.field}
                        >
                            <Input
                                placeholder="Default: 5120"
                                size="large"
                                name="quotaSize"
                                type="number"
                                value={
                                    props.tempProperties?.quota.value.toString() ||
                                    "5120"
                                }
                                onChange={updateQuotaValueProperty}
                                title="Default: 5120"
                            />
                        </Field>
                    </section>
                </article>
            </>
        );
    }

    return null;
}

function Preview(): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    const [provider, setProvider] = useState<Provider | undefined>();
    const [properties, setProperties] = useState<Properties | undefined>();

    const [tempProvider, setTempProvider] = useState<Provider | undefined>();
    const [tempProperties, setTempProperties] = useState<
        Properties | undefined
    >();

    const [canSave, setCanSave] = useState(false);
    const [isDataReceived, setIsDataReceived] = useState(false);

    const isGetPreviewQueryInProgress = state.useState(
        useStorage.preview.isGetQueryInProgress,
    );
    const isUpdatePreviewQueryInProgress = state.useState(
        useStorage.preview.isUpdateQueryInProgress,
    );

    const updateTempProvider = (
        _: ChangeEvent<HTMLSelectElement>,
        data: { value: string },
    ): void => {
        const newTempProvider = { ...providerValueMap.get(data.value) } as
            | Provider
            | undefined;

        if (newTempProvider === undefined) {
            throw new Error(providerNotFoundErrMsg);
        }

        let newProperties: Properties = {
            quota: {
                type: "",
                value: 0,
            },
        };

        if (newTempProvider.value === ProviderValue.Bitmovin) {
            newProperties.quota.type = defaultBitmovinQuotaType;
            newProperties.quota.value = 15;
        } else if (newTempProvider.value === ProviderValue.Ffmpeg) {
            newProperties.quota.type = defaultFfmpegQuotaType;
            newProperties.quota.value = 5120;
        }

        if (
            newTempProvider.value == provider?.value &&
            properties !== undefined
        ) {
            newProperties = { ...properties };
        }

        setTempProperties(newProperties);
        setTempProvider(newTempProvider);
    };

    const onSave = async (): Promise<void> => {
        if (!tempProvider) {
            return;
        }

        const updatePreviewParams: PreviewUpdatePreviewParams = {
            provider: tempProvider.value,
        };

        if (tempProperties) {
            updatePreviewParams.properties = {} as Properties;

            if (tempProperties.storageName) {
                updatePreviewParams.properties.storageName =
                    tempProperties.storageName;
            }

            if (tempProperties.containerName) {
                updatePreviewParams.properties.containerName =
                    tempProperties.containerName;
            }

            if (tempProperties.connectionString) {
                updatePreviewParams.properties.connectionString =
                    tempProperties.connectionString;
            }

            if (tempProperties.apiKey) {
                updatePreviewParams.properties.apiKey = tempProperties.apiKey;
            }

            if (tempProperties.quota) {
                updatePreviewParams.properties.quota = {} as PropertiesQuota;

                if (tempProperties.quota.type) {
                    updatePreviewParams.properties.quota.type =
                        tempProperties.quota.type;
                }

                if (tempProperties.quota.value > 0) {
                    if (tempProperties.quota.type === "size") {
                        updatePreviewParams.properties.quota.value =
                            convertSize(
                                tempProperties.quota.value,
                                "gigabytesToBytes",
                            );
                    } else {
                        updatePreviewParams.properties.quota.value =
                            tempProperties.quota.value;
                    }
                }
            }
        }

        const isOk = await useAdmin.updatePreview(updatePreviewParams);

        if (isOk) {
            setProvider(tempProvider);
            setProperties(tempProperties);
        }
    };

    const onDiscard = (): void => {
        setTempProvider(provider);
        setTempProperties(properties);
    };

    useEffect(() => {
        getPreview().then(res => {
            if (res) {
                setProvider(res.provider);
                setProperties(res.properties);

                setTempProvider(res.provider);
                setTempProperties(res.properties);

                setIsDataReceived(true);
            } else {
                setIsDataReceived(false);
            }
        });
    }, []);

    useEffect(() => {
        setCanSave(
            arePropertiesValid(
                tempProvider,
                provider,
                tempProperties,
                properties,
            ),
        );
    }, [tempProperties, tempProvider, provider, properties]);

    if (
        !tempProvider ||
        isGetPreviewQueryInProgress ||
        isUpdatePreviewQueryInProgress ||
        tempProperties === undefined
    ) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    if (
        !isGetPreviewQueryInProgress &&
        !isUpdatePreviewQueryInProgress &&
        !isDataReceived
    ) {
        return (
            <AdminLayout title={"File preview encoder"}>
                <Text>No data received</Text>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout
            title={"File preview encoder"}
            buttons={[
                {
                    title: "Save changes",
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled:
                        !canSave ||
                        isGetPreviewQueryInProgress ||
                        isUpdatePreviewQueryInProgress,
                    onClick: onSave,
                },
                {
                    title: "Discard changes",
                    appearance: "subtle",
                    disabled:
                        !canSave ||
                        isGetPreviewQueryInProgress ||
                        isUpdatePreviewQueryInProgress,
                    onClick: onDiscard,
                },
            ]}
        >
            <form className="admin__form">
                <section className="common-settings-section">
                    <section className="common-settings-fields">
                        <Field label="Provider" className={commonClasses.field}>
                            <Select
                                id="providerId"
                                onChange={updateTempProvider}
                                value={tempProvider ? tempProvider.title : ""}
                                size="large"
                                placeholder="Select a provider"
                            >
                                {providers.map(option => (
                                    <option
                                        key={option.value}
                                        value={option.title}
                                    >
                                        {option.title}
                                    </option>
                                ))}
                            </Select>
                        </Field>
                    </section>
                </section>

                <PropertiesForm
                    tempProvider={tempProvider}
                    tempProperties={tempProperties}
                    setTempProperties={setTempProperties}
                />
            </form>
        </AdminLayout>
    );
}

export default Preview;
