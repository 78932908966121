import { createState } from "@persevie/statemanjs";
import { StatePort } from "./statePort";
import { UserFile } from "../../../../../../domain/userFile/userFile";
import { SelectionsStateValue } from "../../../../../types";
import { Destination } from "../../../../../../application/ports";
import { Option } from "../../../../../../domain/types/types";
import { StorageRepository } from "../../../../../../domain/storage/storageRepository";

const selectionState = createState<SelectionsStateValue<UserFile>>(new Map());
const predefinedDestinationState = createState<Option<Destination>>(null);

export class StateImpl implements StatePort {
    readonly selection: StorageRepository<SelectionsStateValue<UserFile>> =
        selectionState;
    readonly predefinedDestination: StorageRepository<Option<Destination>> =
        predefinedDestinationState;
}
