import React, { useCallback, useEffect, useState } from "react";
import {
    Button,
    Link,
    makeStyles,
    mergeClasses,
    shorthands,
    Text,
    tokens,
} from "@fluentui/react-components";
import {
    Dismiss20Regular,
    Info20Filled,
    ErrorCircle20Filled,
    Warning20Filled,
    CheckmarkCircle20Filled,
} from "@fluentui/react-icons";
import "./notificationToast.css";
import { NotificationElement } from "../../../application/notification/notificationPort";
import { useTimeFormatter } from "../../../application/useCases/useTimeFormatter";
import { DateFormat } from "../../../domain/timeFormatter/timeFormatter";

type NotificationToastProps = {
    element: NotificationElement;
    onHide: (id: string) => void;
    autoHide?: (id: string) => void;
    onOpen?: () => void;
    above?: boolean;
};

const useStyles = makeStyles({
    iconInfo: {
        color: tokens.colorPaletteBlueForeground2,
    },
    iconError: {
        color: tokens.colorPaletteRedForeground1,
    },
    iconWarn: {
        color: tokens.colorPaletteYellowForeground1,
    },
    iconSuccess: {
        color: tokens.colorPaletteGreenForeground1,
    },
    element: {
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ":hover": {
            boxShadow: tokens.shadow2,
        },
    },
    elementAbove: {
        boxShadow: tokens.shadow4,
    },
    elementInfo: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    elementError: {
        backgroundColor: tokens.colorPaletteRedBackground1,
    },
    elementWarn: {
        backgroundColor: tokens.colorPaletteYellowBackground1,
    },
    elementSuccess: {
        backgroundColor: tokens.colorPaletteGreenBackground1,
    },
});

function NotificationToast(props: NotificationToastProps): JSX.Element {
    const classes = useStyles();
    let Icon: () => JSX.Element;
    let elementClass;
    const [isNotificationActionBtnHidden, setIsNotificationActionBtnHidden] =
        useState(true);

    switch (props.element.style) {
        case "info":
            Icon = (): JSX.Element => (
                <Info20Filled className={classes.iconInfo} />
            );
            elementClass = classes.elementInfo;
            break;

        case "error":
            Icon = (): JSX.Element => (
                <ErrorCircle20Filled className={classes.iconError} />
            );
            elementClass = classes.elementError;
            break;

        case "warning":
            Icon = (): JSX.Element => (
                <Warning20Filled className={classes.iconWarn} />
            );
            elementClass = classes.elementWarn;
            break;

        case "success":
            Icon = (): JSX.Element => (
                <CheckmarkCircle20Filled className={classes.iconSuccess} />
            );
            elementClass = classes.elementSuccess;
            break;

        default:
            Icon = (): JSX.Element => <></>;
    }

    const hide = useCallback((): void => {
        props.onHide(props.element.id);
    }, [props]);

    useEffect(() => {
        const { autoHide } = props;
        if (props.element.autoHide === true && autoHide !== undefined) {
            const timeoutId = setTimeout(() => {
                autoHide(props.element.id);
            }, 10000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [props, props.element.autoHide]);

    return (
        <div
            id={props.element.id}
            className={mergeClasses(
                "notification-element",
                classes.element,
                elementClass,
                props.above ? classes.elementAbove : "",
            )}
            onMouseEnter={(): void => setIsNotificationActionBtnHidden(false)}
            onMouseLeave={(): void => setIsNotificationActionBtnHidden(true)}
        >
            <header className="notification-element__header">
                <div className="notification-element__icon">
                    <Icon />
                </div>

                <Text className="txt_muted">
                    {useTimeFormatter.formatDate(
                        props.element.dateNow,
                        DateFormat.ShortMonthDayTime,
                    )}
                </Text>

                {isNotificationActionBtnHidden ? null : (
                    <Button
                        appearance="subtle"
                        aria-label="Dismiss"
                        icon={<Dismiss20Regular />}
                        onClick={hide}
                    />
                )}
            </header>
            <div className="notification-element__content">
                <Text>{props.element.message}</Text>
                <div>
                    {props.element.actions === undefined ||
                    props.element.actions.length === 0 ? null : (
                        <>
                            {props.element.actions.map(i => {
                                const onClick = (): void => {
                                    i.onClick(hide);
                                };

                                switch (i.as) {
                                    case "link":
                                        return (
                                            <Link as="button" onClick={onClick}>
                                                {i.label}
                                            </Link>
                                        );

                                    case "button":
                                        return (
                                            <Button
                                                appearance="subtle"
                                                onClick={onClick}
                                            >
                                                {i.label}
                                            </Button>
                                        );

                                    default:
                                        return null;
                                }
                            })}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NotificationToast;
