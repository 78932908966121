import { NotificationService } from "../notification/notificationService";
import { AirErrorService } from "../airError/airErrorService";
import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { StoragePort } from "../ports";
import { Jobs, JobsFetchAllParams, JobsMap, JobsPort } from "./jobsPort";
import { Job } from "../../domain/job/job";

export class JobsService {
    private readonly _jobsPort: JobsPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        jobsPort: JobsPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._jobsPort = jobsPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    private _generateJobMap(jobsResponse: Jobs): JobsMap {
        const jobsMap: JobsMap = new Map();

        jobsResponse.results.forEach((job, index) => {
            jobsMap.set(job.id, index);
        });

        return jobsMap;
    }

    async fetchLatest(): Promise<Option<Jobs>> {
        try {
            this._errorService.clear(AirErrorKind.FetchLatestJobs);
            this._storagePort.jobs.isLatestJobsQueryInProgress.set(true);

            // TODO: Temp solution (excludeSignalR)
            const jobs = await this._jobsPort.fetchLatest(
                this._storagePort.excludeSignalRJobs.get(),
            );

            if (jobs.results.length > 0) {
                const jobsMap = this._storagePort.jobs.jobsMap.get();

                this._storagePort.jobs.allJobs.update(s => {
                    s.newCompletedJobsIds = jobs.newCompletedJobsIds;

                    jobs.results.forEach(i => {
                        const index = jobsMap.get(i.id);

                        if (index !== undefined) {
                            s.results[index] = i;
                        }
                    });
                });
            }

            this._storagePort.jobs.isLatestJobsQueryInProgress.set(false);
            this._storagePort.jobs.latestJobs.set(jobs);

            return this._storagePort.jobs.latestJobs.get();
        } catch (error) {
            this._storagePort.jobs.isLatestJobsQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchLatestJobs,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async view(
        jobList: Job[],
        newCompletedJobsIds: number[],
    ): Promise<Option<Record<"countNewCompletedJobs", number>>> {
        try {
            this._errorService.clear(AirErrorKind.ViewJobs);

            if (newCompletedJobsIds.length > 0) {
                const jobsMap = this._storagePort.jobs.jobsMap.get();

                const viewedJobIds: number[] = [];

                newCompletedJobsIds.forEach(i => {
                    const index = jobsMap.get(i);
                    if (index !== undefined) {
                        viewedJobIds.push(jobList[index].id);
                    }
                });

                const res = await this._jobsPort.view({
                    ids: viewedJobIds,
                });

                return res;
            }
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.ViewJobs,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async fetchAll(params: JobsFetchAllParams): Promise<Option<Jobs>> {
        try {
            this._errorService.clear(AirErrorKind.FetchAllJobs);
            this._storagePort.jobs.isAllJobsQueryInProgress.set(true);

            const jobsResponse = await this._jobsPort.fetchAll(params);

            const jobsMap: JobsMap = this._generateJobMap(jobsResponse);

            this._storagePort.jobs.jobsMap.set(jobsMap);
            this._storagePort.jobs.allJobs.set(jobsResponse);
            this._storagePort.jobs.isAllJobsQueryInProgress.set(false);

            return jobsResponse;
        } catch (error) {
            this._storagePort.jobs.isAllJobsQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchAllJobs,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async downloadByLink(jobId: number): Promise<Option<string>> {
        try {
            this._errorService.clear(AirErrorKind.DownloadByLink);
            this._storagePort.jobs.isDownloadByLinkInProgress.set(true);

            const res = await this._jobsPort.downloadLink(jobId);

            this._storagePort.jobs.isDownloadByLinkInProgress.set(false);

            return res;
        } catch (error) {
            this._storagePort.jobs.isDownloadByLinkInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.DownloadByLink,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}
