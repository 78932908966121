import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import {
    SharepointCheckAccessParams,
    SharepointPort,
    SharepointSiteData,
} from "./sharepointPort";
import { AirErrorService } from "../airError/airErrorService";
import { AirErrorKind } from "../airError/airError";
import { Option } from "../../domain/types/types";

export class SharepointService {
    private readonly _sharepointPort: SharepointPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        sharepointServicePort: SharepointPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._sharepointPort = sharepointServicePort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    async checkAccess(
        params: SharepointCheckAccessParams,
    ): Promise<Option<SharepointSiteData>> {
        try {
            this._errorService.clear(AirErrorKind.CheckAccess);
            this._storagePort.sharepoint.isQueryInProgress.set(true);

            const data = await this._sharepointPort.checkAccess(params);

            this._storagePort.sharepoint.isQueryInProgress.set(false);
            this._storagePort.sharepoint.siteData.set(data);

            return this._storagePort.sharepoint.siteData.get();
        } catch (error) {
            this._storagePort.sharepoint.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.CheckAccess,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}
