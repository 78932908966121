import { StoragePort } from "../../../../application/ports";
import { useStorage } from "../../../../application/useCases/useStorage";
import {
    DataParams,
    defaultDataParams,
} from "../../../../domain/dataParams/dataParams";
import { AdminActiveMenuKind } from "../../../models";
import { router } from "../../../router";
import { AdminState, adminState } from "./adminState/adminState";

type AdminUrlParams = DataParams & {
    type: string | null;
    id: string | null;
    activeMenu: string;
    query: string | null;
};

class AdminManager {
    private readonly _storagePort: StoragePort;
    private readonly _adminState: AdminState;

    constructor(storagePort: StoragePort, adminState: AdminState) {
        this._storagePort = storagePort;
        this._adminState = adminState;
    }

    parseUrl(url: URL): AdminUrlParams {
        const searchParams = url.searchParams;
        const pageSize = searchParams.get("pageSize");
        const page = searchParams.get("page");
        const sortKey = searchParams.get("sortKey");
        const isSortDesc = searchParams.get("isSortDesc");
        const id = searchParams.get("id");
        const type = searchParams.get("type");
        const activeMenu = searchParams.get("activeMenu");
        const query = searchParams.get("query");

        return {
            pageSize: pageSize
                ? parseInt(pageSize)
                : defaultDataParams.pageSize,
            page: page ? parseInt(page) : defaultDataParams.page,
            sortKey: sortKey ?? undefined,
            isSortDesc: isSortDesc !== null ? isSortDesc === "true" : false,
            id,
            type,
            activeMenu:
                activeMenu !== null ? activeMenu : AdminActiveMenuKind.Branding,
            query,
        };
    }

    updateState(url: URL): void {
        const urlParams = this.parseUrl(url);

        this._storagePort.adminMiniApp.dataParams.update(s => {
            if (s.pageSize !== urlParams.pageSize) {
                s.pageSize = urlParams.pageSize;
            }

            if (s.page !== urlParams.page) {
                s.page = urlParams.page;
            }

            if (s.isSortDesc !== urlParams.isSortDesc) {
                s.isSortDesc = urlParams.isSortDesc;
            }

            if (s.sortKey !== urlParams.sortKey) {
                s.sortKey = urlParams.sortKey;
            }
        });

        this._storagePort.adminMiniApp.activeMenu.set(
            urlParams.activeMenu as AdminActiveMenuKind,
        );
        this._storagePort.adminMiniApp.id.set(urlParams.id);
        this._storagePort.adminMiniApp.type.set(urlParams.type);
        this._storagePort.tags.searchQuery.set(urlParams.query);

        this._adminState.urlEntities.set(router.getUrlEntities());
    }

    async init(): Promise<void> {
        this.updateState(router.url);
    }
}

export const adminManager = new AdminManager(useStorage, adminState);
