import qs from "qs";
import {
    TagsGetTagsParams,
    TagsGroup,
    TagsPort,
    TagsUpdateOrderGroupsParams,
    Tag,
    TagsEditGroupParams,
    TagsGetGroupParams,
    TagsAddGroupParams,
    TagsAddOrEditTagParams,
    TagsMergeTagsParams,
    TagsSearchTagsParams,
    TagsSearchResults,
} from "../../application/tags/TagsPort";
import {
    apiCall,
    handleNotOk,
    handleNotOkWithBody,
    validateRes,
} from "../api/api";

export class TagsAdapter implements TagsPort {
    async fetchGroups(): Promise<TagsGroup[]> {
        const res = await apiCall("/api/admin/tag-groups", {
            method: "GET",
        });

        return await validateRes(res);
    }

    async getGroup(params: TagsGetGroupParams): Promise<TagsGroup> {
        const res = await apiCall(`/api/admin/tag-groups/${params.groupId}`, {
            method: "GET",
        });
        return await validateRes(res);
    }

    async editGroup(params: TagsEditGroupParams): Promise<void> {
        const res = await apiCall(`/api/admin/tag-groups/${params.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await handleNotOkWithBody(res);
    }

    async addGroup(params: TagsAddGroupParams): Promise<void> {
        const res = await apiCall(`/api/admin/tag-groups`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await handleNotOkWithBody(res);
    }

    async addOrEditTag(params: TagsAddOrEditTagParams): Promise<void> {
        const res = await apiCall(`/api/admin/tags`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        return await handleNotOkWithBody(res);
    }

    async updateOrderGroups(
        params: TagsUpdateOrderGroupsParams,
    ): Promise<void> {
        const res = await apiCall(`/api/admin/tag-groups/order`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        handleNotOk(res);
    }

    async getTags(params: TagsGetTagsParams): Promise<TagsSearchResults> {
        const res = await apiCall(`/api/tags?${qs.stringify(params)}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });
        const total = parseInt(res.headers.get("x-total") ?? "0");
        const results: Tag[] = await validateRes(res);

        return { total, results };
    }

    async searchTags(params: TagsSearchTagsParams): Promise<TagsSearchResults> {
        const res = await apiCall(`/api/tags/search?${qs.stringify(params)}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });
        const total = parseInt(res.headers.get("x-total") ?? "0");
        const results: Tag[] = await validateRes(res);

        return { total, results };
    }

    async mergeTags(params: TagsMergeTagsParams): Promise<void> {
        const res = await apiCall(`/api/admin/tags/merge`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params),
        });

        handleNotOk(res);
    }
}
