import { CognitiveMetadataAdapter } from "../../infrastructure/cognitiveMetadata/cognitiveMetadataAdapter";
import { CognitiveMetadataService } from "../cognitiveMetadata/cognitiveMetadataService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useCognitiveMetadata = new CognitiveMetadataService(
    new CognitiveMetadataAdapter(),
    useError,
    useStorage,
    useNotification,
);
