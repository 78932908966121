import {
    makeStyles,
    mergeClasses,
    Subtitle2,
} from "@fluentui/react-components";
import { tokens } from "@fluentui/react-theme";
import React from "react";
import logoSP from "../../assets/logo-sp.svg";
import "./shareAccessHeader.scss";

export const useStyles = makeStyles({
    header: { backgroundColor: tokens.colorBrandBackground },
    whiteClr: {
        color: "#ffffff",
    },
});

function ShareAccessHeader(): JSX.Element | null {
    const classes = useStyles();

    return (
        <header className={mergeClasses(classes.header, "share-access-header")}>
            <div className="share-access-header__logo">
                <img
                    className="share-access-header__logo-img"
                    height={32}
                    width={32}
                    src={logoSP}
                    alt="sp logo"
                />

                <Subtitle2
                    truncate
                    block
                    wrap={false}
                    as="h1"
                    className={classes.whiteClr}
                    title="AIR Share Access"
                >
                    AIR Share Access
                </Subtitle2>
            </div>
        </header>
    );
}

export default ShareAccessHeader;
