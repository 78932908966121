import React, { RefObject, SyntheticEvent } from "react";
import ExtendedViewSection from "../ExtendedViewSection/ExtendedViewSection";
import PeopleDetails from "../../../PeopleDetails/PeopleDetails";
import { ExtendedViewData } from "../../../models";
import People from "../../../../People/People";
import { FaceInsight } from "../../../../../../application/cognitiveMetadata/cognitiveMetadataPort";
import { Person } from "../../../../../../domain/airFile/details";

type ExtendedViewPeopleProps = {
    duration: number;
    title: string;
    videoRef: RefObject<HTMLVideoElement>;
    data?: ExtendedViewData[];
    isPlayerPlaying: boolean;
    onPrevInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        setCurrentIndex: (index: number) => void,
    ) => void;
    onPauseInPlayer: () => void;
    onPlayInPlayer: () => void;
    onNextInPlayer: (
        e: SyntheticEvent,
        timeTips: number[],
        currentIndex: number,
        setCurrentIndex: (index: number) => void,
    ) => void;
    showTime: (
        e: React.MouseEvent<HTMLElement>,
        setSeekTime: (time: number) => void,
    ) => void;
    onClickTimeLine: (
        e: React.MouseEvent<HTMLElement>,
        seekTime: number,
    ) => void;
};

function ExtendedViewPeople(
    props: ExtendedViewPeopleProps,
): JSX.Element | null {
    return (
        <ExtendedViewSection
            title={props.title}
            data={props.data}
            duration={props.duration}
            videoRef={props.videoRef}
            renderItem={(
                i: ExtendedViewData,
                activeItem: ExtendedViewData,
                setActiveItem: (i: ExtendedViewData) => void,
            ): JSX.Element => (
                <People
                    name={i.value}
                    image={i.image}
                    isActive={
                        activeItem !== undefined && i.value === activeItem.value
                    }
                    onClick={(): void => {
                        setActiveItem(i);
                    }}
                />
            )}
            btnMoreView="people"
            renderDetails={(activeItem: ExtendedViewData): JSX.Element => (
                <PeopleDetails
                    item={activeItem as FaceInsight | Person}
                    duration={props.duration}
                />
            )}
            isPlayerPlaying={props.isPlayerPlaying}
            onPrevInPlayer={props.onPrevInPlayer}
            onPauseInPlayer={props.onPauseInPlayer}
            onPlayInPlayer={props.onPlayInPlayer}
            onNextInPlayer={props.onNextInPlayer}
            showTime={props.showTime}
            onClickTimeLine={props.onClickTimeLine}
        />
    );
}

export default ExtendedViewPeople;
