import { Theme } from "@fluentui/react-theme";
import { createState } from "@persevie/statemanjs";
import { AdminActiveMenuKind, AppTheme, FilesViewModeKind } from "../models";
import {
    ActiveAppSearchFactoryStorageValue,
    AppDialogPanelStateValue,
    AppDialogStateValue,
    AppPanelTabsStateValue,
    CommandBarActionsFactoryStateValue,
} from "./stateValues";
import { NotificationsStorageValue } from "../../application/storageValues";

// Theme
export const themeState = createState<AppTheme>({
    theme: {} as Theme,
    isDark: false,
});

// Pathname
export const pathnameState = createState(window.location.pathname);

// FileExplorerMiniApp
export const fileExplorerFilesViewModeState = createState<FilesViewModeKind>(
    FilesViewModeKind.List,
);

// AppPanel
export const appPanelTabsState = createState<AppPanelTabsStateValue>(new Map());

export const appPanelActiveTabIdState = createState<string>("");

export const appPanelIsOpenState = createState<boolean>(false);

// CommandBar
export const commandBarActionsFactoryState =
    createState<CommandBarActionsFactoryStateValue>(null);

// CollectionsMiniApp
export const collectionsFilesViewModeState = createState<FilesViewModeKind>(
    FilesViewModeKind.List,
);

// AdminMiniApp
export const activeMenuState = createState<AdminActiveMenuKind>(
    AdminActiveMenuKind.Branding,
);

// AppDialog
export const appDialogState = createState<AppDialogStateValue>(null);

// AppDialogPanel
export const appDialogPanelState = createState<AppDialogPanelStateValue>(null);

// ActiveAppSearchFactory
export const activeAppSearchFactoryState =
    createState<ActiveAppSearchFactoryStorageValue>(null);

// DrawerNotifications
export const drawerNotificationsState = createState<NotificationsStorageValue>(
    new Map(),
);

// JobsMiniApp
export const lastSyncState = createState<string>("");
