import { EBEXJS } from "@persevie/ebexjs";
import {
    CallbackParams,
    EventBus,
    RegisterParams,
} from "../../domain/eventBus/eventBus";

export class EventBusImpl implements EventBus {
    private _ebexjs: EBEXJS;

    constructor() {
        this._ebexjs = new EBEXJS();
    }

    on<T extends object>(registerParams: RegisterParams<T>): void {
        this._ebexjs.on(registerParams);
    }

    off(event: string): void {
        return this._ebexjs.off(event);
    }

    emit<T extends object>(
        event: string,
        data: CallbackParams<T>,
    ): Promise<void> {
        return this._ebexjs.emit(event, data);
    }

    once<T extends object>(registerParams: RegisterParams<T>): void {
        return this._ebexjs.once(registerParams);
    }
}
