import React from "react";
import { SelectionsStorageValue } from "../../../../../application/storageValues";

import "./bulkTaggingPanel.css";
import { FilesTagGroupParam } from "../../../../../application/files/filesPort";
import EditTagsForm from "../../../../components/EditTagsForm/EditTagsForm";
import { useFiles } from "../../../../../application/useCases/useFiles";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";

export function openBulkTaggingPanel(
    filesSelection: SelectionsStorageValue,
): void {
    state.appDialogPanel.set(() => (
        <BulkTaggingPanel
            filesSelection={filesSelection}
            onClose={(): void => {
                state.appDialogPanel.set(null);
            }}
        />
    ));
}

async function onSave(
    filesSelection: SelectionsStorageValue,
    tagGroups: FilesTagGroupParam[],
): Promise<void> {
    if (filesSelection && filesSelection.size > 1) {
        const assetIds: number[] = Array.from(filesSelection.values()).map(
            i => i.id,
        );

        await useFiles.editBatchTags({
            assetIds,
            tagGroups: tagGroups,
        });

        const fileDetailsId = useStorage.files.fileDetails.get()?.id;
        if (fileDetailsId !== undefined) {
            await useFiles.getDetails({
                id: fileDetailsId,
            });
        }
    }
}

type BulkTaggingPanelProps = {
    onClose: () => void;
    filesSelection: SelectionsStorageValue;
};

function BulkTaggingPanel(props: BulkTaggingPanelProps): JSX.Element | null {
    if (props.filesSelection !== undefined && props.filesSelection.size < 2) {
        return null;
    }

    return (
        <div className="bulk-tagging">
            <EditTagsForm
                title="Bulk tagging"
                subTitle={props.filesSelection.size + " files selected"}
                initData={[]}
                onClose={props.onClose}
                onSave={(tagGroups: FilesTagGroupParam[]): void => {
                    onSave(props.filesSelection, tagGroups);
                }}
            />
        </div>
    );
}

export default BulkTaggingPanel;
