import { createComputedState, createState } from "@persevie/statemanjs";
import { DirectoryObjectUser } from "../../application/directoryObjects/directoryObjectsPort";
import { Option } from "../../domain/types/types";
import { SharepointSiteData } from "../../application/sharepoint/sharepointPort";
import {
    DataParams,
    defaultDataParams,
} from "../../domain/dataParams/dataParams";
import {
    ActiveDirectoryStorageValue,
    ActiveItemStorageValue,
    AirErrorsStorageValue,
    AirFileDetailsStorageValue,
    DownloadLocationItemsStorageValue,
    FacetsStorageValue,
    FilesActiveFiltersStorageValue,
    FilesItemPermissionsStorageValue,
    FilesSearchResultStorageValue,
    FilesStorageValue,
    IdStorageValue,
    ItemsStorageValue,
    ItemStorageValue,
    JobsStorageValue,
    MetadataDataStorageValue,
    NotificationsStorageValue,
    SearchQueryStorageValue,
    SelectionsStorageValue,
    SessionStorageValue,
    SharingItemStorageValue,
    SharingLinkStorageValue,
    TagGroupsStorageValue,
    TagsSearchQueryStorageValue,
    TagsStorageValue,
    UserStorageValue,
} from "../../application/storageValues";
import { MiniAppKind } from "../../domain/miniApp/miniApp";
import { DownloadLocation } from "../../domain/downloadLocation/downloadLocation";
import { AirFile } from "../../domain/airFile/airFile";
import { Config, getDefaultConfig } from "../../application/config/config";
import { IndexedLocation } from "../../application/indexedLocation/indexedLocation";
import { AdminActiveMenuKind } from "../../ui/models";
import { Tag, TagsGroup } from "../../application/tags/TagsPort";
import { DownloadLocationlAzureParams } from "../../application/downloadLocation/downloadLocationPort";
import { UserFileUploadStorage } from "../../domain/userFileUpload/userFileUploadStorage";
import { UploadLocation } from "../../domain/uploadLocation/uploadLocation";
import { UserFile } from "../../domain/userFile/userFile";
import {
    AirArchiveConfig,
    DEFAULT_AIR_ARCHIVE_CONFIG,
} from "../../domain/airArchive/airArchive";
import { JobsMap } from "../../application/jobs/jobsPort";
import { SharingGetSharingDetailsRes } from "../../application/sharing/sharingPort";
import { AirFileDetails } from "../../domain/airFile/details";

// Authorization
export const userState = createState<UserStorageValue>(null);
export const sessionState = createState<SessionStorageValue>(null);

// CognitiveMetadata
export const metadataState = createState<MetadataDataStorageValue>(null);
export const isCognitiveMetadataQueryInProgressState =
    createState<boolean>(false);

// Collections
export const listState = createState<ItemsStorageValue>(null);
export const itemState = createState<ItemStorageValue>(null);
export const isCollectionsQueryInProgressState = createState<boolean>(false);
export const idState = createState<IdStorageValue>(null);

// DirectoryObjects
export const isDirectoryObjectsQueryInProgressState =
    createState<boolean>(false);
export const directoryObjectUsersState = createState<DirectoryObjectUser[]>([]);

// AirErrors
export const airErrorsState = createState<AirErrorsStorageValue>(new Map());

// Facets
export const isFacetsQueryInProgressState = createState<boolean>(false);
export const facetsState = createState<FacetsStorageValue>([]);

// Files
export const isFilesQueryInProgressState = createState<boolean>(false);
export const filesState = createState<FilesStorageValue>([]);
export const searchFilesState = createState<FilesStorageValue>([]);
export const sharingLinkState = createState<SharingLinkStorageValue>(null);
export const sharingItemState = createState<SharingItemStorageValue>(null);
export const isSharingLinkQueryInProgressState = createState<boolean>(false);
export const searchQueryState = createState<SearchQueryStorageValue>(null);
export const fileDetailsState = createState<AirFileDetailsStorageValue>(null);
export const isFileDetailsQueryInProgressState = createState<boolean>(false);
export const activeFiltersState =
    createState<FilesActiveFiltersStorageValue>(null);
export const filesToShowState = createState<FilesStorageValue>([]);
export const searchFilesToShowState = createState<FilesStorageValue>([]);
export const filePermissionsState =
    createState<FilesItemPermissionsStorageValue>(null);
export const searchResultState =
    createState<FilesSearchResultStorageValue>(null);
export const validFilesState = createState<FilesStorageValue>([]);
export const tagGroupsState = createState<TagGroupsStorageValue>(null);
export const isTagGroupsQueryInProgressState = createState<boolean>(false);
export const tagsState = createState<TagsStorageValue>(null);
export const isTagsQueryInProgressState = createState<boolean>(false);
export const isGetTiersQueryInProgressState = createState<boolean>(false);

// Jobs
export const latestJobsState = createState<JobsStorageValue>({
    newCompletedJobsIds: [],
    results: [],
    total: 0,
});
export const allJobsState = createState<JobsStorageValue>({
    newCompletedJobsIds: [],
    results: [],
    total: 0,
});

export const allJobsDataParamsState = createState<DataParams>({
    ...defaultDataParams,
});

export const isLatestJobsQueryInProgressState = createState<boolean>(false);
export const isAllJobsQueryInProgressState = createState<boolean>(false);
export const isDownloadByLinkInProgressState = createState<boolean>(false);

export const jobsMapState = createState<JobsMap>(new Map());

// Sharepoint
export const siteDataState = createState<Option<SharepointSiteData>>(null);
export const isSharepointQueryInProgressState = createState<boolean>(false);

// AppCommon
export const activeMiniAppState = createState<MiniAppKind>(
    MiniAppKind.Explorer,
);

// FileExplorerMiniApp
export const activeDirectoryState =
    createState<ActiveDirectoryStorageValue>(null);
export const activeItemState = createState<ActiveItemStorageValue>(null);
export const isFileExplorerMiniAppDataInitState = createState<boolean>(false);
export const isFileExplorerMiniAppStateInitState = createState<boolean>(false);
export const isNeedSearchState = createState<boolean>(false);

export const fileExplorerSelectedDownloadLocationState =
    createState<Option<DownloadLocation>>(null);

export const fileExplorerSelectionState = createState<SelectionsStorageValue>(
    new Map<string, AirFile>(),
);

export const fileExplorerDataParamsState = createState<DataParams>({
    ...defaultDataParams,
});
export const fileExploereContextState = createState<Option<string>>(null);
export const fileExploereModeState = createComputedState<"view" | "search">(():
    | "view"
    | "search" => {
    const searchQuery = searchQueryState.get();
    const activeFilters = activeFiltersState.get();

    if (
        (searchQuery !== null &&
            searchQuery !== undefined &&
            searchQuery.length > 0) ||
        (activeFilters !== null &&
            activeFilters !== undefined &&
            activeFilters.size > 0)
    ) {
        return "search";
    }

    return "view";
}, [searchQueryState, activeFiltersState]);

// CollectionsMiniApp
export const isCollectionsMiniAppDataInitState = createState<boolean>(false);
export const isCollectionsMiniAppStateInitState = createState<boolean>(false);

export const collectionsDataParamsState = createState<DataParams>({
    ...defaultDataParams,
});

export const collectionsSelectedDownloadLocationState =
    createState<Option<DownloadLocation>>(null);

export const collectionsSelectionState = createState<SelectionsStorageValue>(
    new Map<string, AirFile>(),
);
export const collectionContextState = createState<Option<string>>(null);

// AdminMiniApp
export const idAdminState = createState<IdStorageValue>(null);
export const typeAdminState = createState<IdStorageValue>(null);
export const isAdminMiniAppStateInitState = createState<boolean>(false);
export const adminActiveMenuState = createState<AdminActiveMenuKind>(
    AdminActiveMenuKind.Branding,
);
export const adminDataParamsState = createState<DataParams>({
    ...defaultDataParams,
});

// Notifications
export const notificationsState = createState<NotificationsStorageValue>(
    new Map(),
);

// Config
export const configState = createState<Config>(getDefaultConfig());
export const isQueryInProgressState = createState<boolean>(false);

// Indexed location
export const indexedLocationActiveItemState =
    createState<IndexedLocation | null>(null);
export const indexedLocationsState = createState<IndexedLocation[]>([]);
export const isQueryIndexedLocationsInProgressState =
    createState<boolean>(false);

// Download location
export const downloadLocationsState = createState<Map<string, boolean>>(
    new Map(),
);
export const azureFilesDownloadLocationsState = createState<
    Map<number, DownloadLocationlAzureParams>
>(new Map());
export const isQueryDownloadLocationsInProgressState =
    createState<boolean>(false);
export const isFetchAzureLocationsQueryInProgressState =
    createState<boolean>(false);
export const isFetchAzureLocationQueryInProgressState =
    createState<boolean>(false);
export const isEditAzureLocationQueryInProgressState =
    createState<boolean>(false);
export const isAddAzureLocationQueryInProgressState =
    createState<boolean>(false);
export const isFetchByTypeQueryInProgressState = createState<boolean>(false);
export const isEditByTypeQueryInProgressState = createState<boolean>(false);
export const isCreateServiceAccountInProgressState =
    createState<boolean>(false);
export const isEditServiceAccountInProgressState = createState<boolean>(false);
export const isDeleteServiceAccountInProgressState =
    createState<boolean>(false);
export const downloadLocationItemsState =
    createState<DownloadLocationItemsStorageValue>(new Map());

// Tags
export const isQueryTagsInProgressState = createState<boolean>(false);
export const isQueryTagsListInProgressState = createState<boolean>(false);
export const tagsGroupsState = createState<Map<number, TagsGroup>>(new Map());
export const tagsListState = createState<Map<number, Tag> | null>(null);
export const selectedTagsState = createState<Map<number, Tag>>(new Map());
export const tagsTotalState = createState<number>(0);
export const tagsSearchQueryState =
    createState<TagsSearchQueryStorageValue>(null);

// UploaderMiniApp
export const isFetchLocationsQueryInProgressState = createState<boolean>(false);
export const locationsState = createState<UploadLocation[] | null>(null);
export const isFetchUploaderFeaturesQueryInProgressState =
    createState<boolean>(false);
export const userFilesState = createState<UserFile[] | null>(null);
export const ignoredUserFilesState = createState<UserFile[] | null>(null);
export const isAddDataQueryInProgressState = createState<boolean>(false);
export const uploadState = createState<UserFileUploadStorage>({
    pending: new Map(),
    uploading: new Map(),
    paused: new Map(),
    completed: new Map(),
    aborted: new Map(),
});
export const isFetchDestinationFoldersQueryInProgressState =
    createState<boolean>(false);
export const uploaderContextState = createState<Option<string>>(null);

// AirArchive (admin)
export const archiveConfigState = createState<AirArchiveConfig>(
    DEFAULT_AIR_ARCHIVE_CONFIG,
);
export const isFetchConfigQueryInProgressState = createState<boolean>(false);
export const isUpdateConfigQueryInProgressState = createState<boolean>(false);

// TODO: Temp solution (excludeSignalRState)
export const excludeSignalRJobsState = createState(false);
// Sharing
export const isCreateQueryInProgressState = createState<boolean>(false);
export const isGetSharingDetailsQueryInProgressState =
    createState<boolean>(false);
export const isGetFilesQueryInProgressState = createState<boolean>(false);
export const isGetDownloadLinkQueryInProgressState =
    createState<boolean>(false);
export const isGetFilesDetailsQueryInProgressState =
    createState<boolean>(false);
export const sharingIsDownloadAllQueryInProgressState =
    createState<boolean>(false);
export const sharingDetailsState =
    createState<Option<SharingGetSharingDetailsRes>>(null);
export const sharingPasswordState = createState<string | null>(null);
export const sharedFilesState = createState<AirFile[]>([]);
export const sharingFileDetailsState =
    createState<Option<AirFileDetails>>(null);
export const sharingShareUrlState = createState<string | null>(null);
export const sharingDownloadLinkState = createState<string | null>(null);
export const sharingFilesToShowState = createState<AirFile[]>([]);
export const sharingIsAccessGrantedState = createState<boolean>(false);

// preview
export const isGetPreviewQueryInProgressState = createState(false);
export const isUpdatePreviewQueryInProgressState = createState(false);
// admin
export const adminIsUpdateShareLinkUIConfigurationQueryInProgressState =
    createState<boolean>(false);
export const adminIsUpdateBrandingConfigQueryInProgressState =
    createState<boolean>(false);
export const adminIsUpdateConfigQueryInProgressState =
    createState<boolean>(false);

// ai
export const isGetAIQueryInProgressState = createState(false);
export const isUpdateAIQueryInProgressState = createState(false);
