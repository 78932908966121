import React from "react";
import { state } from "../../state/stateAdapter";
import { useCommonFluentuiStyles } from "../../styles/griffel";
import { Switch } from "@fluentui/react-components";
import "./userSettings.scss";
import { toggleTheme } from "../../theme";

function UserSettings(): JSX.Element {
    const { isDark } = state.useState(state.theme);
    const classes = useCommonFluentuiStyles();

    return (
        <div className="user-settings">
            <div className="user-settings__group">
                <Switch
                    checked={isDark}
                    label="Dark mode"
                    labelPosition="before"
                    onChange={toggleTheme}
                    className={classes.switch}
                />
            </div>
        </div>
    );
}

export default UserSettings;
