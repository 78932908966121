import React, { useCallback, useEffect, useState } from "react";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    Button,
    makeStyles,
    shorthands,
    tokens,
} from "@fluentui/react-components";

import { Dismiss24Regular, RotateLeftRegular } from "@fluentui/react-icons";

type FullScreenImageProps = {
    title: string;
    path: string;
    isOpen: boolean;
    toggleFullScreenImage: () => void;
};

const useStyles = makeStyles({
    surface: {
        height: "100dvh",
        width: "100vw",
        maxWidth: "100vw",
        ...shorthands.borderRadius(0),
        backgroundColor: tokens.colorNeutralBackgroundAlpha2,
    },
    body: { gridTemplateRows: "max-content auto max-content", height: "100% " },
    outer: {
        width: "100%",
        height: "100%",
        ...shorthands.overflow("auto"),
    },
    inner: {
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
    },
});

function FullScreenImage(props: FullScreenImageProps): JSX.Element {
    const classes = useStyles();
    const [currentRotation, setCurrentRotation] = useState(0);

    const onRotate = useCallback((): void => {
        setCurrentRotation(currentRotation - 90);
    }, [currentRotation]);

    useEffect(() => {
        return () => setCurrentRotation(0);
    }, []);

    return (
        <Dialog
            open={props.isOpen}
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    props.toggleFullScreenImage();
                }
            }}
        >
            <DialogSurface className={classes.surface}>
                <DialogBody className={classes.body}>
                    <DialogTitle
                        action={
                            <>
                                <Button
                                    icon={<RotateLeftRegular />}
                                    appearance="subtle"
                                    onClick={onRotate}
                                >
                                    Rotate
                                </Button>

                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label="close"
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            </>
                        }
                    >
                        {props.title}
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.outer}>
                            <div
                                className={classes.inner}
                                style={{
                                    backgroundImage: `url(${props.path})`,
                                    backgroundPosition: "center",
                                    transform: `rotate(${currentRotation}deg)`,
                                }}
                            />
                        </div>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default FullScreenImage;
