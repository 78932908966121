import React from "react";
import {
    Toolbar,
    ToolbarButton,
    shorthands,
    ToolbarButtonProps,
    makeStyles,
    Divider,
    Text,
} from "@fluentui/react-components";
import "./form.css";

const useStyles = makeStyles({
    toolbar: {
        ...shorthands.padding(0),
        paddingBottom: "8px",
        ...shorthands.gap("16px"),
    },
    title: {
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "24px",
        display: "grid",
        ...shorthands.gap("16px"),
        gridAutoFlow: "column",
        gridAutoColumns: "max-content",
        alignContent: "center",
    },
});

type FormProps = {
    buttons?: ToolbarButtonProps[];
    title?: string;
    subTitle?: string;
    titleIcon?: JSX.Element;
    children: JSX.Element;
};

function Form(props: FormProps): JSX.Element {
    const classes = useStyles();

    return (
        <form className="form" onSubmit={(e): void => e.preventDefault()}>
            <div>
                {props.buttons ? (
                    <>
                        <Toolbar className={classes.toolbar}>
                            {props.buttons.map(
                                (i: ToolbarButtonProps): JSX.Element => (
                                    <ToolbarButton key={i.title} {...i}>
                                        <Text wrap={false} truncate block>
                                            {i.title}
                                        </Text>
                                    </ToolbarButton>
                                ),
                            )}
                        </Toolbar>
                        <Divider />
                    </>
                ) : null}
            </div>
            <div className="form__content">
                <div className="form__header">
                    {props.titleIcon ?? null}
                    <div>
                        {props.title ? (
                            <Text className={classes.title}>{props.title}</Text>
                        ) : null}
                        {props.subTitle ? <Text>{props.subTitle}</Text> : null}
                    </div>
                </div>
                {props.children}
            </div>
        </form>
    );
}

export default Form;
