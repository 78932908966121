import { AirFile } from "../airFile/airFile";
import { DownloadLocation } from "../downloadLocation/downloadLocation";

export type User = {
    id: number;
    name: string;
    rePermissionLocations: number[];
    changeTierLocations: number[];
    downloadLocations: DownloadLocation[];
    photo: string;
    roles: string[];
};

export const adminRoles = ["air-admins"];

export function hasUserAdminPermissions(user: User): boolean {
    return user.roles.reduce((isAdmin, role) => {
        return isAdmin || adminRoles.includes(role);
    }, false);
}

export function hasUserDownloadPermissions(
    selection: AirFile[],
    user: User,
): boolean {
    const hasDownloadPermissions = user.downloadLocations.length > 0;

    return hasDownloadPermissions;
}

export function hasUserChangeTierPermissions(
    selection: AirFile[],
    user: User,
): boolean {
    const hasSourcePermissions = selection.every(item =>
        user.changeTierLocations.includes(item.locationId),
    );

    const hasChangeStorageTierPermissions = user.changeTierLocations.length > 0;

    return hasSourcePermissions && hasChangeStorageTierPermissions;
}

export function hasUserPermissionToRepermissionThisFile(
    file: AirFile,
    user: User,
): boolean {
    return user.rePermissionLocations.includes(file.locationId);
}
