import {
    Field,
    Input,
    InputOnChangeData,
    Select,
    SelectOnChangeData,
    Spinner,
    Switch,
} from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDownloadLocation } from "../../../../../../../application/useCases/useDownloadLocation";
import { useIndexedLocation } from "../../../../../../../application/useCases/useIndexedLocation";
import { useStorage } from "../../../../../../../application/useCases/useStorage";
import { protectedDataParamsKeys } from "../../../../../../../domain/dataParams/dataParams";

import {
    IndexedLocationStorageAccount,
    IndexedLocationSubscription,
} from "../../../../../../../application/indexedLocation/indexedLocation";
import SelectUsersField from "../../../../../../components/Form/SelectUsersField/SelectUsersField";
import Loader from "../../../../../../components/Loader/Loader";
import SliderSize from "../../../../../../components/SliderSize/SliderSize";
import { router } from "../../../../../../router";
import { state } from "../../../../../../state/stateAdapter";
import { useCommonFluentuiStyles } from "../../../../../../styles/griffel";
import AdminLayout from "../../../AdminLayout/AdminLayout";
import {
    downloadLocationButesLimitMax,
    downloadLocationButesLimitMin,
    downloadLocationButesLimitStep,
} from "../../DownloadLocations";
import {
    DownloadLocationType,
    DownloadLocationPermission,
} from "../../../../../../../application/downloadLocation/downloadLocation";
import { AirErrorKind } from "../../../../../../../application/airError/airError";

function isEqual(data: unknown | null, initData: unknown | null): boolean {
    if (
        (data === null && initData === null) ||
        (data === undefined && initData === undefined)
    ) {
        return true;
    }
    return JSON.stringify(data) === JSON.stringify(initData);
}

const emptyData = {
    title: "",
    locationType: DownloadLocationType.AzureFiles,
    bytesLimit: 0,
    enabled: true,
    permissions: [],
    basePath: "",
    subscriptionId: "",
    resourceId: "",
    storageName: "",
    shareName: "",
    accessKey: "",
};

export type AzureLocationFormData = {
    id?: number;
    title: string;
    locationType: DownloadLocationType;
    bytesLimit: number;
    enabled: boolean;
    permissions: DownloadLocationPermission[];
    basePath: string;
    subscriptionId: string | null;
    resourceId: string | null;
    storageName: string | null;
    shareName: string | null;
    accessKey: string | null;
};

function AzureLocationForm(): JSX.Element {
    const id = state.useState(useStorage.adminMiniApp.id);
    const [subscriptions, setSubscriptions] =
        useState<IndexedLocationSubscription[]>();
    const [storageAccounts, setStorageAccounts] = useState<
        IndexedLocationStorageAccount[]
    >([]);
    const [shareNames, setShareNames] = useState<string[]>([]);
    const commonClasses = useCommonFluentuiStyles();
    const [isDisabledField, setIsDisabledField] = useState<boolean>(false);
    const [isQueryInProgress, setIsQueryInProgress] = useState<boolean>(true);
    const [data, setData] = useState<AzureLocationFormData>();
    const [initData, setInitData] = useState<AzureLocationFormData>();
    const [formKey, setFormKey] = useState<number>(0);
    const isFormValid =
        data !== null &&
        data?.permissions &&
        data?.permissions?.length > 0 &&
        data.title.length > 0 &&
        data.subscriptionId !== undefined &&
        data.subscriptionId !== null &&
        data.subscriptionId.length > 0;

    async function onSelectSubscription(
        _e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
        d: InputOnChangeData | SelectOnChangeData,
    ): Promise<void> {
        setIsQueryInProgress(true);
        const res = await useIndexedLocation.fetchStorageAccounts({
            subscriptionId: d.value,
        });
        setIsQueryInProgress(false);

        if (data !== undefined) {
            setData({
                ...data,
                accessKey: "",
                resourceId: "",
                shareName: "",
                subscriptionId: d.value,
            });
        }

        if (res) {
            setStorageAccounts([...res]);
        }
        setShareNames([]);
    }

    async function onSelectStorageAccount(
        _e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
        d: InputOnChangeData | SelectOnChangeData,
    ): Promise<void> {
        if (d.value !== undefined && d.value !== null) {
            setIsQueryInProgress(true);
            const res = await useIndexedLocation.getStorageAccount({
                storageAccountId: d.value,
            });
            setIsQueryInProgress(false);

            if (res !== null && res !== undefined && data !== undefined) {
                setShareNames(res.shares);
                setData({
                    ...data,
                    accessKey: res.accessKey,
                    resourceId: res.id,
                    shareName: "",
                });
            }
        }
    }

    const updateData = useCallback(
        (
            name: string,
            value:
                | string
                | number
                | undefined
                | boolean
                | DownloadLocationPermission[],
        ): void => {
            if (
                data !== undefined &&
                data[name as keyof AzureLocationFormData] !== value
            ) {
                setData({ ...data, [name]: value });
            }
        },
        [data],
    );

    const saveChanges = useCallback(async () => {
        const backLink = {
            search: {
                items: [
                    {
                        key: "type",
                        value: DownloadLocationType.AzureFiles,
                    },
                ],
                protect: [...protectedDataParamsKeys, "activeMenu"],
            },
        };
        if (isFormValid && data !== undefined) {
            try {
                setIsQueryInProgress(true);

                if (data.locationType === null) {
                    data.locationType = DownloadLocationType.AzureFiles;
                }

                if (id == "add") {
                    await useDownloadLocation.addAzureLocation(data);
                    router.goTo(backLink);
                } else {
                    if (id !== undefined && id !== null) {
                        await useDownloadLocation.editAzureLocation({
                            ...data,
                            id: parseInt(id),
                        });
                        router.goTo(backLink);
                    }
                }
            } catch (err) {
                setIsQueryInProgress(false);
            }
        }
    }, [data, id, isFormValid]);

    function discardChanges(): void {
        if (initData !== undefined) {
            setData({ ...initData });
            setFormKey(formKey + 1);
        }
    }

    useEffect(() => {
        async function fetchData(id: string): Promise<void> {
            setIsQueryInProgress(true);
            const item = await useDownloadLocation.fetchAzureLocation({
                id: parseInt(id),
            });

            if (item !== null && item !== undefined) {
                if (item.subscriptionId !== null) {
                    const resStorageAccounts =
                        await useIndexedLocation.fetchStorageAccounts({
                            subscriptionId: item.subscriptionId,
                        });

                    if (
                        useStorage.airErrors
                            .get()
                            .has(AirErrorKind.FetchStorageAccounts)
                    ) {
                        setIsDisabledField(true);
                    } else {
                        if (
                            resStorageAccounts !== null &&
                            resStorageAccounts !== undefined
                        ) {
                            setStorageAccounts([...resStorageAccounts]);
                        }

                        if (
                            item.resourceId !== null &&
                            item.resourceId !== undefined
                        ) {
                            const resStorageAccount =
                                await useIndexedLocation.getStorageAccount({
                                    storageAccountId: item.resourceId,
                                });
                            if (
                                resStorageAccount !== null &&
                                resStorageAccount !== undefined
                            ) {
                                setShareNames(resStorageAccount.shares);
                                item.accessKey = resStorageAccount.accessKey;
                            }
                        }
                    }
                }

                setData({ ...item });
                setInitData({ ...item });
            }
            setIsQueryInProgress(false);
        }

        if (id !== undefined && id !== null && parseInt(id)) {
            fetchData(id);
        } else {
            setData({ ...emptyData });
            setInitData({ ...emptyData });
        }
    }, [id]);

    useEffect(() => {
        if (subscriptions === undefined) {
            useIndexedLocation.fetchSubscriptions().then(res => {
                if (res) {
                    setSubscriptions(res);
                }
                setIsQueryInProgress(false);
            });
        }
    }, [subscriptions]);

    if (data === undefined) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            buttons={[
                {
                    title: "Save changes",
                    onClick: saveChanges,
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled:
                        isQueryInProgress ||
                        !isFormValid ||
                        isEqual(initData, data),
                },
                {
                    title: "Discard changes",
                    onClick: discardChanges,
                    appearance: "subtle",
                    disabled: isQueryInProgress || isEqual(initData, data),
                },
            ]}
            backLink={{
                href: router.createRoute({
                    search: {
                        items: [
                            {
                                key: "type",
                                value: DownloadLocationType.AzureFiles,
                            },
                        ],
                        protect: [...protectedDataParamsKeys, "activeMenu"],
                    },
                }),
                title: "Back to Azure Files download locations",
            }}
            title={id == "add" ? "New Azure restore destination" : data.title}
        >
            <form className="admin__form" key={formKey}>
                <Switch
                    className={commonClasses.field}
                    checked={data.enabled}
                    onChange={(_e, data): void => {
                        updateData("enabled", data.checked);
                    }}
                    label="Enable Azure restore destination"
                />

                <Field
                    className={commonClasses.field}
                    label="Label / Friendly name"
                    required
                >
                    <Input
                        size="large"
                        value={data.title}
                        disabled={!data.enabled}
                        onChange={(_e, d): void => {
                            updateData("title", d.value);
                        }}
                    />
                </Field>

                <Field
                    label="Azure Subscription"
                    required
                    className={commonClasses.field}
                >
                    <Select
                        size="large"
                        name="subscription"
                        value={data?.subscriptionId ?? ""}
                        onChange={onSelectSubscription}
                        placeholder="Select subscription"
                        disabled={
                            isQueryInProgress ||
                            !data.enabled ||
                            isDisabledField
                        }
                    >
                        <option>
                            {isDisabledField
                                ? data.subscriptionId
                                : "Select subscription"}
                        </option>
                        {subscriptions &&
                            subscriptions.map(i => (
                                <option key={i.id} value={i.id}>
                                    {i.name}
                                </option>
                            ))}
                    </Select>
                    {isQueryInProgress ? (
                        <Spinner
                            size="extra-small"
                            className={commonClasses.fieldSpinner}
                        />
                    ) : null}
                </Field>

                <Field label="Storage account" className={commonClasses.field}>
                    <Select
                        size="large"
                        name="resourceId"
                        value={data?.resourceId ?? ""}
                        onChange={onSelectStorageAccount}
                        placeholder="Select storage account"
                        disabled={
                            isQueryInProgress ||
                            data?.subscriptionId === "" ||
                            !data.enabled ||
                            isDisabledField
                        }
                    >
                        <option>
                            {isDisabledField
                                ? data.storageName
                                : "Select storage account"}
                        </option>
                        {storageAccounts.map(i => (
                            <option key={i.id} value={i.id}>
                                {i.name}
                            </option>
                        ))}
                    </Select>
                    {isQueryInProgress ? (
                        <Spinner
                            size="extra-small"
                            className={commonClasses.fieldSpinner}
                        />
                    ) : null}
                </Field>

                <Field label="File share name" className={commonClasses.field}>
                    <Select
                        size="large"
                        name="shareName"
                        value={data?.shareName ?? ""}
                        onChange={(_e, d): void => {
                            updateData("shareName", d.value);
                        }}
                        placeholder="Select share name"
                        disabled={
                            isQueryInProgress ||
                            data?.resourceId === "" ||
                            !data.enabled ||
                            isDisabledField
                        }
                    >
                        <option>
                            {isDisabledField
                                ? data.shareName
                                : "Select share name"}
                        </option>
                        {shareNames.map(i => (
                            <option key={i} value={i}>
                                {i}
                            </option>
                        ))}
                    </Select>
                    {isQueryInProgress ? (
                        <Spinner
                            size="extra-small"
                            className={commonClasses.fieldSpinner}
                        />
                    ) : null}
                </Field>

                <Field label="Access key" className={commonClasses.field}>
                    <Input
                        placeholder="Acsess key"
                        size="large"
                        name="accessKey"
                        value={data.accessKey ?? ""}
                        type="text"
                        disabled
                    />
                </Field>

                <Field label="Base path" className={commonClasses.field}>
                    <Input
                        disabled={!data.enabled}
                        placeholder="Base path"
                        size="large"
                        value={data.basePath}
                        type="text"
                        onChange={(e): void => {
                            updateData("basePath", e.target.value);
                        }}
                    />
                </Field>

                <Field
                    className={commonClasses.field}
                    label="List of AD security groups who could restore to this file share"
                    required
                >
                    <SelectUsersField
                        disabled={!data.enabled}
                        onChange={(permissions): void => {
                            updateData("permissions", permissions);
                        }}
                        data={data?.permissions}
                    />
                </Field>

                <Field className={commonClasses.field}>
                    <SliderSize
                        title="Limit file size for direct download"
                        disabled={!data.enabled}
                        min={downloadLocationButesLimitMin}
                        step={downloadLocationButesLimitStep}
                        max={downloadLocationButesLimitMax}
                        value={data.bytesLimit}
                        onChange={(value: number): void => {
                            updateData("bytesLimit", value);
                        }}
                    />
                </Field>
            </form>
        </AdminLayout>
    );
}

export default AzureLocationForm;
