import { formatDuration } from "../../ui/utility/formatters";
import { normalizePath } from "../../utility";
import { EventBus } from "../eventBus/eventBus";
import { DomainEvents } from "../events/events";
import { FileChunk, splitIntoChunks } from "../fileChunk/fileChunk";
import { GeneratorIdRepository } from "../generatorId/generatorIdRepository";
import { UploadLocation } from "../uploadLocation/uploadLocation";
import { UserFile } from "../userFile/userFile";
import { UserFileUploadRepository } from "./userFileUploadRepository";
import { UserFileUploadStoragePort } from "./userFileUploadStoragePort";

enum UploadingStage {
    CREATING_FILE,
    UPLOADING,
    COMPLETING,
}

function getUserFileUploadErrorMessage(stage: UploadingStage): string {
    switch (stage) {
        case UploadingStage.UPLOADING:
            return "Error during uploading";

        case UploadingStage.COMPLETING:
            return "Error during completing upload";

        default:
            return "Error during creating file";
    }
}

export interface UserFileUpload {
    userFile: UserFile;
    chunks: FileChunk[];
    currentChunkIndex: number;
    abortController: AbortController;
    startTime: number;
    endTime: number;
    uploadedBytes: number;
    uploadSpeed: number;
    path: string;
    chunksMap: string[];
    uploadAttempts: number;
    chunkSize: number;
    location: UploadLocation;
    retryTimer: number;
    eta: string;
    progress: number;
    abortReason: string | null;
    leaseId: string;
}

export function formUploadDestination(
    location: UploadLocation,
    folderPath: string,
): string {
    if (folderPath === "") {
        return location.title;
    }

    return normalizePath(`${location.title}/${folderPath}`);
}

export function formUserFileUploadPath(
    uploadDestination: string,
    userFile: UserFile,
): string {
    return normalizePath(`${uploadDestination}/${userFile.fullFilePath}`);
}

function createUserFileUploadList(
    userFile: UserFile[],
    chunkSize: number,
    location: UploadLocation,
    folderPath: string,
    generatorIdRepository: GeneratorIdRepository,
): UserFileUpload[] {
    return userFile.map(userFile => {
        const chunks = splitIntoChunks(
            userFile.file,
            chunkSize,
            generatorIdRepository,
        );

        const path = formUserFileUploadPath(folderPath, userFile);

        return {
            userFile,
            chunks,
            currentChunkIndex: 0,
            startTime: Date.now(),
            endTime: Date.now(),
            uploadedBytes: 0,
            uploadSpeed: 0,
            path,
            chunksMap: [],
            uploadAttempts: 0,
            chunkSize,
            location,
            retryTimer: 5,
            // TODO: abstract
            abortController: new AbortController(),
            eta: "",
            progress: 0,
            abortReason: null,
            leaseId: generatorIdRepository.generate(),
        };
    });
}

function updateUploadingQueue(
    maxConcurrentUploads: number,
    userFileUploadStoragePort: UserFileUploadStoragePort,
    userFileUploadRepository: UserFileUploadRepository,
    retryCount: number,
    retryDelay: number[],
): void {
    if (userFileUploadStoragePort.get().pending.size === 0) {
        return;
    }

    if (
        userFileUploadStoragePort.get().uploading.size >= maxConcurrentUploads
    ) {
        return;
    }

    const capacity =
        maxConcurrentUploads - userFileUploadStoragePort.get().uploading.size;

    const uploadFiles: UserFileUpload[] = [];

    userFileUploadStoragePort.update(s => {
        let count = 1;
        for (const [key, value] of s.pending) {
            if (count > capacity) {
                break;
            }

            s.uploading.set(key, value);
            uploadFiles.push(value);
            s.pending.delete(key);
            count++;
        }
    });

    uploadFiles.forEach(i => {
        uploadFile(
            maxConcurrentUploads,
            i,
            userFileUploadStoragePort,
            userFileUploadRepository,
            retryCount,
            retryDelay,
        );
    });
}

async function completeUpload(
    userFileUpload: UserFileUpload,
    userFileUploadStoragePort: UserFileUploadStoragePort,
    userFileUploadRepository: UserFileUploadRepository,
): Promise<void> {
    const uploading = userFileUploadStoragePort.get().uploading;

    if (uploading.size === 0 || !uploading.has(userFileUpload.leaseId)) {
        return;
    }

    await userFileUploadRepository.complete(userFileUpload);

    userFileUploadStoragePort.update(s => {
        const file = s.uploading.get(userFileUpload.leaseId);

        if (file !== undefined) {
            file.endTime = Date.now();
            s.completed.set(userFileUpload.leaseId, file);
            s.uploading.delete(userFileUpload.leaseId);
        }
    });
}

function failUpload(
    userFileUpload: UserFileUpload,
    userFileUploadRepository: UserFileUploadRepository,
    userFileUploadStoragePort: UserFileUploadStoragePort,
    reason: string,
): void {
    userFileUpload.abortController.abort();
    userFileUploadStoragePort.update(s => {
        const file = s.uploading.get(userFileUpload.leaseId);

        if (file !== undefined) {
            file.abortReason = reason;
            file.endTime = Date.now();
            s.aborted.set(userFileUpload.leaseId, file);
            s.uploading.delete(userFileUpload.leaseId);
        }
    });
    userFileUploadRepository.fail(
        userFileUpload.leaseId,
        userFileUpload.userFile.file.size,
        userFileUpload.path,
        userFileUpload.location.id,
    );
}

async function tryToUpload(
    userFileUpload: UserFileUpload,
    userFileUploadStoragePort: UserFileUploadStoragePort,
    userFileUploadRepository: UserFileUploadRepository,
    chunk: FileChunk,
    index: number,
    retryCount: number,
    retryDelay: number[],
): Promise<void> {
    try {
        await userFileUploadRepository.uploadChunk(
            userFileUpload.location.id,
            userFileUpload.path,
            userFileUpload.abortController.signal,
            chunk,
            userFileUpload.leaseId,
        );
    } catch (error) {
        const uploadError = new Error(
            `Error during uploading chunk ${index + 1}: ${error}`,
        );

        userFileUploadStoragePort.update(s => {
            const file = s.uploading.get(userFileUpload.leaseId);

            if (file !== undefined) {
                file.abortReason = uploadError.message;
                s.uploading.set(file.leaseId, file);
            }
        });

        if (userFileUpload.uploadAttempts <= retryCount) {
            const currentRetryDelay =
                retryDelay[userFileUpload.uploadAttempts - 1] ??
                retryDelay[retryDelay.length - 1];

            userFileUploadStoragePort.update(s => {
                const file = s.uploading.get(userFileUpload.leaseId);

                if (file !== undefined) {
                    file.retryTimer = currentRetryDelay;
                    s.uploading.set(file.leaseId, file);
                }
            });

            const retryCountInterval = setInterval(() => {
                userFileUploadStoragePort.update(s => {
                    const file = s.uploading.get(userFileUpload.leaseId);

                    if (file !== undefined) {
                        file.retryTimer -= 1;
                        s.uploading.set(file.leaseId, file);
                    }
                });
            }, 1000);

            await new Promise(resolve =>
                setTimeout(resolve, currentRetryDelay * 1000),
            );

            clearInterval(retryCountInterval);

            if (
                userFileUploadStoragePort
                    .get()
                    .uploading.has(userFileUpload.leaseId)
            ) {
                userFileUploadStoragePort.update(s => {
                    const file = s.uploading.get(userFileUpload.leaseId);

                    if (file !== undefined) {
                        file.abortReason = null;
                        file.uploadAttempts += 1;

                        s.uploading.set(file.leaseId, file);
                    }
                });

                await tryToUpload(
                    userFileUpload,
                    userFileUploadStoragePort,
                    userFileUploadRepository,
                    chunk,
                    index,
                    retryCount,
                    retryDelay,
                );
            }
        } else {
            throw uploadError;
        }
    }
}

async function uploadChunks(
    userFileUpload: UserFileUpload,
    userFileUploadStoragePort: UserFileUploadStoragePort,
    userFileUploadRepository: UserFileUploadRepository,
    retryCount: number,
    retryDelay: number[],
): Promise<void> {
    for (let index = 0; index < userFileUpload.chunks.length; index++) {
        const chunk = userFileUpload.chunks[index];

        userFileUploadStoragePort.update(s => {
            const file = s.uploading.get(userFileUpload.leaseId);

            if (file !== undefined) {
                file.currentChunkIndex = index;
                file.uploadAttempts = 1;

                s.uploading.set(file.leaseId, file);
            }
        });

        await tryToUpload(
            userFileUpload,
            userFileUploadStoragePort,
            userFileUploadRepository,
            chunk,
            index,
            retryCount,
            retryDelay,
        );

        userFileUploadStoragePort.update(s => {
            const file = s.uploading.get(userFileUpload.leaseId);

            if (file !== undefined) {
                file.uploadedBytes += chunk.endByte - chunk.startByte;

                const elapsedTime = Date.now() - userFileUpload.startTime;
                const speedBPerMs = userFileUpload.uploadedBytes / elapsedTime;
                const speedKBPerS = (speedBPerMs * 1000) / 1024;

                const remainingSize =
                    userFileUpload.userFile.file.size -
                    userFileUpload.uploadedBytes;

                file.chunksMap.push(chunk.id);
                file.uploadSpeed = speedKBPerS;
                file.progress = file.uploadedBytes / file.userFile.file.size;
                file.eta = formatDuration(remainingSize / speedBPerMs);

                s.uploading.set(file.leaseId, file);
            }
        });
    }
}

async function uploadFile(
    maxConcurrentUploads: number,
    userFileUpload: UserFileUpload,
    userFileUploadStoragePort: UserFileUploadStoragePort,
    userFileUploadRepository: UserFileUploadRepository,
    retryCount: number,
    retryDelay: number[],
): Promise<void> {
    let stage: UploadingStage = UploadingStage.CREATING_FILE;
    try {
        await userFileUploadRepository.createFile(userFileUpload);

        stage = UploadingStage.UPLOADING;
        await uploadChunks(
            userFileUpload,
            userFileUploadStoragePort,
            userFileUploadRepository,
            retryCount,
            retryDelay,
        );

        stage = UploadingStage.COMPLETING;
        await completeUpload(
            userFileUpload,
            userFileUploadStoragePort,
            userFileUploadRepository,
        );

        updateUploadingQueue(
            maxConcurrentUploads,
            userFileUploadStoragePort,
            userFileUploadRepository,
            retryCount,
            retryDelay,
        );
    } catch (error) {
        failUpload(
            userFileUpload,
            userFileUploadRepository,
            userFileUploadStoragePort,
            getUserFileUploadErrorMessage(stage),
        );

        updateUploadingQueue(
            maxConcurrentUploads,
            userFileUploadStoragePort,
            userFileUploadRepository,
            retryCount,
            retryDelay,
        );
    }
}

export async function upload(
    maxConcurrentUploads: number,
    chunkSize: number,
    userFiles: UserFile[],
    userFileUploadStoragePort: UserFileUploadStoragePort,
    userFileUploadRepository: UserFileUploadRepository,
    location: UploadLocation,
    folderPath: string,
    retryCount: number,
    generatorIdRepository: GeneratorIdRepository,
    eventBus: EventBus,
    retryDelay: number[],
): Promise<void> {
    const userFileUploadList = createUserFileUploadList(
        userFiles,
        chunkSize,
        location,
        folderPath,
        generatorIdRepository,
    );

    userFileUploadStoragePort.update(s => {
        userFileUploadList.forEach(i => {
            if (!s.pending.has(i.leaseId) && !s.uploading.has(i.leaseId)) {
                s.pending.set(i.leaseId, i);
            } else {
                eventBus.emit<{ filePath: string }>(
                    DomainEvents.FileUploadSkip,
                    { filePath: i.path },
                );
            }
        });
    });

    updateUploadingQueue(
        maxConcurrentUploads,
        userFileUploadStoragePort,
        userFileUploadRepository,
        retryCount,
        retryDelay,
    );
}

export function cancelUpload(
    userFileUpload: UserFileUpload,
    userFileUploadStoragePort: UserFileUploadStoragePort,
    maxConcurrentUploads: number,
    userFileUploadRepository: UserFileUploadRepository,
    retryCount: number,
    retryDelay: number[],
): void {
    userFileUpload.abortController.abort("Canceled by user");
    userFileUploadStoragePort.update(s => {
        s.uploading.delete(userFileUpload.leaseId);
    });

    updateUploadingQueue(
        maxConcurrentUploads,
        userFileUploadStoragePort,
        userFileUploadRepository,
        retryCount,
        retryDelay,
    );
}

export async function retryUpload(
    maxConcurrentUploads: number,
    chunkSize: number,
    userFileUpload: UserFileUpload,
    userFileUploadStoragePort: UserFileUploadStoragePort,
    userFileUploadRepository: UserFileUploadRepository,
    retryCount: number,
    generatorIdRepository: GeneratorIdRepository,
    eventBus: EventBus,
    retryDelay: number[],
): Promise<void> {
    const userFileList: UserFile[] = [{ ...userFileUpload.userFile }];
    const location = userFileUpload.location;
    const folderPath = userFileUpload.path;

    userFileUploadStoragePort.update(s => {
        s.aborted.delete(userFileUpload.leaseId);
    });

    upload(
        maxConcurrentUploads,
        chunkSize,
        userFileList,
        userFileUploadStoragePort,
        userFileUploadRepository,
        location,
        folderPath,
        retryCount,
        generatorIdRepository,
        eventBus,
        retryDelay,
    );
}
