import { AuthorizationConfig } from "./authorizationConfig";
import { AuthorizationPort } from "./authorizationPort";
import { Session } from "../../domain/session/session";
import { AirErrorKind } from "../airError/airError";
import { AppCachePort } from "../appCache/appCachePort";
import { StoragePort } from "../ports";
import { AirErrorService } from "../airError/airErrorService";
import { AppInterfacePort } from "../appInterface/appInterfacePort";
import { Option } from "../../domain/types/types";
import { UserCredentials } from "../../domain/user/userCredentials";

export class AuthorizationService {
    private readonly _authorizationPort: AuthorizationPort;
    private readonly _cachePort: AppCachePort;
    private readonly _storagePort: StoragePort;
    private readonly _errorService: AirErrorService;
    private readonly _interfacePort: AppInterfacePort;

    constructor(
        authorizationPort: AuthorizationPort,
        cachePort: AppCachePort,
        storagePort: StoragePort,
        errorService: AirErrorService,
        interfacePort: AppInterfacePort,
    ) {
        this._authorizationPort = authorizationPort;
        this._cachePort = cachePort;
        this._storagePort = storagePort;
        this._errorService = errorService;
        this._interfacePort = interfacePort;
    }

    async authenticate(): Promise<void> {
        try {
            this._errorService.clear(AirErrorKind.Authenticate);

            const user = this._cachePort.getUser();
            const session = this._cachePort.getSession();

            if (user !== null && session !== null) {
                if (
                    this.isValidSessionExpirations(session) &&
                    !this.isSessionExpired(session)
                ) {
                    this._storagePort.authorization.user.set(user);
                    this._storagePort.authorization.session.set(session);

                    return;
                }
            }

            const authConfig: AuthorizationConfig =
                await this._authorizationPort.getAuthorizationConfig();
            this._cachePort.setAuthorizationState(authConfig.state);
            this._cachePort.setReturnUri(
                this._interfacePort.getCurrentLocation(),
            );

            this._interfacePort.navigate(authConfig.uri);
        } catch (error) {
            this._errorService.createAndSave(
                AirErrorKind.Authenticate,
                error as Error,
            );
        }
    }

    async signIn(): Promise<void> {
        try {
            this._errorService.clear(AirErrorKind.SignIn);

            const authorizationState = this._cachePort.getAuthorizationState();
            const returnUri = this._cachePort.getReturnUri();

            if (authorizationState === null || returnUri === null) {
                await this.authenticate();
            } else {
                const userCredentials = await this.getUserCredentials(
                    authorizationState,
                );

                if (userCredentials !== null && userCredentials !== undefined) {
                    this._cachePort.setUser({
                        ...userCredentials.user,
                        photo: userCredentials.photo,
                    });
                    this._cachePort.setSession({
                        start: Date.now(),
                        token: userCredentials.accessToken,
                        expiresIn: userCredentials.expiresIn * 1000,
                        sessionId: authorizationState,
                    });
                    this._cachePort.setAuthorizationCode(userCredentials.code);

                    await this._authorizationPort.signIn(
                        userCredentials.accessToken,
                        authorizationState,
                    );

                    if (returnUri === null) {
                        throw new Error("No return uri");
                    }

                    this._interfacePort.navigate(returnUri);
                } else {
                    await this.signOut();
                }
            }
        } catch (error) {
            this._errorService.createAndSave(
                AirErrorKind.SignIn,
                error as Error,
            );
        }
    }

    async signOut(): Promise<void> {
        try {
            this._errorService.clear(AirErrorKind.SignOut);

            const session = this._storagePort.authorization.session.get();

            if (session == null || session.sessionId == null) {
                throw new Error("no authorization state found");
            }

            const user = this._storagePort.authorization.user.get();

            if (user == null) {
                throw new Error("no user found");
            }

            await this._authorizationPort.signOut(
                session.token,
                session.sessionId,
            );

            this._storagePort.authorization.user.set(null);
            this._storagePort.authorization.session.set(null);

            this._cachePort.clear();
        } catch (error) {
            this._errorService.createAndSave(
                AirErrorKind.SignOut,
                error as Error,
            );
        }
    }

    isValidSessionExpirations(session: Session): boolean {
        if (
            typeof session.start !== "number" ||
            isNaN(session.start) ||
            typeof session.expiresIn !== "number" ||
            isNaN(session.expiresIn)
        ) {
            return false;
        }

        return true;
    }

    isSessionExpired(session: Session): boolean {
        return Date.now() - session.start >= session.expiresIn;
    }

    async getAuthorizationConfig(
        redirectUri: string,
    ): Promise<Option<AuthorizationConfig>> {
        try {
            return await this._authorizationPort.getAuthorizationConfig(
                redirectUri,
            );
        } catch (error) {
            this._errorService.createAndSave(
                AirErrorKind.Authenticate,
                error as Error,
            );
        }
    }

    async getUserCredentials(
        authorizationState: string,
    ): Promise<Option<UserCredentials>> {
        try {
            return await this._authorizationPort.getUserCredentials(
                authorizationState,
            );
        } catch (error) {
            this._errorService.createAndSave(
                AirErrorKind.Authenticate,
                error as Error,
            );
        }
    }
}
