import React from "react";
import { useNotification } from "../../../../../application/useCases/useNotification";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { useUploader } from "../../../../../application/useCases/useUploader";
import { AddDataMethod } from "../../../../../domain/userFile/userFile";
import { state } from "../../../../state/stateAdapter";
import ConfirmationDialog from "../ConfirmatioDialog/ConfirmationDialog";
import { uploaderState } from "./state";

function processData(files: FileList, addDataMethod: AddDataMethod): void {
    uploaderState.selection.set(new Map());

    // trick to close menu
    document.body.click();
    const userFiles = useStorage.uploaderMiniApp.userFiles.get();
    if (userFiles !== null && userFiles !== undefined && userFiles.length > 0) {
        state.appDialog.set(() => (
            <ConfirmationDialog
                title="Adding new data"
                confirmationCb={function (): void {
                    useUploader.addData(files, addDataMethod);
                }}
                message="Adding new data will clear the current ones.
                This will not affect current uploads. Are
                you sure you want to proceed?"
            />
        ));
    } else {
        useUploader.addData(files, addDataMethod);
    }
}

export function onAddData(event: React.ChangeEvent<HTMLInputElement>): void {
    const files = event.target.files;
    if (files && files.length > 0) {
        processData(files, AddDataMethod.Files);
    }
}

export function onAddCameraCard(
    event: React.ChangeEvent<HTMLInputElement>,
): void {
    const files = event.target.files;

    if (files && files.length > 0) {
        const firstFile = files[0];
        if (
            firstFile.webkitRelativePath &&
            firstFile.webkitRelativePath.split("/").length > 1
        ) {
            processData(files, AddDataMethod.CameraCard);
        } else {
            useNotification.show(
                "Please select a folder, not a file.",
                "error",
            );
        }
    }
}

export function onDragNDroppData(files: File[]): void {
    processData(files as unknown as FileList, AddDataMethod.DragNDrop);
}

export function changeMetadata(
    name: string,
    description: string,
    id: string,
): void {
    const userFiles = useStorage.uploaderMiniApp.userFiles.get();

    if (userFiles === null || userFiles === undefined) {
        return;
    }

    const fileIndex = userFiles.findIndex(f => f.id === id);

    if (fileIndex === -1) {
        return;
    }

    useStorage.uploaderMiniApp.userFiles.update(s => {
        if (s !== null && s !== undefined) {
            s[fileIndex].name = name;
            s[fileIndex].description = description;
        }
    });
}

export function changeSelectedFilesMetadata(description: string): void {
    const selection = uploaderState.selection.get();

    if (selection.size === 0) {
        return;
    }

    useStorage.uploaderMiniApp.userFiles.update(s => {
        if (s !== null && s !== undefined) {
            for (const [key, _] of selection) {
                const fileIndex = s.findIndex(f => f.id === key);

                if (fileIndex === -1) {
                    continue;
                }

                s[fileIndex].description = description;
            }
        }
    });
}

export function removeFile(fileId: string): void {
    const userFiles = useStorage.uploaderMiniApp.userFiles.get();

    if (userFiles === null || userFiles === undefined) {
        return;
    }

    const selection = uploaderState.selection.get();
    const newState = userFiles.filter(f => f.id !== fileId);

    if (newState.length !== userFiles.length) {
        if (selection !== null && selection !== undefined) {
            uploaderState.selection.update(s => {
                s.delete(fileId);
            });
        }

        useStorage.uploaderMiniApp.userFiles.set(newState);
    }
}

export function removeSelectedFiles(): void {
    const selection = uploaderState.selection.get();

    if (selection.size === 0) {
        return;
    }

    const userFiles = useStorage.uploaderMiniApp.userFiles.get();

    if (userFiles === null || userFiles === undefined) {
        return;
    }

    const selectionIds = Array.from(selection.values()).map(i => i.id);

    const newState = userFiles.filter(f => {
        return !selectionIds.includes(f.id);
    });

    if (newState.length !== userFiles.length) {
        useStorage.uploaderMiniApp.userFiles.set(newState);
        clearSelection();
    }
}

export function clearSelection(): void {
    uploaderState.selection.set(new Map());
}

export function toggleAppPanel(): void {
    state.appPanel.isOpen.set(!state.appPanel.isOpen.get());
}
