import { Caption1, Checkbox, Text } from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import React, { useCallback, useState } from "react";
import Loader from "../../../../components/Loader/Loader";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";
import { useAdmin } from "../../../../../application/useCases/useAdmin";
import {
    ConfigKey,
    ShareLinkUIConfig,
} from "../../../../../application/config/config";
import { useConfig } from "../../../../../application/useCases/useConfig";

function Sharing(): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    const shareLinkUiConfiguration = state.useState(useStorage.config.config, {
        properties: ["features.shareLinkUiConfiguration"],
    }).features.shareLinkUiConfiguration;
    const isAdminUpdateConfigQueryInProgress = state.useState(
        useStorage.admin.isUpdateConfigQueryInProgress,
    );
    const isUpdateConfigQueryInProgress = state.useState(
        useStorage.config.isQueryInProgress,
    );

    const [isLoading, setIsLoading] = useState(false);
    const [shareByLinkVariant, setShareByLinkVariant] = useState(
        shareLinkUiConfiguration.plainLink,
    );
    const [shareMSEIDVariant, setShareMSEIDVariant] = useState(
        shareLinkUiConfiguration.domainOnly,
    );
    const [shareWithPasswordVariant, setShareWithPasswordVariant] = useState(
        shareLinkUiConfiguration.password,
    );

    const isSharingDisabled =
        shareByLinkVariant === false &&
        shareMSEIDVariant === false &&
        shareWithPasswordVariant === false;

    const areVariantsTheSame =
        shareLinkUiConfiguration.plainLink === shareByLinkVariant &&
        shareLinkUiConfiguration.domainOnly === shareMSEIDVariant &&
        shareLinkUiConfiguration.password === shareWithPasswordVariant;

    const areActionsDisabled = areVariantsTheSame;

    const onSave = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        const res = await useAdmin.updateConfigByKey<ShareLinkUIConfig>(
            ConfigKey.Sharing,
            {
                plainLink: shareByLinkVariant,
                domainOnly: shareMSEIDVariant,
                password: shareWithPasswordVariant,
                emailNotifications: false,
                enabled: !isSharingDisabled,
            },
        );

        if (res) {
            await useConfig.updateConfigByKey(ConfigKey.Sharing, res);
        } else {
            await useConfig.getConfig();
        }

        setIsLoading(false);
    }, [
        isSharingDisabled,
        shareByLinkVariant,
        shareMSEIDVariant,
        shareWithPasswordVariant,
    ]);

    const onDiscard = useCallback((): void => {
        setShareByLinkVariant(shareLinkUiConfiguration.plainLink);
        setShareMSEIDVariant(shareLinkUiConfiguration.domainOnly);
        setShareWithPasswordVariant(shareLinkUiConfiguration.password);
    }, [
        shareLinkUiConfiguration.domainOnly,
        shareLinkUiConfiguration.password,
        shareLinkUiConfiguration.plainLink,
    ]);

    if (
        isAdminUpdateConfigQueryInProgress ||
        isUpdateConfigQueryInProgress ||
        isLoading
    ) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            buttons={[
                {
                    title: "Save changes",
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled: areActionsDisabled,
                    onClick: onSave,
                },
                {
                    title: "Discard changes",
                    onClick: onDiscard,
                    appearance: "subtle",
                    disabled: areActionsDisabled,
                },
            ]}
        >
            <form className="admin__form">
                <section className="common-settings-section">
                    <section className="common-settings-fields">
                        <div>
                            <Text weight="semibold">
                                Allowed variants to share content
                            </Text>

                            <br />
                            {isSharingDisabled ? (
                                <>
                                    <Caption1>
                                        If no options are selected, sharing is
                                        disabled across an app regardless
                                        permissions set
                                    </Caption1>
                                </>
                            ) : null}
                            <br />

                            <div className={commonClasses.hContainer}>
                                <Checkbox
                                    checked={shareMSEIDVariant}
                                    onChange={(): void =>
                                        setShareMSEIDVariant(!shareMSEIDVariant)
                                    }
                                    label="Microsoft Entra ID (applied to Internal tenant only)"
                                />

                                <Checkbox
                                    checked={shareWithPasswordVariant}
                                    onChange={(): void =>
                                        setShareWithPasswordVariant(
                                            checked => !checked,
                                        )
                                    }
                                    label="Plain Password"
                                />

                                <Checkbox
                                    checked={shareByLinkVariant}
                                    onChange={(): void =>
                                        setShareByLinkVariant(
                                            !shareByLinkVariant,
                                        )
                                    }
                                    label="Anyone with the link"
                                />
                            </div>
                        </div>
                    </section>
                </section>
            </form>
        </AdminLayout>
    );
}

export default Sharing;
