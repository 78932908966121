import React from "react";
import { PanelTab } from "../../../../models";
import { appManager } from "../../../../appManager/appManager";
import FileExplorerFilters from "./FileExplorerFilters";

const filtersTab: PanelTab = {
    id: "filters",
    title: "Filters",
    content: <FileExplorerFilters />,
    onClose: () => appManager.deleteTab("filters"),
};

export default filtersTab;
