import {
    useTableFeatures,
    useTableSort,
    TableColumnId,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    TableColumnDefinition,
    SortDirection,
    useTableSelection,
    TableSelectionCell,
} from "@fluentui/react-components";
import React from "react";
import { UserFile } from "../../../domain/userFile/userFile";
import { useCommonFluentuiStyles } from "../../styles/griffel";
import { FileSelectionsStateValue } from "../../state/stateValues";

type SortParams = {
    sortDirection: "ascending" | "descending";
    sortColumn: string;
};

type UserFileTableProps = {
    columns: TableColumnDefinition<UserFile>[];
    items: UserFile[];
    sortState: SortParams;
    selectionState: FileSelectionsStateValue<UserFile>;
    onSort: (
        e: React.SyntheticEvent<Element, Event>,
        state: SortParams,
    ) => void;
    onSelect: (
        event: React.SyntheticEvent<Element, Event>,
        file: UserFile,
    ) => void;
    onAllSelect: (
        e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
        items?: UserFile[],
    ) => void;
    columnSizes: Map<string | number, number>;
};

function UserFileTable(props: UserFileTableProps): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    const {
        getRows,
        sort: { getSortDirection, toggleColumnSort, sort },
    } = useTableFeatures(
        {
            columns: props.columns,
            items: props.items,
        },
        [
            useTableSort({
                sortState: props.sortState,
                onSortChange: props.onSort,
            }),
            useTableSelection({
                selectionMode: "multiselect",
                selectedItems: Array.from(props.selectionState.values()).map(
                    i => i.id,
                ),
            }),
        ],
    );

    const headerSortProps = (
        columnId: TableColumnId,
    ): {
        onClick: (e: React.MouseEvent) => void;
        sortDirection: SortDirection | undefined;
    } => ({
        onClick: (e: React.MouseEvent) => toggleColumnSort(e, columnId),
        sortDirection: getSortDirection(columnId),
    });

    const rows = sort(
        getRows(row => {
            const isSelected = (id: string): boolean => {
                return props.selectionState.has(id);
            };

            return {
                ...row,
                onClick: (e: React.MouseEvent, file: UserFile) =>
                    props.onSelect(e, file),
                onKeyDown: (e: React.KeyboardEvent, file: UserFile): void => {
                    if (e.key === " ") {
                        e.preventDefault();
                        props.onSelect(e, file);
                    }
                },
                getAppearance: (
                    id: string,
                ): "none" | "brand" | "neutral" | undefined =>
                    isSelected(id) ? ("neutral" as const) : ("none" as const),
                isSelected,
            };
        }),
    );

    const toggleAllKeydown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === " ") {
            props.onAllSelect(e, props.items);
            e.preventDefault();
        }
    };

    return (
        <div className="table-container">
            <Table sortable aria-label="user file table" className="table">
                <TableHeader className={commonClasses.tableHeader}>
                    <TableRow>
                        <TableSelectionCell
                            checked={
                                props.selectionState.size === props.items.length
                                    ? true
                                    : props.selectionState.size > 0
                                    ? "mixed"
                                    : false
                            }
                            onClick={(
                                e:
                                    | React.MouseEvent<Element, MouseEvent>
                                    | React.KeyboardEvent<Element>,
                            ): void => props.onAllSelect(e, props.items)}
                            onKeyDown={toggleAllKeydown}
                            checkboxIndicator={{
                                "aria-label": "Select all rows ",
                            }}
                        />

                        {props.columns.map(i => (
                            <TableHeaderCell
                                key={`header-row-${i.columnId}`}
                                {...headerSortProps(i.columnId)}
                                style={
                                    props.columnSizes.has(i.columnId)
                                        ? {
                                              width: `${props.columnSizes.get(
                                                  i.columnId,
                                              )}px`,
                                          }
                                        : undefined
                                }
                            >
                                {i.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map(
                        (
                            {
                                item,
                                isSelected,
                                onClick,
                                onKeyDown,
                                getAppearance,
                            },
                            rowIndex,
                        ) => (
                            <TableRow
                                key={`row-${rowIndex}-${item.id}`}
                                onClick={(
                                    e: React.MouseEvent<Element, MouseEvent>,
                                ): void => onClick(e, item)}
                                onKeyDown={(e): void => onKeyDown(e, item)}
                                appearance={getAppearance(item.id)}
                                className={commonClasses.tableRow}
                            >
                                <TableSelectionCell
                                    subtle
                                    checked={isSelected(item.id)}
                                    checkboxIndicator={{
                                        "aria-label": "Select row",
                                    }}
                                />

                                {props.columns.map((column, columnIndex) => (
                                    <TableCell
                                        key={`cell-${columnIndex}-${item.id}`}
                                    >
                                        {column.renderCell(item)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ),
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

export default UserFileTable;
