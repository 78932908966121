import { CollectionsAdapter } from "../../infrastructure/collections/collectionsAdapter";
import { CollectionsService } from "../collections/collectionsService";
import { useError } from "./useError";
import { useFiles } from "./useFiles";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useCollections = new CollectionsService(
    new CollectionsAdapter(),
    useFiles,
    useError,
    useStorage,
    useNotification,
);
