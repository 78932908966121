import {
    AppearanceInsight,
    KeyFrameInsight,
} from "../../../application/cognitiveMetadata/cognitiveMetadataPort";

export enum VideoExtendedViewTabKind {
    Insights,
    Timeline,
}

export type ExtendedViewData = {
    value: string;
    description: string | null;
    appearances: AppearanceInsight[];
    url?: string | null;
    image?: string | null;
    title?: string | null;
    tags?: string[] | null;
    keyFrames?: KeyFrameInsight[] | null;
};
