import React, { SyntheticEvent, useCallback } from "react";
import { TableColumnDefinition, Text } from "@fluentui/react-components";
import FilesTable from "../../../../components/FilesTable/FilesTable";
import { FilesViewModeKind } from "../../../../models";
import { state } from "../../../../state/stateAdapter";
import { AirFile, isFile } from "../../../../../domain/airFile/airFile";
import Tiles from "../../../../components/Tiles/Tiles";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { CollectionInfo } from "../../../../../domain/collection/collections";
import CollectionTiles from "../CollectionTiles/CollectionTiles";
import { AirError } from "../../../../../application/airError/airError";
import { columnSizes } from "../collectionsColumns";
import { router, routes } from "../../../../router";
import { protectedDataParamsKeys } from "../../../../../domain/dataParams/dataParams";
import { RouteParams } from "../../../../router/urlEntities";

type CollectionsDataProps = {
    error: AirError | null;
    items: unknown[];
    columns: TableColumnDefinition<unknown>[];
    selectable: boolean;
};

function onAllSelect(
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent,
    items?: unknown[],
): void {
    const target = e.target as HTMLInputElement | null;

    if (target?.tagName === "INPUT" && items) {
        if (target.checked) {
            const selection = (items as AirFile[])
                .map(file => file.hierarchy)
                .join(",");
            router.setSearchParams([{ key: "selection", value: selection }]);
        } else {
            router.removeSearchParam("selection");
        }
    }
}

function onSelect(e: SyntheticEvent, item: unknown): void {
    const target = e.target as HTMLElement | null;
    const airFile = item as AirFile;

    if (target && target.tagName === "INPUT") {
        const selectionStr = router
            .getUrlEntities()
            .searchParams.get("selection");
        const selectionArr = selectionStr ? selectionStr.split(",") : [];

        const index = selectionArr.indexOf(airFile.hierarchy);

        if (index > -1) {
            selectionArr.splice(index, 1);
        } else {
            selectionArr.push(airFile.hierarchy);
        }

        if (selectionArr.length) {
            router.setSearchParams([
                { key: "selection", value: selectionArr.join(",") },
            ]);
        } else {
            router.removeSearchParam("selection");
        }
    }
}

function onSingleSelect(
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
    item: unknown,
): void {
    const target = e.target as HTMLElement | null;

    if (target && target.tagName !== "INPUT") {
        router.setSearchParams([
            { key: "selection", value: (item as AirFile).hierarchy },
        ]);
    }
}

function CollectionsData(props: CollectionsDataProps): JSX.Element {
    const { isSortDesc, sortKey } = state.useState(
        useStorage.collectionsMiniApp.dataParams,
    );
    const filesViewMode = state.useState(
        state.collectionsMiniApp.filesViewMode,
    );
    const filesSelection = state.useState(
        useStorage.collectionsMiniApp.selection,
    );

    const createLink = useCallback((item: AirFile, isFile: boolean): string => {
        const linkParams: RouteParams = isFile
            ? {
                  search: {
                      items: [
                          {
                              key: "activeItem",
                              value: item.id.toString(),
                          },
                      ],
                      protect: [
                          ...protectedDataParamsKeys,
                          "viewMode",
                          "path",
                          "id",
                      ],
                  },
              }
            : {
                  page: routes.explorer,
                  search: {
                      items: [
                          {
                              key: "path",
                              value: item.hierarchy,
                          },
                      ],
                  },
              };
        const link = router.createRoute(linkParams);

        return link;
    }, []);

    if (props.items.length === 0) {
        return <Text>Nothing to show</Text>;
    }

    if (filesViewMode === FilesViewModeKind.Tiles) {
        const collectionId = useStorage.collections.id.get();

        if (collectionId === null) {
            return <CollectionTiles items={props.items as CollectionInfo[]} />;
        }

        return (
            <Tiles
                isQueryInProgress={false}
                error={undefined}
                activeDirectory={""}
                files={props.items as AirFile[]}
                filesSelection={filesSelection}
                onSelect={onSelect}
                onSingleSelect={onSingleSelect}
                isFile={isFile}
                selectable={props.selectable}
                createLink={createLink}
            />
        );
    }

    return (
        <FilesTable
            isQueryInProgress={false}
            error={undefined}
            files={props.items}
            columns={props.columns as TableColumnDefinition<unknown>[]}
            selection={
                props.selectable
                    ? {
                          filesSelection,
                          onAllSelect,
                          onSelect,
                      }
                    : undefined
            }
            sorting={{
                isSortDesc,
                sortKey: sortKey ?? "",
            }}
            columnSizes={columnSizes}
        />
    );
}

export default CollectionsData;
