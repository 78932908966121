import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

/** Common styles for fluentui components */
export const useCommonFluentuiStyles = makeStyles({
    noMarginText: { ...shorthands.margin(0) },
    switch: {
        alignItems: "center",
        "& label": {
            ...shorthands.padding(0),
        },
    },
    noPadding: { ...shorthands.padding(0) },
    linkClr: { color: tokens.colorBrandForegroundLink },
    borderBottom: {
        ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
    },
    dangerBackground: {
        backgroundColor: tokens.colorPaletteRedBackground3,
        ":hover": {
            backgroundColor: tokens.colorPaletteRedForeground1,
        },
    },
    field: {
        position: "relative",
        "& > label": {
            fontWeight: tokens.fontWeightSemibold,
        },
    },
    fieldSpinner: {
        position: "absolute",
        right: "12px",
        top: "34px",
    },
    tableRow: {
        ":hover": {
            backgroundColor: tokens.colorNeutralBackground4Hover,
        },
    },
    btnMenuItem: {
        fontWeight: "normal",
        paddingRight: "8px",
        paddingLeft: "8px",
        display: "grid",
        justifyContent: "left",
    },
    splitBtnMenuItem: {
        display: "grid",
        fontWeight: "normal",
        width: "100%",
        gridAutoFlow: "column",
        gridTemplateColumns: "auto max-content",
        justifyContent: "normal",
        justifyItems: "start",
        paddingLeft: "0",
        paddingRight: "0",
    },
    tableHeader: {
        position: "sticky",
        top: 0,
        zIndex: 2,
    },
    brandTxt: {
        color: tokens.colorBrandForeground1,
    },
    select: {
        "& > select": {
            "background-color": "transparent",
            ...shorthands.border("none"),
        },
    },
    neutralBg: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    hContainer: {
        display: "grid",
        gridAutoRows: "auto",
    },
});
