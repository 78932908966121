import { AuthorizationAdapter } from "../../infrastructure/authorization/authorizationAdapter";
import { AuthorizationService } from "../authorization/authorizationService";
import { useCache } from "./useCache";
import { useError } from "./useError";
import { useInterface } from "./useInterface";
import { useStorage } from "./useStorage";

export const useAuthorization = new AuthorizationService(
    new AuthorizationAdapter(),
    useCache,
    useStorage,
    useError,
    useInterface,
);
