import { useStatemanjs } from "@persevie/statemanjs-react";
import { StatemanjsAPI } from "@persevie/statemanjs";
import {
    AdminMiniAppStatePort,
    AppDialogPanelStatePort,
    AppDialogStatePort,
    AppPanelStatePort,
    CollectionsMiniAppStatePort,
    CommandBarStatePort,
    FileExplorerMiniAppStatePort,
    JobsMiniAppPort,
    PathnameStatePort,
    StatePort,
    ThemeStatePort,
} from "./statePort";
import {
    NotifyCondition,
    Properties,
    StorageItemPort,
} from "../../application/storageItemPort";
import {
    activeAppSearchFactoryState,
    appDialogState,
    appPanelActiveTabIdState,
    appPanelIsOpenState,
    appPanelTabsState,
    collectionsFilesViewModeState,
    commandBarActionsFactoryState,
    fileExplorerFilesViewModeState,
    pathnameState,
    themeState,
    appDialogPanelState,
    drawerNotificationsState,
    lastSyncState,
} from "./stateService";
import { ActiveAppSearchFactoryStorageValue } from "./stateValues";
import {
    adminActiveMenuState,
    idAdminState,
} from "../../infrastructure/storage/storageService";
import { NotificationsStorageValue } from "../../application/storageValues";

class StateAdapter implements StatePort {
    readonly useState = <T>(
        state: StorageItemPort<T>,
        options?: {
            notifyCondition?: NotifyCondition<T>;
            properties?: Properties;
        },
    ): T => {
        return useStatemanjs(state as StatemanjsAPI<T>, options);
    };

    readonly unwrap = <T>(state: StorageItemPort<T>): T => {
        return (state as StatemanjsAPI<T>).unwrap();
    };

    readonly appDialog: AppDialogStatePort = appDialogState;

    readonly appDialogPanel: AppDialogPanelStatePort = appDialogPanelState;

    readonly appPanel: AppPanelStatePort = {
        activeTabId: appPanelActiveTabIdState,
        isOpen: appPanelIsOpenState,
        tabs: appPanelTabsState,
    };

    readonly collectionsMiniApp: CollectionsMiniAppStatePort = {
        filesViewMode: collectionsFilesViewModeState,
    };

    readonly adminMiniApp: AdminMiniAppStatePort = {
        activeMenu: adminActiveMenuState,
        id: idAdminState,
    };

    readonly commandBar: CommandBarStatePort = {
        actionsFactory: commandBarActionsFactoryState,
    };

    readonly fileExplorerMiniApp: FileExplorerMiniAppStatePort = {
        filesViewMode: fileExplorerFilesViewModeState,
    };

    readonly pathname: PathnameStatePort = pathnameState;

    readonly theme: ThemeStatePort = themeState;

    readonly activeAppSearchFactory: StorageItemPort<ActiveAppSearchFactoryStorageValue> =
        activeAppSearchFactoryState;

    readonly drawerNotifications: StorageItemPort<NotificationsStorageValue> =
        drawerNotificationsState;

    readonly jobsMiniApp: JobsMiniAppPort = {
        lastSyncState: lastSyncState,
    };
}

export const state = new StateAdapter();
