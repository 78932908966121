import * as React from "react";
import {
    TagGroup,
    InteractionTag,
    InteractionTagPrimary,
    InteractionTagPrimaryProps,
    makeStyles,
    shorthands,
    Menu,
    MenuPopover,
    MenuTrigger,
    useIsOverflowItemVisible,
    useOverflowMenu,
    Overflow,
    OverflowItem,
    Button,
    Tooltip,
    Caption1,
} from "@fluentui/react-components";
import { state } from "../../../../state/stateAdapter";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { DismissRegular, FilterDismissRegular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { FilterData } from "../../shared/types";
import {
    dataParamsKeys,
    defaultDataParams,
} from "../../../../../domain/dataParams/dataParams";
import { router } from "../../../../router";
import { fileExplorerManager } from "../fileExplorerManager";

type FilterTag = InteractionTagPrimaryProps & {
    value: string;
    group: string;
    tooltipContent: JSX.Element;
};

type OverflowMenuItemProps = {
    tag: FilterTag;
    disabled: boolean;
};

const useStyles = makeStyles({
    container: {
        display: "grid",
        gridAutoFlow: "column",
        columnGap: "16px",
        width: "100%",
        ...shorthands.overflow("hidden"),
        minWidth: "150px",
        gridAutoColumns: "32px auto",
        position: "relative",
    },
    tagGroup: {
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "max-content",
        columnGap: "8px",
        alignItems: "center",
        width: "calc(100%- 32px - 16px)",
    },
    tag: {
        "> span": {
            display: "grid",
            gridAutoFlow: "column",
            gridAutoColumns: "max-content",
            columnGap: "2px",
            alignItems: "center",
            alignContent: "center",
        },
    },
});

function OverflowMenuItem(props: OverflowMenuItemProps): JSX.Element | null {
    const { tag } = props;
    const isVisible = useIsOverflowItemVisible(tag.value!);
    const styles = useStyles();

    if (isVisible) {
        return null;
    }

    return (
        <Tooltip
            withArrow
            content={tag.tooltipContent}
            relationship="label"
            showDelay={750}
        >
            <InteractionTag
                key={`${tag.value}-oft`}
                shape="circular"
                appearance="brand"
                size="small"
                disabled={props.disabled}
            >
                <InteractionTagPrimary
                    value={tag.value}
                    aria-label={tag["aria-label"]}
                    className={styles.tag}
                    disabled={props.disabled}
                    onClick={(): void => toggleFilter(tag.group, tag.value)}
                >
                    {tag.children} <DismissRegular />
                </InteractionTagPrimary>
            </InteractionTag>
        </Tooltip>
    );
}

function OverflowMenu(props: { filterTags: FilterTag[] }): JSX.Element | null {
    const { ref, isOverflowing, overflowCount } =
        useOverflowMenu<HTMLButtonElement>();
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );

    if (!isOverflowing) {
        return null;
    }

    return (
        <InteractionTag disabled={isFilesQueryInProgress}>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <InteractionTagPrimary
                        ref={ref}
                        aria-label={`${overflowCount} more filters`}
                        disabled={isFilesQueryInProgress}
                    >
                        {`+${overflowCount}`}
                    </InteractionTagPrimary>
                </MenuTrigger>
                <MenuPopover
                    style={{
                        display: "grid",
                        gridAutoFlow: "row",
                        rowGap: "8px",
                        maxWidth: "max-content",
                    }}
                >
                    {props.filterTags.map(item => (
                        <OverflowMenuItem
                            key={item.value}
                            tag={item}
                            disabled={isFilesQueryInProgress}
                        />
                    ))}
                </MenuPopover>
            </Menu>
        </InteractionTag>
    );
}

function updateFilters(filtersToUpdate: FilterData[]): void {
    router.removeSearchParam(dataParamsKeys.page, true);
    useStorage.fileExplorerMiniApp.dataParams.update(s => {
        s.page = defaultDataParams.page;
    });
    fileExplorerManager.updateFilters(filtersToUpdate);
}

function toggleFilter(
    filterGroup: string | null,
    filterName: string | number | null,
): void {
    if (filterGroup !== null && filterName !== null) {
        updateFilters([{ filterGroup, filterName }]);
    }
}

function onFiltersClear(): void {
    router.removeSearchParam("page", true);
    fileExplorerManager.clearAllFilters();
}

function ActiveFilters(): JSX.Element {
    const styles = useStyles();

    const activeFilters = state.useState(useStorage.files.activeFilters);
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );

    const [filterTags, setFilterTags] = useState<FilterTag[]>([]);

    useEffect(() => {
        const filterTags: FilterTag[] = [];

        if (activeFilters) {
            [...activeFilters].forEach(([group, filters]) => {
                filters.forEach(filter => {
                    const title = "Click to reset filter";

                    filterTags.push({
                        value: filter,
                        children: filter,
                        "aria-label": title,
                        group,
                        tooltipContent: (
                            <>
                                <Caption1>{title}</Caption1>
                                <br />
                                <Caption1>
                                    {group}: {filter}
                                </Caption1>
                            </>
                        ),
                    });
                });
            });
        }

        setFilterTags(filterTags);
    }, [activeFilters]);

    if (!activeFilters) {
        return <></>;
    }

    return (
        <div className={styles.container}>
            <Button
                appearance="subtle"
                icon={<FilterDismissRegular />}
                title="Reset all filters"
                onClick={onFiltersClear}
                disabled={isFilesQueryInProgress}
            />

            <Overflow minimumVisible={2} padding={60}>
                <TagGroup
                    className={styles.tagGroup}
                    aria-label="Active filters"
                    style={{ width: "calc(100% - 32px - 16px)" }}
                >
                    {filterTags.map(i => (
                        <OverflowItem key={i.value} id={i.value!}>
                            <Tooltip
                                withArrow
                                content={i.tooltipContent}
                                relationship="label"
                                showDelay={750}
                            >
                                <InteractionTag
                                    key={`${i.value}-t`}
                                    shape="circular"
                                    appearance="brand"
                                    size="small"
                                    disabled={isFilesQueryInProgress}
                                >
                                    <InteractionTagPrimary
                                        aria-label={i["aria-label"]}
                                        value={i.value}
                                        className={styles.tag}
                                        onClick={(): void =>
                                            toggleFilter(i.group, i.value)
                                        }
                                        disabled={isFilesQueryInProgress}
                                    >
                                        {i.children} <DismissRegular />
                                    </InteractionTagPrimary>
                                </InteractionTag>
                            </Tooltip>
                        </OverflowItem>
                    ))}
                    <OverflowMenu filterTags={filterTags} />
                </TagGroup>
            </Overflow>
        </div>
    );
}

export default ActiveFilters;
