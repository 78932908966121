export function normalizePath(path: string): string {
    return path.replace(/\/+/g, "/");
}

export function formatSize(size: number): string {
    if (size === 0) {
        return "";
    }

    if (size < 1024) {
        return `${Math.round(size)} B`;
    } else if (size >= 1024 && size < 1048576) {
        return `${Math.round(size / 1000)} KB`;
    } else if (size >= 1048576 && size < 1073741824) {
        return `${Math.round(size * 1e-6 * 100) / 100} MB`;
    } else if (size >= 1073741824) {
        return `${Math.round(size * 1e-9 * 100) / 100} GB`;
    } else {
        return "";
    }
}

export function calculateOffset(page: number, pageSize: number): number {
    return (page - 1) * pageSize;
}

export function isEmailFormat(email: string): boolean {
    const regex = /^\S+@\S+\.\S+$/;
    return regex.test(email);
}

type ConversionType = "bytesToGigabytes" | "gigabytesToBytes";

export function convertSize(
    value: number,
    conversionType: ConversionType,
): number {
    const bytesInGigabyte = 1024 * 1024 * 1024;

    if (conversionType === "bytesToGigabytes") {
        return value / bytesInGigabyte;
    } else if (conversionType === "gigabytesToBytes") {
        return value * bytesInGigabyte;
    } else {
        throw new Error("Invalid conversion type");
    }
}
