import {
    TimeFormatter,
    DateFormat,
} from "../../domain/timeFormatter/timeFormatter";
import moment from "moment";

export class TimeFormatterImpl implements TimeFormatter {
    formatDate(timestamp: number, dateFormat: DateFormat | string): string {
        return moment(timestamp).format(dateFormat);
    }
}
