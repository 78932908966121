import { IndexedLocationAdapter } from "../../infrastructure/indexedLocation/indexedLocationAdapter";
import { IndexedLocationService } from "../indexedLocation/indexedLocationService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useIndexedLocation = new IndexedLocationService(
    new IndexedLocationAdapter(),
    useError,
    useStorage,
    useNotification,
);
