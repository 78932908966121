import { SharepointAdapter } from "../../infrastructure/sharepoint/sharepointAdapter";
import { SharepointService } from "../sharepoint/sharepointService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useSharepoint = new SharepointService(
    new SharepointAdapter(),
    useError,
    useStorage,
    useNotification,
);
