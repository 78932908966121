import { Option } from "../../domain/types/types";
import { AddDataMethod } from "../../domain/userFile/userFile";
import { UserFileRepository } from "../../domain/userFile/userFileRepository";
import { minimatch } from "minimatch";

export class UserFileRepositoryImpl implements UserFileRepository {
    private readonly _metadataRegExp = /(XDROOT\/MEDIAPRO.XML)/gm;

    isIgnoredFile(fileName: string, ignoreList: string[]): boolean {
        for (const pattern of ignoreList) {
            if (minimatch(fileName, pattern, { dot: true, nocase: true })) {
                return true;
            }
        }
        return false;
    }

    getIconPath(category: string): string {
        return `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${category}.svg`;
    }

    async parseAsCameraCard(
        files: FileList | File[],
        addDataMethod: AddDataMethod,
    ): Promise<Option<Document>> {
        try {
            let metadataFile: Option<File> = null;

            for (let index = 0; index < files.length; index++) {
                if (addDataMethod === AddDataMethod.DragNDrop) {
                    if (
                        (files[index] as File & { path: string }).path.match(
                            this._metadataRegExp,
                        )
                    ) {
                        metadataFile = files[index];
                    }
                } else {
                    if (
                        (
                            files[index] as File & {
                                webkitRelativePath: string;
                            }
                        ).webkitRelativePath.match(this._metadataRegExp)
                    ) {
                        metadataFile = files[index];
                    }
                }
            }

            if (metadataFile !== undefined && metadataFile !== null) {
                const parser = new DOMParser();
                const text = await new Response(metadataFile).text();

                return parser.parseFromString(text, "text/xml");
            }
        } catch (error) {
            throw new Error((error as Error).message);
        }
    }
}
