import mime from "mime-types";
import { MimeRepository } from "../../domain/mime/mimeRepository";

export class MimeRepositoryImpl implements MimeRepository {
    getContentType(filenameOrExt: string): string | false {
        return mime.contentType(filenameOrExt);
    }

    getExtension(typeString: string): string | false {
        return mime.extension(typeString);
    }
}
