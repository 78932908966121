export enum UIEvents {
    FileExplorerRefresh = "FileExplorerRefresh",
    FileExplorerChangeFilters = "FileExplorerChangeFilters",
    FileExplorerResetFilters = "FileExplorerResetFilters",
    FileExplorerChangeSort = "FileExplorerChangeSort",
    FileExplorerChangeSearchQuery = "FileExplorerChangeSearchQuery",
    FileExplorerChangeActiveDirectory = "FileExplorerChangeActiveDirectory",
    FileExplorerChangeActiveItem = "FileExplorerChangeActiveItem",
    FileExplorerInitState = "FileExplorerInitState",
    FileExplorerChangePagination = "FileExplorerChangePagination",
    FileExplorerChangeViewMode = "FileExplorerChangeViewMode",
    FileExplorerChangeSelection = "FileExplorerChangeSelection",

    CollectionsChangeSelection = "CollectionsChangeSelection",
}
