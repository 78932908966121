import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Text,
    Combobox,
    Option,
    Persona,
    makeStyles,
    tokens,
    useId,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableCellActions,
    mergeClasses,
    shorthands,
} from "@fluentui/react-components";
import { AirFile } from "../../../../../domain/airFile/airFile";
import { FilesItemPermissions } from "../../../../../application/files/filesPort";
import Loader from "../../../../components/Loader/Loader";
import { useDirectoryObjects } from "../../../../../application/useCases/useDirectoryObjects";
import { DirectoryObjectUser } from "../../../../../application/directoryObjects/directoryObjectsPort";
import {
    CheckmarkFilled,
    DeleteRegular,
    WarningRegular,
} from "@fluentui/react-icons";
import { state } from "../../../../state/stateAdapter";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { AirErrorKind } from "../../../../../application/airError/airError";
import { useFiles } from "../../../../../application/useCases/useFiles";
import { useError } from "../../../../../application/useCases/useError";
import { hasUserPermissionToRepermissionThisFile } from "../../../../../domain/user/user";
import ButtonTag from "../../../../components/Form/ButtonTag/ButtonTag";

const useStyles = makeStyles({
    listBox: {
        overflowX: "hidden",
    },
    option: {
        display: "grid",
        gridTemplateColumns: "20px auto",
        alignItems: "center",
    },
    persona: {
        whiteSpace: "nowrap",
    },
    addActions: {
        display: "grid",
        gridAutoFlow: "column",
        columnGap: "18px",
        gridAutoColumns: "max-content",
    },
    tagsList: {
        listStyleType: "none",
        marginBottom: tokens.spacingVerticalXXS,
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        gridGap: tokens.spacingHorizontalXXS,
        flexWrap: "wrap",
    },
    permissionsContainer: {
        display: "grid",
        rowGap: "12px",
    },
    content: {
        rowGap: "18px",
    },
    table: {
        maxHeight: "150px",
        overflowX: "hidden",
        overflowY: "auto",
    },
    addPermissionsContainer: {
        display: "grid",
        rowGap: "8px",
    },
    success: {
        color: tokens.colorPaletteLightGreenForeground1,
    },
    err: {
        color: tokens.colorPaletteRedForeground1,
    },
    inputs: {
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateColumns: "auto max-content",
        columnGap: "8px",
        justifyContent: "stretch",
    },
    truncated: {
        maxWidth: "200px",
        ...shorthands.overflow("hidden"),
        textOverflow: "ellipsis",
        ...shorthands.margin(0),
        display: "block",
        textWrap: "nowrap",
    },
});

type PermissionsDialogProps = {
    isOpen: boolean;
    item: AirFile;
    onClose: () => void;
};

type Data = {
    optionValue: string | undefined;
    optionText: string | undefined;
    selectedOptions: string[];
};

const columns = [{ columnKey: "user", label: "User" }];

type PermissionsDialogContentProps = {
    repermissionStatus: "none" | "done" | "err";
    title: string;
    isItemPermissionsQueryInProgress: boolean;
    isRePermissionInProgress: boolean;
    itemPermissions: FilesItemPermissions | null | undefined;
    selectedUsers: Map<string, string>;
    selectedListId: string;
    selectedListRef: React.RefObject<HTMLUListElement>;
    comboId: string;
    labelledBy: string;
    onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    onUserSelect: (data: Data) => void;
    isSearchUsersInProgress: boolean;
    users: DirectoryObjectUser[];
    onTagClick: (option: string, index: number) => void;
    hasUserPermission: boolean;
    onDeleteItemPermission: (userId: string) => Promise<void>;
};

function PermissionsDialogContent(
    props: PermissionsDialogContentProps,
): JSX.Element {
    const classes = useStyles();

    if (props.repermissionStatus === "done") {
        return (
            <Text>
                <CheckmarkFilled className={classes.success} /> Re-permission
                job for &quot;{props.title}&quot; has been started. You can find
                its status at Job list`
            </Text>
        );
    }

    if (props.repermissionStatus === "err") {
        return (
            <Text>
                <WarningRegular className={classes.err} /> An error occurred
                while adding permission to &quot;
                {props.title}
                &quot;
            </Text>
        );
    }

    if (
        props.isItemPermissionsQueryInProgress ||
        props.isRePermissionInProgress
    ) {
        return <Loader text="Loading..." size="small" />;
    }

    return (
        <>
            <div className={classes.permissionsContainer}>
                {props.itemPermissions && props.itemPermissions.length > 0 ? (
                    <>
                        <Text weight="semibold">Current permissions</Text>

                        <div className={classes.table}>
                            <Table size="small">
                                <TableHeader>
                                    <TableRow>
                                        {columns.map(column => (
                                            <TableHeaderCell
                                                key={column.columnKey}
                                            >
                                                {column.label}
                                            </TableHeaderCell>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {props.itemPermissions.map(p => (
                                        <TableRow
                                            key={`${p.title}-${p.userId}`}
                                        >
                                            <TableCell>
                                                <TableCellLayout>
                                                    {p.title}
                                                </TableCellLayout>
                                                {props.hasUserPermission ? (
                                                    <TableCellActions>
                                                        <Button
                                                            icon={
                                                                <DeleteRegular />
                                                            }
                                                            appearance="subtle"
                                                            size="small"
                                                            onClick={(): Promise<void> =>
                                                                props.onDeleteItemPermission(
                                                                    p.userId,
                                                                )
                                                            }
                                                        />
                                                    </TableCellActions>
                                                ) : null}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </>
                ) : (
                    <Text>No current permissions</Text>
                )}
            </div>

            {props.hasUserPermission ? (
                <div className={classes.permissionsContainer}>
                    <Text weight="semibold">Add permissions</Text>

                    <div className={classes.addPermissionsContainer}>
                        {props.selectedUsers.size > 0 ? (
                            <ul
                                id={props.selectedListId}
                                className={classes.tagsList}
                                ref={props.selectedListRef}
                            >
                                <span id={`${props.comboId}-remove`} hidden>
                                    Remove
                                </span>
                                {Array.from(props.selectedUsers.entries()).map(
                                    (option, i) => (
                                        <li key={option[0]}>
                                            <ButtonTag
                                                onClick={(): void =>
                                                    props.onTagClick(
                                                        option[0],
                                                        i,
                                                    )
                                                }
                                                id={`${props.comboId}-remove-${i}`}
                                                aria-labelledby={`${props.comboId}-remove ${props.comboId}-remove-${i}`}
                                            >
                                                {option[1]}
                                            </ButtonTag>
                                        </li>
                                    ),
                                )}
                            </ul>
                        ) : null}

                        <div className={classes.inputs}>
                            <Combobox
                                aria-labelledby={props.labelledBy}
                                placeholder="Find user"
                                onChange={props.onSearch}
                                multiselect={true}
                                onOptionSelect={(_, data): void =>
                                    props.onUserSelect(data)
                                }
                                selectedOptions={Array.from(
                                    props.selectedUsers.keys(),
                                )}
                                listbox={{ className: classes.listBox }}
                            >
                                {props.isSearchUsersInProgress ? (
                                    <div className="app-dialog__content">
                                        <Loader
                                            text={"Searching..."}
                                            size="small"
                                        />
                                    </div>
                                ) : (
                                    props.users.map(i => (
                                        <Option
                                            key={i.id}
                                            disabled={
                                                !!props.itemPermissions &&
                                                props.itemPermissions !==
                                                    null &&
                                                props.itemPermissions.some(
                                                    p => p.userId === i.id,
                                                )
                                            }
                                            value={i.id}
                                            text={i.displayName}
                                            className={classes.option}
                                            title={`${i.displayName}; ${i.email}`}
                                        >
                                            <Persona
                                                size="small"
                                                className={classes.persona}
                                                name={i.displayName}
                                                secondaryText={
                                                    <Text
                                                        as="p"
                                                        className={
                                                            classes.truncated
                                                        }
                                                    >
                                                        {i.email}
                                                    </Text>
                                                }
                                                primaryText={{
                                                    className:
                                                        classes.truncated,
                                                }}
                                            />
                                        </Option>
                                    ))
                                )}
                            </Combobox>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

function PermissionsDialog(props: PermissionsDialogProps): JSX.Element {
    const classes = useStyles();
    const errors = state.useState(useStorage.airErrors);

    const error =
        errors.get(AirErrorKind.GetItemPermissions) ??
        errors.get(AirErrorKind.SearchUser) ??
        errors.get(AirErrorKind.RePermission) ??
        null;

    const comboId = useId("combo-multi");
    const selectedListId = `${comboId}-selection`;

    const selectedListRef = React.useRef<HTMLUListElement>(null);
    const comboboxInputRef = React.useRef<HTMLInputElement>(null);

    const [
        isItemPermissionsQueryInProgress,
        setIsItemPermissionsQueryInProgress,
    ] = useState(false);
    const [itemPermissions, setItemPermissions] = useState<
        FilesItemPermissions | null | undefined
    >(null);
    const [users, setUsers] = useState<DirectoryObjectUser[]>([]);
    // eslint-disable-next-line no-undef
    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
        null,
    );
    const [selectedUsers, setSelectedUsers] = useState<Map<string, string>>(
        new Map(),
    );
    const [isSearchUsersInProgress, setIsSearchUsersInProgress] =
        useState(false);
    const [isRePermissionInProgress, setIsRePermissionInProgress] =
        useState(false);
    const [repermissionStatus, setRepermissionStatus] = useState<
        "none" | "done" | "err"
    >("none");

    const onUserSelect = (data: Data): void => {
        if (!data.optionValue) {
            setSelectedUsers(new Map());
        } else {
            if (selectedUsers.has(data.optionValue)) {
                selectedUsers.delete(data.optionValue);
            } else {
                selectedUsers.set(data.optionValue, data.optionText ?? "");
            }

            setSelectedUsers(new Map([...selectedUsers]));
        }
    };

    const onSearch = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setIsSearchUsersInProgress(true);
            setUsers([]);
            const query = e.target.value.trim();

            if (debounceTimer !== null) {
                clearTimeout(debounceTimer);
                setDebounceTimer(null);
            }
            const newDebounceTimer = setTimeout(async (): Promise<void> => {
                const users = await useDirectoryObjects.searchUser({
                    query: query ?? "",
                });
                setUsers(users ?? []);
                setIsSearchUsersInProgress(false);
            }, 1000);

            setDebounceTimer(newDebounceTimer);
        },
        [debounceTimer],
    );

    const onSetPermission = async (): Promise<void> => {
        try {
            setIsRePermissionInProgress(true);
            await useFiles.rePermission({
                addPermissions: Array.from(selectedUsers.keys()),
                id: props.item.id,
            });
            setIsRePermissionInProgress(false);
            setRepermissionStatus("done");
        } catch (_) {
            setRepermissionStatus("err");
        }
    };

    const onDeleteItemPermission = async (userId: string): Promise<void> => {
        try {
            setIsRePermissionInProgress(true);
            await useFiles.rePermission({
                removePermissions: [userId],
                id: props.item.id,
            });
            setIsRePermissionInProgress(false);
            setRepermissionStatus("done");
        } catch (_) {
            setRepermissionStatus("err");
        }
    };

    const onTagClick = (option: string, index: number): void => {
        selectedUsers.delete(option);
        setSelectedUsers(new Map([...selectedUsers]));

        const indexToFocus = index === 0 ? 1 : index - 1;
        const optionToFocus = selectedListRef.current?.querySelector(
            `#${comboId}-remove-${indexToFocus}`,
        );
        if (optionToFocus) {
            (optionToFocus as HTMLButtonElement).focus();
        } else {
            comboboxInputRef.current?.focus();
        }
    };

    const labelledBy =
        selectedUsers.size > 0 ? `${comboId} ${selectedListId}` : comboId;

    const hasUserPermission = hasUserPermissionToRepermissionThisFile(
        props.item,
        useStorage.authorization.user.get()!,
    );

    useEffect(() => {
        useError.clear(AirErrorKind.GetItemPermissions);
        useError.clear(AirErrorKind.RePermission);
        useError.clear(AirErrorKind.SearchUser);
    }, []);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            setIsItemPermissionsQueryInProgress(true);
            const itemPermissions = await useFiles.getItemPermissions({
                id: props.item.id,
            });
            setIsItemPermissionsQueryInProgress(false);
            setItemPermissions(itemPermissions);
        };

        fetchData();
    }, [props.item.id]);

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    props.onClose();
                }
            }}
            open={isItemPermissionsQueryInProgress || props.isOpen}
        >
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle>Permissions</DialogTitle>
                    <DialogContent>
                        <div
                            className={mergeClasses(
                                "app-dialog__content",
                                classes.content,
                            )}
                        >
                            {error !== null ? (
                                <Text>{error.toString()}</Text>
                            ) : (
                                <PermissionsDialogContent
                                    repermissionStatus={repermissionStatus}
                                    title={props.item.title}
                                    isItemPermissionsQueryInProgress={
                                        isItemPermissionsQueryInProgress
                                    }
                                    isRePermissionInProgress={
                                        isRePermissionInProgress
                                    }
                                    itemPermissions={itemPermissions}
                                    selectedUsers={selectedUsers}
                                    selectedListId={selectedListId}
                                    selectedListRef={selectedListRef}
                                    comboId={comboId}
                                    labelledBy={labelledBy}
                                    onSearch={onSearch}
                                    onUserSelect={onUserSelect}
                                    isSearchUsersInProgress={
                                        isSearchUsersInProgress
                                    }
                                    users={users}
                                    onTagClick={onTagClick}
                                    hasUserPermission={hasUserPermission}
                                    onDeleteItemPermission={
                                        onDeleteItemPermission
                                    }
                                />
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={props.onClose}
                            disabled={
                                isItemPermissionsQueryInProgress ||
                                isRePermissionInProgress
                            }
                        >
                            Close
                        </Button>

                        {repermissionStatus === "none" ? (
                            <Button
                                appearance="primary"
                                disabled={
                                    selectedUsers.size === 0 ||
                                    !hasUserPermission
                                }
                                onClick={onSetPermission}
                            >
                                Set permission
                            </Button>
                        ) : null}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export function onOpenPermissions(item: AirFile): void {
    state.appDialog.set(() => (
        <PermissionsDialog
            isOpen={true}
            onClose={(): void => {
                state.appDialog.set(null);
            }}
            item={item}
        />
    ));
}

export default PermissionsDialog;
