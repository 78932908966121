import { routes } from "../../../router";
import { RouteListener } from "../../../router/routeListener";
import { collectionsManager } from "./collectionsManager";

export const collectionsRouteListener: RouteListener = {
    onRouteChange: (url: URL): void => {
        collectionsManager.updateState(url);
    },
    pathname: routes.collections,
};
