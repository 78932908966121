import { UploaderConfig } from "../../../../../application/config/config";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { uploaderSettingsState } from "./uploaderSettingsState";

export enum UploaderAdminHash {
    IgnoredPaths = "ignoredPaths",
}

export function createTempUploaderConfig(): UploaderConfig {
    return JSON.parse(
        JSON.stringify(useStorage.config.config.get().features.uploader),
    );
}

export function isTempConfigEqualWithCurrentOne(): boolean {
    return (
        JSON.stringify(uploaderSettingsState.tempUploaderSettings.get()) ===
        JSON.stringify(useStorage.config.config.get().features.uploader)
    );
}
