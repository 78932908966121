import React, { useState } from "react";
import {
    Slider,
    Text,
    SliderProps,
    useId,
    Button,
    Tooltip,
    makeStyles,
    Switch,
} from "@fluentui/react-components";
import { AddRegular, SubtractRegular } from "@fluentui/react-icons";
import { formatBytes } from "../../utility/formatters";
import "./sliderSize.css";
import { useCommonFluentuiStyles } from "../../styles/griffel";

const useStyles = makeStyles({
    btn: { minWidth: "20px" },
});

type SliderSizeProps = {
    title?: string;
    disabled: boolean;
    value: number;
    max: number;
    step: number;
    min: number;
    onChange: (value: number) => void;
};

function SliderSize(props: SliderSizeProps): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();
    const classes = useStyles();
    const [ref, setRef] = useState<HTMLSpanElement | null>(null);
    const id = useId();
    const textValue = props.value ? formatBytes(props.value) : "No limit";
    const isLimit = props.value > 0;

    const onSliderChange: SliderProps["onChange"] = (_, data) => {
        props.onChange(data.value);
    };

    return (
        <>
            <Switch
                className={commonClasses.field}
                checked={isLimit}
                onChange={(_e, data): void => {
                    props.onChange(data.checked ? props.min : 0);
                }}
                label={props.title ?? `Limit file size`}
                disabled={props.disabled}
            />
            <div className="slider-size">
                <Button
                    appearance="subtle"
                    disabled={props.disabled || !isLimit}
                    className={classes.btn}
                    onClick={(): void => {
                        if (props.value - props.step > props.min) {
                            props.onChange(props.value - props.step);
                        } else {
                            props.onChange(0);
                        }
                    }}
                    icon={<SubtractRegular />}
                />
                <Tooltip
                    withArrow
                    content={textValue}
                    relationship="label"
                    positioning={{ target: ref }}
                >
                    <Slider
                        disabled={props.disabled || !isLimit}
                        thumb={{ ref: setRef }}
                        aria-valuetext={`Value is ${props.value}`}
                        value={props.value}
                        min={props.min}
                        max={props.max}
                        onChange={onSliderChange}
                        id={id}
                    />
                </Tooltip>
                <Button
                    disabled={props.disabled || !isLimit}
                    appearance="subtle"
                    className={classes.btn}
                    onClick={(): void => {
                        if (props.value + props.step > props.max) {
                            props.onChange(props.max);
                        } else {
                            props.onChange(props.value + props.step);
                        }
                    }}
                    icon={<AddRegular />}
                />
                <Text align="end" className="slider-size__info">
                    {textValue}
                </Text>
            </div>
        </>
    );
}

export default SliderSize;
