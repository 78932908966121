import { createState } from "@persevie/statemanjs";
import { StorageItemPort } from "../../../../../application/storageItemPort";
import { UrlEntities } from "../../../../router";

export type AdminState = {
    urlEntities: StorageItemPort<UrlEntities | null>;
};

class AdminStateImpl {
    readonly urlEntities: StorageItemPort<UrlEntities | null>;

    constructor() {
        this.urlEntities = createState<UrlEntities | null>(null);
    }
}

export const adminState = new AdminStateImpl();
