import React, { useCallback, useEffect, useState } from "react";
import {
    Tab,
    TabList,
    MenuButton,
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuItemCheckbox,
    MenuGroup,
    MenuDivider,
    MenuGroupHeader,
    MenuPopover,
    SelectTabData,
    Input,
    Overflow,
    OverflowItem,
    useOverflowMenu,
    useIsOverflowItemVisible,
    makeStyles,
    shorthands,
    tokens,
    mergeClasses,
} from "@fluentui/react-components";
import { state } from "../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../videoExtendedViewLocalState";
import {
    LayerRegular,
    SearchRegular,
    MoreHorizontal20Filled,
    ArrowDownloadRegular,
} from "@fluentui/react-icons";
import "./videoExtendedViewCommandBar.css";
import { VideoExtendedViewTabKind } from "../../models";
import { InsightLabel, InsightCollectionLabel } from "../shared";

const useStyles = makeStyles({
    root: {
        ...shorthands.borderBottom(
            tokens.strokeWidthThin,
            "solid",
            tokens.colorNeutralStroke2,
        ),
    },
    menuItemActive: {
        backgroundColor: tokens.colorNeutralBackground1Hover,
    },
    flex: {
        display: "flex",
    },
});

type VideoExtendedViewCommandBarProps = {
    insightsViewList: InsightLabel[];
    timelineViewList: InsightLabel[];
    viewList: InsightLabel[];
    collectionViews: InsightCollectionLabel[];
    onDownloadPreviewVideo: () => void;
    onDownloadInsights: () => void;
    onDownloadSourceVideo: () => void;
    isDownloadSourceVideoBtnDisabled: boolean;
    isDownloadVideoPreviewBtnDisabled: boolean;
};

function VideoExtendedViewCommandBar(
    props: VideoExtendedViewCommandBarProps,
): JSX.Element {
    const classes = useStyles();
    const selectedTab = state.useState(videoExtendedLocalState.selectedTab);
    const query = state.useState(videoExtendedLocalState.query);
    const [debouncedQuery, setDebouncedQuery] = useState(query);

    useEffect(() => {
        const timer = setTimeout(
            () => videoExtendedLocalState.query.set(debouncedQuery),
            500,
        );
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    useEffect(() => {
        videoExtendedLocalState.query.set(query);
    }, [query]);

    return (
        <div
            className={mergeClasses("extended-view-command-bar", classes.root)}
        >
            <Overflow>
                <div className="extended-view-command-bar__overflow">
                    <OverflowItem id="tabs">
                        <TabList
                            selectedValue={selectedTab}
                            onTabSelect={(_e, d: SelectTabData): void => {
                                videoExtendedLocalState.selectedTab.set(
                                    d.value,
                                );
                            }}
                        >
                            <Tab
                                id={VideoExtendedViewTabKind.Insights.toString()}
                                value={VideoExtendedViewTabKind.Insights}
                            >
                                Insights
                            </Tab>
                            <Tab
                                id={VideoExtendedViewTabKind.Timeline.toString()}
                                value={VideoExtendedViewTabKind.Timeline}
                            >
                                Timeline
                            </Tab>
                        </TabList>
                    </OverflowItem>

                    <div className={classes.flex}>
                        <OverflowItem id="download">
                            <div>
                                <MenuListDownload
                                    isTriggerButton={true}
                                    {...props}
                                />
                            </div>
                        </OverflowItem>

                        <OverflowItem id="view">
                            <div>
                                <MenuListView
                                    isTriggerButton={true}
                                    {...props}
                                />
                            </div>
                        </OverflowItem>
                    </div>
                    <OverflowMenu
                        itemIds={["tabs", "download", "view"]}
                        {...props}
                    />
                </div>
            </Overflow>
            <Input
                contentAfter={<SearchRegular />}
                value={debouncedQuery}
                onChange={(_e, data): void => setDebouncedQuery(data.value)}
                placeholder="Find"
                className="extended-view-command-bar__search"
            />
        </div>
    );
}

type MenuListProps = {
    isTriggerButton: boolean;
};

function MenuListDownload(
    props: MenuListProps & VideoExtendedViewCommandBarProps,
): JSX.Element {
    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                {props.isTriggerButton ? (
                    <MenuButton
                        appearance="subtle"
                        icon={<ArrowDownloadRegular />}
                        className="extended-view-command-bar__view"
                    >
                        Download
                    </MenuButton>
                ) : (
                    <MenuItem icon={<ArrowDownloadRegular />}>
                        Download
                    </MenuItem>
                )}
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuItem
                        disabled={props.isDownloadVideoPreviewBtnDisabled}
                        title={
                            props.isDownloadVideoPreviewBtnDisabled
                                ? "Video preview is not available."
                                : ""
                        }
                        onClick={props.onDownloadPreviewVideo}
                    >
                        Video preview
                    </MenuItem>
                    <MenuItem
                        disabled={props.isDownloadSourceVideoBtnDisabled}
                        title={
                            props.isDownloadSourceVideoBtnDisabled
                                ? "You do not have permission to download the source video."
                                : ""
                        }
                        onClick={props.onDownloadSourceVideo}
                    >
                        Source video
                    </MenuItem>
                    <MenuItem onClick={props.onDownloadInsights}>
                        Insights (JSON)
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}

type MenuListViewProps = {
    isTriggerButton: boolean;
};

function MenuListView(
    props: MenuListViewProps & VideoExtendedViewCommandBarProps,
): JSX.Element {
    const classes = useStyles();
    const selectedTab = state.useState(videoExtendedLocalState.selectedTab);
    const viewInsights = state.useState(videoExtendedLocalState.viewInsights);
    const viewTimeline = state.useState(videoExtendedLocalState.viewTimeline);
    const view =
        selectedTab === VideoExtendedViewTabKind.Insights
            ? Array.from(viewInsights.keys())
            : Array.from(viewTimeline.keys());

    const onChangeInsightView = useCallback(
        (insightLabel: InsightLabel): void => {
            if (selectedTab === VideoExtendedViewTabKind.Insights) {
                videoExtendedLocalState.viewInsights.update(s => {
                    if (s.has(insightLabel.key)) {
                        s.delete(insightLabel.key);
                    } else {
                        s.set(insightLabel.key, insightLabel);
                    }
                });
            }

            if (selectedTab === VideoExtendedViewTabKind.Timeline) {
                videoExtendedLocalState.viewTimeline.update(s => {
                    if (s.has(insightLabel.key)) {
                        s.delete(insightLabel.key);
                    } else {
                        s.set(insightLabel.key, insightLabel);
                    }
                });
            }
        },
        [selectedTab],
    );

    const onChangeInsightCollectionView = useCallback(
        (insightCollectionLabel: InsightCollectionLabel): void => {
            const insightsState =
                selectedTab === VideoExtendedViewTabKind.Insights
                    ? videoExtendedLocalState.viewInsights
                    : videoExtendedLocalState.viewTimeline;

            if (
                insightsState.get() !== undefined &&
                Array.from(insightsState.get().keys()).join() ===
                    insightCollectionLabel.insightLabels.map(i => i.key).join()
            ) {
                insightsState.set(new Map());
            } else {
                insightsState.set(
                    new Map(
                        insightCollectionLabel.insightLabels.map(i => [
                            i.key,
                            i,
                        ]),
                    ),
                );
            }
        },
        [selectedTab],
    );

    return (
        <Menu checkedValues={{ view: view }} hasCheckmarks>
            <MenuTrigger disableButtonEnhancement>
                {props.isTriggerButton ? (
                    <MenuButton
                        appearance="subtle"
                        icon={<LayerRegular />}
                        className="extended-view-command-bar__view"
                    >
                        View
                    </MenuButton>
                ) : (
                    <MenuItem icon={<LayerRegular />}>View</MenuItem>
                )}
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuGroup>
                        <MenuGroupHeader>Show insights</MenuGroupHeader>
                        {props.collectionViews.map(i => (
                            <MenuItem
                                className={
                                    i.insightLabels.sort().toString() ===
                                    view.sort().toString()
                                        ? classes.menuItemActive
                                        : ""
                                }
                                key={i.key}
                                onClick={(): void => {
                                    onChangeInsightCollectionView(i);
                                }}
                            >
                                {i.text}
                            </MenuItem>
                        ))}
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup>
                        <MenuGroupHeader>Custom view</MenuGroupHeader>
                        {selectedTab === VideoExtendedViewTabKind.Insights
                            ? props.insightsViewList.map(i => (
                                  <MenuItemCheckbox
                                      name="view"
                                      value={i.key}
                                      key={i.key}
                                      onClick={(): void => {
                                          onChangeInsightView(i);
                                      }}
                                  >
                                      {i.text}
                                  </MenuItemCheckbox>
                              ))
                            : props.viewList.map(i => (
                                  <MenuItemCheckbox
                                      name="view"
                                      value={i.key}
                                      key={i.key}
                                      onClick={(): void => {
                                          onChangeInsightView(i);
                                      }}
                                  >
                                      {i.text}
                                  </MenuItemCheckbox>
                              ))}
                    </MenuGroup>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}

type OverflowMenuItemProps = {
    id: string;
};

function OverflowMenuItem(
    props: OverflowMenuItemProps & VideoExtendedViewCommandBarProps,
): JSX.Element | null {
    const isVisible = useIsOverflowItemVisible(props.id);
    const selectedTab = state.useState(videoExtendedLocalState.selectedTab);

    if (isVisible) {
        return null;
    }

    if (props.id === "tabs") {
        return (
            <>
                <MenuItem
                    onClick={(): void => {
                        videoExtendedLocalState.selectedTab.set(
                            VideoExtendedViewTabKind.Insights,
                        );
                    }}
                    className={
                        VideoExtendedViewTabKind.Insights === selectedTab
                            ? "extended-view-command-bar__menu-item_active"
                            : ""
                    }
                >
                    Insights
                </MenuItem>
                <MenuItem
                    onClick={(): void => {
                        videoExtendedLocalState.selectedTab.set(
                            VideoExtendedViewTabKind.Timeline,
                        );
                    }}
                    className={
                        VideoExtendedViewTabKind.Timeline === selectedTab
                            ? "extended-view-command-bar__menu-item_active"
                            : ""
                    }
                >
                    Timeline
                </MenuItem>
                <MenuDivider />
            </>
        );
    }

    if (props.id === "download") {
        return <MenuListDownload isTriggerButton={false} {...props} />;
    }

    if (props.id === "view") {
        return <MenuListView isTriggerButton={false} {...props} />;
    }

    return null;
}

type OverflowMenuProps = {
    itemIds: string[];
};

function OverflowMenu(
    props: OverflowMenuProps & VideoExtendedViewCommandBarProps,
): JSX.Element | null {
    const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu hasIcons>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    icon={<MoreHorizontal20Filled />}
                    aria-label="More items"
                    appearance="subtle"
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {props.itemIds.map(i => {
                        return <OverflowMenuItem key={i} id={i} {...props} />;
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}

export default VideoExtendedViewCommandBar;
