import React from "react";
import Uploads from "../MiniApp/Uploader/Uploads/Uploads";
import { PanelTab } from "../../models";

const appUploadsTab: PanelTab = {
    id: "uploads",
    title: "Uploads",
    content: <Uploads />,
};

export default appUploadsTab;
