import {
    Badge,
    Button,
    Caption1Strong,
    Checkbox,
    Text,
    Toolbar,
    ToolbarButton,
    makeStyles,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import React, { useState } from "react";
import { state } from "../../../state/stateAdapter";
import "./fileTags.css";
import { TextBulletListSquareEditRegular } from "@fluentui/react-icons";
import {
    AirFileDetails,
    Tag,
    TagKind,
} from "../../../../domain/airFile/details";
import EditTagsPanel from "../EditTagsPanel/EditTagsPanel";
import { AirFileDetailsStorageValue } from "../../../../application/storageValues";

const useStyles = makeStyles({
    tagContainer: {
        display: "grid",
    },
    tag: {
        ...shorthands.borderColor(tokens.colorNeutralStroke2),
        backgroundColor: tokens.colorNeutralBackground4,
        ...shorthands.padding("1px", "6px"),
        columnGap: "4px",
        maxWidth: "100%",
    },
    tagText: {
        fontSize: "12px",
        fontWeight: 600,
        color: tokens.colorNeutralForeground3,
    },
    tagBadge: {
        width: "12px",
        height: "12px",
        fontSize: "8px",
        fontWeight: 400,
    },
    tagsToolbar: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    accordionHeader: {
        "& > button": {
            ...shorthands.border(0),
            ...shorthands.padding(0),
        },
    },
    accordionPanel: {
        paddingBottom: "20px",
        ...shorthands.margin(0),
    },
});

type FileTagGroupProps = {
    isShowAITags: boolean;
    name: string;
    tags: Tag[];
};

function FileTagGroup(props: FileTagGroupProps): JSX.Element | null {
    const classes = useStyles();

    const tags = props.tags.filter(
        i => i.tagSource !== TagKind.AI || props.isShowAITags === true,
    );

    if (!tags.length) {
        return null;
    }

    return (
        <div className="file-tags__group">
            <Caption1Strong>{props.name}</Caption1Strong>
            <div className="file-tags__list">
                {tags.map((i, index) => (
                    <Button
                        key={i.name + index}
                        className={classes.tag}
                        size="small"
                        shape="circular"
                        iconPosition="after"
                    >
                        <Text
                            truncate
                            block
                            wrap={false}
                            className={classes.tagText}
                        >
                            {i.name}
                        </Text>
                        {i.tagSource === TagKind.AI ? (
                            <Badge
                                appearance="filled"
                                color="important"
                                size="extra-small"
                                shape="rounded"
                                className={classes.tagBadge}
                            >
                                AI
                            </Badge>
                        ) : null}
                    </Button>
                ))}
            </div>
        </div>
    );
}

function openEditTagsPanel(airFileDetails: AirFileDetails): void {
    state.appDialogPanel.set(() => (
        <EditTagsPanel
            airFileDetails={airFileDetails}
            onClose={(): void => {
                state.appDialogPanel.set(null);
            }}
        />
    ));
}

type FileTagsProps = {
    fileDetails: AirFileDetailsStorageValue;
    canEdit?: boolean;
};

function FileTags(props: FileTagsProps): JSX.Element | null {
    const [isShowAITags, setIsShowAITags] = useState<boolean>(true);
    const airFileDetails = props.fileDetails;
    const classes = useStyles();

    if (airFileDetails == null || airFileDetails.tagGroups === null) {
        return null;
    }

    return (
        <div className="file-tags">
            <Toolbar className={classes.tagsToolbar}>
                {props.canEdit ? (
                    <ToolbarButton
                        onClick={(): void => {
                            openEditTagsPanel(airFileDetails);
                        }}
                        icon={<TextBulletListSquareEditRegular />}
                    >
                        Edit tags
                    </ToolbarButton>
                ) : null}
                <Checkbox
                    checked={isShowAITags}
                    onChange={(_e, data): void => {
                        setIsShowAITags(data.checked ? true : false);
                    }}
                    disabled={airFileDetails.tagGroups.length === 0}
                    label="Show AI generated tags"
                />
            </Toolbar>
            {airFileDetails.tagGroups !== undefined &&
                airFileDetails.tagGroups.map(i =>
                    i.isVisible && i.tags !== null && i.tags !== undefined ? (
                        <FileTagGroup
                            name={i.name}
                            tags={i.tags}
                            key={i.id}
                            isShowAITags={isShowAITags}
                        />
                    ) : null,
                )}
        </div>
    );
}

export default FileTags;
