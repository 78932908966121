import React, { useEffect } from "react";
import {
    Overflow,
    OverflowItem,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
    useOverflowMenu,
} from "@fluentui/react-components";
import { ChevronDownRegular } from "@fluentui/react-icons";
import { ExtendedViewData } from "../../../models";

const useStyles = makeStyles({
    more: {
        ...shorthands.border("2px", "solid", tokens.colorNeutralBackground5),
        ...shorthands.transition(
            "background, border, color",
            tokens.durationFaster,
            tokens.curveEasyEase,
        ),
        "&:hover": {
            backgroundColor: tokens.colorNeutralBackground5Hover,
        },
    },
    moreTag: {
        color: tokens.colorNeutralForeground3,
        ...shorthands.border("1px", "solid", tokens.colorNeutralBackground5),
    },
});

export type ExtendedViewOverflowProps = {
    data: ExtendedViewData[];
    isOverflow: boolean;
    toggleOverflow: () => void;
    onSetHiddenCount: (count: number) => void;
    renderItem: (i: ExtendedViewData) => JSX.Element;
    btnMoreView?: string;
};

function ExtendedViewOverflow(
    props: ExtendedViewOverflowProps,
): JSX.Element | null {
    if (props.data.length === 0) {
        return null;
    }

    if (props.isOverflow)
        return (
            <Overflow>
                <div className="extended-view__section-list extended-view__section-list_overflow">
                    {props.data.map(i => (
                        <OverflowItem key={i.value} id={i.value}>
                            <div className="extended-view__item">
                                {props.renderItem(i)}
                            </div>
                        </OverflowItem>
                    ))}
                    <ExtendedViewBtnMore
                        btnMoreView={props.btnMoreView}
                        toggleOverflow={props.toggleOverflow}
                        onSetHiddenCount={props.onSetHiddenCount}
                    />
                </div>
            </Overflow>
        );
    return (
        <div className="extended-view__section-list">
            {props.data.map((i, index) => (
                <div className="extended-view__item" key={i.value + index}>
                    {props.renderItem(i)}
                </div>
            ))}
        </div>
    );
}

function ExtendedViewBtnMore(props: {
    toggleOverflow: () => void;
    onSetHiddenCount: (count: number) => void;
    btnMoreView?: string;
}): JSX.Element | null {
    const classes = useStyles();
    const { overflowCount, ref, isOverflowing } =
        useOverflowMenu<HTMLButtonElement>();

    useEffect(() => {
        props.onSetHiddenCount(overflowCount);
    }, [overflowCount, props]);

    if (!isOverflowing) {
        return null;
    }

    if (props.btnMoreView === "people") {
        return (
            <div className="extended-view__item">
                <button
                    ref={ref}
                    onClick={props.toggleOverflow}
                    className={mergeClasses(
                        "extended-view__more-people",
                        classes.more,
                    )}
                    title={`Show more`}
                >
                    +{overflowCount}
                </button>
            </div>
        );
    }

    return (
        <div className="extended-view__item">
            <button
                ref={ref}
                onClick={props.toggleOverflow}
                className={mergeClasses(
                    "extended-view__more-tag",
                    classes.more,
                    classes.moreTag,
                )}
            >
                +{overflowCount}{" "}
                <ChevronDownRegular className="extended-view__more-icon" />
            </button>
        </div>
    );
}

export default ExtendedViewOverflow;
