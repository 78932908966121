import { GeneratorIdRepository } from "../generatorId/generatorIdRepository";

export interface FileChunk {
    id: string;

    startByte: number;

    endByte: number;

    chunkData: FormData;
}

export function splitIntoChunks(
    file: File,
    chunkSize: number,
    generatorIdRepository: GeneratorIdRepository,
): FileChunk[] {
    const fileChunks: FileChunk[] = [];

    let start = 0;
    while (start < file.size) {
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        const chunkData = new FormData();
        chunkData.append("file", chunk);

        fileChunks.push({
            id: generatorIdRepository.generate(),
            startByte: start,
            endByte: end,
            chunkData,
        });

        start = end;
    }

    return fileChunks;
}
