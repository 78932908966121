import {
    DirectoryObjectUser,
    DirectoryObjectsPort,
    DirectoryObjectsSearchUserParams,
} from "../../application/directoryObjects/directoryObjectsPort";
import { apiCall, validateRes } from "../api/api";

export class DirectoryObjectsAdapter implements DirectoryObjectsPort {
    async searchUser(
        params: DirectoryObjectsSearchUserParams,
    ): Promise<DirectoryObjectUser[]> {
        const res = await apiCall(
            `/api/directory-objects/search?query=${params.query}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );

        return await validateRes(res);
    }
}
