import React from "react";
import { Text, mergeClasses } from "@fluentui/react-components";
import { TimelineDataItem, useTimelineStyles } from "../Timeline";

export default function TimelineLabel(props: {
    data?: TimelineDataItem[];
}): JSX.Element | null {
    const classes = useTimelineStyles();

    if (props.data === undefined) {
        return null;
    }

    return (
        <div>
            {props.data.map(i => (
                <Text
                    className={mergeClasses(classes.text, "timeline__label")}
                    key={i.value}
                >
                    {i.value}
                </Text>
            ))}
        </div>
    );
}
