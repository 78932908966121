import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    Input,
    DialogActions,
    DialogTrigger,
    Button,
    Textarea,
    Field,
    Radio,
    RadioGroup,
    makeStyles,
    Divider,
    mergeClasses,
    Combobox,
    Option,
    useId,
    tokens,
    Text,
} from "@fluentui/react-components";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { state } from "../../../../state/stateAdapter";
import { AirFile } from "../../../../../domain/airFile/airFile";
import {
    Calendar24Regular,
    Dismiss24Regular,
    Link24Regular,
    LockClosed24Regular,
    Send24Regular,
} from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import Tag from "../../../../components/Tag/Tag";
import { useSharing } from "../../../../../application/useCases/useSharing";
import { useInterface } from "../../../../../application/useCases/useInterface";
import { useNotification } from "../../../../../application/useCases/useNotification";
import { useStorage } from "../../../../../application/useCases/useStorage";
import Loader from "../../../../components/Loader/Loader";
import "./sharingLinkDialog.css";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";
import ButtonTag from "../../../../components/Form/ButtonTag/ButtonTag";
import { formatSize, isEmailFormat } from "../../../../../utility";
import {
    FilesGetTiersResult,
    TierInfo,
} from "../../../../../application/files/filesPort";
import { useFiles } from "../../../../../application/useCases/useFiles";
import { Tier, tiers } from "../../../../models/tiers";
import { defaultDataParams } from "../../../../../domain/dataParams/dataParams";
import { useJobs } from "../../../../../application/useCases/useJobs";
import { MiniAppKind } from "../../../../../domain/miniApp/miniApp";
import { useEventBus } from "../../../../../application/useCases/useEventBus";
import { UIEvents } from "../../../../uiEvents";
import { useCollections } from "../../../../../application/useCases/useCollections";

type SharingLinkDialogProps = {
    isOpen: boolean;
    onAfterApply?: () => void;
    sharingItem: AirFile;
    onClose: () => void;
};

type SelectionEvents =
    | React.ChangeEvent<HTMLElement>
    | React.KeyboardEvent<HTMLElement>
    | React.MouseEvent<HTMLElement>;

type OptionOnSelectData = {
    optionValue: string | undefined;
    optionText: string | undefined;
    selectedOptions: string[];
};

enum AccessOption {
    AnyoneWithTheLink = "AnyoneWithTheLink",
    MicrosoftEntraID = "MicrosoftEntraID",
    PlainPassword = "PlainPassword",
}

const useStyles = makeStyles({
    message: {
        height: "80px",
    },
    inputs: { display: "grid", rowGap: "8px" },
    date: {
        display: "grid",
        rowGap: "11px",
    },
    dateTags: {
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "max-content",
        columnGap: "4px",
    },
    actions: {
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "max-content",
        columnGap: "8px",
    },
    emails: {
        display: "grid",
        gridAutoFlow: "row",
        rowGap: "4px",
        gridAutoRows: "max-content",
    },
    tagsList: {
        listStyleType: "none",
        marginBottom: tokens.spacingVerticalXXS,
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        gridGap: tokens.spacingHorizontalXXS,
        flexWrap: "wrap",
    },
    addEmailsContainer: {
        display: "grid",
        rowGap: "8px",
    },
});

const expirationDatePresets = [
    {
        label: "One week",
        getDate: (): Date => {
            const currentDate = new Date();
            const nextDate = new Date(currentDate);

            nextDate.setDate(currentDate.getDate() + 7);

            return nextDate;
        },
    },
    {
        label: "Two weeks",
        getDate: (): Date => {
            const currentDate = new Date();
            const nextDate = new Date(currentDate);

            nextDate.setDate(currentDate.getDate() + 14);

            return nextDate;
        },
    },
    {
        label: "One month",
        getDate: (): Date => {
            const currentDate = new Date();
            const nextDate = new Date(currentDate);

            nextDate.setMonth(currentDate.getMonth() + 1);

            return nextDate;
        },
    },
    {
        label: "Three months",
        getDate: (): Date => {
            const currentDate = new Date();
            const nextDate = new Date(currentDate);

            nextDate.setMonth(currentDate.getMonth() + 3);

            return nextDate;
        },
    },
];

function generateWarnArchiveTierText(tierInfo: TierInfo): string {
    return `The selection has ${tierInfo.files} file(s) sized ${formatSize(
        tierInfo.size,
    )} in Archive storage tier to rehydrate. Rehydration takes up to 24 hours implies costs as per Azure pricing.`;
}

// TODO: refactor; DRY; (FileExplorerCommandBar.tsx)
async function onChangeStorageTier(
    tier: Tier,
    filesSelectionFiles: AirFile[],
): Promise<void> {
    const res = await useFiles.changeTier({
        ids: filesSelectionFiles.map(i => i.id),
        target: tier.key,
    });

    if (res !== undefined && res !== null) {
        if (useStorage.appCommon.activeMiniApp.get() === MiniAppKind.Explorer) {
            await useEventBus.emit(UIEvents.FileExplorerRefresh, {});
        } else if (
            useStorage.appCommon.activeMiniApp.get() === MiniAppKind.Collections
        ) {
            // TODO: refactor; DRY; Collections.tsx
            useStorage.collectionsMiniApp.selection.set(new Map());
            const item = useStorage.collections.item.get();

            // TODO: refactor; DRY; Collections.tsx
            if (item !== null && item !== undefined) {
                useStorage.files.files.set([]);
                const res = await useCollections.fetchById({ id: item.id });
                if (res && res.items.length > 0) {
                    await useFiles.fetch({
                        ids: res.items,
                    });
                }
            } else {
                await useCollections.fetch(
                    useStorage.collectionsMiniApp.dataParams.get(),
                );
            }
        }

        useStorage.jobs.dataParams.set({ ...defaultDataParams });
        await useJobs.fetchAll({
            limit: defaultDataParams.pageSize,
            offset: 0,
        });
    }
}

function SharingConfirmation(props: {
    sharingItem: AirFile;
    onClose: () => void;
    onProceed: () => void;
    tiers: FilesGetTiersResult;
    isOpen: boolean;
}): JSX.Element {
    let totalFiles = 0;
    let totalSize = 0;

    for (const tier of Object.keys(
        props.tiers.tiers,
    ) as (keyof typeof props.tiers.tiers)[]) {
        const tierInfo = props.tiers.tiers[tier];
        if (tierInfo) {
            totalFiles += tierInfo.files;
            totalSize += tierInfo.size;
        }
    }

    const formatedTotalSize = formatSize(totalSize);

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    props.onClose();
                }
            }}
            open={props.isOpen}
        >
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle>Sharing confirmation</DialogTitle>
                    <DialogContent>
                        <div className="app-dialog__content">
                            <Text>
                                You are about to share {totalFiles} file(s)
                                (total size: {formatedTotalSize}).
                            </Text>

                            {props.tiers.tiers.Archive ? (
                                <Text>
                                    {generateWarnArchiveTierText(
                                        props.tiers.tiers.Archive,
                                    )}
                                </Text>
                            ) : null}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className="app-dialog__actions-btns">
                            <Button
                                appearance="secondary"
                                onClick={props.onClose}
                                className="app-dialog__action-btn"
                            >
                                Cancel
                            </Button>

                            {props.tiers.tiers.Archive ? (
                                <>
                                    <Button
                                        appearance="secondary"
                                        onClick={(): void => {
                                            onChangeStorageTier(tiers[0], [
                                                props.sharingItem,
                                            ]);
                                            props.onClose();
                                        }}
                                        className="app-dialog__action-btn"
                                    >
                                        Rehydrate
                                    </Button>
                                </>
                            ) : null}

                            <Button
                                appearance={
                                    props.tiers.tiers.Archive
                                        ? "primary"
                                        : "secondary"
                                }
                                onClick={(): void => {
                                    props.onProceed();
                                }}
                                className="app-dialog__action-btn"
                            >
                                Proceed
                            </Button>
                        </div>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

function SharingLinkDialog(props: SharingLinkDialogProps): JSX.Element | null {
    const classes = useStyles();
    const commonClasses = useCommonFluentuiStyles();

    const [emails, setEmails] = useState<string[]>([]);
    const [message, setMessage] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
        null,
    );
    const [activeItem, setActiveItem] = useState<string | undefined>();
    const [selectedAccessOption, setSelectedAccessOption] = useState(
        AccessOption.AnyoneWithTheLink,
    );
    const [password, setPassword] = useState("");
    const [customSearch, setCustomSearch] = useState<string | undefined>();
    const shareLinkUIConfig = state.useState(useStorage.config.config, {
        properties: ["features.shareLinkUiConfiguration"],
    }).features.shareLinkUiConfiguration;
    const isCreateQueryInProgress = state.useState(
        useStorage.sharing.isCreateQueryInProgress,
    );
    const [isProceed, setIsProceed] = useState(false);
    const [tiers, setTiers] = useState<FilesGetTiersResult | null>(null);

    const selectedListRef = useRef<HTMLUListElement>(null);
    const comboboxInputRef = useRef<HTMLInputElement>(null);

    const avaliableAccessOptions = useMemo(
        () =>
            [
                {
                    key: AccessOption.MicrosoftEntraID,
                    title: "Microsoft Entra ID",
                    isAvaliable: shareLinkUIConfig.domainOnly,
                },
                {
                    key: AccessOption.PlainPassword,
                    title: "Plain password",
                    isAvaliable: shareLinkUIConfig.password,
                },
                {
                    key: AccessOption.AnyoneWithTheLink,
                    title: "Anyone with the link",
                    isAvaliable: shareLinkUIConfig.plainLink,
                },
            ].filter(i => i.isAvaliable),
        [shareLinkUIConfig],
    );

    const comboId = useId("combo-multi");
    const selectedListId = `${comboId}-selection`;

    const isSendEmailEnabled = useMemo(
        () =>
            emails.length > 0 &&
            selectedDate &&
            (selectedAccessOption === AccessOption.PlainPassword
                ? password.length > 0
                : true),
        [emails, selectedDate, selectedAccessOption, password],
    );

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.trim();
            setCustomSearch(value.length ? value : undefined);
        },
        [],
    );

    const onOptionSelect = useCallback(
        (_event: SelectionEvents, data: OptionOnSelectData) => {
            setCustomSearch(data.optionText);

            if (data.optionText) {
                const isEmail = isEmailFormat(data.optionText);

                if (isEmail) {
                    if (emails.includes(data.optionText)) {
                        setEmails(emails.filter(i => i !== data.optionText));
                    } else {
                        setEmails([...emails, data.optionText]);
                    }
                }
            }
        },
        [emails],
    );

    const copySharingLink = useCallback(
        async (sendNotification: boolean): Promise<void> => {
            if (
                selectedDate &&
                (selectedAccessOption === AccessOption.PlainPassword
                    ? password.length > 0
                    : true)
            ) {
                const res = await useSharing.create({
                    id: props.sharingItem.id,
                    expiryDate: selectedDate.toISOString(),
                    password: password.length > 0 ? password : null,
                    isDomainOnly:
                        selectedAccessOption === AccessOption.MicrosoftEntraID,
                    emails: emails,
                    message: message,
                    sendNotification,
                });

                if (res !== null && res !== undefined) {
                    if (res.shareUrl === undefined) {
                        useNotification.show(
                            "Sharing link could not be generated",
                            "error",
                        );
                    } else {
                        useInterface.copyToClipboard(res.shareUrl);
                    }
                }
            }
        },
        [
            emails,
            message,
            password,
            props.sharingItem.id,
            selectedAccessOption,
            selectedDate,
        ],
    );

    const onCopySharingLink = useCallback((): void => {
        copySharingLink(false);
    }, [copySharingLink]);

    const onSendNotification = useCallback(async (): Promise<void> => {
        await copySharingLink(true);

        state.appDialog.set(null);

        if (props.onClose) {
            props.onClose();
        }
    }, [copySharingLink, props]);

    const onTagClick = useCallback(
        (email: string): void => {
            setEmails(emails.filter(i => i !== email));

            comboboxInputRef.current?.focus();
        },
        [emails],
    );

    const onDatePickerSelect = useCallback((date: Date | null | undefined) => {
        setActiveItem(undefined);
        setSelectedDate(undefined);
        setSelectedDate(date);
    }, []);

    const onProceed = useCallback(() => {
        setIsProceed(true);
    }, []);

    const isGetTiersQueryInProgress = state.useState(
        useStorage.files.isGetTiersQueryInProgress,
    );

    const onClose = useCallback(() => {
        state.appDialog.set(null);
        if (props.onClose) {
            props.onClose();
        }
    }, [props]);

    useEffect(() => {
        if (avaliableAccessOptions.length === 1) {
            setSelectedAccessOption(avaliableAccessOptions[0].key);
        }
    }, [avaliableAccessOptions]);

    useEffect(() => {
        useFiles.getTiers({ ids: [props.sharingItem.id] }).then(r => {
            if (r) {
                setTiers(r);
            }
        });
    }, [props.sharingItem]);

    if (isGetTiersQueryInProgress && (tiers === undefined || tiers === null)) {
        return (
            <Dialog
                onOpenChange={(_e, data): void => {
                    if (!data.open && !isGetTiersQueryInProgress) {
                        onClose();
                    }
                }}
                open={props.isOpen}
            >
                <DialogSurface
                    onClick={(e): void => {
                        e.stopPropagation();
                    }}
                >
                    <Loader text="Loading tiers data..." />
                </DialogSurface>
            </Dialog>
        );
    }

    if (tiers === undefined || tiers === null) {
        return (
            <Dialog
                onOpenChange={(_e, data): void => {
                    if (!data.open && !isGetTiersQueryInProgress) {
                        onClose();
                    }
                }}
                open={props.isOpen}
            >
                <DialogSurface
                    onClick={(e): void => {
                        e.stopPropagation();
                    }}
                >
                    <DialogBody>
                        <DialogTitle>Download</DialogTitle>
                        <DialogContent>
                            <div className="app-dialog__content">
                                <Text>
                                    Could not get information about storage
                                    tiers
                                </Text>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                onClick={props.onClose}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    }

    if (!isProceed) {
        return (
            <SharingConfirmation
                sharingItem={props.sharingItem}
                onClose={props.onClose}
                onProceed={onProceed}
                tiers={tiers}
                isOpen={props.isOpen}
            />
        );
    }

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open && !isCreateQueryInProgress) {
                    state.appDialog.set(null);

                    if (props.onClose) {
                        props.onClose();
                    }
                }
            }}
            open={props.isOpen}
        >
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        Sharing
                    </DialogTitle>

                    <DialogContent>
                        <Text title={props.sharingItem.title}>
                            {props.sharingItem.title}
                        </Text>

                        <br />

                        <div className="app-dialog__content">
                            {shareLinkUIConfig.emailNotifications ? (
                                <>
                                    <div className={classes.emails}>
                                        <div
                                            className={
                                                classes.addEmailsContainer
                                            }
                                        >
                                            {emails.length > 0 ? (
                                                <ul
                                                    id={selectedListId}
                                                    ref={selectedListRef}
                                                    className={classes.tagsList}
                                                >
                                                    <span
                                                        id={`${comboId}-remove`}
                                                        hidden
                                                    >
                                                        Remove
                                                    </span>
                                                    {emails.map(email => (
                                                        <li key={email}>
                                                            <ButtonTag
                                                                onClick={(): void =>
                                                                    onTagClick(
                                                                        email,
                                                                    )
                                                                }
                                                                id={`${comboId}-remove-${email}`}
                                                            >
                                                                {email}
                                                            </ButtonTag>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : null}
                                        </div>

                                        <Combobox
                                            freeform
                                            placeholder="Add emails"
                                            onChange={onChange}
                                            onOptionSelect={onOptionSelect}
                                            multiselect={true}
                                            ref={comboboxInputRef}
                                        >
                                            {customSearch ? (
                                                <Option
                                                    key="freeform"
                                                    text={customSearch}
                                                    className="sharing-link__email-option"
                                                >
                                                    {customSearch}
                                                </Option>
                                            ) : null}
                                        </Combobox>
                                    </div>

                                    <Textarea
                                        className={classes.message}
                                        placeholder="Add a message"
                                        value={message}
                                        onChange={(_e, data): void => {
                                            setMessage(data.value);
                                        }}
                                    />
                                </>
                            ) : null}

                            {avaliableAccessOptions.length > 1 ? (
                                <>
                                    <Field label="Access options" size="large">
                                        <RadioGroup
                                            value={selectedAccessOption}
                                            onChange={(_e, data): void => {
                                                if (
                                                    data.value !==
                                                    AccessOption.PlainPassword
                                                ) {
                                                    setPassword("");
                                                }

                                                setSelectedAccessOption(
                                                    data.value as AccessOption,
                                                );
                                            }}
                                        >
                                            {avaliableAccessOptions.map(i => (
                                                <Radio
                                                    key={i.key}
                                                    value={i.key}
                                                    label={i.title}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </Field>
                                </>
                            ) : null}

                            <div className={classes.inputs}>
                                {selectedAccessOption ===
                                AccessOption.PlainPassword ? (
                                    <>
                                        <Divider />

                                        <Field
                                            label="Set password"
                                            size="large"
                                        >
                                            <Input
                                                appearance="underline"
                                                type="password"
                                                contentBefore={
                                                    <LockClosed24Regular />
                                                }
                                                placeholder="no password"
                                                value={password}
                                                onChange={(_e, data): void => {
                                                    setPassword(data.value);
                                                }}
                                            />
                                        </Field>
                                    </>
                                ) : null}

                                <Field
                                    label="Expiration date"
                                    size="large"
                                    className={classes.date}
                                >
                                    <DatePicker
                                        value={selectedDate}
                                        contentBefore={<Calendar24Regular />}
                                        contentAfter={<></>}
                                        appearance="underline"
                                        onSelectDate={onDatePickerSelect}
                                        placeholder="Select a date..."
                                    />
                                    <div className={classes.dateTags}>
                                        {expirationDatePresets.map(i => (
                                            <Tag
                                                key={i.label}
                                                item={{
                                                    value: i.label,
                                                }}
                                                isActive={
                                                    activeItem !== undefined &&
                                                    i.label === activeItem
                                                }
                                                onClick={(): void => {
                                                    setActiveItem(i.label);
                                                    setSelectedDate(
                                                        i.getDate(),
                                                    );
                                                }}
                                            />
                                        ))}
                                    </div>
                                </Field>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.actions}>
                            {/* {isFile(props.sharingItem) ? (
                                <Button
                                    icon={<CodeRegular />}
                                    appearance="subtle"
                                    disabled={true}
                                >
                                    Embed
                                </Button>
                            ) : null} */}

                            <Button
                                icon={<Link24Regular />}
                                appearance="secondary"
                                disabled={
                                    isCreateQueryInProgress ||
                                    selectedAccessOption ===
                                        AccessOption.PlainPassword
                                        ? password.length === 0 ||
                                          selectedDate === null ||
                                          selectedDate === undefined
                                        : selectedDate === null ||
                                          selectedDate === undefined
                                }
                                onClick={onCopySharingLink}
                            >
                                Copy link
                            </Button>

                            {shareLinkUIConfig.emailNotifications ? (
                                <Button
                                    icon={<Send24Regular />}
                                    appearance="primary"
                                    disabled={!isSendEmailEnabled}
                                    onClick={onSendNotification}
                                >
                                    Send notification
                                </Button>
                            ) : null}
                        </div>
                    </DialogActions>

                    {/* Loader inside dialog */}
                    {isCreateQueryInProgress ? (
                        <div
                            className={mergeClasses(
                                commonClasses.neutralBg,
                                "sharing-link-dialog__loader-container",
                            )}
                        >
                            <Loader text="Processing..." />
                        </div>
                    ) : null}
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export function openSharingLinkDialog(sharingItem: AirFile): void {
    state.appDialog.set(() => (
        <SharingLinkDialog
            sharingItem={sharingItem}
            isOpen={true}
            onClose={(): void => {
                state.appDialog.set(null);
            }}
        />
    ));
}

export default SharingLinkDialog;
