import React from "react";
import {
    ArchiveRegular,
    FireRegular,
    WeatherSnowflakeRegular,
    WeatherSquallsRegular,
} from "@fluentui/react-icons";

export type Tier = {
    key: string;
    text: string;
    iconProps: JSX.Element;
};

export const tiers: Tier[] = [
    {
        key: "hot",
        text: "Hot",
        iconProps: <FireRegular />,
    },
    {
        key: "cool",
        text: "Cool",
        iconProps: <WeatherSquallsRegular />,
    },
    {
        key: "cold",
        text: "Cold",
        iconProps: <WeatherSnowflakeRegular />,
    },
    {
        key: "archive",
        text: "Archive",
        iconProps: <ArchiveRegular />,
    },
];
