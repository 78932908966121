import {
    Button,
    Caption1,
    Link,
    TableCellActions,
    TableCellLayout,
    TableColumnDefinition,
    Text,
    createTableColumn,
} from "@fluentui/react-components";
import { SettingsRegular } from "@fluentui/react-icons";
import React from "react";
import { TagsGroup } from "../../../../../../application/tags/TagsPort";
import { protectedDataParamsKeys } from "../../../../../../domain/dataParams/dataParams";
import { router } from "../../../../../router";
import { openTagsGroupSettings, renderIsVisibleIcon } from "./TagsGroups";

export enum ColumnKind {
    Name = "name",
    NumberOfTags = "numberOfTags",
    IsVisibleInPanel = "isVisibleInPanel",
    IsSearchable = "isSearchable",
}

export const columnSizes = new Map<string | number, number>([
    [ColumnKind.NumberOfTags, 200],
    [ColumnKind.IsVisibleInPanel, 140],
    [ColumnKind.IsSearchable, 160],
]);

export const columns: TableColumnDefinition<TagsGroup>[] = [
    createTableColumn<TagsGroup>({
        columnId: ColumnKind.Name,
        renderHeaderCell: () => <Caption1>Tags group name</Caption1>,
        renderCell: item => {
            return (
                <>
                    <TableCellLayout className="cell-layout">
                        <Link
                            href={router.createRoute({
                                search: {
                                    items: [
                                        {
                                            key: "id",
                                            value: item.id.toString(),
                                        },
                                    ],
                                    protect: [
                                        ...protectedDataParamsKeys,
                                        "activeMenu",
                                    ],
                                },
                            })}
                            data-route
                            appearance="subtle"
                        >
                            <Text truncate block wrap={false} title={item.name}>
                                {item.name}
                            </Text>
                        </Link>
                    </TableCellLayout>
                    <TableCellActions>
                        <Button
                            icon={<SettingsRegular />}
                            appearance="subtle"
                            title="Tags group settings"
                            onClick={(): void => openTagsGroupSettings(item)}
                        />
                    </TableCellActions>
                </>
            );
        },
    }),
    createTableColumn<TagsGroup>({
        columnId: ColumnKind.NumberOfTags,
        renderHeaderCell: () => (
            <TableCellLayout>
                <Caption1 truncate block wrap={false}>
                    Number of tags
                </Caption1>
            </TableCellLayout>
        ),
        renderCell: item => (
            <Text truncate block wrap={false}>
                {item.numberOfTags}
            </Text>
        ),
    }),
    createTableColumn<TagsGroup>({
        columnId: ColumnKind.IsVisibleInPanel,
        renderHeaderCell: () => (
            <TableCellLayout>
                <Caption1 truncate block wrap={false}>
                    Visible in Tags Panel
                </Caption1>
            </TableCellLayout>
        ),
        renderCell: item => renderIsVisibleIcon(item.isVisibleInPanel),
    }),
    createTableColumn<TagsGroup>({
        columnId: ColumnKind.IsSearchable,
        renderHeaderCell: () => (
            <TableCellLayout>
                <Caption1 truncate block wrap={false}>
                    Visible in Search Criteria
                </Caption1>
            </TableCellLayout>
        ),
        renderCell: item => renderIsVisibleIcon(item.isSearchable),
    }),
];
