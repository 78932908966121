import { createState } from "@persevie/statemanjs";
import { StorageItemPort } from "../../../../../application/storageItemPort";
import { UploaderConfig } from "../../../../../application/config/config";

type UploaderSettingsState = {
    tempUploaderSettings: StorageItemPort<UploaderConfig | null>;
};

class UploaderSettingsStateImpl implements UploaderSettingsState {
    readonly tempUploaderSettings: StorageItemPort<UploaderConfig | null>;

    constructor() {
        this.tempUploaderSettings = createState<UploaderConfig | null>(null);
    }
}

export const uploaderSettingsState = new UploaderSettingsStateImpl();
