import React from "react";
import { getIconPath } from "../../services/iconService";
import { mimeTypeKeys } from "../../models/mimes";

type IconItemProps = {
    filePath?: string;
    fileType?: string;
    className?: string;
    isFile?: boolean;
};

export function getExt(filePath: string): string {
    const splitedName = filePath.split(".");
    return splitedName[splitedName.length - 1];
}

function IconItem(props: IconItemProps): JSX.Element {
    const ext =
        (props.isFile || props.fileType === "archive") && props.filePath
            ? getExt(props.filePath)
            : "folder";

    const iconPath = getIconPath(ext, mimeTypeKeys);

    return <img src={iconPath} alt="icon" className={props.className} />;
}

export default IconItem;
