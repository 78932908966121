import { StoragePort } from "../../../../application/ports";
import { useStorage } from "../../../../application/useCases/useStorage";
import { Filters } from "../../../../domain/filter/filters";
import { FilesViewModeKind } from "../../../models";
import { router, routes } from "../../../router";
import { defaultDataParams } from "../../../../domain/dataParams/dataParams";
import { KeyValueObj } from "../../../../domain/types/types";
import { FileExplorerUrlParams, FilterData } from "../shared/types";
import { useFiles } from "../../../../application/useCases/useFiles";
import { state } from "../../../state/stateAdapter";
import filtersTab from "./FileExplorerFilters/fileExplorerFiltersTab";
import { calculateOffset } from "../../../../utility";
import {
    SortKey,
    createBreadcrumbs,
    prepareFilesToShow,
    sortFilesByType,
} from "../../../../domain/airFile/airFile";
import { fileExplorerLocalState } from "./fileExplorerLocalState";
import fileDetailsTab from "../../../components/FileDetails/fileDetailsTab";
import { appManager } from "../../../appManager/appManager";
import { useCognitiveMetadata } from "../../../../application/useCases/useCognitiveMetadata";
import { refreshEventHandler } from "./fileExplorerEventsHandlers";
import { UIEvents } from "../../../uiEvents";
import { useEventBus } from "../../../../application/useCases/useEventBus";

class FileExplorerManager {
    private readonly _filterPrefix: string;
    private readonly _filterRegexp: RegExp;
    private readonly _storagePort: StoragePort;

    constructor(storagePort: StoragePort, filterPrefix = "filter_") {
        this._storagePort = storagePort;
        this._filterPrefix = filterPrefix;

        this._filterRegexp = new RegExp(
            `^${this._filterPrefix}((\\w+\\.\\w+)|\\w+)(?:\\[\\d+\\])?`,
        );
    }

    private _prevFileExplorerUrlParams: FileExplorerUrlParams = {
        activeDirectory: null,
        activeItem: null,
        filters: null,
        filtersParams: null,
        filtersParamsStr: null,
        ...defaultDataParams,
        viewMode: FilesViewModeKind.List,
        query: null,
        selection: null,
    };

    private _toFilterParams(filters: Filters, withPrefix = false): KeyValueObj {
        const filterParams: KeyValueObj = {};

        filters.forEach((value: string[], key: string) => {
            value.forEach((item, index) => {
                const newKey = withPrefix
                    ? `${this._filterPrefix}${key}[${index}]`
                    : `${key}[${index}]`;
                filterParams[newKey] = item;
            });
        });

        return filterParams;
    }

    private _isFilterKey(key: string): boolean {
        return this._filterRegexp.test(key);
    }

    parseUrl(url: URL): FileExplorerUrlParams {
        const searchParams = url.searchParams;
        const filters: Filters = new Map();
        const matches: string[] = [];

        searchParams.forEach((value, key) => {
            const match = this._filterRegexp.exec(key);
            if (match) {
                const filterGroup = match[1];
                if (!filters.has(filterGroup)) {
                    matches.push(filterGroup);
                    filters.set(filterGroup, []);
                }

                filters.get(filterGroup)!.push(value);
            }
        });

        const pageSize = searchParams.get("pageSize");
        const page = searchParams.get("page");
        const sortKey = searchParams.get("sortKey");
        const isSortDesc = searchParams.get("isSortDesc");
        const viewMode = searchParams.get("viewMode");
        const query = searchParams.get("query");
        const selection = searchParams.get("selection");

        return {
            activeDirectory: searchParams.get("path"),
            activeItem: searchParams.get("activeItem"),
            filters: filters.size > 0 ? filters : null,
            filtersParams: this._toFilterParams(filters),
            filtersParamsStr:
                filters.size > 0
                    ? JSON.stringify(this._toFilterParams(filters))
                    : null,
            pageSize: pageSize
                ? parseInt(pageSize)
                : defaultDataParams.pageSize,
            page: page ? parseInt(page) : defaultDataParams.page,
            sortKey: sortKey ?? undefined,
            isSortDesc: isSortDesc !== null ? isSortDesc === "true" : false,
            viewMode:
                viewMode !== null ? parseInt(viewMode) : FilesViewModeKind.List,
            query,
            selection: selection ? selection.split(",") : null,
        };
    }

    /***
     *  @returns isSearchPerformed
     */
    async defineFiltersChanging(data: {
        filters: Filters | null;
        filtersParamsStr: string | null;
    }): Promise<boolean> {
        if (
            data.filtersParamsStr === null ||
            data.filtersParamsStr.length === 0
        ) {
            useStorage.files.activeFilters.set(null);
            useStorage.fileExplorerMiniApp.selection.set(new Map());
            return false;
        } else {
            useStorage.files.activeFilters.set(data.filters);

            useStorage.fileExplorerMiniApp.selection.set(new Map());

            const activeDirectory =
                useStorage.fileExplorerMiniApp.activeDirectory.get();
            const dataParams = useStorage.fileExplorerMiniApp.dataParams.get();
            const query = useStorage.files.searchQuery.get();

            const offset =
                dataParams.page !== null && dataParams.pageSize !== null
                    ? calculateOffset(dataParams.page, dataParams.pageSize)
                    : 0;

            await useFiles.search({
                path: activeDirectory ?? "",
                query: query ?? undefined,
                limit: dataParams.pageSize ?? undefined,
                offset,
                sortKey: dataParams.sortKey ?? undefined,
                isSortDesc: dataParams.isSortDesc ?? undefined,
                filters: data.filters ?? undefined,
            });

            return true;
        }
    }

    async updateParams(url: URL): Promise<void> {
        const urlParams = this.parseUrl(url);

        if (url.pathname === routes.explorer) {
            this.updateContext(url.href);

            let isFilesLoaded = false;
            let isSearchPerformed = false;
            let isFilesPrepared = false;
            let isSelectionUpdated = false;
            let isBreadcrumbsUpdated = false;

            const data = {
                urlParams,
                prevUrlParams: this._prevFileExplorerUrlParams,
            };

            if (
                data.urlParams.activeDirectory !==
                data.prevUrlParams.activeDirectory
            ) {
                useStorage.fileExplorerMiniApp.activeDirectory.set(
                    data.urlParams.activeDirectory,
                );

                if (!isSelectionUpdated) {
                    useStorage.fileExplorerMiniApp.selection.set(new Map());
                }
                isSelectionUpdated = true;

                if (!isFilesLoaded) {
                    await useFiles.getFiles({
                        folderHierarchy:
                            data.urlParams.activeDirectory ?? undefined,
                    });

                    isFilesLoaded = true;
                }

                if (
                    (!isSearchPerformed &&
                        data.urlParams.query !== null &&
                        data.urlParams.query !== undefined &&
                        data.urlParams.query.length > 0) ||
                    (data.urlParams.filters !== null &&
                        data.urlParams.filters !== undefined &&
                        data.urlParams.filters.size > 0) ||
                    state.appPanel.tabs.get().has(filtersTab.id)
                ) {
                    const offset =
                        data.urlParams.page !== null &&
                        data.urlParams.pageSize !== null
                            ? calculateOffset(
                                  data.urlParams.page,
                                  data.urlParams.pageSize,
                              )
                            : 0;

                    await useFiles.search({
                        path: data.urlParams.activeDirectory ?? undefined,
                        query: data.urlParams.query ?? undefined,
                        limit: data.urlParams.pageSize ?? undefined,
                        offset,
                        sortKey: data.urlParams.sortKey ?? undefined,
                        isSortDesc: data.urlParams.isSortDesc ?? undefined,
                        filters: data.urlParams.filters ?? undefined,
                    });

                    isSearchPerformed = true;
                }
            }

            if (
                data.urlParams.filtersParamsStr !==
                data.prevUrlParams.filtersParamsStr
            ) {
                useStorage.files.activeFilters.set(data.urlParams.filters);

                if (!isSearchPerformed) {
                    isSearchPerformed = await this.defineFiltersChanging({
                        filters: data.urlParams.filters,
                        filtersParamsStr: data.urlParams.filtersParamsStr,
                    });

                    if (!isSearchPerformed && !isFilesLoaded) {
                        await useFiles.getFiles({
                            folderHierarchy:
                                data.urlParams.activeDirectory ?? undefined,
                        });

                        isFilesLoaded = true;
                    }
                }
            }

            if (data.urlParams.query !== data.prevUrlParams.query) {
                useStorage.files.searchQuery.set(data.urlParams.query);

                if (!isSearchPerformed) {
                    if (
                        (data.urlParams.query !== null &&
                            data.urlParams.query !== undefined &&
                            data.urlParams.query.length > 0) ||
                        (data.urlParams.filters !== null &&
                            data.urlParams.filters !== undefined &&
                            data.urlParams.filters.size > 0) ||
                        state.appPanel.tabs.get().has(filtersTab.id)
                    ) {
                        const offset =
                            data.urlParams.page !== null &&
                            data.urlParams.pageSize !== null
                                ? calculateOffset(
                                      data.urlParams.page,
                                      data.urlParams.pageSize,
                                  )
                                : 0;

                        await useFiles.search({
                            path: data.urlParams.activeDirectory ?? undefined,
                            query: data.urlParams.query ?? undefined,
                            limit: data.urlParams.pageSize ?? undefined,
                            offset,
                            sortKey: data.urlParams.sortKey ?? undefined,
                            isSortDesc: data.urlParams.isSortDesc ?? undefined,
                            filters: data.urlParams.filters ?? undefined,
                        });

                        isSearchPerformed = true;
                    }
                }
            }

            if (
                data.urlParams.sortKey !== data.prevUrlParams.sortKey ||
                data.urlParams.isSortDesc !== data.prevUrlParams.isSortDesc
            ) {
                useStorage.fileExplorerMiniApp.dataParams.update(s => {
                    s.sortKey = data.urlParams.sortKey;
                    s.isSortDesc = data.urlParams.isSortDesc;
                });

                if (
                    (data.urlParams.query !== null &&
                        data.urlParams.query !== undefined &&
                        data.urlParams.query.length > 0) ||
                    (data.urlParams.filters !== null &&
                        data.urlParams.filters !== undefined &&
                        data.urlParams.filters.size > 0)
                ) {
                    if (!isSearchPerformed) {
                        refreshEventHandler();

                        const offset =
                            data.urlParams.page !== null &&
                            data.urlParams.pageSize !== null
                                ? calculateOffset(
                                      data.urlParams.page,
                                      data.urlParams.pageSize,
                                  )
                                : 0;

                        await useFiles.search({
                            path: data.urlParams.activeDirectory ?? undefined,
                            query: data.urlParams.query ?? undefined,
                            limit: data.urlParams.pageSize ?? undefined,
                            offset,
                            sortKey: data.urlParams.sortKey ?? undefined,
                            isSortDesc: data.urlParams.isSortDesc ?? undefined,
                            filters: data.urlParams.filters ?? undefined,
                        });

                        isSearchPerformed = true;
                    }
                } else {
                    if (!isFilesPrepared) {
                        const sorted = data.urlParams.sortKey
                            ? sortFilesByType(
                                  state.unwrap(useStorage.files.validFiles),
                                  data.urlParams.sortKey as SortKey,
                                  data.urlParams.isSortDesc,
                              )
                            : state.unwrap(useStorage.files.validFiles);

                        useStorage.files.filesToShow.set(
                            prepareFilesToShow(
                                sorted,
                                data.urlParams.filters ?? undefined,
                                data.urlParams.page,
                                data.urlParams.pageSize,
                            ),
                        );

                        isFilesPrepared = true;
                    }
                }
            }

            if (
                data.urlParams.page !== data.prevUrlParams.page ||
                data.urlParams.pageSize !== data.prevUrlParams.pageSize
            ) {
                useStorage.fileExplorerMiniApp.selection.set(new Map());
                useStorage.fileExplorerMiniApp.dataParams.update(s => {
                    s.page = data.urlParams.page;
                    s.pageSize = data.urlParams.pageSize;
                });

                if (
                    (data.urlParams.query !== null &&
                        data.urlParams.query !== undefined &&
                        data.urlParams.query.length > 0) ||
                    (data.urlParams.filters !== null &&
                        data.urlParams.filters !== undefined &&
                        data.urlParams.filters.size > 0)
                ) {
                    if (!isSearchPerformed) {
                        const offset =
                            data.urlParams.page !== null &&
                            data.urlParams.pageSize !== null
                                ? calculateOffset(
                                      data.urlParams.page,
                                      data.urlParams.pageSize,
                                  )
                                : 0;

                        await useFiles.search({
                            path: data.urlParams.activeDirectory ?? undefined,
                            query: data.urlParams.query ?? undefined,
                            limit: data.urlParams.pageSize ?? undefined,
                            offset,
                            sortKey: data.urlParams.sortKey ?? undefined,
                            isSortDesc: data.urlParams.isSortDesc ?? undefined,
                            filters: data.urlParams.filters ?? undefined,
                        });

                        isSearchPerformed = true;
                    }
                } else {
                    if (!isFilesPrepared) {
                        const sorted = data.urlParams.sortKey
                            ? sortFilesByType(
                                  state.unwrap(useStorage.files.validFiles),
                                  data.urlParams.sortKey as SortKey,
                                  data.urlParams.isSortDesc,
                              )
                            : state.unwrap(useStorage.files.validFiles);

                        useStorage.files.filesToShow.set(
                            prepareFilesToShow(
                                sorted,
                                data.urlParams.filters ?? undefined,
                                data.urlParams.page,
                                data.urlParams.pageSize,
                            ),
                        );

                        isFilesPrepared = true;
                    }
                }
            }

            if (data.urlParams.viewMode !== data.prevUrlParams.viewMode) {
                state.fileExplorerMiniApp.filesViewMode.set(
                    data.urlParams.viewMode,
                );
            }

            if (data.urlParams.activeItem !== data.prevUrlParams.activeItem) {
                useStorage.fileExplorerMiniApp.activeItem.set(
                    data.urlParams.activeItem,
                );

                if (!isBreadcrumbsUpdated) {
                    if (
                        data.urlParams.activeDirectory === undefined ||
                        data.urlParams.activeDirectory === null
                    ) {
                        fileExplorerLocalState.breadcrumbsPath.set([]);
                    } else {
                        const breadcrumbs = createBreadcrumbs(
                            data.urlParams.activeDirectory,
                            useStorage.files.files.get() || [],
                        );
                        fileExplorerLocalState.breadcrumbsPath.set(breadcrumbs);
                    }

                    isBreadcrumbsUpdated = true;
                }

                if (
                    data.urlParams.activeItem &&
                    data.urlParams.activeItem.length
                ) {
                    if (!state.appPanel.tabs.get().has(fileDetailsTab.id)) {
                        appManager.addTab(fileDetailsTab);
                    }

                    const details = await useFiles.getDetails({
                        id: parseInt(data.urlParams.activeItem),
                    });

                    if (details?.fileType?.toLowerCase() === "video") {
                        await useCognitiveMetadata.getCognitiveMetadata({
                            id: details.id,
                        });
                    }
                } else {
                    if (state.appPanel.tabs.get().has(fileDetailsTab.id)) {
                        appManager.deleteTab(fileDetailsTab.id);
                    }
                }
            }

            if (!isBreadcrumbsUpdated) {
                if (
                    data.urlParams.activeDirectory === undefined ||
                    data.urlParams.activeDirectory === null
                ) {
                    fileExplorerLocalState.breadcrumbsPath.set([]);
                } else {
                    const breadcrumbs = createBreadcrumbs(
                        data.urlParams.activeDirectory,
                        useStorage.files.files.get() || [],
                    );
                    fileExplorerLocalState.breadcrumbsPath.set(breadcrumbs);
                }

                isBreadcrumbsUpdated = true;
            }

            if (
                this._prevFileExplorerUrlParams.selection !==
                urlParams.selection
            ) {
                useEventBus.emit(UIEvents.FileExplorerChangeSelection, {
                    selection: urlParams.selection,
                });
            }

            // updating prev
            this._prevFileExplorerUrlParams.activeDirectory =
                urlParams.activeDirectory;

            this._prevFileExplorerUrlParams.filtersParamsStr =
                urlParams.filtersParamsStr;

            this._prevFileExplorerUrlParams.sortKey = urlParams.sortKey;

            this._prevFileExplorerUrlParams.isSortDesc = urlParams.isSortDesc;

            this._prevFileExplorerUrlParams.query = urlParams.query;

            this._prevFileExplorerUrlParams.page = urlParams.page;

            this._prevFileExplorerUrlParams.pageSize = urlParams.pageSize;

            this._prevFileExplorerUrlParams.viewMode = urlParams.viewMode;

            this._prevFileExplorerUrlParams.activeItem = urlParams.activeItem;

            this._prevFileExplorerUrlParams.selection = urlParams.selection;
        }
    }

    async updateUrlParams(): Promise<FileExplorerUrlParams> {
        const urlParams = this.parseUrl(router.url);
        this._prevFileExplorerUrlParams = urlParams;

        return urlParams;
    }

    async updateFilters(filtersToUpdate: FilterData[]): Promise<void> {
        const url = router.url;
        const urlParams = this.parseUrl(router.url);
        const filters = urlParams.filters ?? new Map();

        for (const filterData of filtersToUpdate) {
            const { filterGroup, filterName } = filterData;

            const filterValues = filters.get(filterGroup) || [];

            const filterIndex = filterValues.indexOf(`${filterName}`);
            if (filterIndex === -1) {
                filterValues.push(filterName);
            } else {
                filterValues.splice(filterIndex, 1);
            }

            filters.set(filterGroup, filterValues);

            if (filters.get(filterGroup)!.length === 0) {
                filters.delete(filterGroup);
            }
        }

        const keysToRemove: string[] = [];

        url.searchParams.forEach((_value, key) => {
            if (this._isFilterKey(key)) {
                keysToRemove.push(key);
            }
        });

        keysToRemove.forEach(key => {
            url.searchParams.delete(key);
        });

        const filterParams = this._toFilterParams(filters, true);

        for (const name in filterParams) {
            const value = filterParams[name];
            if (value !== undefined && value !== null) {
                url.searchParams.set(name, `${value}`);
            }
        }

        router.updateSearchParams(url.searchParams);
    }

    clearAllFilters(): void {
        const url = router.url;

        Array.from(url.searchParams.keys()).forEach(key => {
            if (this._isFilterKey(key)) {
                url.searchParams.delete(key);
            }
        });

        router.updateSearchParams(url.searchParams);
    }

    removeFilter(filterGroup: string): void {
        router.removeSearchParam(`${this._filterPrefix}${filterGroup}`);
    }

    async updateDateFilter(
        filterGroup: string,
        filterName: string,
    ): Promise<void> {
        const url = router.url;

        url.searchParams.set(
            `${this._filterPrefix}${filterGroup}[0]`,
            filterName,
        );
        router.updateSearchParams(url.searchParams);
    }

    updateContext(href: string): void {
        this._storagePort.fileExplorerMiniApp.context.set(href);
    }
}

export const fileExplorerManager = new FileExplorerManager(useStorage);
