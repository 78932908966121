import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import {
    TagsGetTagsParams,
    TagsGroup,
    TagsPort,
    TagsUpdateOrderGroupsParams,
    TagsEditGroupParams,
    TagsGetGroupParams,
    TagsAddGroupParams,
    TagsAddOrEditTagParams,
    TagsMergeTagsParams,
    TagsSearchTagsParams,
    TagsSearchResults,
} from "./TagsPort";

export class TagsService {
    private readonly _tagsPort: TagsPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        tagsPort: TagsPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._tagsPort = tagsPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    async fetchGroups(): Promise<Option<TagsGroup[]>> {
        try {
            this._errorService.clear(AirErrorKind.FetchTagsGroups);
            this._storagePort.tags.isQueryInProgress.set(true);
            const res = await this._tagsPort.fetchGroups();
            this._storagePort.tags.groups.set(new Map(res.map(i => [i.id, i])));
            this._storagePort.tags.isQueryInProgress.set(false);
            return res;
        } catch (error) {
            this._storagePort.tags.isQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchTagsGroups,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getGroup(params: TagsGetGroupParams): Promise<Option<TagsGroup>> {
        try {
            this._errorService.clear(AirErrorKind.FetchTagsGroupById);
            const res = await this._tagsPort.getGroup(params);
            return res;
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchTagsGroupById,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async editGroup(params: TagsEditGroupParams): Promise<Option<void>> {
        try {
            this._errorService.clear(AirErrorKind.EditTagsGroup);
            this._storagePort.tags.isQueryInProgress.set(true);
            await this._tagsPort.editGroup(params);
            const res = await this._tagsPort.fetchGroups();
            this._storagePort.tags.groups.set(new Map(res.map(i => [i.id, i])));
            this._notificationService.show("Tags group updated", "success");
            this._storagePort.tags.isQueryInProgress.set(false);
        } catch (error) {
            this._storagePort.tags.isQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.EditTagsGroup,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async addGroup(params: TagsAddGroupParams): Promise<Option<void>> {
        try {
            this._storagePort.tags.isQueryInProgress.set(true);
            this._errorService.clear(AirErrorKind.AddTagsGroup);
            await this._tagsPort.addGroup(params);
            const res = await this._tagsPort.fetchGroups();
            this._storagePort.tags.groups.set(new Map(res.map(i => [i.id, i])));
            this._storagePort.tags.isQueryInProgress.set(false);
            this._notificationService.show("Tags group added", "success");
        } catch (error) {
            this._storagePort.tags.isQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.AddTagsGroup,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async updateOrderGroups(
        params: TagsUpdateOrderGroupsParams,
    ): Promise<Option<void>> {
        try {
            this._errorService.clear(AirErrorKind.UpdateOrderTagsGroups);
            this._storagePort.tags.isQueryInProgress.set(true);

            await this._tagsPort.updateOrderGroups(params);
            await this._tagsPort.fetchGroups();
            this._storagePort.tags.isQueryInProgress.set(false);
            this._notificationService.show("Order group updated", "success");
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.UpdateOrderTagsGroups,
                error as Error,
            );
            this._storagePort.tags.isQueryInProgress.set(false);
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getTags(
        params: TagsGetTagsParams,
    ): Promise<Option<TagsSearchResults>> {
        try {
            this._errorService.clear(AirErrorKind.GetTagsGroups);
            const res = await this._tagsPort.getTags(params);
            this._storagePort.tags.isQueryTagsInProgress.set(true);
            this._storagePort.tags.tags.set(
                new Map(res.results.map(i => [i.id, i])),
            );
            this._storagePort.tags.totalTags.set(res.total);
            this._storagePort.tags.isQueryTagsInProgress.set(false);
            return res;
        } catch (error) {
            this._storagePort.tags.isQueryTagsInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.GetTagsGroups,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async searchTags(
        params: TagsSearchTagsParams,
    ): Promise<Option<TagsSearchResults>> {
        try {
            this._errorService.clear(AirErrorKind.SearchTags);
            const res = await this._tagsPort.searchTags(params);
            this._storagePort.tags.tags.set(
                new Map(res.results.map(i => [i.id, i])),
            );
            this._storagePort.tags.totalTags.set(res.total);
            return res;
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.SearchTags,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async addOrEditTag(params: TagsAddOrEditTagParams): Promise<Option<void>> {
        try {
            this._errorService.clear(AirErrorKind.AddOrUpdateTag);
            await this._tagsPort.addOrEditTag(params);
            if (params.id) {
                this._notificationService.show("Tag updated", "success");
            } else {
                this._notificationService.show("Tag added", "success");
            }
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.AddOrUpdateTag,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async mergeTags(params: TagsMergeTagsParams): Promise<Option<void>> {
        try {
            this._errorService.clear(AirErrorKind.MergeTags);
            await this._tagsPort.mergeTags(params);
            this._notificationService.show("Tags merged", "success");
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.MergeTags,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}
