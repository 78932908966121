import { NotificationService } from "../notification/notificationService";
import {
    DirectoryObjectUser,
    DirectoryObjectsPort,
    DirectoryObjectsSearchUserParams,
} from "./directoryObjectsPort";
import { Option } from "../../domain/types/types";
import { StoragePort } from "../ports";
import { AirErrorService } from "../airError/airErrorService";
import { AirErrorKind } from "../airError/airError";

export class DirectoryObjectsService {
    private readonly _directoryObjectsPort: DirectoryObjectsPort;
    private readonly _errorService: AirErrorService;
    private readonly _notificationService: NotificationService;
    private readonly _storagePort: StoragePort;

    constructor(
        directoryObjectsPort: DirectoryObjectsPort,
        errorService: AirErrorService,
        notificationService: NotificationService,
        storagePort: StoragePort,
    ) {
        this._directoryObjectsPort = directoryObjectsPort;
        this._errorService = errorService;
        this._notificationService = notificationService;
        this._storagePort = storagePort;
    }

    async searchUser(
        params: DirectoryObjectsSearchUserParams,
    ): Promise<Option<DirectoryObjectUser[]>> {
        try {
            this._errorService.clear(AirErrorKind.SearchUser);
            this._storagePort.directoryObjects.isQueryInProgress.set(true);

            const directoryObjectUsers =
                await this._directoryObjectsPort.searchUser(params);

            this._storagePort.directoryObjects.directoryObjectUsers.set(
                directoryObjectUsers,
            );
            this._storagePort.directoryObjects.isQueryInProgress.set(false);

            return directoryObjectUsers;
        } catch (error) {
            this._storagePort.directoryObjects.isQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.SearchUser,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}
