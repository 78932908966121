import { DownloadLocationAdapter } from "../../infrastructure/downlodLocation/downloadLocationAdapter";
import { DownloadLocationService } from "../downloadLocation/downloadLocationService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const useDownloadLocation = new DownloadLocationService(
    new DownloadLocationAdapter(),
    useError,
    useStorage,
    useNotification,
);
