import React from "react";
import { PanelTab } from "../../../../models";
import { appManager } from "../../../../appManager/appManager";
import UploadDestination from "./UploadDestination";

const uploadDestinationTab: PanelTab = {
    id: "uploadDestination",
    title: "Upload destination",
    content: <UploadDestination />,
    onClose: () => appManager.deleteTab("uploadDestination"),
};

export default uploadDestinationTab;
