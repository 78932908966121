import {
    IndexedLocation,
    IndexedLocationStorageAccount,
    IndexedLocationSubscription,
} from "./indexedLocation";
import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import {
    IndexedLocationAddParams,
    IndexedLocationConfigureEventParams,
    IndexedLocationFetchStorageAccountsParams,
    IndexedLocationGetParams,
    IndexedLocationGetStorageAccountParams,
    IndexedLocationPort,
    IndexedLocationValidateEventRequest,
} from "./indexedLocationPort";

export class IndexedLocationService {
    private readonly _indexedLocationPort: IndexedLocationPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        indexedLocationPort: IndexedLocationPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._indexedLocationPort = indexedLocationPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    async fetchIndexedLocations(): Promise<Option<IndexedLocation[]>> {
        try {
            this._errorService.clear(AirErrorKind.FetchIndexedLocations);
            this._storagePort.indexedLocation.isQueryInProgress.set(true);
            const res = await this._indexedLocationPort.fetchIndexedLocations();
            this._storagePort.indexedLocation.indexedLocations.set(res);
            this._storagePort.indexedLocation.isQueryInProgress.set(false);
            return res;
        } catch (error) {
            this._storagePort.indexedLocation.isQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchIndexedLocations,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async fetchSubscriptions(): Promise<Option<IndexedLocationSubscription[]>> {
        try {
            this._errorService.clear(AirErrorKind.FetchSubscriptions);

            return await this._indexedLocationPort.fetchSubscriptions();
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchSubscriptions,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async fetchStorageAccounts(
        params: IndexedLocationFetchStorageAccountsParams,
    ): Promise<Option<IndexedLocationStorageAccount[]>> {
        try {
            this._errorService.clear(AirErrorKind.FetchStorageAccounts);

            return await this._indexedLocationPort.fetchStorageAccounts(params);
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchStorageAccounts,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getStorageAccount(
        params: IndexedLocationGetStorageAccountParams,
    ): Promise<Option<IndexedLocationStorageAccount>> {
        try {
            this._errorService.clear(AirErrorKind.FetchStorageAccount);

            return await this._indexedLocationPort.getStorageAccount(params);
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.FetchStorageAccount,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getIndexedLocation(
        params: IndexedLocationGetParams,
    ): Promise<Option<IndexedLocation>> {
        try {
            this._errorService.clear(AirErrorKind.GetIndexedLocation);
            this._storagePort.indexedLocation.isQueryInProgress.set(true);
            const res = await this._indexedLocationPort.getIndexedLocation(
                params,
            );
            this._storagePort.indexedLocation.activeItem.set(res);
            this._storagePort.indexedLocation.isQueryInProgress.set(false);

            return res;
        } catch (error) {
            this._storagePort.indexedLocation.isQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.GetIndexedLocation,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async addIndexedLocation(
        params: IndexedLocationAddParams,
    ): Promise<Option<IndexedLocation>> {
        try {
            this._errorService.clear(AirErrorKind.AddIndexedLocation);
            const res = await this._indexedLocationPort.addIndexedLocation(
                params,
            );
            const indexedLocations =
                await this._indexedLocationPort.fetchIndexedLocations();
            this._storagePort.indexedLocation.indexedLocations.set(
                indexedLocations,
            );
            this._notificationService.show("Indexed Location added", "success");

            return res;
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.AddIndexedLocation,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);

            throw new Error(airError.toString());
        }
    }

    async editIndexedLocation(
        params: IndexedLocation,
    ): Promise<Option<IndexedLocation>> {
        try {
            this._errorService.clear(AirErrorKind.EditIndexedLocation);
            const res = await this._indexedLocationPort.editIndexedLocation(
                params,
            );
            const indexedLocations =
                await this._indexedLocationPort.fetchIndexedLocations();
            this._storagePort.indexedLocation.indexedLocations.set(
                indexedLocations,
            );
            this._notificationService.show(
                "Indexed Location updated",
                "success",
            );
            return res;
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.EditIndexedLocation,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
            throw new Error(airError.toString());
        }
    }

    async validateEvent(
        params: IndexedLocationGetParams,
    ): Promise<Option<IndexedLocationValidateEventRequest>> {
        try {
            this._errorService.clear(AirErrorKind.ValidateEvent);
            return await this._indexedLocationPort.validateEvent(params);
        } catch (error) {
            this._storagePort.indexedLocation.isQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.ValidateEvent,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async configureEvent(
        params: IndexedLocationConfigureEventParams,
    ): Promise<Response> {
        this._errorService.clear(AirErrorKind.ConfigureEvent);
        return await this._indexedLocationPort.configureEvent(params);
    }
}
