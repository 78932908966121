import React, { SyntheticEvent } from "react";
import {
    Text,
    TableColumnDefinition,
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableSelectionCell,
    useTableFeatures,
    useTableSelection,
    useTableSort,
    TableColumnId,
    SortDirection,
} from "@fluentui/react-components";
import { v4 as uuidv4 } from "uuid";
import { AirFile } from "../../../domain/airFile/airFile";
import Loader from "../Loader/Loader";
import { router } from "../../router";
import { useCommonFluentuiStyles } from "../../styles/griffel";

type FilesWithoutSortingSelectionProps = {
    isQueryInProgress: boolean;
    error: string | undefined;
    files: unknown[];
    columns: TableColumnDefinition<unknown>[];
    columnSizes: Map<string | number, number>;
};

type FilesWithSortingSelectionProps = FilesWithoutSortingSelectionProps & {
    selection: {
        filesSelection: Map<string, unknown>;
        onAllSelect: (
            e:
                | React.MouseEvent<Element, MouseEvent>
                | React.KeyboardEvent<Element>,
            items?: unknown[],
        ) => void;
        onSelect: (e: SyntheticEvent, item: unknown) => void;
    };
    sorting: {
        sortKey: string;
        isSortDesc: boolean;
    };
};

type FilesProps = Omit<
    FilesWithSortingSelectionProps,
    "selection" | "sorting"
> & {
    selection?: {
        filesSelection: Map<string, unknown>;
        onAllSelect: (
            e:
                | React.MouseEvent<Element, MouseEvent>
                | React.KeyboardEvent<Element>,
            items?: unknown[],
        ) => void;
        onSelect: (e: SyntheticEvent, item: unknown) => void;
    };
    sorting?: {
        sortKey: string;
        isSortDesc: boolean;
    };
};

function FilesTable(props: FilesProps): JSX.Element {
    if (props.selection !== undefined && props.sorting !== undefined) {
        return (
            <FilesWithSortingMultipleSelection
                {...props}
                selection={props.selection}
                sorting={props.sorting}
            />
        );
    }

    if (props.sorting !== undefined) {
        return (
            <FilesWithSortingNoSelection {...props} sorting={props.sorting} />
        );
    }

    return <FilesWithoutSortingSelection {...props} />;
}

function FilesWithSortingMultipleSelection(
    props: FilesProps & {
        sorting: {
            sortKey: string;
            isSortDesc: boolean;
        };
        selection: {
            onAllSelect: (
                e:
                    | React.MouseEvent<Element, MouseEvent>
                    | React.KeyboardEvent<Element>,
                items?: unknown[],
            ) => void;
            onSelect: (e: SyntheticEvent, item: unknown) => void;
        };
    },
): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    const {
        getRows,
        tableRef,
        sort: { getSortDirection, toggleColumnSort },
    } = useTableFeatures(
        {
            columns: props.columns,
            items: props.files,
        },
        [
            useTableSelection({
                selectionMode: "multiselect",
                defaultSelectedItems: Array.from(
                    props.selection.filesSelection.values(),
                ).map(i => (i as AirFile).id),
            }),
            useTableSort({
                defaultSortState: {
                    sortColumn: props.sorting.sortKey,
                    sortDirection: props.sorting.isSortDesc
                        ? "descending"
                        : "ascending",
                },
            }),
        ],
    );

    const headerSortProps = (
        columnId: TableColumnId,
    ): {
        onClick: (e: React.MouseEvent) => void;
        sortDirection: SortDirection | undefined;
    } => ({
        onClick: (e: React.MouseEvent): void => {
            toggleColumnSort(e, columnId);
            let isSortDesc = false;
            const sortDir = getSortDirection(columnId);

            if (sortDir !== undefined) {
                if (sortDir === "ascending") {
                    isSortDesc = true;
                }
            }

            router.setSearchParams([
                { key: "sortKey", value: columnId.toString() },
                { key: "isSortDesc", value: `${isSortDesc}` },
            ]);
        },
        sortDirection: getSortDirection(columnId),
    });

    const rows = getRows(row => {
        const isSelected = (id: string): boolean => {
            return props.selection.filesSelection.has(id);
        };

        return {
            ...row,
            onClick: (e: React.MouseEvent, item: unknown) =>
                props.selection.onSelect(e, item),
            onKeyDown: (e: React.KeyboardEvent, item: unknown): void => {
                if (e.key === " ") {
                    e.preventDefault();
                    props.selection.onSelect(e, item);
                }
            },
            isSelected,
            getAppearance: (
                id: string,
            ): "none" | "brand" | "neutral" | undefined =>
                isSelected(id) ? ("neutral" as const) : ("none" as const),
        };
    });

    const toggleAllKeydown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === " ") {
            props.selection.onAllSelect(e, props.files);
            e.preventDefault();
        }
    };

    if (props.isQueryInProgress) {
        return <Loader text="Fetching files..." />;
    }

    if (props.error) {
        return <Text>Error {props.error}</Text>;
    }

    if (props.files.length === 0) {
        return <Text>Nothing to show</Text>;
    }

    return (
        <div className="table-container">
            <Table
                aria-label="AIR Files"
                ref={tableRef}
                className="table"
                sortable
            >
                <TableHeader className={commonClasses.tableHeader}>
                    <TableRow>
                        <TableSelectionCell
                            checked={
                                props.selection.filesSelection.size ===
                                props.files.length
                                    ? true
                                    : props.selection.filesSelection.size > 0
                                    ? "mixed"
                                    : false
                            }
                            onClick={(
                                e:
                                    | React.MouseEvent<Element, MouseEvent>
                                    | React.KeyboardEvent<Element>,
                            ): void =>
                                props.selection.onAllSelect(e, props.files)
                            }
                            onKeyDown={toggleAllKeydown}
                            checkboxIndicator={{
                                "aria-label": "Select all rows ",
                            }}
                        />

                        {props.columns.map(i => (
                            <TableHeaderCell
                                key={uuidv4()}
                                {...headerSortProps(i.columnId)}
                                style={
                                    props.columnSizes.has(i.columnId)
                                        ? {
                                              width: `${props.columnSizes.get(
                                                  i.columnId,
                                              )}px`,
                                          }
                                        : undefined
                                }
                            >
                                {i.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map(
                        ({
                            item,
                            isSelected,
                            onClick,
                            onKeyDown,
                            getAppearance,
                        }) => (
                            <TableRow
                                key={uuidv4()}
                                onClick={(
                                    e: React.MouseEvent<Element, MouseEvent>,
                                ): void => onClick(e, item)}
                                onKeyDown={(e): void => onKeyDown(e, item)}
                                aria-selected={isSelected(
                                    (item as AirFile).hierarchy,
                                )}
                                appearance={getAppearance(
                                    (item as AirFile).hierarchy,
                                )}
                                className={commonClasses.tableRow}
                            >
                                <TableSelectionCell
                                    subtle
                                    checked={isSelected(
                                        (item as AirFile).hierarchy,
                                    )}
                                    checkboxIndicator={{
                                        "aria-label": "Select row",
                                    }}
                                />

                                {props.columns.map(i => (
                                    <TableCell key={uuidv4()}>
                                        {i.renderCell(item)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ),
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

function FilesWithSortingNoSelection(
    props: FilesWithoutSortingSelectionProps & {
        sorting: {
            sortKey: string;
            isSortDesc: boolean;
        };
    },
): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    const {
        getRows,
        tableRef,
        sort: { getSortDirection, toggleColumnSort },
    } = useTableFeatures(
        {
            columns: props.columns,
            items: props.files,
        },
        [
            useTableSort({
                defaultSortState: {
                    sortColumn: props.sorting.sortKey,
                    sortDirection: props.sorting.isSortDesc
                        ? "descending"
                        : "ascending",
                },
            }),
        ],
    );

    const headerSortProps = (
        columnId: TableColumnId,
    ): {
        onClick: (e: React.MouseEvent) => void;
        sortDirection: SortDirection | undefined;
    } => ({
        onClick: (e: React.MouseEvent): void => {
            toggleColumnSort(e, columnId);
            let isSortDesc = false;
            const sortDir = getSortDirection(columnId);

            if (sortDir !== undefined) {
                if (sortDir === "ascending") {
                    isSortDesc = true;
                }
            }

            router.setSearchParams([
                { key: "sortKey", value: columnId.toString() },
                { key: "isSortDesc", value: `${isSortDesc}` },
            ]);
        },
        sortDirection: getSortDirection(columnId),
    });

    const rows = getRows(row => {
        return {
            ...row,
            appearance: "none" as const,
        };
    });

    if (props.isQueryInProgress) {
        return <Loader text="Fetching files..." />;
    }

    if (props.error) {
        return <Text>Error {props.error}</Text>;
    }

    if (props.files.length === 0) {
        return <Text>Nothing to show</Text>;
    }

    return (
        <div className="table-container">
            <Table
                aria-label="AIR Files"
                ref={tableRef}
                className="table"
                sortable
            >
                <TableHeader className={commonClasses.tableHeader}>
                    <TableRow>
                        {props.columns.map(i => (
                            <TableHeaderCell
                                key={uuidv4()}
                                {...headerSortProps(i.columnId)}
                                style={
                                    props.columnSizes.has(i.columnId)
                                        ? {
                                              width: `${props.columnSizes.get(
                                                  i.columnId,
                                              )}px`,
                                          }
                                        : undefined
                                }
                            >
                                {i.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map(({ item, appearance }) => (
                        <TableRow
                            key={uuidv4()}
                            appearance={appearance}
                            className={commonClasses.tableRow}
                        >
                            {props.columns.map(i => (
                                <TableCell key={uuidv4()}>
                                    {i.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

function FilesWithoutSortingSelection(
    props: FilesWithoutSortingSelectionProps,
): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();

    const { getRows, tableRef } = useTableFeatures(
        {
            columns: props.columns,
            items: props.files,
        },
        [],
    );

    const rows = getRows(row => {
        return {
            ...row,
            appearance: "none" as const,
        };
    });

    if (props.isQueryInProgress) {
        return <Loader text="Fetching files..." />;
    }

    if (props.error) {
        return <Text>Error {props.error}</Text>;
    }

    if (props.files.length === 0) {
        return <Text>Nothing to show</Text>;
    }

    return (
        <div className="table-container">
            <Table
                aria-label="AIR Files"
                ref={tableRef}
                className="table"
                sortable
            >
                <TableHeader className={commonClasses.tableHeader}>
                    <TableRow>
                        {props.columns.map(i => (
                            <TableHeaderCell
                                key={uuidv4()}
                                style={
                                    props.columnSizes.has(i.columnId)
                                        ? {
                                              width: `${props.columnSizes.get(
                                                  i.columnId,
                                              )}px`,
                                          }
                                        : undefined
                                }
                            >
                                {i.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map(({ item, appearance }) => (
                        <TableRow
                            key={uuidv4()}
                            appearance={appearance}
                            className={commonClasses.tableRow}
                        >
                            {props.columns.map(i => (
                                <TableCell key={uuidv4()}>
                                    {i.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default FilesTable;
