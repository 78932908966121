import { PanelTab } from "../models";
import { StoragePort } from "../../application/ports";
import { StatePort } from "../state/statePort";
import { useStorage } from "../../application/useCases/useStorage";
import { state } from "../state/stateAdapter";
import { router } from "../router";
import { Router } from "../router/router";

interface AppManagerPort {
    clearActiveItem(): void;

    addTab(tab: PanelTab): void;

    toggleTab(tab: PanelTab): void;

    deleteTab(tabId: string): void;

    activateTab(tab: PanelTab): boolean;
}

class AppManager<T extends { [key: string]: string }>
    implements AppManagerPort
{
    private readonly _storagePort: StoragePort;
    private readonly _statePort: StatePort;
    private readonly _router: Router<T>;

    constructor(
        storagePort: StoragePort,
        statePort: StatePort,
        router: Router<T>,
    ) {
        this._storagePort = storagePort;
        this._statePort = statePort;
        this._router = router;

        this.addTab = this.addTab.bind(this);
        this.clearActiveItem = this.clearActiveItem.bind(this);
        this.deleteTab = this.deleteTab.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
    }

    addTab(tab: PanelTab): void {
        this._statePort.appPanel.isOpen.set(true);
        this._statePort.appPanel.activeTabId.set(tab.id);
        this._statePort.appPanel.tabs.update(s => {
            s.set(tab.id, tab);
        });
    }

    clearActiveItem(): void {
        this._storagePort.fileExplorerMiniApp.activeItem.set(null);
        this._router.clearSearchByKey("activeItem");
    }

    deleteTab(tabId: string): void {
        this._statePort.appPanel.tabs.update(s => {
            s.delete(tabId);
        });

        if (tabId === this._statePort.appPanel.activeTabId.get()) {
            this._statePort.appPanel.activeTabId.set(
                this._statePort.appPanel.tabs.get().size > 0
                    ? this._statePort.appPanel.tabs.get().keys().next().value
                    : "",
            );
        }

        if (
            this._statePort.appPanel.tabs.get().size === 0 &&
            this._statePort.appPanel.isOpen.get()
        ) {
            this._statePort.appPanel.isOpen.set(false);
        }
    }

    toggleTab(tab: PanelTab): void {
        if (this._statePort.appPanel.tabs.get().has(tab.id)) {
            if (this._statePort.appPanel.activeTabId.get() === tab.id) {
                this.deleteTab(tab.id);
            } else {
                this._statePort.appPanel.activeTabId.set(tab.id);
            }
        } else {
            this.addTab(tab);
        }
    }

    activateTab(tab: PanelTab): boolean {
        return this._statePort.appPanel.activeTabId.set(tab.id);
    }
}

export const appManager = new AppManager(useStorage, state, router);
