import React from "react";
import { state } from "../../../../state/stateAdapter";
import IndexedLocationsList from "./IndexedLocationsList/IndexedLocationsList";
import IndexedLocationAdd from "./IndexedLocationsAdd/IndexedLocationsAdd";
import IndexedLocationEdit from "./IndexedLocationEdit/IndexedLocationEdit";
// import { DeleteIndexedLocationDialog } from "./DeleteIndexedLocationDialog/DeleteIndexedLocationDialog";
import { useStorage } from "../../../../../application/useCases/useStorage";

function IndexedLocations(): JSX.Element {
    const id = state.useState(useStorage.adminMiniApp.id);

    // TODO: uncoment when delete indexed location API will be done
    // useEffect(() => {
    //     state.appDialog.set(() => <DeleteIndexedLocationDialog />);
    // });

    if (id == "add") {
        return <IndexedLocationAdd />;
    }

    if (id) {
        return <IndexedLocationEdit />;
    }

    return <IndexedLocationsList />;
}
export default IndexedLocations;
