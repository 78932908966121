import React, { ChangeEvent, useCallback, useState } from "react";
import {
    Text,
    Combobox,
    Option,
    Persona,
    makeStyles,
    tokens,
    useId,
    shorthands,
} from "@fluentui/react-components";
import Loader from "../../Loader/Loader";
import { useDirectoryObjects } from "../../../../application/useCases/useDirectoryObjects";
import ButtonTag from "../ButtonTag/ButtonTag";

export type UserForSelect = {
    id: string;
    displayName: string;
    email: string | null;
    image?: string;
};

export type SelectUsersData = {
    key: string;
    value: string;
};

const useStyles = makeStyles({
    listBox: {
        overflowX: "hidden",
    },
    option: {
        display: "grid",
        gridTemplateColumns: "20px auto",
        alignItems: "center",
    },
    tagsList: {
        listStyleType: "none",
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        gridGap: tokens.spacingHorizontalXS,
        flexWrap: "wrap",
        marginBlockEnd: tokens.spacingHorizontalS,
    },
    root: {
        display: "grid",
    },
    inputs: {
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateColumns: "auto max-content",
        columnGap: tokens.spacingHorizontalS,
        justifyContent: "stretch",
    },
    truncated: {
        maxWidth: "200px",
        ...shorthands.overflow("hidden"),
        textOverflow: "ellipsis",
        ...shorthands.margin(0),
        display: "block",
        textWrap: "nowrap",
    },
    persona: {
        whiteSpace: "nowrap",
    },
});

type SelectUsersFieldProps = {
    disabled?: boolean;
    data?: SelectUsersData[] | null;
    onChange: (users: SelectUsersData[]) => void;
    onBlur?: (users: SelectUsersData[]) => void;
};

function SelectUsersField(props: SelectUsersFieldProps): JSX.Element {
    const comboId = useId("combo-multi");
    const selectedListId = `${comboId}-selection`;
    const selectedListRef = React.useRef<HTMLUListElement>(null);
    const comboboxInputRef = React.useRef<HTMLInputElement>(null);
    const [users, setUsers] = useState<UserForSelect[]>([]);
    // eslint-disable-next-line no-undef
    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
        null,
    );
    const initSelectedUsers = props.data
        ? new Map(props.data.map(i => [i.key, i]))
        : new Map();
    const [selectedUsers, setSelectedUsers] =
        useState<Map<string, SelectUsersData>>(initSelectedUsers);
    const [isSearchUsersInProgress, setIsSearchUsersInProgress] =
        useState(false);

    const onUserSelect = (user: UserForSelect): void => {
        if (selectedUsers.has(user.id)) {
            selectedUsers.delete(user.id);
        } else {
            selectedUsers.set(user.id, {
                key: user.id,
                value: user.displayName,
            });
        }
        setSelectedUsers(new Map([...selectedUsers]));
        props.onChange(Array.from(selectedUsers.values()));
    };

    const onSearch = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setIsSearchUsersInProgress(true);
            setUsers([]);
            const query = e.target.value.trim();

            if (debounceTimer !== null) {
                clearTimeout(debounceTimer);
                setDebounceTimer(null);
            }
            const newDebounceTimer = setTimeout(async (): Promise<void> => {
                const users = await useDirectoryObjects.searchUser({
                    query: query ?? "",
                });
                setUsers(users ?? []);
                setIsSearchUsersInProgress(false);
            }, 1000);

            setDebounceTimer(newDebounceTimer);
        },
        [debounceTimer],
    );

    const onTagClick = (option: string, index: number): void => {
        selectedUsers.delete(option);
        setSelectedUsers(new Map([...selectedUsers]));

        const indexToFocus = index === 0 ? 1 : index - 1;
        const optionToFocus = selectedListRef.current?.querySelector(
            `#${comboId}-remove-${indexToFocus}`,
        );
        if (optionToFocus) {
            (optionToFocus as HTMLButtonElement).focus();
        } else {
            comboboxInputRef.current?.focus();
        }
        props.onChange(Array.from(selectedUsers.values()));
    };

    const labelledBy =
        selectedUsers.size > 0 ? `${comboId} ${selectedListId}` : comboId;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {selectedUsers.size > 0 ? (
                <ul
                    id={selectedListId}
                    className={classes.tagsList}
                    ref={selectedListRef}
                >
                    <span id={`${comboId}-remove`} hidden>
                        Remove
                    </span>
                    {Array.from(selectedUsers.entries()).map((option, i) => (
                        <li key={option[0]}>
                            <ButtonTag
                                disabled={props.disabled}
                                onClick={(): void => onTagClick(option[0], i)}
                                id={`${comboId}-remove-${i}`}
                                aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                            >
                                {option[1].value}
                            </ButtonTag>
                        </li>
                    ))}
                </ul>
            ) : null}

            <div className={classes.inputs}>
                <Combobox
                    disabled={props.disabled}
                    size="large"
                    aria-labelledby={labelledBy}
                    placeholder="Find user"
                    onChange={onSearch}
                    onBlur={(): void => {
                        props.onBlur &&
                            props.onBlur(Array.from(selectedUsers.values()));
                    }}
                    multiselect={true}
                    selectedOptions={Array.from(selectedUsers.keys()).flat()}
                    listbox={{ className: classes.listBox }}
                >
                    {isSearchUsersInProgress ? (
                        <div className="app-dialog__content">
                            <Loader text={"Searching..."} size="small" />
                        </div>
                    ) : (
                        users.map(i => (
                            <Option
                                key={i.id}
                                value={i.id}
                                text={i.displayName}
                                className={classes.option}
                                title={`${i.displayName}; ${i.email}`}
                                onClick={(): void => onUserSelect(i)}
                            >
                                <Persona
                                    size="small"
                                    className={classes.persona}
                                    name={i.displayName}
                                    avatar={{
                                        image: {
                                            src: i.image ?? "",
                                        },
                                    }}
                                    secondaryText={
                                        <Text
                                            as="p"
                                            className={classes.truncated}
                                        >
                                            {i.email}
                                        </Text>
                                    }
                                    primaryText={{
                                        className: classes.truncated,
                                    }}
                                />
                            </Option>
                        ))
                    )}
                </Combobox>
            </div>
        </div>
    );
}

export default SelectUsersField;
