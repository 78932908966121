export enum DateFormat {
    FullMonthDayYear = "MMMM Do YYYY",
    MonthDayYearNumeric = "MM-DD-YYYY",
    ShortMonthDayTime = "MMM D HH:mm",
    ShortTimeWithSeconds = "HH:mm:ss",
}

export interface TimeFormatter {
    formatDate(timestamp: number, dateFormat: DateFormat | string): string;
}
