import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";
import {
    Button,
    Divider,
    makeStyles,
    mergeClasses,
    tokens,
    InfoLabel,
    Toolbar,
    ToolbarButton,
    Text,
    Caption1,
} from "@fluentui/react-components";
import { UploadLocation } from "../../../../../domain/uploadLocation/uploadLocation";
import Loader from "../../../../components/Loader/Loader";
import { useUploader } from "../../../../../application/useCases/useUploader";
import PathBreadcrumbs, {
    Crumb,
} from "../../../../components/Breadcrumbs/PathBreadcrumbs";
import { BreadcrumbsSeparator } from "../../../../components/Breadcrumbs/breadcrumbsShared";
import { CloudAddRegular } from "@fluentui/react-icons";
import "./uploadDestination.css";
import {
    FolderIcon,
    LocationIcon,
    NewFolderIcon,
    RefreshFolderIcon,
} from "../../../../components/BundledIcons";
import { uploaderState } from "../shared/state";
import { useNotification } from "../../../../../application/useCases/useNotification";
import { appManager } from "../../../../appManager/appManager";
import uploadDestinationTab from "./uploadDestinationTab";
import { formUploadDestination } from "../../../../../domain/userFileUpload/userFileUpload";
import CreateFolderDialog from "../../shared/CreateFolderDialog/CreateFolderDialog";
import { UserFile } from "../../../../../domain/userFile/userFile";
import { normalizePath } from "../../../../../utility";
import { router } from "../../../../router";
import { useTimeFormatter } from "../../../../../application/useCases/useTimeFormatter";
import appUploadsTab from "../../../AppUploadsTab/appUploadsTab";

const useStyles = makeStyles({
    btn: {
        width: "100%",
        ":hover": {
            backgroundColor: tokens.colorNeutralBackground1Pressed,
        },
        ":hover:active": {
            backgroundColor: tokens.colorNeutralBackground2Pressed,
        },
        fontWeight: "normal",
    },
});

type UploadDestination = {
    location: UploadLocation;
    destination: string;
};

const recentDestinationsKey = "recentDestinations";

function getRecentDestinations(): UploadDestination[] {
    const recentDestinationsString = localStorage.getItem("recentDestinations");
    return recentDestinationsString ? JSON.parse(recentDestinationsString) : [];
}

function updateRecentDestinations(
    location: UploadLocation,
    destination: string,
): UploadDestination[] {
    const recentDestinations = getRecentDestinations();

    const existingIndex = recentDestinations.findIndex(
        i => i.destination === destination,
    );
    if (existingIndex !== -1) {
        recentDestinations.splice(existingIndex, 1);
    }

    recentDestinations.unshift({
        location,
        destination,
    });

    if (recentDestinations.length > 5) {
        recentDestinations.length = 5;
    }

    localStorage.setItem(
        recentDestinationsKey,
        JSON.stringify(recentDestinations),
    );

    return recentDestinations;
}

function onUpload(
    filesSelection: UserFile[],
    selectedLocation: UploadLocation,
    destinationPath: string,
): void {
    useUploader.upload(
        [...filesSelection.values()],
        selectedLocation,
        destinationPath,
    );

    if (state.appPanel.tabs.get().has(appUploadsTab.id)) {
        appManager.activateTab(appUploadsTab);
    } else {
        appManager.addTab(appUploadsTab);
    }

    if (!state.appPanel.isOpen.get()) {
        state.appPanel.isOpen.set(true);
    }

    if (uploaderState.predefinedDestination.get()) {
        const fileExplorerContext =
            useStorage.fileExplorerMiniApp.context.get();

        if (fileExplorerContext && fileExplorerContext.length > 0) {
            router.navigateToHref(fileExplorerContext);
        } else {
            window.history.back();
        }
    }
}

function UploadDestination(): JSX.Element | null {
    const predefinedDestination = uploaderState.predefinedDestination.get();
    const isFetchDestinationFoldersQueryInProgress = state.useState(
        useStorage.uploaderMiniApp.isFetchDestinationFoldersQueryInProgress,
    );
    const locations = state.useState(useStorage.uploaderMiniApp.locations);
    const [currentPath, setCurrentPath] = useState<string>(
        predefinedDestination ? predefinedDestination.path ?? "" : "",
    );
    const [folders, setFolders] = useState<string[]>([]);
    const classes = useStyles();
    const [selectedLocation, setSelectedLocation] =
        useState<UploadLocation | null>(
            predefinedDestination
                ? predefinedDestination.selectedLocation
                : null,
        );
    const filesSelection = state.useState(uploaderState.selection);
    const [recentDestinations, setRecentDestinations] = useState<
        UploadDestination[]
    >(getRecentDestinations());
    const uploaderConfig = state.useState(useStorage.config.config, {
        properties: ["features.uploader"],
    }).features.uploader;

    const onSelectDestination = useCallback(
        async (
            location: UploadLocation | null,
            path: string,
        ): Promise<void> => {
            let folders: string[] = [];

            if (location !== null) {
                folders =
                    (await useUploader.fetchLocationFolders(location, path)) ??
                    [];
            }

            if (selectedLocation !== location) {
                setSelectedLocation(location);
            }

            setCurrentPath(path);
            setFolders(folders);
        },
        [selectedLocation],
    );

    const onCreateFolder = useCallback((): void => {
        state.appDialog.set(() => (
            <CreateFolderDialog
                createFolderCb={(folderName: string): void => {
                    const path = normalizePath(
                        currentPath.length > 0
                            ? `${currentPath}/${folderName}`
                            : folderName,
                    );
                    folders.push(`${path}/`);
                    setFolders([...folders]);
                    onSelectDestination(selectedLocation, path);
                }}
            />
        ));
    }, [currentPath, folders, onSelectDestination, selectedLocation]);

    const renderCrumb = useCallback(
        (
            folder: string,
            index: number,
            disabled: boolean,
            isLast: boolean,
        ): JSX.Element => {
            if (index === 0) {
                return (
                    <>
                        <Crumb
                            isActive={false}
                            disabled={disabled}
                            as="button"
                            onCrumbClick={(e): void => {
                                e.stopPropagation();
                                onSelectDestination(null, "");
                            }}
                        >
                            Root
                        </Crumb>

                        <BreadcrumbsSeparator disabled={disabled} />

                        {selectedLocation !== null &&
                            selectedLocation.title === folder && (
                                <Crumb
                                    isActive={isLast}
                                    disabled={disabled}
                                    as="button"
                                    onCrumbClick={(e): void => {
                                        e.stopPropagation();
                                        onSelectDestination(
                                            selectedLocation,
                                            "",
                                        );
                                    }}
                                >
                                    {selectedLocation.title}
                                </Crumb>
                            )}
                    </>
                );
            }

            return (
                <Crumb
                    isActive={isLast}
                    disabled={disabled}
                    as="button"
                    index={index}
                >
                    {folder}
                </Crumb>
            );
        },
        [onSelectDestination, selectedLocation],
    );

    const handleCrumbClickPropagation = useCallback(
        (e: SyntheticEvent, pathSplit: string[]): void => {
            const target = e.target as HTMLElement;
            const dataIndex = target.getAttribute("data-index");

            if (dataIndex !== null && dataIndex.length > 0) {
                const folderIndex = parseInt(dataIndex);

                if (folderIndex >= 0) {
                    const newPath =
                        pathSplit.slice(1, folderIndex + 1).join("/") + "/";

                    onSelectDestination(selectedLocation, newPath);
                }
            }
        },
        [onSelectDestination, selectedLocation],
    );

    const onCancel = useCallback((): void => {
        appManager.toggleTab(uploadDestinationTab);
        setSelectedLocation(null);
        setCurrentPath("");
        setFolders([]);
    }, []);

    /** for breadcrumbs and navigation */
    const baseDestinationPath =
        selectedLocation !== null
            ? formUploadDestination(selectedLocation, currentPath)
            : "";

    const destinationPath =
        uploaderConfig.folderNamingConvention.length > 0
            ? `${currentPath}${useTimeFormatter.formatDate(
                  Date.now(),
                  uploaderConfig.folderNamingConvention,
              )}/`
            : currentPath;

    useEffect(() => {
        if (
            predefinedDestination !== null &&
            predefinedDestination !== undefined
        ) {
            const updateFolders = async (): Promise<void> => {
                const folders =
                    (await useUploader.fetchLocationFolders(
                        predefinedDestination.selectedLocation,
                        predefinedDestination.path !== null &&
                            predefinedDestination.path.length > 0
                            ? normalizePath(`${predefinedDestination.path}/`)
                            : "",
                    )) ?? [];
                setFolders(folders);
            };

            updateFolders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (filesSelection.size === 0) {
        return <Text>Please select file(s) to upload</Text>;
    }

    if (locations === null) {
        appManager.toggleTab(uploadDestinationTab);
        useNotification.show("You don't have any upload locations", "error");
        return null;
    }

    return (
        <div className="upload-destination-container">
            {isFetchDestinationFoldersQueryInProgress ? (
                <Loader
                    className="upload-destination-container__loader"
                    text="Fetching folders..."
                />
            ) : (
                <>
                    {selectedLocation !== null ? (
                        <div className="upload-destination-paths-container_breadcrumbs">
                            <header className="upload-destination-header">
                                <Toolbar style={{ padding: 0 }}>
                                    <ToolbarButton
                                        aria-label="Create new folder"
                                        icon={<NewFolderIcon />}
                                        onClick={onCreateFolder}
                                    >
                                        Create new folder
                                    </ToolbarButton>
                                    <ToolbarButton
                                        onClick={(): void => {
                                            onSelectDestination(
                                                selectedLocation,
                                                currentPath,
                                            );
                                        }}
                                        aria-label="Refresh"
                                        icon={<RefreshFolderIcon />}
                                    >
                                        Refresh
                                    </ToolbarButton>
                                </Toolbar>

                                <Divider
                                    style={{
                                        paddingTop: "6px",
                                        paddingBottom: "6px",
                                    }}
                                />

                                <PathBreadcrumbs
                                    path={baseDestinationPath}
                                    handleCrumbClickPropagation={(
                                        e: SyntheticEvent,
                                        pathSplit: string[],
                                    ): void => {
                                        handleCrumbClickPropagation(
                                            e,
                                            pathSplit,
                                        );
                                    }}
                                    renderCrumb={renderCrumb}
                                    disabled={
                                        isFetchDestinationFoldersQueryInProgress
                                    }
                                />
                            </header>

                            <div className="upload-destination-paths-container">
                                {folders.length === 0 ? (
                                    <div>no folders</div>
                                ) : (
                                    folders.map((i, index) => (
                                        <Button
                                            icon={<FolderIcon />}
                                            appearance="subtle"
                                            key={i + index}
                                            className={mergeClasses(
                                                "upload-destination-path-btn",
                                                classes.btn,
                                            )}
                                            onClick={(): void => {
                                                onSelectDestination(
                                                    selectedLocation,
                                                    i,
                                                );
                                            }}
                                        >
                                            {i
                                                .split("/")
                                                .filter(i => i !== "")
                                                .pop()}
                                        </Button>
                                    ))
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>
                            {recentDestinations.length ? (
                                <div>
                                    <Caption1>Upload to recent path</Caption1>

                                    <div>
                                        {recentDestinations.map(
                                            (item, index) => {
                                                const rPath =
                                                    formUploadDestination(
                                                        item.location,
                                                        item.destination,
                                                    );

                                                return (
                                                    <Button
                                                        icon={<LocationIcon />}
                                                        className={mergeClasses(
                                                            "upload-destination-path-btn",
                                                            classes.btn,
                                                        )}
                                                        appearance="subtle"
                                                        key={
                                                            item.destination +
                                                            index
                                                        }
                                                        onClick={(): void => {
                                                            onUpload(
                                                                [
                                                                    ...filesSelection.values(),
                                                                ],
                                                                item.location,
                                                                item.destination,
                                                            );
                                                        }}
                                                        title={rPath}
                                                    >
                                                        <Text>{rPath}</Text>
                                                    </Button>
                                                );
                                            },
                                        )}
                                    </div>
                                    <Divider
                                        style={{
                                            paddingTop: "6px",
                                            paddingBottom: "6px",
                                        }}
                                    />
                                </div>
                            ) : null}

                            {locations.map(i => (
                                <Button
                                    icon={<LocationIcon />}
                                    className={mergeClasses(
                                        "upload-destination-path-btn",
                                        classes.btn,
                                    )}
                                    appearance="subtle"
                                    key={i.id}
                                    onClick={(): void => {
                                        onSelectDestination(i, "");
                                    }}
                                    title={i.title}
                                >
                                    <Text>{i.title}</Text>
                                </Button>
                            ))}
                        </div>
                    )}

                    {selectedLocation !== null && (
                        <div>
                            <Divider
                                style={{
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                }}
                            />

                            <div className="upload-destination-upload-btns">
                                <InfoLabel
                                    info={destinationPath}
                                    className="upload-destination-upload-btn-label"
                                >
                                    <Button
                                        icon={<CloudAddRegular />}
                                        appearance="primary"
                                        onClick={(): void => {
                                            setRecentDestinations(
                                                updateRecentDestinations(
                                                    selectedLocation,
                                                    destinationPath,
                                                ),
                                            );

                                            onUpload(
                                                [...filesSelection.values()],
                                                selectedLocation,
                                                destinationPath,
                                            );
                                        }}
                                    >
                                        Upload here
                                    </Button>
                                </InfoLabel>

                                <Button
                                    appearance="secondary"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default UploadDestination;
